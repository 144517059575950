import Routes from './routes/index';
import './App.scss';
import { useEffect, useState } from 'react';

//toaster
import { ToastContainer, /**toast*/ } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//loader
import { TailSpin } from 'react-loader-spinner';
import PubSub from 'pubsub-js';

function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchLoader();
  }, []);

  const fetchLoader = () => {
    const loaderResponse = PubSub.subscribe('loader', (msg, data) => {
      setLoading(data)
    });
  }

  return (
    <>
      {
        loading && <div className="loader">
          <TailSpin color="#FF6600" height={80} width={80} />
          {/* <TailSpin
            height="80"
            width="80"
            color="#FF6600"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /> */}
        </div>
      }
      <Routes />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />
    </>
  );
}

export default App;

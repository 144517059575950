import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './RejectInterviewFeedback.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import checkBos from '../../../../assets/images/checkNew.svg';
import phoneCall from '../../../../assets/images/selectOp.svg';
import rejectCall from '../../../../assets/images/notAnswer.svg';
import defaultUser from '../../../../assets/images/profile.svg';
import perfectImg from '../../../../assets/images/perfect.svg';
import goodImg from '../../../../assets/images/good.svg';
import averageImg from '../../../../assets/images/average.svg';
import badImg from '../../../../assets/images/bad.svg';
import starImg from '../../../../assets/images/star.svg';
import { useForm } from "react-hook-form";

//components
import TextArea from "../../TextArea/TextArea";
import Popup from "../Popup";
import { toast } from "react-toastify";
import { rejectJob } from "../../../../services/api/common";
import { updateInterviewFeedback } from "../../../../services/api/employer";
import { hideLoader, showLoader } from "../../../../helpers";
import InterviewFeedbackSubmit from "../InterviewFeedbackSubmit/InterviewFeedbackSubmit";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function RejectInterviewFeedback({
    isOpen,
    handleClose,
    data: {
        starRank,
        profilePicUrl,
        candidateName,
        candidateDesignationName,
        jobId,
        candidateId,
        employerId,
        // candidateDetails,
        _id,
        candidateAction,
        interviewId,
        employerAction
    },
    updateStatus,
    handleCallHistory
}) {

    const { register, handleSubmit, /**formState: { errors },*/ reset, setValue } = useForm();

    let formattedRank = starRank ? starRank.split("/") : [];

    const [candidateInteriewVia, setcandidateInteriewVia] = useState();
    const [showForm, setShowForm] = useState(false);
    const [interviewWas, setInterviewWas] = useState();
    const [starRating, setStarRating] = useState(0);
    const [employerReasonsToReject, setEmployerReasonsToReject] = useState({
        locationMismatch: false,
        salaryMismatch: false,
        wrongExpectation: false,
        falseInfo: false,
        candidateMismatch: false,
        candidateNotSerious: false,
        candidateDidnotAttend: false,
        candidateJoinedElsewhere: false,
        candidateNotGood: false
    });
    const [error, setError] = useState(false);
    const [candidateAttended, setCandidateAttended] = useState(false);
    const [rejectError, setRejectError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleInterviewVia = (data, isAttended) => {
        setcandidateInteriewVia(data);
        setCandidateAttended(isAttended);
        if (candidateAttended !== isAttended) {
            setInterviewWas("");
            setStarRating(0);
            setValue('otherReason', '');
            setEmployerReasonsToReject({
                locationMismatch: false,
                salaryMismatch: false,
                wrongExpectation: false,
                falseInfo: false,
                candidateMismatch: false,
                candidateNotSerious: false,
                candidateDidnotAttend: false,
                candidateJoinedElsewhere: false,
                candidateNotGood: false
            });
            setError(false);
            setRejectError(false);
        }
        setShowForm(true);
    }

    const handleClickBack = () => {
        setShowForm(false);
        toast.warning('To move next simply choose again same option or any')
    }

    const handleInterviewWas = (data) => {
        setInterviewWas(data);
    }

    const handleStarRating = (data) => {
        setStarRating(data);
    }

    const handleCheckbox = ({ target: { name, checked } }) => {
        setEmployerReasonsToReject({
            ...employerReasonsToReject,
            [name]: checked
        })
    }

    const handleReset = () => {
        reset();
        setError(false);
        setEmployerReasonsToReject({
            locationMismatch: false,
            salaryMismatch: false,
            wrongExpectation: false,
            falseInfo: false,
            candidateMismatch: false,
            candidateNotSerious: false,
            candidateDidnotAttend: false,
            candidateJoinedElsewhere: false,
            candidateNotGood: false
        });
        setShowForm(false);
        setInterviewWas("");
        setStarRating(0);
        setcandidateInteriewVia("");
        handleClose();
        setIsSuccess(false);
    }

    const submit = async (submitData) => {
        try {

            const { locationMismatch, salaryMismatch, wrongExpectation, falseInfo, candidateMismatch, candidateNotSerious, candidateDidnotAttend, candidateJoinedElsewhere, candidateNotGood } = employerReasonsToReject;

            if ((!locationMismatch && !salaryMismatch && !wrongExpectation && !falseInfo && !candidateMismatch && !candidateNotSerious && !candidateDidnotAttend && !candidateJoinedElsewhere && !candidateNotGood) && (!submitData.otherReason)) {
                if ((candidateAttended && !interviewWas) || !starRating) {
                    setRejectError(true);
                } else {
                    setRejectError(false);
                }
                setError(true);
                return;
            } else {
                setError(false);
                if ((candidateAttended && !interviewWas) || !starRating) {
                    setRejectError(true);
                    return;
                } else {
                    setRejectError(false);
                }

                showLoader();
                submitData.employerId = employerId;
                submitData.jobId = jobId;
                submitData.candidateId = candidateId;
                submitData.whoRejected = 'employer';
                submitData.employerReasonsToReject = employerReasonsToReject;
                submitData.candidateReasonsToReject = {};


                const {
                    data: {
                        /**  data, message*/
                    } } = await rejectJob(submitData);


                hideLoader();
                // toast.success(message ? message : "Success");
                updateInterview(submitData.otherReason);
                setIsSuccess(true);
            }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const updateInterview = async (interviewComments) => {
        try {
            showLoader();
            let submitData = {};
            submitData.candidateInteriewVia = candidateInteriewVia;
            submitData.candidateAttended = candidateAttended;
            submitData.interviewWas = interviewWas;
            submitData.candidateWas = starRating;
            submitData.interviewComments = interviewComments;
            submitData.chanceToHire = 0;
            let params = {
                interviewId: interviewId
            }
            const {
                data: {
                    message
                } } = await updateInterviewFeedback(submitData, params);
            hideLoader();
            toast.success(message ? message : "Success");
            updateStatus(jobId, _id, 'negative', candidateAction, employerAction);
            handleReset();
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
            handleReset();
        }
    }

    return (
        <Popup isOpen={isOpen} isClose={handleReset}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleReset} alt="" />
                <Col md={12} className={styles.newBg}>
                    {
                        !showForm && <Row className={styles.rowMar}>
                            <Col md={12}>
                                <div className={styles.headReject}>
                                    <h3>Interview & Reject Feedback</h3>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={styles.userDetail}>
                                    <div className={styles.rankImg}>
                                        <div className={styles.rankNew}>
                                            <span>{formattedRank && formattedRank.length > 0 && formattedRank[0]}<br></br><span className={styles.slashName}>/</span>{formattedRank && formattedRank.length > 0 && formattedRank[1]}</span>
                                        </div>
                                        <div className={styles.userInfo}>
                                            <img src={profilePicUrl ? profilePicUrl : defaultUser} alt="" />
                                            <h3>{candidateName}<span>{candidateDesignationName}</span></h3>
                                        </div>
                                    </div>
                                    <div className={styles.callHistory}>
                                        <div className={styles.btnNew} onClick={handleCallHistory}>
                                            <div className={styles.effNew}></div>
                                            <button>Call  history<span></span></button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={styles.selectOpt}>
                                    <div className={styles.interviewFeedOp}>
                                        <ul>
                                            <li className={styles.marRight}>
                                                {candidateInteriewVia === 'Phone Call' && candidateAttended && <img className={styles.checkImage} src={checkBos} alt="" />}
                                                <a
                                                    onClick={() => handleInterviewVia('Phone Call', true)}
                                                    className={(candidateInteriewVia === 'Phone Call' && candidateAttended) ? styles.phoneCallSelected : " "}>
                                                    <img className={styles.phoneCall} src={phoneCall} alt="phoneCall" />
                                                    Yes, via Phone Call
                                                </a>
                                            </li>
                                            <li>
                                                {candidateInteriewVia === 'Office' && candidateAttended && <img className={styles.checkImage} src={checkBos} alt="" />}
                                                <a
                                                    onClick={() => handleInterviewVia('Office', true)}
                                                    className={(candidateInteriewVia === 'Office' && candidateAttended) ? styles.phoneCallSelected : ""}>
                                                    <img className={styles.phoneCall} src={phoneCall} alt="" />
                                                    Yes, attended physically
                                                </a>
                                            </li>
                                            <li className={styles.marRight}>
                                                {candidateInteriewVia === 'Phone Call' && !candidateAttended && <img className={styles.checkImage} src={checkBos} alt="" />}
                                                <a
                                                    onClick={() => handleInterviewVia('Phone Call', false)}
                                                    className={candidateInteriewVia === 'Phone Call' && !candidateAttended ? styles.didShowupSelected : ""}>
                                                    <img className={styles.didShowup} src={rejectCall} alt="" />
                                                    No! The candidate didn’t answer the call
                                                </a>
                                            </li>
                                            <li>
                                                {candidateInteriewVia === 'Office' && !candidateAttended && <img className={styles.checkImage} src={checkBos} alt="" />}
                                                <a
                                                    onClick={() => handleInterviewVia('Office', false)}
                                                    className={(candidateInteriewVia === 'Office' && !candidateAttended) ? styles.didShowupSelected : ""}>
                                                    <img className={styles.didShowup} src={rejectCall} alt="" />
                                                    No! The candidate didn’t show up
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    }
                    {showForm && <Row className={styles.rowMar}>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>What do you think about the candidate</h3>
                            </div>
                        </Col>
                        {/* <Col md={12}>
                            <div className={styles.userDetail}>
                                <div className={styles.rankImg}>
                                    <div className={styles.rankNew}>
                                        <span>{formattedRank && formattedRank.length > 0 && formattedRank[0]}<br></br><span className={styles.slashName}>/</span>{formattedRank && formattedRank.length > 0 && formattedRank[1]}</span>
                                    </div>
                                    <div className={styles.userInfo}>
                                        <img src={profilePicUrl ? profilePicUrl : defaultUser} />
                                        <h3>{candidateName}<span>{candidateDesignationName}</span></h3>
                                    </div>
                                </div>
                                <div className={styles.callHistory}>
                                    <div className={styles.btnNew}>
                                        <div className={styles.effNew}></div>
                                        <button>Call  history<span></span></button>
                                    </div>
                                </div>
                            </div>
                        </Col> */}
                        <Col md={12}>
                            {candidateAttended && <div className={styles.howInterview}>
                                <label>How was the interview?</label>
                                <ul>
                                    <li>
                                        {interviewWas === 'Perfect' && <img className={styles.checkImage} src={checkBos} alt="" />}
                                        <a
                                            onClick={() => handleInterviewWas('Perfect')}
                                            className={interviewWas === 'Perfect' ? styles.perfectImgSelected : ""}>
                                            <img className={styles.perfectImg} src={perfectImg} alt="" />
                                            Perfect
                                        </a>
                                    </li>
                                    <li>
                                        {interviewWas === 'Good' && <img className={styles.checkImage} src={checkBos} alt="" />}
                                        <a
                                            onClick={() => handleInterviewWas('Good')}
                                            className={interviewWas === 'Good' ? styles.goodImgSelected : ""}>
                                            <img className={styles.goodImg} src={goodImg} alt="" />
                                            Good
                                        </a>
                                    </li>
                                    <li>
                                        {interviewWas === 'Average' && <img className={styles.checkImage} src={checkBos} alt="" />}
                                        <a
                                            onClick={() => handleInterviewWas('Average')}
                                            className={interviewWas === 'Average' ? styles.averageImgSelected : ""}>
                                            <img className={styles.averageImg} src={averageImg} alt="" />
                                            Average
                                        </a>
                                    </li>
                                    <li>
                                        {interviewWas === 'Bad' && <img className={styles.checkImage} src={checkBos} alt="" />}
                                        <a
                                            onClick={() => handleInterviewWas('Bad')}
                                            className={interviewWas === 'Bad' ? styles.badImgSelected : ""}>
                                            <img className={styles.badImg} src={badImg} alt="" />
                                            Bad
                                        </a>
                                    </li>

                                </ul>
                                {rejectError && !interviewWas && <p className="errorMessage mt-1 mb-0">Required</p>}
                            </div>}
                            <div className={styles.rateCandidate}>
                                <label>Rate the Candidate</label>
                                <ul>
                                    <li>
                                        <a
                                            onClick={() => handleStarRating(1)}
                                            className={starRating >= 1 ? styles.startImgSelected : ""}>
                                            <img src={starImg} alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => handleStarRating(2)}
                                            className={starRating >= 2 ? styles.startImgSelected : ""}>
                                            <img src={starImg} alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => handleStarRating(3)}
                                            className={starRating >= 3 ? styles.startImgSelected : ""}>
                                            <img src={starImg} alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => handleStarRating(4)}
                                            className={starRating >= 4 ? styles.startImgSelected : ""}>
                                            <img src={starImg} alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => handleStarRating(5)}
                                            className={starRating >= 5 ? styles.startImgSelected : ""}>
                                            <img src={starImg} alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => handleStarRating(6)}
                                            className={starRating >= 6 ? styles.startImgSelected : ""}>
                                            <img src={starImg} alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => handleStarRating(7)}
                                            className={starRating >= 7 ? styles.startImgSelected : ""}>
                                            <img src={starImg} alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => handleStarRating(8)}
                                            className={starRating >= 8 ? styles.startImgSelected : ""}>
                                            <img src={starImg} alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => handleStarRating(9)}
                                            className={starRating >= 9 ? styles.startImgSelected : ""}>
                                            <img src={starImg} alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => handleStarRating(10)}
                                            className={starRating === 10 ? styles.startImgSelected : ""}>
                                            <img src={starImg} alt="" />
                                        </a>
                                    </li>
                                </ul>
                                {rejectError && !starRating && <p className="errorMessage mt-1 mb-0">Required</p>}
                            </div>

                            <div className={styles.chanceHire}>
                                <label>Why do you want to reject the candidate</label>
                                <div className={styles.newSelect}>
                                    <ul>
                                        <li>
                                            <label htmlFor="locationMismatch">Location mismatch</label>
                                            <label className={styles.checkbox}>
                                                <input
                                                    type="checkbox"
                                                    id="locationMismatch"
                                                    name="locationMismatch"
                                                    className={styles.checkboxInput}
                                                    checked={employerReasonsToReject.locationMismatch}
                                                    onChange={handleCheckbox}
                                                />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="salaryMismatch">Salary mismatch</label>
                                            <label className={styles.checkbox}>
                                                <input
                                                    type="checkbox"
                                                    id="salaryMismatch"
                                                    name="salaryMismatch"
                                                    className={styles.checkboxInput}
                                                    checked={employerReasonsToReject.salaryMismatch}
                                                    onChange={handleCheckbox}
                                                />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="wrongExpectation">Wrong expectation</label>
                                            <label className={styles.checkbox}>
                                                <input
                                                    type="checkbox"
                                                    id="wrongExpectation"
                                                    name="wrongExpectation"
                                                    className={styles.checkboxInput}
                                                    checked={employerReasonsToReject.wrongExpectation}
                                                    onChange={handleCheckbox}
                                                />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="falseInfo">False informations</label>
                                            <label className={styles.checkbox}>
                                                <input
                                                    type="checkbox"
                                                    id="falseInfo"
                                                    name="falseInfo"
                                                    className={styles.checkboxInput}
                                                    checked={employerReasonsToReject.falseInfo}
                                                    onChange={handleCheckbox}
                                                />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="candidateMismatch">Candidate/ Profile mismatch</label>
                                            <label className={styles.checkbox}>
                                                <input
                                                    type="checkbox"
                                                    id="candidateMismatch"
                                                    name="candidateMismatch"
                                                    className={styles.checkboxInput}
                                                    checked={employerReasonsToReject.candidateMismatch}
                                                    onChange={handleCheckbox}
                                                />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="candidateNotSerious">Candidate not serious</label>
                                            <label className={styles.checkbox}>
                                                <input
                                                    type="checkbox"
                                                    id="candidateNotSerious"
                                                    name="candidateNotSerious"
                                                    className={styles.checkboxInput}
                                                    checked={employerReasonsToReject.candidateNotSerious}
                                                    onChange={handleCheckbox}
                                                />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="candidateDidnotAttend">Candidate didn’t attend the interview</label>
                                            <label className={styles.checkbox}>
                                                <input
                                                    type="checkbox"
                                                    id="candidateDidnotAttend"
                                                    name="candidateDidnotAttend"
                                                    className={styles.checkboxInput}
                                                    checked={employerReasonsToReject.candidateDidnotAttend}
                                                    onChange={handleCheckbox}
                                                />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="candidateJoinedElsewhere">Candidate already joined elsewhere</label>
                                            <label className={styles.checkbox}>
                                                <input
                                                    type="checkbox"
                                                    id="candidateJoinedElsewhere"
                                                    name="candidateJoinedElsewhere"
                                                    className={styles.checkboxInput}
                                                    checked={employerReasonsToReject.candidateJoinedElsewhere}
                                                    onChange={handleCheckbox}
                                                />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="candidateNotGood">Candidate not good enough</label>
                                            <label className={styles.checkbox}>
                                                <input
                                                    type="checkbox"
                                                    id="candidateNotGood"
                                                    name="candidateNotGood"
                                                    className={styles.checkboxInput}
                                                    checked={employerReasonsToReject.candidateNotGood}
                                                    onChange={handleCheckbox}
                                                />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className={`${styles.FormGroup} mb-1`}>
                                <label>Any additional information you want to share</label>
                                <TextArea
                                    name="otherReason"
                                    placeholder="Write your comment here"
                                    maxLength='300'
                                    register={register}
                                />
                            </div>
                            {error && <p className="errorMessage mt-0 mb-0">Select any option to reject or enter the reason</p>}
                        </Col>

                        <Col md={12}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnCancel} onClick={handleClickBack}>
                                    <div className={styles.btnEff}></div>
                                    <button>Back <span></span></button>
                                </div>
                                <div className={styles.btnReset} onClick={handleSubmit(submit)}>
                                    <div className={styles.effReset}></div>
                                    <button type="submit">Send feedback<span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>}
                </Col>
            </div>
            <InterviewFeedbackSubmit
                isOpen={isSuccess}
                handleClose={handleReset}
            />
        </Popup>
    );
}







import { axiosInstance } from "../axiosInstance";
import { endPoints } from "../endPoints";

export const createEmployerSubscription = (data) => {
  return axiosInstance({
    method: "post",
    url: endPoints.subscription.createEmployerSubscription,
    data
  });
};

export const subscriptionTransactionEnd = (data) => {
  return axiosInstance({
    method: "post",
    url: endPoints.subscription.subscriptionTransactionEnd,
    data
  });
};
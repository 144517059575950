// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeJobStatus_jobStatus__r9jVS {
  background: #222E4E;
  float: right;
  display: inline-block;
  padding: 6px 10px;
  border-radius: 5px;
}
.ChangeJobStatus_jobStatus__r9jVS p {
  color: #B0B3B7;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 0px;
}
.ChangeJobStatus_jobStatus__r9jVS .ChangeJobStatus_dropdown__Y70aM {
  display: inline-block;
  margin-left: 20px;
  width: 130px;
  border: 1px solid #ccc;
  background-color: #455377;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}
.ChangeJobStatus_jobStatus__r9jVS .ChangeJobStatus_dropdown__Y70aM options {
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ChangeJobStatus/ChangeJobStatus.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AACI;EACI,cAAA;EACA,eAAA;EACA,qBAAA;EACA,kBAAA;AACR;AAEI;EACI,qBAAA;EACA,iBAAA;EACA,YAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,yBAAA;AAAR;AAEQ;EACI,eAAA;AAAZ","sourcesContent":[".jobStatus {\n    background: #222E4E;\n    float: right;\n    display: inline-block;\n    padding: 6px 10px;\n    border-radius: 5px;\n\n    p {\n        color: #B0B3B7;\n        font-size: 12px;\n        display: inline-block;\n        margin-bottom: 0px;\n    }\n\n    .dropdown {\n        display: inline-block;\n        margin-left: 20px;\n        width: 130px;\n        border: 1px solid #ccc;\n        background-color: #455377;\n        border-radius: 5px;\n        font-weight: 400;\n        font-size: 0.875rem;\n        line-height: 1.43;\n        letter-spacing: 0.01071em;\n\n        options {\n            font-size: 13px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jobStatus": `ChangeJobStatus_jobStatus__r9jVS`,
	"dropdown": `ChangeJobStatus_dropdown__Y70aM`
};
export default ___CSS_LOADER_EXPORT___;

// import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './CustomQuote.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import SmarySec from '../../../../assets/images/jobPost.svg';


import Popup from "../Popup";

export default function CustomQuote({
    isOpen,
    handleClose,
    selectedJobId
}) {

    const viewSummary = () => {
        window.location.href = `/jobs/edit/${selectedJobId}`
    }
    return (
        <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleClose} alt="" />
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <img src={SmarySec} alt="" />

                                <p>Email sent successfully We will contact you shortly</p>
                            </div>
                        </Col>

                        <Col md={12}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnReset} onClick={handleClose}>
                                    <div className={styles.effReset}></div>
                                    <button onClick={() => viewSummary()}>View Job Summary<span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div >
        </Popup >
    );
}







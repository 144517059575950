// import { useState, useEffect } from "react";
// import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './MatchRight.module.scss';
import ShortlistImage from '../../../../../assets/images/shortlist.svg';


export default function MatchExpandedRight({type}) {
  let buttonHeight, buttonWidth;
  if(type === "cardView") {
    buttonHeight = "35px";
    buttonWidth = "120px";
  } else {
    buttonHeight = "38px";
    buttonWidth = "160px";
  }

  return (
    <div className={styles.actionButtons}>
        <div className={styles.ShotlistButton} style={{width: buttonWidth, height: buttonHeight}}>
            <div className={styles.ShotlisteffView}></div>
            <button style={{lineHeight: buttonHeight}}><img src={ShortlistImage} alt='' /> Shortlist<span></span> </button>
        </div>
    </div>
  );
}







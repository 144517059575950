import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './VerifyEmailPhone.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import editvery from '../../../../assets/images/editvery.svg';
import checkBos from '../../../../assets/images/checkNew.svg';
import OtpInput from "otp-input-react";

import Popup from "../Popup";
// import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { useForm } from "react-hook-form";
// import InputField from "../../../common/InputField/InputField";
import TextArea from "../../TextArea/TextArea";
import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import {
//     Duration
// } from '../../../../common/DropdownOptions';
import { hideLoader, showLoader } from "../../../../helpers";
import { deactivateAccount } from "../../../../services/api/employer";
import { toast } from "react-toastify";
import { requestOtpForLogin, verifyOtpEmailPhoneforPreference } from '../../../../services/api/otp';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function VerifyEmailPhone({
    isOpen,
    fullName,
    userId,
    handleClose,
    verifyOption,
    sentOTP,
    userData,
    newEmail,
    newPhone,
    checkUserverified
}) {
    const oldEmail = userData.companyEmail;
    const oldPhone = userData.alternateNo;
    const { register, handleSubmit /** ,formState: { errors }, getValues, control, setValue */ } = useForm();
    const [otpPhoneNumber, setOtpPhoneNumber] = useState("");
    const [otpEmailNumber, setOtpEmailNumber] = useState("");
    const [otpPhoneError, setOtpPhoneError] = useState(false);
    const [otpEmailError, setOtpEmailError] = useState(false);
    const [otpEmailPhoneTiming, setOtpEmailPhoneTiming] = useState(180);
    const [emailPhoneIntervalId, setEmailPhoneIntervalId] = useState(0);
    //handleOtpTiming();
    useEffect(() => {
        if (emailPhoneIntervalId === 0) {
            handleOtpTiming();
        }
        if (emailPhoneIntervalId && otpEmailPhoneTiming <= 0) {
            clearInterval(emailPhoneIntervalId);
            // setOtpPhoneTiming(60);
            // setIntervalId(0);
        }
    }, [otpEmailPhoneTiming])

    const handleOtpChange = (data, type) => {
        if (type === 'phone') {
            setOtpPhoneNumber(data);
            data ? setOtpPhoneError(false) : setOtpPhoneError(true);
        } else {
            setOtpEmailNumber(data);
            data ? setOtpEmailError(false) : setOtpEmailError(true);
        }
    }

    const handleOtpTiming = async () => {
        let interval = setInterval(() => {
            setOtpEmailPhoneTiming(otpTiming => otpTiming - 1);
        }, 1000);
        setEmailPhoneIntervalId(interval);
    }


    const handleResendOtp = (type) => {
        sentOTP();
        setOtpEmailPhoneTiming(180)
    }

    const verifyOtp = async () => {
        if (otpPhoneNumber === "") {
            setOtpPhoneError(true)
        }
        if (otpEmailNumber === "") {
            setOtpEmailError(true)
        }
        if (otpPhoneNumber !== "" && otpEmailNumber !== "") {
            try {
                showLoader();
                const requestObj = {
                    "userType": "employer",
                    "oldEmail": oldEmail,
                    "newEmail": newEmail,
                    "oldWhatsappMobile": oldPhone,
                    "newWhatsappMobile": newPhone,
                    "mobileOtp": otpPhoneNumber,
                    "emailOtp": otpEmailNumber
                }
                let {
                    data: {
                        data, message
                    }
                } = await verifyOtpEmailPhoneforPreference(requestObj);

                checkUserverified(true);
                handleClose();
                toast.success(message ? message : "Success");
                hideLoader();
            } catch (error) {
                const {
                    data: {
                        message
                    }
                } = error;
                hideLoader();
                toast.error(message ? message : "Failed");
            }
        }
    }

    const editOption = () => {
        handleClose();

    }

    const phoneText = oldPhone === newPhone ? `Enter OTP sent to your registered phone number.` : `Enter OTP sent to your new phone number.`
    const emailText = oldEmail === newEmail ? `Enter OTP from your registered email ID.` : `Enter OTP from your new email ID.`
    return (
        <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleClose} alt='' />
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Enter Verification Code <span></span></h3>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={styles.checkItm}>
                                <p>We have just sent 2 OTPs, one to your email and one to your new phone number</p>
                            </div>
                        </Col>
                        <Col md={12}>

                            <div className={styles.checkItmne}>
                                {oldPhone === newPhone ? <p>Your new email ID</p> : <p>Your new mobile number</p>}
                                <b>{verifyOption} <img src={editvery} onClick={() => editOption()} /></b>
                            </div>
                            <div className={styles.inputcode}>
                                <div className={styles.mobileOtp}>
                                    <p>{phoneText}</p>
                                    <div className={styles.inpOtp}>
                                        <OtpInput
                                            value={otpPhoneNumber}
                                            onChange={(e) => handleOtpChange(e, "phone")}
                                            autoFocus
                                            OTPLength={6}
                                            otpType="number"
                                            disabled={false}
                                            placeholder="------"
                                        />

                                        {otpPhoneError && <span className='errorMessage mt-3'>Phone Otp number is required</span>}

                                    </div>
                                </div>
                                <div className={styles.emailOtp}>
                                    <p>{emailText}</p>
                                    <div className={styles.inpOtp}>
                                        <OtpInput
                                            value={otpEmailNumber}
                                            onChange={(e) => handleOtpChange(e, "email")}
                                            OTPLength={6}
                                            otpType="number"
                                            disabled={false}
                                            placeholder="------"
                                        />

                                        {otpEmailError && <span className='errorMessage mt-3'>Email Otp number is required</span>}

                                    </div>
                                </div>
                                <div className={styles.emailOtp}>

                                    <p>Haven’t receive the code?&nbsp;
                                        <a href="javascript:void(0);" className={styles.resendOtp} onClick={() => handleResendOtp("email")}>Resend Otp</a>&nbsp;
                                        for email and phone number {otpEmailPhoneTiming !== 0 && <span className={styles.newtime}>{otpEmailPhoneTiming}</span>}</p>

                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={styles.btnNew}>
                                <button className={styles.btnCancel} type="button" onClick={() => { handleClose() }}>Cancel<span></span></button>
                                <button className={styles.btnReset} type="button" onClick={() => verifyOtp()}>Verify<span></span></button>
                            </div>
                        </Col>

                    </Row>
                </Col>
            </div >
        </Popup >
    );
}







// import { useState, useEffect } from "react";
// import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './RejectLarge.module.scss';
import RejectImage from '../../../../assets/images/newReject.svg';


export default function RejectLarge(/**{
  children,
  isOpen,
  isClose
 }*/) {

  return (
    <div className={styles.paddLeft}>
        <div className={styles.tooltipcss}>
            <span tooltip="Reject" flow="right">
                <img src={RejectImage} alt='' />
            </span>
        </div>
    </div>
  );
}







// import { style } from "@mui/system";
import { Col, Container, Row } from "react-bootstrap"
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
// import SubHeader from '../../common/SubHeader/SubHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './JobOfferCardView.module.scss';
import previewExpand from '../../../assets/images/previewCard.svg';
// import { Insights } from "@mui/icons-material";
// import moment from "moment";




export default function JobOfferCardView() {
  
    return (
        <HomeLayout>
          
          <section className={styles.offerList}>
            {/* <SubHeader /> */}
            <Container fluid className={styles.subHeader}>
                <Row>
                    <Col md={5}>
                        <div className={styles.subHeader}>
                            <h3>3 Offers Accepted <p>(*Click on an offer to get the detail)</p></h3>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                    <div className={styles.offerCard}>
                        <div className={styles.scrollbarContainer}>
                            <div className={styles.scrollContainer}>
                            <Row className={styles.rowMar}>
                                <Col md={4}>
                                    <div className={styles.cardDetail}>
                                        <h3>22nd July 2022</h3>
                                        <img src={previewExpand} alt="" />
                                        <h2>Jobgendra Kumar Sharma</h2>
                                        <p>Position <span>Supervisor</span></p>
                                        <div className={styles.offDet}>
                                            <Row>
                                                <Col md={6}>
                                                    <div className={styles.cntnData}>
                                                        <h3>
                                                            <span>Job City</span>
                                                            Lucknow
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.cntnData}>
                                                        <h3>
                                                            <span>Salary</span>
                                                            ₹ 14,000
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.joinDate}>
                                                        <h3>
                                                            <span>Accepted on</span>
                                                            22nd July 2022
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.joinDate}>
                                                        <h3>
                                                            <span>Joining Date</span>
                                                            17th August 2022
                                                        </h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className={styles.cardDetail}>
                                        <h3>22nd July 2022</h3>
                                        <img src={previewExpand} alt="" />
                                        <h2>Jobgendra Kumar Sharma</h2>
                                        <p>Position <span>Supervisor</span></p>
                                        <div className={styles.offDet}>
                                            <Row>
                                                <Col md={6}>
                                                    <div className={styles.cntnData}>
                                                        <h3>
                                                            <span>Job City</span>
                                                            Lucknow
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.cntnData}>
                                                        <h3>
                                                            <span>Salary</span>
                                                            ₹ 14,000
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.joinDate}>
                                                        <h3>
                                                            <span>Accepted on</span>
                                                            22nd July 2022
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.joinDate}>
                                                        <h3>
                                                            <span>Joining Date</span>
                                                            17th August 2022
                                                        </h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className={styles.cardDetail}>
                                        <h3>22nd July 2022</h3>
                                        <img src={previewExpand} alt="" />
                                        <h2>Jobgendra Kumar Sharma</h2>
                                        <p>Position <span>Supervisor</span></p>
                                        <div className={styles.offDet}>
                                            <Row>
                                                <Col md={6}>
                                                    <div className={styles.cntnData}>
                                                        <h3>
                                                            <span>Job City</span>
                                                            Lucknow
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.cntnData}>
                                                        <h3>
                                                            <span>Salary</span>
                                                            ₹ 14,000
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.joinDate}>
                                                        <h3>
                                                            <span>Accepted on</span>
                                                            22nd July 2022
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.joinDate}>
                                                        <h3>
                                                            <span>Joining Date</span>
                                                            17th August 2022
                                                        </h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className={styles.cardDetail}>
                                        <h3>22nd July 2022</h3>
                                        <img src={previewExpand} alt="" />
                                        <h2>Jobgendra Kumar Sharma</h2>
                                        <p>Position <span>Supervisor</span></p>
                                        <div className={styles.offDet}>
                                            <Row>
                                                <Col md={6}>
                                                    <div className={styles.cntnData}>
                                                        <h3>
                                                            <span>Job City</span>
                                                            Lucknow
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.cntnData}>
                                                        <h3>
                                                            <span>Salary</span>
                                                            ₹ 14,000
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.joinDate}>
                                                        <h3>
                                                            <span>Accepted on</span>
                                                            22nd July 2022
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.joinDate}>
                                                        <h3>
                                                            <span>Joining Date</span>
                                                            17th August 2022
                                                        </h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className={styles.cardDetail}>
                                        <h3>22nd July 2022</h3>
                                        <img src={previewExpand} alt="" />
                                        <h2>Jobgendra Kumar Sharma</h2>
                                        <p>Position <span>Supervisor</span></p>
                                        <div className={styles.offDet}>
                                            <Row>
                                                <Col md={6}>
                                                    <div className={styles.cntnData}>
                                                        <h3>
                                                            <span>Job City</span>
                                                            Lucknow
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.cntnData}>
                                                        <h3>
                                                            <span>Salary</span>
                                                            ₹ 14,000
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.joinDate}>
                                                        <h3>
                                                            <span>Accepted on</span>
                                                            22nd July 2022
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.joinDate}>
                                                        <h3>
                                                            <span>Joining Date</span>
                                                            17th August 2022
                                                        </h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
            </Container>
          </section>
          
        </HomeLayout>

    )
}
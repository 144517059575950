import { Col, Row } from "react-bootstrap";
import styles from './JobOfferSpecialization.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import Popup from "../Popup";
import { useEffect, useState } from "react";
import checkSpeci from '../../../../assets/images/chcekSpec.svg';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function JobOfferSpecialization({
    isOpen,
    handleClose,
    data,
    // jobPosition,
    handleSpecialization
}) {
    const [optionData, setOptionData] = useState([]);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setOptionData(data);
    }, [data])

    const handleOption = (index, isSelected) => {

        let array = optionData.filter(i => i.isSelected === true);
        if (!isSelected && array.length <= 4) {
            optionData[index]['isSelected'] = true;
            setIsError(false);
        } else if (isSelected) {
            optionData[index]['isSelected'] = false;
        }
        setOptionData([...optionData]);
    }

    const handleSubmit = () => {
        let array = optionData.filter(i => i.isSelected === true);
        if (array.length === 0) {
            setIsError(true);
        } else {
            handleSpecialization(optionData);
            handleClose();
        }
    }

    const cancel = () => {
        let array = optionData.filter(i => i.isSelected === true);
        console.log("optionData----------->", array)
        if (array.length === 0) {
            setIsError(true)
        } else {
            handleClose();
        }
    }

    return (
        <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleClose} alt="" />
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Specializations<span></span></h3>
                                <p>You can only select <span>5</span>, so select the best ones</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className={styles.formBg}>
                        <Col md={12}>
                            <div className={styles.specList}>
                                <h3>Teacher Computer</h3>
                                <div className={styles.specBox}>
                                    <ul>
                                        {optionData && optionData.length > 0 && optionData.map(({ label, isSelected }, index) => {
                                            return (
                                                // <li 
                                                // key={index} 
                                                // className={isSelected ? styles.selectSpec : ""}
                                                // onClick={()=>handleOption(index)}
                                                // >
                                                //     {label}
                                                // </li>
                                                isSelected ? (<li key={index} onClick={() => handleOption(index, isSelected)} className={styles.selectSpec}>{label} <img src={checkSpeci} alt="" /></li>) :
                                                    (<li key={index} onClick={() => handleOption(index, isSelected)}>{label} </li>)
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        {isError && (
                            <span className="errorMessage">Select atleast one option.</span>
                        )}

                        <Col md={12}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnCancel} onClick={() => cancel()}>
                                    <div className={styles.effNew}></div>
                                    <button>CANCEL <span></span></button>
                                </div>
                                <div className={styles.btnReset} onClick={handleSubmit}>
                                    <div className={styles.effReset}></div>
                                    <button>Submit<span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}







import { React } from 'react';
// import { StylesContext } from "@material-ui/styles";
// import { style } from "@mui/system";
import { Col, /**Container, FormGroup,*/ Row } from 'react-bootstrap';
import styles from './CandidateCardView.module.scss';
import defaultUser from '../../../assets/images/profile.svg';
import RejectImage from '../../../assets/images/newReject.svg';
// import ShortlistImage from '../../../assets/images/shortlist.svg';
// import videoIcon from '../../../assets/images/videoIcon.svg';
// import userClose from '../../../assets/images/close.svg';
// import userCheck from '../../../assets/images/check.svg';
import downArrow from '../../../assets/images/downArrow.svg';

// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Typography from '@mui/material/Typography';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleCheck, faCircleXmark, faPencil, faUser, faUsers, faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { currencySymbol } from "../../../common/CurrencySymbol";
import CandidateExpandedView from "../CandidateExpandedView/CandidateExpandedVIew";
import moment from 'moment';
//import { toast } from 'react-toastify';
import WaitForCandidateCardView from '../../common/Buttons/ScheduleButton/WaitForcandidate/WaitForCandidateCardView';
import { checkIsInterviewTimePassed, getLocalTimeFormatted } from '../../../helpers';
import InterviewAcceptedExpend from '../../common/Buttons/ScheduleButton/InterviewAccepted/InterviewAcceptedExpend';
import InterviewCompletedExpendRight from '../../common/Buttons/ScheduleButton/InterviewCompleted/InterviewCompletedExpendRight';
import CandidateCallExpendRight from '../../common/Buttons/ScheduleButton/CandidateCall/CandidateCallExpendRight';
import WaitForCandidateExpendedView from '../../common/Buttons/ScheduleButton/WaitForcandidate/WaitForcandidateExpendedView';
import JobOfferExpandedRight from '../../common/Buttons/JobOffers/JobOffersRight/JobOfferExpandedRight';
import InterviewAcceptedCard from '../../common/Buttons/ScheduleButton/InterviewAccepted/InterviewAcceptedCard';
import InterviewCompletedCardRight from '../../common/Buttons/ScheduleButton/InterviewCompleted/InterviewCompletedCardRight';

import ShortlistNormalRight from '../../common/Buttons/ShortlistButton/ShortlistNormalRight/ShortlistNormalRight';
import ShortlistExpandedRight from '../../common/Buttons/ShortlistButton/ShortlistNormalRight/ShortlistExpandedRight';
import InReviewRight from '../../common/Buttons/InReview/InReviewRight/InReviewRight';
import InReviewExpandedRight from '../../common/Buttons/InReview/InReviewRight/InReviewExpandedRight';
import CandidateCallLargeRight from '../../common/Buttons/ScheduleButton/CandidateCall/CandidateCallLargeRight';


export default function CandidateCardView({
    data,
    item,
    isMatched,
    handleExpanded,
    showExpanded,
    value,
    handleDrop,
    onDragOver,
    //handleClick,
    onDragStart,
    // isOpenRejectResponse,
    // handleRejectResponse,
    // isOpenScheduleInterview,
    // handleScheduleInterview,
    // updateStatus,
    path,
    showButton,
    handleInterviewData,
    // setCandidateData,
    // handleWaitingForCandidate,
    // editScheduleInterview,
    handleAction,
    rightButtonExpanded,
    leftButtonExpanded,
    showQuickCallButton,
    handleQuickCall,
    rightButtonCard
}) {

    return (
        <div>
            <Row >


                {data && data.length > 0 && data && data.map((content, /**contentIndex*/) => {
                    return (
                        content && content[item] && content[item].length > 0 && content[item].map((item, dataIndex) => {
                            const { candidateName,
                                candidateExperience,
                                candidateEducation,
                                currentSalary,
                                candidateDesiredSalary,
                                candidateCity,
                                // educationDegree,
                                // educationBranch,
                                profilePicUrl,
                                jobId,
                                _id,
                                candidateAction,
                                starRank,
                                candidateSalaryCurrency,
                                // candidateDesignationName,
                                // candidateId,
                                // employerId,
                                // candidateDetails,
                                employerAction,
                                interviewOccurred,
                                finalDateTimeInterview,
                                //interviewVia,
                                interviewId
                            } = item;
                            
                            let isPointerLabel = (isMatched && dataIndex === 0) || (!isMatched) ? true : false;
                            let formattedRank = starRank ? starRank.split("/") : "";
                            let currency = candidateSalaryCurrency ? currencySymbol[candidateSalaryCurrency] : currencySymbol['INR'];
                            // let scheduleData = {
                            //     starRank, profilePicUrl, candidateName, candidateDesignationName, jobId, candidateId, employerId, candidateDetails, _id, candidateAction, interviewId, employerAction
                            // }
                            let isTimepassed = false;
                            if (finalDateTimeInterview && finalDateTimeInterview.interviewDate && finalDateTimeInterview.interviewTime) {
                                isTimepassed = checkIsInterviewTimePassed(finalDateTimeInterview?.interviewDate, finalDateTimeInterview?.interviewTime);
                            }
                            let statusInfo, cardButton;
                            if (path === 'interview') {
                                if ((candidateAction === 'Apply' && employerAction === 'Interview' && !interviewOccurred) || (candidateAction === 'WaitingToAccept' && employerAction === 'InterviewRescheduled')) {
                                    statusInfo = 'Candidate has not respond to your time, Please wait for some time so the candidate can respond.';
                                    cardButton = <WaitForCandidateCardView />
                                    rightButtonExpanded = <WaitForCandidateExpendedView />;
                                } else if (candidateAction === 'InterviewRescheduled' && (employerAction === 'WaitingToAccept' || employerAction === 'Interview')) {
                                    statusInfo = 'New interview date and time has been sent to you by the candidate, Please check your availability.'
                                    rightButtonExpanded = <InterviewAcceptedExpend />;
                                    cardButton = <InterviewAcceptedCard />;
                                } else if ((candidateAction === 'InterviewAccepted' || candidateAction === 'OfferAccepted' || employerAction === 'InterviewAccepted') && !interviewOccurred) {
                                    statusInfo = isTimepassed ? 'Your interview call time it’s time for you to take the interview of this candidate.' : 'Interview has been set, date and time has been accepted for the interview.';
                                    if (employerAction === "InReview" && candidateAction !== 'OfferAccepted') {
                                        cardButton = <InReviewRight />
                                        rightButtonExpanded = <InReviewExpandedRight type="expandedView" />
                                    } else if (candidateAction === 'OfferAccepted') {
                                        statusInfo = 'Your interview call time it’s time for you to take the interview of this candidate.';
                                        cardButton = <CandidateCallExpendRight type="cardView" />;
                                        rightButtonExpanded = <CandidateCallExpendRight type="expandedView" />;
                                    } else {
                                        rightButtonExpanded = isTimepassed ? <CandidateCallExpendRight type="expandedView" /> : <InterviewAcceptedExpend />;
                                        cardButton = isTimepassed ? <CandidateCallExpendRight type="cardView" /> : <InterviewAcceptedExpend />;
                                    }
                                } else if (interviewOccurred) {
                                    if (employerAction === "InReview") {
                                        statusInfo = 'How was the interview, please tell us a few details about the interview.';
                                        rightButtonExpanded = <InReviewExpandedRight type="expandedView" />
                                        cardButton = <InReviewExpandedRight type="expandedView" />
                                    } else {
                                        statusInfo = 'How was the interview, please tell us a few details about the interview.';
                                        rightButtonExpanded = <InterviewCompletedExpendRight />;
                                        cardButton = <InterviewCompletedCardRight />;
                                    }
                                } else if (employerAction === 'Shortlist' && candidateAction === 'Apply') {
                                    statusInfo = 'Yet to interview';
                                    cardButton = <ShortlistExpandedRight type="expandedView" />
                                    rightButtonExpanded = <ShortlistNormalRight />
                                    //rightButtonCard = <ShortlistExpandedRight type="cardView" />
                                }
                            }
                            else if (path === "joboffers") {
                                if (candidateAction === "OfferAccepted" && employerAction === "JobOffer") {
                                    cardButton = <JobOfferExpandedRight type="cardView" />
                                    rightButtonExpanded = <JobOfferExpandedRight type="expandedView" />;
                                } else {
                                    cardButton = <WaitForCandidateCardView />
                                    rightButtonExpanded = <WaitForCandidateExpendedView />;
                                }
                            } else {
                                cardButton = rightButtonCard;
                            }
                            return (
                                !showExpanded[`tab${value}[${dataIndex}]`] ? (
                                    <Col md={4} className={`${styles.paddNewSIde} ${(isMatched && dataIndex !== 0) ? 'blur' : ""}`} key={dataIndex}>
                                        <div className={styles.cardCandidate}>
                                            {path === 'interview' && <div className={styles.statusCandidate}>
                                                <h3><span>Status:</span> {statusInfo}</h3>
                                            </div>}

                                            <div className={styles.profileRank}>
                                                <div className={`${styles.openArrow} ${isPointerLabel ? 'pointer' : ''}`} onClick={() => handleExpanded(dataIndex)}>
                                                    <img src={downArrow} alt='' />
                                                </div>
                                                {formattedRank?.length > 0 && <div className={styles.rankNew}>
                                                    <span>{formattedRank[0]}<br></br><span className={styles.slashName}>/</span>{formattedRank[1]}</span>
                                                </div>}
                                                <div className={styles.nameCandi}>
                                                    <img src={profilePicUrl ? profilePicUrl : defaultUser} alt='' />
                                                    <h3>{candidateName}</h3>
                                                </div>
                                            </div>
                                            <Col md={12} className={styles.detailInfo}>
                                                <Row>
                                                    <Col md={6} className={styles.paddNew}>
                                                        <div className={styles.dataInfo}>
                                                            <h3>
                                                                <span>Experience </span>
                                                                {candidateExperience}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={styles.dataInfo}>
                                                            <h3>
                                                                <span>Education </span>
                                                                {candidateEducation}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={styles.dataInfo}>
                                                            <h3>
                                                                <span>Current Salary </span>
                                                                {currentSalary ? `${currency} ${currentSalary}` : "-"}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={styles.dataInfo}>
                                                            <h3>
                                                                <span>Expected Salary  </span>
                                                                {candidateDesiredSalary ? `${currency} ${candidateDesiredSalary}` : "-"}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className={styles.dataInfo}>
                                                            <h3>
                                                                <span>City </span>
                                                                {candidateCity}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    {path === 'interview' && finalDateTimeInterview && <div class={styles.interviewHis}>
                                                        <div class={styles.interviewList}>
                                                            <h3>
                                                                <span>Interview</span>
                                                                <p><span>Date: </span>
                                                                    {finalDateTimeInterview?.interviewDate ? (moment(finalDateTimeInterview.interviewDate).format("DD MMM`YY")) : '-'}</p>
                                                                | <p><span>Time: </span>
                                                                    {finalDateTimeInterview?.interviewTime ? getLocalTimeFormatted(finalDateTimeInterview.interviewTime) : '-'}</p>
                                                                | <p><span>Duration: </span>
                                                                    {finalDateTimeInterview?.interviewLength}</p>
                                                                | <p><span>Interview: </span>On Call</p>
                                                            </h3>
                                                        </div>
                                                        <div class={styles.btnNew}>
                                                            <div class={styles.effNew}></div>
                                                            <button onClick={() => handleInterviewData(interviewId, item)}>Interview history<span></span></button>
                                                        </div>
                                                    </div>}
                                                </Row>
                                            </Col>
                                            {showButton && <Col md={12} className={styles.contentButtonBorder}>
                                                <div className={styles.actionButtons}>
                                                    {isPointerLabel ? (
                                                        <div className={styles.btnNew}
                                                            onDrop={handleDrop}
                                                            onDragOver={onDragOver}
                                                            id="negative"
                                                            onClick={() => handleAction(item, _id, jobId, candidateAction, 'negative', employerAction, interviewId, finalDateTimeInterview, interviewOccurred, isTimepassed)}
                                                        >
                                                            <div className={styles.effNew}></div>
                                                            <button>
                                                                <img src={RejectImage} alt='' /> Reject<span></span>
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className={`${styles.btnNew}`}>
                                                            <div className={styles.effNew}></div>
                                                            <button>
                                                                <img src={RejectImage} alt='' /> Reject<span></span>
                                                            </button>
                                                        </div>
                                                    )}
                                                    {isPointerLabel ? (
                                                        <div className={styles.posId}
                                                            onDrop={handleDrop}
                                                            onDragOver={onDragOver}
                                                            id="positive"
                                                            onClick={() => handleAction(item, _id, jobId, candidateAction, 'positive', employerAction, interviewId, finalDateTimeInterview, interviewOccurred, isTimepassed)}
                                                        >
                                                            {cardButton}
                                                        </div>
                                                    ) : (
                                                        <div className={`${styles.posId}`}>
                                                            {cardButton}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>}
                                        </div>
                                    </Col>
                                ) : (
                                    <CandidateExpandedView
                                        data={item}
                                        dataIndex={dataIndex}
                                        onDragOver={onDragOver}
                                        onDragStart={onDragStart}
                                        handleExpanded={handleExpanded}
                                        handleDrop={handleDrop}
                                        currency={currency}
                                        handleAction={handleAction}
                                        path={path}
                                        showButton={showButton}
                                        statusInfo={statusInfo}
                                        handleInterviewData={handleInterviewData}
                                        rightButtonExpanded={rightButtonExpanded}
                                        leftButtonExpanded={leftButtonExpanded}
                                        isTimepassed={isTimepassed}
                                        showQuickCallButton={((employerAction === 'Shortlist' && candidateAction === 'Apply') || (employerAction === 'InReview')) ? false : showQuickCallButton}
                                        handleQuickCall={handleQuickCall}
                                    />
                                )
                            )
                        })
                    )
                })
                }
            </Row>

        </div>
    )
}
import { axiosInstance } from "../axiosInstance";
import { endPoints } from "../endPoints";

export const getAllJobs = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.employer.getJobs, params });
};

export const getProfile = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.employer.getProfile, params });
};

export const createJob = (data) => {
  return axiosInstance({ method: "post", url: endPoints.employer.createJob, data });
};

export const cronNewJob = (data) => {
  return axiosInstance({ method: "post", url: `${endPoints.common.cronNewJob}/{jobId}?jobId=${data}`, data });
};


export const viewJob = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.employer.viewJob, params });
};

export const updateJob = (data, params = {}) => {
  return axiosInstance({ method: "put", url: endPoints.employer.editJob, data, params });
};

export const createInterview = (data) => {
  return axiosInstance({ method: "post", url: endPoints.employer.createInterview, data });
};

export const updateInterviewFeedback = (data, params = {}) => {
  return axiosInstance({ method: "put", url: endPoints.employer.updateInterviewFeedback, data, params });
};

export const viewCandidate = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.employer.viewCandidate, params });
};

export const createJobOffer = (data) => {
  return axiosInstance({ method: "post", url: endPoints.employer.createJobOffer, data });
};

export const employerRegister = (data) => {
  return axiosInstance({ method: "post", url: endPoints.employer.register, data });
};

export const employerOnBoard = (data, params = {}) => {
  return axiosInstance({ method: "put", url: endPoints.employer.onBoard, data, params });
};

export const updateProfile = (data, params = {}) => {
  return axiosInstance({ method: "put", url: endPoints.employer.updateProfile, data, params });
};

export const deactivateAccount = (data, params = {}) => {
  return axiosInstance({ method: "put", url: endPoints.employer.deactivateAccount, data, params });
};

export const getDashboard = (params = {}) => {
  return axiosInstance({ method: "get", url: endPoints.employer.getDashboard, params });
};
// import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './MultiMediaPlayer.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
// import SmarySec from '../../../../assets/images/smartsec.svg';
import Popup from "../Popup";

export default function MultiMediaPlayer({
    isOpen,
    handleClose,
    source,
    fileName,
    title
}) {
    let image = ['jpg', 'jpeg', 'jfif', 'pjpeg', 'png'];
    let video = ['mp4'];
    let pdf = ['pdf'];
    let name = fileName?.split(".") ?? [];
    let type = name?.length > 0 && image.includes(name[1]) ? 'image' : video.includes(name[1]) ? 'video' : pdf.includes(name[1]) ? 'document' : "";

    return (
        <Popup isOpen={isOpen} isClose={handleClose} >
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleClose} alt=""/>
                <Col md={12} className={styles.newBg}>
                   {title ? (<Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>{title}<span></span></h3>
                            </div>
                        </Col>
                    </Row>) : ("")
                    }
                    <Row>
                        <Col md={12}>
                            <div className={styles.videoCom}>
                                {
                                    name?.length !== 0 && type === 'video' ? (
                                        <video className={styles.videoUi} width="720" height="500" controls>
                                            <source src={source} type="video/mp4" />
                                        </video>
                                    ) : name?.length !== 0 && type === 'image' ? (
                                        <img src={source} className={styles.videoUi} width="700" height="500" alt="" />
                                    ) : (
                                        <p>Not found</p>
                                    )
                                }

                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    )
    // }

}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popup_popupBox__gcmI9 .Popup_saveJobCont__igEe3 {
  width: 700px;
  border-radius: 8px;
  border: solid 1px #3a4f9b !important;
  background-color: #273457 !important;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Popup/Popup.module.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,kBAAA;EACA,oCAAA;EACA,oCAAA;EACA,kBAAA;AAAR","sourcesContent":[".popupBox{\n    .saveJobCont{\n        width: 700px;\n        border-radius: 8px;\n        border: solid 1px #3a4f9b !important;\n        background-color: #273457 !important; \n        position: relative;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupBox": `Popup_popupBox__gcmI9`,
	"saveJobCont": `Popup_saveJobCont__igEe3`
};
export default ___CSS_LOADER_EXPORT___;

// import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './JobPosted.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import SmarySec from '../../../../assets/images/jobPost.svg';


import Popup from "../Popup";
// import { Controller, useForm } from "react-hook-form";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { TextField } from "@mui/material";
// import { getLocalTime } from "../../../../helpers";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function JobPosted({
    isOpen,
    handleClose,
}) {

    return (
        <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleClose} alt=""/>
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <img src={SmarySec} alt=""/>
                                <h3>Job has been posted</h3>
                                <p>New job has been added, now just sit back and relax<br />
                                    till we take it to your live jobs.</p>
                            </div>
                        </Col>

                        <Col md={12}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnReset} onClick={handleClose}>
                                    <div className={styles.effReset}></div>
                                    <button>Ok<span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}







import styles from './InReviewRight.module.scss';
import InReview from '../../../../../assets/images/inReview.svg';


export default function InReviewExpandedRight({type}) {
  let buttonHeight, buttonWidth;
  if(type === "cardView") {
    buttonHeight = "35px";
    buttonWidth = "120px";
  } else {
    buttonHeight = "38px";
    buttonWidth = "160px";
  }

  return (
    <div className={styles.actionButtons}>
       <div className={styles.ShotlistButton} style={{width: buttonWidth, height: buttonHeight}}>
            <div className={styles.effView}></div>
            <button style={{lineHeight: buttonHeight}}><img src={InReview} alt='' /> In Review<span></span> </button>
        </div> 

    </div>
  );
}







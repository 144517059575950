import React from 'react';
import { useEffect, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
const GOOGLE_MAP_API_KEY = "AIzaSyD8KBT1s2sy9Wwh3Ui3MosKSAIsfuGDOOs";



const GoogleAutoComplete = (props) => {
    const { inputName, inputVal, setPlace, setOfficeAddress, styles } = props;
    //const { register } = useForm();
    const placeInputRef = useRef(null);

    useEffect(() => {
        loadGoogleMapScript(() => {
            initPlaceAPI();
        });
    }, [])
    const loadGoogleMapScript = (callback) => {
        if (
            typeof window.google === "object" &&
            typeof window.google.maps === "object"
        ) {
            callback();
        } else {
            const googleMapScript = document.createElement("script");
            googleMapScript.defer = true;
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places&callback=Function.prototype`;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener("load", callback);
        }
    };
    const initPlaceAPI = () => {
        var options = {
            types: ['(cities)'],
            componentRestrictions: {
                country: 'in'
            }
        };
        let autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current);

        new window.google.maps.event.addListener(autocomplete, "place_changed", function () {

            let place = autocomplete.getPlace();
            setPlace({
                address: place.formatted_address,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });
            //autocomplete = null;
            setOfficeAddress(place.formatted_address)
        });
    };

    const enterOfficeAddress = (event) => {
        event.preventDefault();
        setOfficeAddress(event.target.value)
        setTimeout(() => {
            let pacContainer = document.querySelectorAll('.pac-container')[document.querySelectorAll('.pac-container').length - 1];
            let style = pacContainer.getAttribute("style");
            pacContainer.setAttribute("style", style + ";background-color:#6C81D0;color:red;z-index:9999999 !important")
        }, 100);
    }


    return <input ref={placeInputRef} value={inputVal} onFocus={e => enterOfficeAddress(e)} onChange={e => enterOfficeAddress(e)} autoComplete="off" style={{ borderBottom: "1px solid #7784A7" }}
        type="text" name={inputName} placeholder="Enter Your Office Address" className={`${styles}`}
    />
}

export default GoogleAutoComplete;
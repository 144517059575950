import styles from './Sidebar.module.scss';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import CompanyImage from '../../../assets/images/company-logo.jpeg';
import dashboardIcon from '../../../assets/images/dashboardIcon.svg';
import jobsIcon from '../../../assets/images/jobs.svg';
import candidateIcon from '../../../assets/images/candidateIcon.svg';
import offerLetter from '../../../assets/images/offerLetterNav.svg';
import analyticImage from '../../../assets/images/analytic.svg';


import { Collapse } from '@mui/material';
// import { PieChart, Pie, Cell } from "recharts";
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { getProfilesCount } from "../../../services/api/common";
import { getCookie } from "../../../../src/helpers/index"

import { useEffect, useState } from 'react';
const Sidebar = ({
    userData
}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState({
        jobs: false,
        candidates: false,
        offers: false,
        analytics: false
    });
    // let liveJobsCount = 10;
    // let pendingJobsCount = 100;
    //let suspendJobsCount = 500;
    // let closedJobsCount = 200;
    const minimumValue = 0;
    const maximumValue = 1000;
    const liveJobColor = "#00C49F";
    const pendingJobColor = "#0088FE";
    const suspendedJobColor = "#FFBB28";
    const closedJobColor = "#FF8042";
    // let matchedCount = 2;
    //   const shortlistedCount = 2;
    //  let interviewCount = 2;
    //  const inReviewCount = 2;
    //   let jobOffersCount = 2;
    // let hiredCount = 2;
    // let rejectedCount = 2;

    // getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts"))

    // setLiveJobsCount(data?.liveJobsCount);
    // setPendingJobsCount(data?.pendingJobsCount);
    // setSuspendJobsCount(data?.suspendJobsCount);
    // setClosedJobsCount(data?.closedJobsCount);
    // setMatchedCount(data?.appliedCount);
    // setInterviewCount(data?.interviewsCount);
    // setJobOffersCount(data?.jobOffersCount);
    // setHiredCount(data?.hiredCount);
    // setRejectedCount(data?.rejectedCount);

    const [liveJobsCount, setLiveJobsCount] = useState(10);
    const [pendingJobsCount, setPendingJobsCount] = useState(100);
    const [suspendJobsCount, setSuspendJobsCount] = useState(500);
    const [closedJobsCount, setClosedJobsCount] = useState(200);
    const [customQuoteJobsCount, setCustomQuoteJobsCount] = useState(0);
    const [matchedCount, setMatchedCount] = useState(2);
    const [interviewCount, setInterviewCount] = useState(2);
    const [jobOffersCount, setJobOffersCount] = useState(2);
    const [hiredCount, setHiredCount] = useState(2);
    const [rejectedCount, setRejectedCount] = useState(2);



    const triggerCollapse = (type) => {
        isOpen[type] && isOpen[type] === true ? setIsOpen({ [type]: false }) : setIsOpen({ [type]: true });
        if (type === 'jobs' && !isOpen[type]) {
            navigate('/jobs/live');
        } else if (type === 'candidates' && !isOpen[type]) {
            navigate('/candidates/matched');
        }
    }

    // const chartStyle = {
    //     height: '70%'
    // }

    // const jobsData = [
    //     { name: "Live jobs", value: liveJobsCount / 100 },
    //     { name: "Pending jobs", value: pendingJobsCount / 100 },
    //     { name: "Suspended jobs", value: suspendJobsCount / 100 },
    //     { name: "Closed jobs", value: closedJobsCount / 100 }
    // ];
    // const jobsColors = [liveJobColor, pendingJobColor, suspendedJobColor, closedJobColor];

    // const candidatesData = [
    //     { name: "Live jobs", value: matchedCount / 100 },
    //     { name: "Pending jobs", value: shortlistedCount / 100 },
    //     { name: "Suspended jobs", value: interviewCount / 100 },
    //     { name: "Closed jobs", value: inReviewCount / 100 },
    //     { name: "Live jobs", value: jobOffersCount / 100 },
    //     { name: "Pending jobs", value: hiredCount / 100 },
    //     { name: "Suspended jobs", value: rejectedCount / 100 },
    // ];
    const candidatesColors = ["#3498db", "#1abc9c", "#F1C40F", "#FF6B81", "#9B59B6", "#E67E22", "#7F8C8D"];
    // let totalJobsCount = liveJobsCount + suspendJobsCount + pendingJobsCount + closedJobsCount;
    // let totalCandidatesCount = matchedCount + shortlistedCount + interviewCount + inReviewCount + jobOffersCount + hiredCount + rejectedCount;

    const getJobsAndProfilesCount = async () => {
        const {
            data: {
                data
            } } = await getProfilesCount();
        setLiveJobsCount(data?.liveJobsCount);
        setPendingJobsCount(data?.pendingJobsCount);
        setSuspendJobsCount(data?.suspendJobsCount);
        setClosedJobsCount(data?.closedJobsCount);
        setMatchedCount(data?.appliedCount);
        setInterviewCount(data?.interviewsCount);
        setJobOffersCount(data?.jobOffersCount);
        setHiredCount(data?.hiredCount);
        setRejectedCount(data?.rejectedCount);
        setCustomQuoteJobsCount(data?.customQuoteJobsCount);

    }

    useEffect(() => {
        getJobsAndProfilesCount();
        let newPath = pathname.split('/');
        (newPath[1] === 'jobs' && newPath[2] === "add") ? setIsOpen({ 'jobs': false }) : newPath[1] === 'jobs' ? setIsOpen({ 'jobs': true }) : newPath[1] === 'candidates' ? setIsOpen({ 'candidates': true }) : setIsOpen({ 'analytics': true });
    }, [pathname]);

    return (
        <div className={styles.sidebarWrapper}>
            <div className={styles.sidebarBrand}>
                <Link to="/dashboard ">
                    <img src={userData && userData.companyLogoUrl ? userData.companyLogoUrl : CompanyImage} alt='' />
                </Link>
                <h3>{userData.companyName}</h3>
            </div>
            <div className={styles.navNewScroll}>
                <nav className={styles.dashboardNavList}>
                    <div className={styles.dashboardNavDropdown}>
                        <div className={styles.navHead} onClick={() => navigate('/dashboard')} style={(pathname === '/dashboard' || pathname === "/") ? { color: "#ff6600", background: "#222E4E" } : {}}>Home
                            <img className={`${styles.dashboardIcon} ${(pathname === '/dashboard' || pathname === "/") ? styles.dashboardIconActive : ""}`} src={dashboardIcon} alt="" />
                           
                        </div>
                    </div>
                    <div className={styles.dashboardNavDropdown}>
                        <div className={`${styles.navHead} ${isOpen.jobs ? styles.navHeadColor : ""}`} onClick={() => triggerCollapse('jobs')}> Jobs
                            <img className={`${styles.jobsIcon} ${isOpen.jobs ? styles.jobsIconActive : ""}`} src={jobsIcon} alt="" />
                            {/* <PieChart width={60} height={50}>
                                <text dy={0} x={29} y={27} textAnchor="middle" dominantBaseline="middle" fontSize="11px" fill="#B0B3B7">
                                    {totalJobsCount}
                                </text>
                                <Pie
                                    data={jobsData}
                                    cx={25}
                                    cy={23}
                                    startAngle={220}
                                    endAngle={-40}
                                    innerRadius={18}
                                    outerRadius={23}
                                    fill="#8884d8"
                                    paddingAngle={0}
                                    dataKey="value"
                                >
                                    {jobsData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={jobsColors[index % jobsColors.length]} />
                                    ))}
                                </Pie>
                            </PieChart> */}
                        </div>
                        <Collapse in={isOpen.jobs} timeout={1000}>
                            <div>
                                <NavLink to="/jobs/live" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#1ABC9C", background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: "#1ABC9C" }}></span>Live Jobs</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).liveJobsCount : liveJobsCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).liveJobsCount : liveJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: liveJobColor,
                                                textColor: liveJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/jobs/pending" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#3498DB", background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}>
                                        <span className={styles.dotStyle} style={{ background: "#3498DB" }}></span>Pending
                                    </div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).pendingJobsCount : pendingJobsCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).pendingJobsCount : pendingJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: pendingJobColor,
                                                textColor: pendingJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/jobs/suspended" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#F1C40F", background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: "#F1C40F" }}></span>Suspend</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).suspendJobsCount : suspendJobsCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).suspendJobsCount : suspendJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: suspendedJobColor,
                                                textColor: suspendedJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/jobs/closed" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#E74C3C", background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: "#E74C3C" }}></span>Closed</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).closedJobsCount : closedJobsCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).closedJobsCount : closedJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: closedJobColor,
                                                textColor: closedJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/jobs/CustomQuote" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[4], background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: candidatesColors[4] }}></span>Waiting for Quote</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).customQuoteJobsCount : customQuoteJobsCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).customQuoteJobsCount : customQuoteJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: closedJobColor,
                                                textColor: closedJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                            </div>
                        </Collapse>
                    </div>
                    <div className={styles.dashboardNavDropdown}>
                        <div className={`${styles.navHead} ${isOpen.candidates ? styles.navHeadColor : ""}`} onClick={() => triggerCollapse('candidates')}> Candidates
                            <img className={`${styles.candidateIcon} ${isOpen.candidates ? styles.candidateIconActive : ""}`} src={candidateIcon} alt="" />
                            {/* <PieChart width={60} height={50}>
                                <text dy={0} x={29} y={27} textAnchor="middle" dominantBaseline="middle" fontSize="11px" fill="#B0B3B7">
                                    {totalCandidatesCount}
                                </text>
                                <Pie
                                    data={candidatesData}
                                    cx={25}
                                    cy={23}
                                    startAngle={220}
                                    endAngle={-40}
                                    innerRadius={18}
                                    outerRadius={23}
                                    fill="#8884d8"
                                    paddingAngle={0}
                                    dataKey="value"
                                >
                                    {candidatesData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={candidatesColors[index % candidatesColors.length]} />
                                    ))}
                                </Pie>
                            </PieChart> */}
                        </div>
                        <Collapse in={isOpen.candidates} timeout={1000}>
                            <div>
                                <NavLink to="/candidates/matched" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[0], background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: candidatesColors[0] }}></span>Applied</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).appliedCount : matchedCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).appliedCount : matchedCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[0],
                                                textColor: candidatesColors[0],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                {/* <NavLink to="/candidates/shortlisted" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[1], background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: candidatesColors[1] }}></span>Shortlisted</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={shortlistedCount}
                                            text={shortlistedCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[1],
                                                textColor: candidatesColors[1],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink> */}
                                <NavLink to="/candidates/interview" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[2], background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: candidatesColors[2] }}></span>Interviews</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).interviewsCount : interviewCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).interviewsCount : interviewCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[2],
                                                textColor: candidatesColors[2],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                {/* <NavLink to="/candidates/inreview" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[3], background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: candidatesColors[3] }}></span>In Review</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={inReviewCount}
                                            text={inReviewCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[3],
                                                textColor: candidatesColors[3],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink> */}
                                <NavLink to="/candidates/joboffers" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[4], background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: candidatesColors[4] }}></span>Job Offers</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).jobOffersCount : jobOffersCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).jobOffersCount : jobOffersCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[4],
                                                textColor: candidatesColors[4],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/candidates/hired" className={styles.navItem} style={({ isActive }) => isActive ? { color: candidatesColors[5], background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: candidatesColors[5] }}></span>Hired</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).hiredCount : hiredCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).hiredCount : hiredCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[5],
                                                textColor: candidatesColors[5],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="/candidates/rejected" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#E74C3C", background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: candidatesColors[6] }}></span>Rejected</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).rejectedCount : rejectedCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).rejectedCount : rejectedCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: candidatesColors[6],
                                                textColor: candidatesColors[6],
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                            </div>
                        </Collapse>
                    </div>

                    <div className={styles.dashboardNavDropdown}>
                        <div className={`${styles.navHead} ${isOpen.offers ? styles.navHeadColor : ""}`} onClick={() => triggerCollapse('offers')}>Offer Letter<span>Coming Soon</span>
                            <img className={`${styles.offerLet} ${isOpen.offers ? styles.offerLetActive : ""}`} src={offerLetter} alt="" />
                        </div>
                        <Collapse in={isOpen.offers} timeout={1000}>
                            <div>
                                <NavLink to="" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#F1C40F", background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: "#F1C40F" }}></span>Pending Offers</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).liveJobsCount : liveJobsCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).liveJobsCount : liveJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: liveJobColor,
                                                textColor: liveJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#1ABC9C", background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}>
                                        <span className={styles.dotStyle} style={{ background: "#1ABC9C" }}></span>Accepted Offers
                                    </div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).pendingJobsCount : pendingJobsCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).pendingJobsCount : pendingJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: pendingJobColor,
                                                textColor: pendingJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                                <NavLink to="" className={styles.navItem} style={({ isActive }) => isActive ? { color: "#E74C3C", background: "#222E4E" } : {}}>
                                    <div className={styles.sideSpace}><span className={styles.dotStyle} style={{ background: "#E74C3C" }}></span>Rejected Offers</div>
                                    <div className={styles.graph}>
                                        <CircularProgressbar
                                            value={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).suspendJobsCount : suspendJobsCount}
                                            text={getCookie("profileCounts") ? JSON.parse(getCookie("profileCounts")).suspendJobsCount : suspendJobsCount}
                                            circleRatio={0.75}
                                            minValue={minimumValue}
                                            maxValue={maximumValue}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                strokeLinecap: "butt",
                                                trailColor: "#eee",
                                                pathColor: suspendedJobColor,
                                                textColor: suspendedJobColor,
                                                textSize: "25px"
                                            })}
                                        />
                                    </div>
                                </NavLink>
                            </div>
                        </Collapse>
                    </div>
                    {/* <div className={styles.dashboardNavDropdown}>
                        <div className={styles.navHead}>Offer Letter<span>Coming Soon</span>
                        <img className={styles.offerLet} src={offerLetter}/>
                        </div>
                    </div> */}
                    <div className={styles.dashboardNavDropdown}>
                        <div className={styles.navHead}>Analytics<span>Coming Soon</span>
                            <img className={styles.analyticsImg} src={analyticImage} alt="" />
                            {/* <PieChart width={60} height={50}>
                                <text dy={0} x={29} y={27} textAnchor="middle" dominantBaseline="middle" fontSize="11px" fill="#B0B3B7">
                                    {totalJobsCount}
                                </text>
                                <Pie
                                    data={jobsData}
                                    cx={25}
                                    cy={23}
                                    startAngle={220}
                                    endAngle={-40}
                                    innerRadius={18}
                                    outerRadius={23}
                                    fill="#8884d8"
                                    paddingAngle={0}
                                    dataKey="value"
                                >
                                    {jobsData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={jobsColors[index % jobsColors.length]} />
                                    ))}
                                </Pie>
                            </PieChart> */}
                        </div>
                    </div>
                    {/* <div className={styles.btnNew} onClick={()=>navigate('/jobs/add')}>
                        <div className={styles.effNew}></div>
                        <a><FontAwesomeIcon icon={faPlus} /> ADD NEW JOB <span></span></a>
                    </div> */}
                </nav>

            </div>
        </div>
    )
}

export default Sidebar;
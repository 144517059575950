import styles from './InReviewRight.module.scss';
import InReview from '../../../../../assets/images/inReview.svg';


export default function InReviewRight() {

  return (
    <div className={styles.paddRight}>
      <div className={styles.tooltipcss}>
        <span tooltip="Send a job offer to candidate" flow="left">
          <img src={InReview} alt='' />
        </span>
      </div>
    </div>
  );
}







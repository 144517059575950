// import { useState, useEffect } from "react";
import { Col, /**Container, FormGroup,*/ Row } from "react-bootstrap";
import styles from './ChoosIntereviewOption.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
// import croseClose from '../../../../assets/images/croseclose.svg';
import pro from '../../../../assets/images/userProfile.jpg';
import checkBos from '../../../../assets/images/checkNew.svg';
import qucikCall from '../../../../assets/images/quickCallNor.svg';
import scheduleInterview from '../../../../assets/images/shdlinterviewNormal.svg';
import infoNew from '../../../../assets/images/infoNew.svg'

import Popup from "../Popup";
// import { Dropdown } from "../../../common/Dropdown/Dropdown";
// import { useForm, Controller } from "react-hook-form";
// import InputField from "../../../common/InputField/InputField";
// import TextArea from "../../TextArea/TextArea";
import * as React from 'react';
// import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import {
//    Duration
// } from '../../../../common/DropdownOptions';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function ChoosIntereviewOption() {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

//   const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm();

  return (
    <div>
       <Button onClick={handleOpen}>Open modal</Button>
          <Popup isOpen={open} isClose={handleClose}>
            <div className={styles.newCss}>
            <img src={closeJob} onClick={handleClose} alt=''/>
            <Col md={12} className={styles.newBg}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headReject}>
                            <h3>Choose an interview option<span></span></h3>
                        </div>
                        <div className={styles.profileContent}>
                            <div class={styles.rankNew}>
                                <span>10<br/><span class={styles.slashName}>/</span>10</span>
                            </div>
                            <div className={styles.profileNew}>
                                <img src={pro} alt=''/>
                                <h3>Arun Kumar<span>Web Designer</span></h3>
                           </div>
                        </div>
                    </Col> 
                    <Col md={12}>
                         <div className={styles.btnsInterview}>
                            <ul>
                                <li>
                                    <img className={styles.checkList} src={checkBos} alt=''/>
                                    <a className={styles.quickCall}> <img src={qucikCall} alt=''/> Quick call interview</a>
                                </li>
                                <li>    
                                    <a className={styles.scheduleInterview}> <img src={scheduleInterview} alt=''/> Schedule an Interview</a>
                                </li>
                            </ul>
                        </div>

                        <div className={styles.quickCallContent}>
                            <div className={styles.contentQuick}>
                                <img src={infoNew} alt=''/>
                                <p>Scheduling an interview will increase the chances of the candidate picking up your call by 90%.</p>
                            </div>
                        </div>
                       
                        <div className={styles.btnNew}>
                            <div className={styles.btnReset} >
                                <div className={styles.effReset}></div>
                                <button>Submit<span></span></button>
                            </div>
                        </div>

                    </Col>
                </Row> 
            </Col>
            </div>
        </Popup>
        </div>
  );
}







import styles from './InputField.module.scss';
import { Fragment } from 'react';

export const InputField = ({
    label,
    type = "text",
    placeholder = "Type here",
    register = null,
    defaultValue = "",
    value = "",
    name = "",
    maxLength = null,
    readOnly = false,
    viewOnly = false,
    disabled = false,
    error,
    onClick = () => {
      return null;
    },
    onKeyUp = () => {
      return null;
    },
    onChange = () => {
      return null;
    },
    message = {},
    className = "",

    validation = {},
    borderRadius = "none",
    backgroundColor = "#ffffff",
    borderBottom,
    id,
    valueAsNumber
  }) => {
    return (
      <div className={styles.field}>
        {viewOnly ? (
          <Fragment>
            <label>{label}</label>
            <br />
            <span>{value}</span>
          </Fragment>
        ) : (
          <Fragment>
            <label>{label}</label>
            {register === null ? (
              <input
                type={type}
                placeholder={placeholder}
                value={value}
                name={name}
                maxLength={maxLength}
                onChange={onChange}
                disabled={disabled}
                readOnly={readOnly}
                onClick={onClick}
                onKeyUp={onKeyUp}
                aria-invalid={error !== undefined ? true : false}
                autoComplete="off"
                onWheel={(e) => type === "number" && e.target.blur()}
                id={id}
                className={`${styles.formControl} ${className}`}
              />
            ) : (
              <input
                {...register(name, validation, valueAsNumber)}
                type={type}
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                maxLength={maxLength}
                disabled={disabled}
                readOnly={readOnly}
                onKeyUp={onKeyUp}
                aria-invalid={error !== undefined ? true : false}
                autoComplete="off"
                onWheel={(e) => type === "number" && e.target.blur()}
                id={id}
                className={`${styles.formControl} ${className}`}
                onChange={onChange}
              />
            )}
          </Fragment>
        )}
        {error !== undefined && (
          <span className="errorMessage">{error.message}</span>
        )}
      </div>
    );
  };

  export default InputField;
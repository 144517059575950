// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SmartSecretary_newCss__2j7lb {
  width: 480px;
  border-radius: 8px;
  border: solid 1px #3a4f9b !important;
  background-color: #273457 !important;
  padding: 20px;
  position: relative;
}
.SmartSecretary_newCss__2j7lb img {
  position: absolute;
  right: 20px;
  top: 20px;
}
.SmartSecretary_newCss__2j7lb .SmartSecretary_headReject__ZTxMs {
  text-align: center;
  margin: 20px 0px 30px;
}
.SmartSecretary_newCss__2j7lb .SmartSecretary_headReject__ZTxMs img {
  position: inherit;
  margin-bottom: 30px;
  width: 130px;
}
.SmartSecretary_newCss__2j7lb .SmartSecretary_headReject__ZTxMs h3 {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.03em;
  font-weight: 500;
  line-height: 22px;
  margin: 20px 0px 15px;
}
.SmartSecretary_newCss__2j7lb .SmartSecretary_headReject__ZTxMs p {
  font-size: 12px;
  color: #B0B3B7;
  line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Popup/SmartSecretary/SmartSecretary.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,oCAAA;EACA,oCAAA;EACA,aAAA;EACA,kBAAA;AACJ;AAAQ;EACI,kBAAA;EACA,WAAA;EACA,SAAA;AAEZ;AAAQ;EACI,kBAAA;EACA,qBAAA;AAEZ;AADQ;EACI,iBAAA;EACA,mBAAA;EACA,YAAA;AAGZ;AADQ;EACI,WAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AAGZ;AADQ;EACI,eAAA;EACA,cAAA;EACA,iBAAA;AAGZ","sourcesContent":[".newCss{\n    width: 480px;\n    border-radius: 8px;\n    border: solid 1px #3a4f9b !important;\n    background-color: #273457 !important; \n    padding: 20px;\n    position: relative;\n        img{\n            position: absolute;\n            right: 20px;\n            top: 20px;  \n        }\n        .headReject{\n            text-align: center;\n            margin: 20px 0px 30px;\n        img{\n            position: inherit;\n            margin-bottom: 30px;\n            width: 130px;\n        }\n        h3{\n            color: #fff;\n            font-size: 18px;\n            letter-spacing: 0.03em;\n            font-weight: 500;\n            line-height:22px;\n            margin: 20px 0px 15px;\n        }\n        p{\n            font-size: 12px;\n            color: #B0B3B7;\n            line-height: 20px;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newCss": `SmartSecretary_newCss__2j7lb`,
	"headReject": `SmartSecretary_headReject__ZTxMs`
};
export default ___CSS_LOADER_EXPORT___;

// import { useState, useEffect } from "react";
// import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './ShortlistNormalRight.module.scss';
import ScheduleInterviewImage from '../../../../../assets/images/interviewSch.svg';


export default function ShortlistNormalRight(/**{
  children,
  isOpen,
  isClose
 }*/) {

  return (
    <div className={styles.paddRight}>
      <div className={styles.tooltipcss}>
        <span tooltip="Schedule an interview" flow="left">
          <img src={ScheduleInterviewImage} alt='' />
        </span>
      </div>
    </div>
  );
}







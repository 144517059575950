import { React, useState } from 'react';
import { Col, /**Container, FormGroup,*/ Row } from 'react-bootstrap';
import styles from './CandidateExpandedView.module.scss';
import defaultUser from '../../../assets/images/profile.svg';
//import proRank from '../../../assets/images/rankTen.svg';
import videoIcon from '../../../assets/images/videoIcon.svg';
import userClose from '../../../assets/images/close.svg';
import userCheck from '../../../assets/images/check.svg';
// import RejectImage from '../../../assets/images/newReject.svg';
// import ShortlistImage from '../../../assets/images/shortlist.svg';
import quickCall from '../../../assets/images/quickCallimag.svg';
import downArrow from '../../../assets/images/downArrow.svg';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { getLocalTimeFormatted } from '../../../helpers';
import MultiMediaPlayer from '../../common/Popup/MultiMediaPlayer/MultiMediaPlayer';


function CandidateExpandedView({
    data,
    // onDragStart = () => {
    //     return null
    // },
    onDragOver = () => {
        return null;
    },
    handleExpanded = () => {
        return null;
    },
    handleDrop = () => {
        return null;
    },
    handleAction = () => {
        return null;
    },
    dataIndex,
    path,
    showButton,
    statusInfo,
    handleInterviewData,
    rightButtonExpanded,
    leftButtonExpanded,
    currency,
    isTimepassed,
    showQuickCallButton,
    handleQuickCall
}) {

    //state
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [isOpenIdentity, setIsOpenIdentity] = useState(false);
    const {
        candidateName,
        candidateExperience,
        candidateEducation,
        currentSalary,
        // candidateSalaryCurrency,
        candidateDesiredSalary,
        candidateCity,
        starRank,
        candidateDesignationName,
        candidateAge,
        candidateGender,
        educationDegree,
        educationBranch,
        candidateSpecializations,
        experienceDetails,
        candidateDL,
        candidateOwnBike,
        candidateOwnCar,
        jobId,
        _id,
        candidateAction,
        candidateId,
        employerId,
        candidateDetails,
        finalDateTimeInterview,
        // interviewVia,
        interviewId,
        employerAction,
        interviewOccurred
    } = data;
    let picUrl = candidateDetails?.profilePicUrl ?? '';
    let scheduleData = {
        starRank, picUrl, candidateName, candidateDesignationName, jobId, candidateId, employerId, candidateDetails, _id, candidateAction, interviewId, employerAction
    }
    let formattedRank = starRank ? starRank.split("/") : "";
    return (
        <Col md={12} className={styles.padNo} >
            <Row className={styles.openDetail}
            // draggable 
            // onDragStart={(e) => onDragStart(e, _id, jobId, candidateAction)} 
            >
                <Col md={12}>
                    <Row className={styles.proDetail}>
                        {path === 'interview' && <Col md={12}>
                            <div className={styles.statusCandidate}>
                                <h3><span>Status:</span> {statusInfo}</h3>
                            </div>
                        </Col>}

                        <Col md={2}>
                            <div className={styles.proImg}>
                                {formattedRank?.length > 0 && <div className={styles.rankNew}>
                                    <span>{formattedRank[0]}<br></br><span className={styles.slashName}>/</span>{formattedRank[1]}</span>
                                </div>}
                                <img src={candidateDetails.profilePicUrl ? candidateDetails.profilePicUrl : defaultUser} alt='' />
                                <h3>{candidateName}</h3>
                            </div>
                        </Col>
                        <Col md={10}>
                            <div className={`${styles.openArrow} pointer`} onClick={() => handleExpanded(dataIndex)}>
                                <img src={downArrow} alt='' />
                            </div>
                            <Row className={styles.rowPadNew}>
                                <Col md={2}>
                                    <div className={styles.dtatList}>
                                        <h3><span>Experience</span>{candidateExperience}</h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.dtatList}>
                                        <h3><span>Education</span>{candidateEducation}</h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.dtatList}>
                                        <h3><span>Current Salary</span>{currentSalary ? `${currency} ${currentSalary}` : "-"}</h3>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className={styles.dtatList}>
                                        <h3><span>Expected Salary </span>{candidateDesiredSalary ? `${currency} ${candidateDesiredSalary}` : "-"}</h3>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className={styles.dtatList}>
                                        <h3><span>City</span>{candidateCity}</h3>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={styles.rowPad}>
                                <Col md={2}>
                                    <div className={styles.dtatList}>
                                        <h3><span>Birth Day</span>{candidateDetails?.birthDate ? moment(candidateDetails.birthDate).format("DD MMM' YY") : "-"}</h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.dtatList}>
                                        <h3><span>Age</span>{candidateAge}</h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.dtatList}>
                                        <h3><span>Gender</span>{candidateGender}</h3>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className={styles.dtatList}>
                                        <h3><span>Degree</span>{educationDegree ? educationDegree : "-"}</h3>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className={styles.dtatList}>
                                        <h3><span>Branch / Stream</span>{educationBranch ? educationBranch : "-"}</h3>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={styles.rowPad}>
                                <Col md={2}>
                                    <div className={styles.dtatList}>
                                        <h3 className={styles.pdfColor}>
                                            <span>Resume</span>
                                            {(candidateDetails?.resumeDocument && candidateDetails?.resumeDocument !== "" && candidateDetails?.resumeDocument !== "Not Available" && candidateDetails?.resumeDocument != null) ? (
                                                <a rel="noreferrer" href={candidateDetails?.resumeDocumentUrl} target="_blank" className="pointer">
                                                    <img src={userCheck} alt='' />
                                                    Document</a>
                                            ) : ('-')}
                                        </h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.dtatList}>
                                        <h3 className={styles.penDet}>
                                            <span>Identity</span>
                                            <ul>
                                                {(candidateDetails?.panProof && candidateDetails?.panProof !== "Not Available" && candidateDetails?.panProof !== "null") ? (
                                                    <li><img src={userCheck} alt='' />
                                                        <a onClick={() => setIsOpenIdentity(true)} className="pointer"> Pan card</a>
                                                    </li>
                                                ) : ('-')}
                                            </ul>
                                        </h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.dtatList}>
                                        <h3 className={styles.penDet}>
                                            <span>Intro Video</span>
                                            <ul>
                                                <li className={styles.pdfColor}>
                                                    {(candidateDetails?.personalVideo && candidateDetails?.personalVideo !== "Not Available" && candidateDetails?.personalVideo !== "null") ? (
                                                        <>
                                                            <img src={videoIcon} alt='' />
                                                            <a target="_blank" onClick={() => setIsOpenVideo(true)} className="pointer"> Video</a>
                                                        </>
                                                    ) : ('-')}
                                                </li>
                                            </ul>
                                        </h3>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div className={styles.dtatList}>
                                        <h3 className={styles.dldrive}>
                                            <span>Driving Requirements</span>
                                            <ul>
                                                <li><img src={candidateDL ? userCheck : userClose} alt='' />Driver’s License</li>
                                                <li><img src={candidateOwnBike ? userCheck : userClose} alt='' />Motorcycle/Scooty </li>
                                                <li><img src={candidateOwnCar ? userCheck : userClose} alt='' />Car</li>
                                            </ul>
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={styles.rowPad}>
                                <Col md={4}>
                                    <div className={styles.dtatList}>
                                        <h3><span>Desired Designation</span>{candidateDesignationName}</h3>
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className={styles.specCode}>
                                        <span>Specialization</span>
                                        {candidateSpecializations && candidateSpecializations.length > 0 && candidateSpecializations.map((item, index) => {
                                            return (
                                                <button className={styles.spList} key={index}>{item}</button>
                                            )
                                        })}

                                    </div>
                                </Col>

                            </Row>
                        </Col>
                        {path === 'interview' && finalDateTimeInterview && <Col md={12}>
                            <div className={styles.interviewHis}>
                                <div className={styles.interviewList}>
                                    <h3>
                                        <span>Interview</span> -
                                        <p>
                                            <span>Date: </span>
                                            {finalDateTimeInterview?.interviewDate ? (moment(finalDateTimeInterview.interviewDate).format("DD MMM`YY")) : '-'}
                                        </p> |
                                        <p>
                                            <span>Time: </span>
                                            {finalDateTimeInterview?.interviewTime ? getLocalTimeFormatted(finalDateTimeInterview.interviewTime) : '-'}
                                        </p> |
                                        <p>
                                            <span>Duration: </span>
                                            {finalDateTimeInterview?.interviewLength}
                                        </p> |
                                        <p><span>Interview: </span>
                                            On Call
                                        </p>
                                    </h3>
                                </div>
                                {(!path === 'matched' || 'shortlisted') && <div className={styles.btnNew}>
                                    <div className={styles.effNew}></div>
                                    <button onClick={() => handleInterviewData(interviewId, data)}>Interview history<span></span></button>
                                </div>}
                            </div>
                        </Col>}
                        <Col md={12} mb-2="true">
                            <Accordion className={styles.accoData}>
                                <AccordionSummary className={styles.colorHead} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>Past & Current Experiences</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Row className={styles.expCom}>
                                            {experienceDetails && experienceDetails.length > 0 ? experienceDetails.map(({
                                                lastPosition,
                                                lastCompany,
                                                lastSalary,
                                                fromMonth,
                                                toMonth
                                            }, index) => {
                                                return (
                                                    <Col md={3} key={index}>
                                                        <div className={styles.expData}>
                                                            <h2>{lastCompany ? lastCompany : '-'}</h2>
                                                            <ul>
                                                                <li><span>Profile:</span><h3>{lastPosition ? lastPosition : '-'}</h3></li>
                                                                {/* <li><span>Company:</span><h3>{lastCompany ? lastCompany : '-'}</h3> </li> */}
                                                                <li><span>Salary:</span><h3>{lastSalary ? lastSalary : '-'}</h3></li>
                                                                <li><span>Duration:</span><h3>{fromMonth ? fromMonth : ""} - {toMonth ? toMonth : ""}</h3></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                                : (
                                                    <Col md={3}>
                                                        <div className={styles.expData}>
                                                            <h2>No data found</h2>
                                                        </div>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Col>

                        {showButton && <Col md={12}>
                            <div className={styles.actionButtons}>
                                {/* <div className={styles.btnNew} onDrop={handleDrop} onDragOver={onDragOver} id="negative" onClick={() => handleAction(scheduleData, _id, jobId, candidateAction, 'negative', employerAction)}>
                                    <div className={styles.effNew}></div>
                                    <button><img src={RejectImage} alt='' /> Reject<span></span></button>
                                </div> */}



                                <div className={styles.negiId}
                                    onDrop={handleDrop}
                                    onDragOver={onDragOver}
                                    id="negative"
                                    onClick={() => handleAction(scheduleData, _id, jobId, candidateAction, 'negative', employerAction, interviewId, finalDateTimeInterview, interviewOccurred, isTimepassed)}
                                >
                                    {leftButtonExpanded}
                                </div>

                                <div className={styles.newBtn}>
                                    {showQuickCallButton && <div className={styles.quickCall} onClick={() => handleQuickCall(_id)}>
                                        <div className={styles.quickCallnew}></div>
                                        <button><img src={quickCall} alt='' /> Quick call interview<span></span> </button>
                                    </div>}
                                    <div className={styles.posId}
                                        onDrop={handleDrop}
                                        onDragOver={onDragOver}
                                        id="positive"
                                        onClick={() => handleAction(scheduleData, _id, jobId, candidateAction, 'positive', employerAction, interviewId, finalDateTimeInterview, interviewOccurred, isTimepassed)}
                                    >{rightButtonExpanded}
                                    </div>
                                </div>



                            </div>
                        </Col>}
                        {!showButton && showQuickCallButton && <div className={styles.actionButtons}>
                            <div className={styles.newBtn}>
                                <div className={styles.quickCall} onClick={() => handleQuickCall(_id)}>
                                    <div className={styles.quickCallnew}></div>
                                    <button><img src={quickCall} alt='' /> Quick call interview<span></span> </button>
                                </div>
                            </div>
                        </div>}

                        {/* {(path === 'hired' && employerAction === 'InReview') && <div className={styles.actionButtons}>
                            <div className={styles.negiId}
                                onDrop={handleDrop}
                                onDragOver={onDragOver}
                                id="negative"
                                onClick={() => handleAction(scheduleData, _id, jobId, candidateAction, 'negative', employerAction, interviewId, finalDateTimeInterview, interviewOccurred, isTimepassed)}
                            >
                                {leftButtonExpanded}
                            </div>
                            <div className={styles.newBtn}>
                                <div className={styles.posId}
                                    onDrop={handleDrop}
                                    onDragOver={onDragOver}
                                    id="positive"
                                    onClick={() => handleAction(scheduleData, _id, jobId, candidateAction, 'positive', employerAction, interviewId, finalDateTimeInterview, interviewOccurred, isTimepassed)}
                                >{rightButtonExpanded}
                                </div>
                            </div>
                        </div>} */}
                    </Row>
                </Col>
            </Row>
            <MultiMediaPlayer
                isOpen={isOpenVideo}
                handleClose={() => setIsOpenVideo(false)}
                source={candidateDetails?.personalVideoUrl ?? ""}
                fileName={candidateDetails?.personalVideo}
                title="Intro Video"
            />
            <MultiMediaPlayer
                isOpen={isOpenIdentity}
                handleClose={() => setIsOpenIdentity(false)}
                source={candidateDetails?.panProofUrl ?? ""}
                fileName={candidateDetails?.panProof}
                title="Identity"
            />
        </Col>
    )
}

export default CandidateExpandedView;
import { useState, useEffect } from "react";
import { Col, Container, /**FormGroup,*/ Row } from "react-bootstrap";
import styles from './EditProfile.module.scss';
import HomeLayout from "../../layouts/HomeLayout/HomeLayout";
import newupdate from '../../assets/images/newupdate.svg';
import Profile from '../../assets/images/profile.svg';
import { Dropdown } from "../../components/common/Dropdown/Dropdown";
import InputField from "../../components/common/InputField/InputField";
import TextArea from "../../components/common/TextArea/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";

import {
    companySize,
    representativeRole
} from '../../common/DropdownOptions';
import { /**Navigate,*/ useLocation, useNavigate } from "react-router-dom";
import { getCity, getIndustryList } from "../../services/api/common";
import { toast } from "react-toastify";
import { fileUpload } from "../../services/api/upload";
import { hideLoader, showLoader } from "../../helpers";
import { getProfile, updateProfile } from "../../services/api/employer";
import GoogleAutoComplete from '../common/GoogleAutoComplete/GoogleAutoComplete'

export default function EditProfile() {

    const { state } = useLocation();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue, /**clearErrors,*/ reset } = useForm();

    //state
    const [businessData, setBusinessData] = useState();
    const [cityData, setCityData] = useState();
    const [dropDownDefaultValues, setDropdownDefaultValues] = useState({});
    const [companyPictureObject, setCompanyPictureObject] = useState();
    const [companyPictureName, setCompanyPictureName] = useState();
    const [companyLogoUrl, setCompanyLogoUrl] = useState();
    const [companyLogoObject, setCompanyLogoObject] = useState();
    const [representativeProfileUrl, setRepresentativeProfileUrl] = useState();
    const [representativeProfileObject, setRepresentativeProfileObject] = useState();
    const [welcomeVideoObject, setWelcomeVideoObject] = useState();
    const [welcomeVideoName, setWelcomeVideoName] = useState();
    const [initialData, setInitialData] = useState({});
    const [officeAddress, setOfficeAddress] = useState(state.data.companyAddress)
    const [place, setPlace] = useState({});
    const [isAddrEnter, setIsAddrEnter] = useState(false);
    const [userState, setUserState] = useState(state.data.state);

    useEffect(() => {
        getIndustries();
        getCityList();
        if (state?.data) {
            setData(state.data);
        }
    }, []);

    /**
     * API->https://api.jobsgaar.com/v1/common/get/industry/list
     * This method is used get list of industries/business type
     */
    const getIndustries = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getIndustryList();
            setBusinessData(
                data && data.length > 0 && data.map(({ industryName }) => {
                    return { label: industryName, value: industryName }
                }
                )
            );
        } catch (error) {
        }
    }

    /**
     * API->https://api.jobsgaar.com/v1/common/get/industry/list
     * This method is used get list of cities
     */
    const getCityList = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getCity();
            setCityData(
                data && data.length > 0 && data.map(({ stateName, districtName }) => {
                    let data = districtName.map((item) => { return { label: item, value: item, state: stateName } });
                    return {
                        label: stateName,
                        options: data
                    }
                })
            );
        } catch (error) {
        }
    }

    /**
     * This method is used to set the default values from user profile information 
     */
    const setData = (data) => {
        showLoader();
        let defaultData = {};
        setDropdownDefaultValues({
            ...dropDownDefaultValues,
            representativeRole: {
                label: data?.representativeRole ? data.representativeRole : "",
                value: data?.representativeRole ? data.representativeRole : ""
            },
            companyBusiness: {
                label: data?.companyBusiness?.length > 0 ? data.companyBusiness[0] : "",
                value: data?.companyBusiness?.length > 0 ? data.companyBusiness[0] : ""
            },
            companySize: {
                label: data?.companySize ? data.companySize : "",
                value: data?.companySize ? data.companySize : ""
            },
            city: {
                label: data?.city ? data.city : "",
                value: data?.city ? data.city : ""
            }
        });
        if (data.companyLogoUrl) {
            setCompanyLogoUrl(data.companyLogoUrl);
        }
        if (data.representativeProfileUrl) {
            setRepresentativeProfileUrl(data.representativeProfileUrl);
        }
        defaultData.representativeName = data.representativeName;
        defaultData.representativeEmail = data.representativeEmail;
        defaultData.alternateNo = data.alternateNo;
        defaultData.representativeRole = data.representativeRole;
        defaultData.companyName = data.companyName;
        defaultData.companyEmail = data.companyEmail;
        defaultData.whatsappMobile = data.whatsappMobile;
        defaultData.companyBusiness = data.companyBusiness?.length > 0 ? data.companyBusiness : [];
        defaultData.companySize = data.companySize;
        defaultData.jobGPSCoordinates = {
            lat: Object.keys(place).length !== 0 && place.lat ? place.lat : 0,
            lng: Object.keys(place).length !== 0 && place.lng ? place.lng : 0
        }
        defaultData.city = data.city;
        defaultData.companyAddress = data.companyAddress ? data.companyAddress : officeAddress;
        defaultData.companyAbout = data.companyAbout;
        defaultData.welcomeVideo = data.welcomeVideo;
        defaultData.companyPicture = data.companyPicture;

        data.welcomeVideo ? setWelcomeVideoName(data.welcomeVideo) : setWelcomeVideoName("");
        data.companyPicture ? setCompanyPictureName(data.companyPicture) : setCompanyPictureName("");
        reset(defaultData);
        setInitialData(defaultData);
        hideLoader();
    }

    // const handleCity = (city, state) => {
    //     setValue('city', city);
    //     // setValue('jobState', state);
    // }

    /**
     * This method is used to store the files to local state
     */
    const handleFileUpload = async ({ target: { files, id } }) => {
        if (files && files[0]) {
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            if (id === 'companyLogo') {
                setCompanyLogoObject(files[0]);
                reader.onload = () => {
                    setCompanyLogoUrl(reader.result);
                }
                // setCompanyLogoName(files[0].name);
            } else if (id === 'companyPicture') {
                setCompanyPictureObject(files[0]);
                setCompanyPictureName(files[0].name);
            } else if (id === 'representativeProfile') {
                setRepresentativeProfileObject(files[0]);
                reader.onload = () => {
                    setRepresentativeProfileUrl(reader.result);
                }
            } else if (id === 'welcomeVideo') {
                var vid = document.createElement('video');
                var fileURL = URL.createObjectURL(files[0]);
                vid.src = fileURL;
                // wait for duration to change from NaN to the actual duration
                vid.ondurationchange = () => {
                    if (vid.duration <= 120) {
                        setWelcomeVideoName(files[0].name);
                        setWelcomeVideoObject(files[0]);
                    } else {
                        document.getElementById(id).value = null;
                        toast.warning('Video length should be lass than 2 mins');
                    }
                };
            }
            // setValue(id, files[0].name)
        }
    }

    /**
     * Here we clear the files or modify the files
     */
    const clearFileUpload = (id) => {
        if (id === 'welcomeVideo') {
            setWelcomeVideoObject();
            setWelcomeVideoName("");
        } else if (id === 'companyPicture') {
            setCompanyPictureObject();
            setCompanyPictureName("");
        } else {
            // setGstProofObject({});
            // setGstProofName("");
        }
        document.getElementById(id).value = null;
        setValue(id, '');
    }

    /**
     * Here we store the selected values to the local state
     */
    const handleDropdownDefault = (name, data) => {
        setUserState(data.state)
        setDropdownDefaultValues({
            ...dropDownDefaultValues,
            [name]: data
        });
        if (name === 'companyBusiness') {
            return [data?.value];
        } else {
            return data?.value;
        }
    }

    /**
    * API-> https://api.jobsgaar.com/v1/employer/get/my/profile
    *This method is used to get the user information from the DB
    */
    const getProfileData = async () => {
        try {
            showLoader();
            let {
                data: {
                    data
                }
            } = await getProfile();
            hideLoader();
            return data;

        } catch (error) {
            hideLoader();
        }
    }
    /**
     *  API-> https://api.jobsgaar.com/v1/file
     * uploaded files are send to above api
     * @param {*} type 
     * @param {*} image 
     */
    const uploadFiles = async (type, image) => {
        try {
            // showLoader();
            let content = new FormData();
            content.append('fileKey', image);
            const {
                data: {
                    data
                } } = await fileUpload(content);
            setValue(type, data?.fileKey);
            // hideLoader();
        } catch (error) {
            toast.error("Failed");
            hideLoader();
        }
    }

    /**
     * 
     * @param {*} submitValues 
     */
    const submit = async () => {
        try {
            const submitValues = getValues();
            submitValues.jobGPSCoordinates = {
                lat: Object.keys(place).length !== 0 && place.lat ? place.lat : 0,
                lng: Object.keys(place).length !== 0 && place.lng ? place.lng : 0
            }
            submitValues.companyAddress = officeAddress;
            submitValues.state = userState;
            if (officeAddress === "") {
                setIsAddrEnter(true)
            } else {
                setIsAddrEnter(false)
                showLoader();
                let imageArray = [];
                if (representativeProfileObject) {
                    imageArray.push({
                        type: "representativeProfile",
                        image: representativeProfileObject
                    });
                }
                if (companyLogoObject) {
                    imageArray.push({
                        type: "companyLogo",
                        image: companyLogoObject
                    });
                }
                if (welcomeVideoObject) {
                    imageArray.push({
                        type: "welcomeVideo",
                        image: welcomeVideoObject
                    });
                }
                if (companyPictureObject) {
                    imageArray.push({
                        type: "companyPicture",
                        image: companyPictureObject
                    });
                }
                imageArray?.length > 0 && imageArray.map(async (item) => {
                    await uploadFiles(item.type, item.image);
                });

                setTimeout(async () => {
                    const newReqObj = getValues();
                    const reqObj = { ...submitValues, ...newReqObj }
                    let {
                        data: {
                  /**data,*/ message
                        }
                    } = await updateProfile(reqObj);
                    hideLoader();
                    toast.success(message ? message : "Employer profile updated successfully");
                    let employerData = await getProfileData();
                    localStorage.setItem("userData", JSON.stringify(employerData));
                    navigate('/employerprofile');
                }, 2000);
            }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const resetForm = () => {
        showLoader();
        setData(initialData);
        document.getElementById("representativeImage").scrollIntoView();
        // setTimeout(() => {
        // }, 1000);
        setTimeout(() => {
            hideLoader();
        }, 300);
    }



    return (
        <HomeLayout>
            <section className={styles.editProfile}>
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <div className={styles.headEdit}>
                                <h3>Edit Profile</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className={styles.editList}>
                                <div className={styles.imageUpdate}>
                                    <h3>Profile Images</h3>
                                    <div className={styles.bgimageUp}>
                                        <div className={styles.imageUpload}>
                                            <div className={styles.profileImage}>
                                                <img src={representativeProfileUrl ? representativeProfileUrl : Profile} alt="" id="representativeImage" />
                                            </div>
                                            <div className={styles.file}>
                                                <label htmlFor='representativeProfile'>Change profile image</label>
                                                <input
                                                    type="file"
                                                    onChange={handleFileUpload}
                                                    accept="image/png, image/jpeg"
                                                    id="representativeProfile"
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.imageUpload}>
                                            <div className={styles.profileImage}>
                                                <img
                                                    src={companyLogoUrl ? companyLogoUrl : Profile}
                                                    alt=""
                                                />
                                            </div>
                                            <div className={styles.file}>
                                                <label htmlFor='companyLogo'>Change company image</label>
                                                <input
                                                    type="file"
                                                    onChange={handleFileUpload}
                                                    accept="image/png, image/jpeg"
                                                    id="companyLogo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.personalDetail}>
                                    <h3>Personal profile</h3>
                                    <div className={styles.personalBg}>
                                        <Row>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Full Name</label>
                                                <InputField
                                                    name="representativeName"
                                                    type="text"
                                                    placeholder="Enter your name"
                                                    register={register}
                                                    validation={{
                                                        required: 'Required',
                                                        pattern: {
                                                            value: /^[A-Za-z ]+$/,
                                                            message: 'Name should contain only alphabets and spaces'
                                                        }
                                                    }}
                                                    error={errors?.representativeName}
                                                />
                                            </Col>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Email</label>
                                                <InputField
                                                    name="representativeEmail"
                                                    type="text"
                                                    placeholder="Enter your mail"
                                                    register={register}
                                                    validation={{
                                                        required: 'Required',
                                                        pattern: {
                                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                            message: 'Enter valid mail id'
                                                        }
                                                    }}
                                                    error={errors?.representativeEmail}
                                                />
                                            </Col>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Alternate Number</label>
                                                <InputField
                                                    name="alternateNo"
                                                    type="text"
                                                    placeholder="Enter alternate number"
                                                    register={register}
                                                    validation={{
                                                        required: 'Required',
                                                        pattern: {
                                                            value: /^[0-9]*$/,
                                                            message: 'Numbers only allowed'
                                                        },
                                                        maxLength: {
                                                            value: 10,
                                                            message: "Maximum allowed numbers 10"
                                                        }
                                                    }}
                                                    error={errors?.alternateNo}
                                                />
                                            </Col>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Role</label>
                                                <Controller
                                                    name="representativeRole"
                                                    control={control}
                                                    render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                        <Dropdown
                                                            isSearchable={true}
                                                            options={representativeRole}
                                                            placeholder="Choose Your role"
                                                            className={styles.formControlselect}
                                                            onChange={(e) => onChange(handleDropdownDefault('representativeRole', e))}
                                                            error={errors?.representativeRole}
                                                            inputRef={ref}
                                                            value={dropDownDefaultValues.representativeRole}
                                                        />
                                                    )}
                                                    // rules={{required: true}}
                                                    onFocus={errors.representativeRole?.ref}
                                                />
                                            </Col>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Upload video for candidates (Max 2 mins.) </label>
                                                <div className={styles.videoUpload}>
                                                    <span className={styles.docName}>
                                                        <b>{welcomeVideoName ? welcomeVideoName : 'Video not added'}</b>
                                                        {welcomeVideoName && <FontAwesomeIcon icon={faX} onClick={() => clearFileUpload("welcomeVideo")} />}
                                                    </span>
                                                    <div className={styles.fileUpload}>
                                                        <input
                                                            type="file"
                                                            onChange={handleFileUpload}
                                                            accept="video/mp4"
                                                            id="welcomeVideo"
                                                        />
                                                        <img src={newupdate} alt="" />

                                                        {/* <FontAwesomeIcon icon={faFileUpload} /> */}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className={styles.personalDetail}>
                                    <h3>Company Profile.</h3>
                                    <div className={styles.personalBg}>
                                        <Row>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Company Name</label>
                                                <InputField
                                                    name="companyName"
                                                    type="text"
                                                    placeholder="Enter your company name"
                                                    register={register}
                                                    validation={{
                                                        required: 'Required'
                                                    }}
                                                    error={errors?.companyName}
                                                />
                                            </Col>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Company’s Email Address</label>
                                                <InputField
                                                    name="companyEmail"
                                                    type="text"
                                                    placeholder="Enter Your Company Email ID"
                                                    register={register}
                                                    validation={{
                                                        required: 'Required',
                                                        pattern: {
                                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                            message: 'Enter valid mail id'
                                                        }
                                                    }}
                                                    error={errors?.companyEmail}
                                                />
                                            </Col>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>WhatsApp Number</label>
                                                <InputField
                                                    name="whatsappMobile"
                                                    type="text"
                                                    register={register}
                                                    disabled={true}
                                                    placeholder=""
                                                />
                                            </Col>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Your Main Business</label>
                                                <Controller
                                                    name="companyBusiness"
                                                    control={control}
                                                    render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                        <Dropdown
                                                            isSearchable={true}
                                                            options={businessData}
                                                            placeholder="Business Type"
                                                            className={styles.formControlselect}
                                                            onChange={(e) => onChange(handleDropdownDefault('companyBusiness', e))}
                                                            error={errors?.companyBusiness}
                                                            inputRef={ref}
                                                            value={dropDownDefaultValues.companyBusiness}
                                                        />
                                                    )}
                                                    // rules={{ required: true }}
                                                    onFocus={errors.companyBusiness?.ref}
                                                />
                                            </Col>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Company Size</label>
                                                <Controller
                                                    name="companySize"
                                                    control={control}
                                                    render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                        <Dropdown
                                                            isSearchable={true}
                                                            options={companySize}
                                                            placeholder="Enter Your Company Size"
                                                            className={styles.formControlselect}
                                                            onChange={(e) => onChange(handleDropdownDefault('companySize', e))}
                                                            error={errors?.companySize}
                                                            inputRef={ref}
                                                            value={dropDownDefaultValues.companySize}
                                                        />
                                                    )}
                                                    // rules={{required: true}}
                                                    onFocus={errors.companySize?.ref}
                                                />
                                            </Col>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Select your District / City</label>
                                                <Controller
                                                    name="city"
                                                    control={control}
                                                    render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                        <Dropdown
                                                            isSearchable={true}
                                                            options={cityData}
                                                            placeholder="Select your city/district"
                                                            className={styles.formControlselect}
                                                            onChange={(e) => onChange(handleDropdownDefault('city', e))}
                                                            error={errors?.city}
                                                            inputRef={ref}
                                                            value={dropDownDefaultValues.city}
                                                        />
                                                    )}
                                                    rules={{ required: true }}
                                                    onFocus={errors.city?.ref}
                                                />
                                            </Col>
                                            <Col md={4} className={styles.FormGroup}>
                                                <label>Upload business/photo/document (For Verification) </label>
                                                <div className={styles.videoUpload}>
                                                    <span className={styles.docName}>
                                                        <b>{companyPictureName ? companyPictureName : 'No file chosen'}</b>
                                                        {companyPictureName && <FontAwesomeIcon icon={faX} onClick={() => clearFileUpload('companyPicture')} />}
                                                    </span>
                                                    <div className={styles.fileUpload}>
                                                        <input
                                                            type="file"
                                                            onChange={handleFileUpload}
                                                            accept="image/png, image/jpeg"
                                                            id="companyPicture"
                                                        />
                                                        <img src={newupdate} alt="" />

                                                        {/* <FontAwesomeIcon icon={faFileUpload} /> */}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8} className={styles.FormGroup}>
                                                <label>Office Address</label>
                                                <GoogleAutoComplete inputName="companyAddress" setOfficeAddress={setOfficeAddress}
                                                    inputVal={officeAddress} setPlace={setPlace} styles={styles.formControl} />
                                                {isAddrEnter && <span className="errorMessage">Required</span>}
                                                {/* <TextArea
                                                    name="companyAddress"
                                                    placeholder="Enter You Office Address"
                                                    type="text"
                                                    register={register}
                                                    validation={{
                                                        required: 'Required',
                                                    }}
                                                    error={errors?.companyAddress}
                                                    maxLength='300'
                                                /> */}
                                            </Col>
                                            <Col md={12} className={styles.FormGroup}>
                                                <label>About Company/Business Information </label>
                                                <TextArea
                                                    name="companyAbout"
                                                    placeholder="Enter About your Company/Business Information"
                                                    type="text"
                                                    register={register}
                                                    // validation={{
                                                    //     required: 'Required',
                                                    // }}
                                                    // error={errors?.jobDescription}
                                                    maxLength='300'
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.btnMain}>
                                <div className={styles.btnNew}>
                                    <div className={styles.btnCancel} onClick={() => navigate('/employerprofile')}>
                                        <div className={styles.effNew}></div>
                                        <button>Cancel <span></span></button>
                                    </div>
                                    <div className={styles.btnReset} onClick={resetForm}>
                                        <div className={styles.effReset}></div>
                                        <button>Reset<span></span></button>
                                    </div>
                                </div>
                                <div className={styles.btnSubmit} onClick={handleSubmit(submit)}>
                                    <div className={styles.effSubmit}></div>
                                    <button>Submit<span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </HomeLayout>
    );
}







import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './SuspendNotification.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';

import Popup from "../Popup";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function SuspendNotification({
    isOpen,
    handleClose,
    handleApply,
    communication: {
        suspend
    }
}) {

    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        setIsChecked(suspend);
    }, [suspend]);
    const handleReset = (type) => {
        setIsChecked(false);
        type === 'close' ? handleClose() : handleApply();
    }
  return (
          <Popup isOpen={isOpen} isClose={()=>handleReset('close')}>
            <div className={styles.newCss}>
            <img src={closeJob} onClick={()=>handleReset('close')} alt=""/>
            <Col md={12} className={styles.newBg}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headReject}>
                            <h3>Suspend all notifications for me<span></span></h3>
                        </div>
                    </Col> 
                    <Col md={12}>
                        <Row>
                            <Col md={6}>
                                <div className={styles.matchJobBy}>
                                    <div className={styles.optionSelect}>
                                        <h3>Notifications</h3>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="suspend">No WhatsApp, No App, No Email notification</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="checkbox"
                                            className={styles.checkboxInput} 
                                            onChange={()=>setIsChecked(!isChecked)}
                                            checked={isChecked}
                                            id="suspend"
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                </div>
                            </Col>
                            
                            <Col md={12}>
                                <div className={styles.inforBox}>
                                    <div className={styles.infoCon}>
                                    <p>It is not recommended to suspend all notifications, since you will not hear from us, and you might miss some
                                        incredible opportunities.</p>
                                    <p>If this is for a short period of time, it is ok, but don’t forget to turn it back on soon.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className={styles.btnNew}>
                            <div className={styles.btnCancel} onClick={()=>handleReset('close')}>
                                <div className={styles.effNew}></div>
                                <button>Cancel <span></span></button>
                            </div>
                            {isChecked ? (
                                <div className={styles.btnReset} onClick={()=>handleReset('apply')}>
                                <div className={styles.effReset}></div>
                                <button>Apply<span></span></button>
                            </div>
                            ) : (
                                <div className={styles.btnReset} >
                                {/* <div className={styles.effReset}></div> */}
                                <button disabled>Apply</button>
                            </div>
                            )}
                            
                        </div>

                    </Col>
                </Row> 
            </Col>
            </div>
        </Popup>
  );
}







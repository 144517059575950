import { useState, useEffect } from "react";
import { Col, /**Container, FormGroup,*/ Row } from "react-bootstrap";
import styles from './ScheduleInterview.module.scss';
import closeJob from '../../../../assets/images/closeIcon.svg';
// import pro from '../../../../assets/images/userProfile.jpg';
import callInter from '../../../../assets/images/onCall.svg';
import officeInter from '../../../../assets/images/officeInterview.svg';
import checkBos from '../../../../assets/images/checkNew.svg';
import defaultUser from '../../../../assets/images/profile.svg';

import Popup from "../Popup";
import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { useForm, Controller } from "react-hook-form";
import InputField from "../../../common/InputField/InputField";
import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
import {
    Duration
} from '../../../../common/DropdownOptions';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import TextArea from "../../TextArea/TextArea";
import { createInterview } from "../../../../services/api/employer";
import { getLocalTime, getUtcTime, hideLoader, showLoader } from "../../../../helpers";
import { toast } from "react-toastify";
import { rescheduleInterview } from "../../../../services/api/common";
import InterviewScheduled from "../InterviewScheduled/InterviewScheduled";


// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function ScheduleInterview({
    isOpen,
    handleClose,
    updateStatus,
    data: {
        starRank,
        profilePicUrl,
        candidateName,
        candidateDesignationName,
        jobId,
        candidateId,
        employerId,
        candidateDetails,
        _id,
        candidateAction,
        employerAction,
        // interviewId,
        finalDateTimeInterview
    },
    initialData,
    // isUpdateScheduleAction,
    handleQuickCall
}) {
    //   const [open, setOpen] = React.useState(false);
    //   const handleOpen = () => setOpen(true);
    //   const handleClose = () => setOpen(false);
    const [interviewVia, setInterviewVia] = useState('Phone Call');
    const [defaultDuration, setDefaultDuration] = useState();
    const [isSuccess, setIsSuccess] = useState(false);

    const { register, handleSubmit, formState: { errors }, getValues, control, /**setValue,*/ reset } = useForm();
    let isReschedule = initialData && Object.keys(initialData).length > 0 ? true : false;

    useEffect(() => {
        if (initialData && Object.keys(initialData).length > 0 && initialData.finalDateTimeInterview) {
            const { interviewVia, interviewTitle, interviewDescription, finalDateTimeInterview: { interviewDate, interviewTime, interviewLength } } = initialData;
            setInterviewVia(interviewVia);
            setDefaultDuration({
                label: interviewLength === '15 min' ? '15 Min' : '30 min' ? '30 min' : '45 min' ? '45min' : '60 Min',
                value: interviewLength
            })
            reset({
                interviewTitle: interviewTitle,
                interviewDescription: interviewDescription,
                interviewDate: interviewDate,
                interviewTime: getLocalTime(interviewTime),
                interviewLength: interviewLength
            });
        }
    }, [initialData]);
    const submit = async (submitData) => {
        try {
            showLoader();
            let submitObj = {};
            let suggestedObj = [{
                suggestedDate: submitData.interviewDate,
                suggestedTime: getUtcTime(submitData.interviewTime),
                interviewLength: submitData.interviewLength,
                suggestedBy: "employer",
                approved: false
            }];
            let finalObj = {
                interviewDate: submitData.interviewDate,
                interviewTime: getUtcTime(submitData.interviewTime),
                interviewLength: submitData.interviewLength,
                interviewDateTime: moment(submitData.interviewDate + " " + submitData.interviewTime).toISOString()
            }
            //   let suggestArray = [];
            //   suggestArray.push(obj);

            submitObj.employerId = employerId;
            submitObj.jobId = jobId;
            submitObj.candidateId = candidateId;
            submitObj.phoneNo = candidateDetails?.whatsappMobile ?? '';
            submitObj.interviewStatus = "Pending";
            submitObj.interviewVia = interviewVia;
            submitObj.suggestedDateTimeInterview = suggestedObj;
            submitObj.finalDateTimeInterview = finalObj;
            submitObj.interviewTitle = submitData.interviewTitle;
            submitObj.interviewDescription = submitData.interviewDescription;

            const {
                data: {
                    /**data, message*/
                } } = await createInterview(submitObj);
            hideLoader();
            // toast.success(message ? message : "Success");
            setIsSuccess(true);
            // handleReset();
            updateStatus(jobId, _id, 'positive', candidateAction, employerAction, "Interview");
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const submitReschedule = async (scheduleData) => {
        const { suggestedDateTimeInterview } = initialData;
        try {
            showLoader();
            let newSuggestObj = {
                suggestedDate: scheduleData.interviewDate,
                suggestedTime: getUtcTime(scheduleData.interviewTime),
                interviewLength: scheduleData.interviewLength,
                suggestedBy: "employer",
                approved: false
            }
            let newSuggestArray = [...suggestedDateTimeInterview];
            newSuggestArray.push(newSuggestObj);
            let finalReschedule = {
                interviewDate: scheduleData.interviewDate,
                interviewTime: getUtcTime(scheduleData.interviewTime),
                interviewLength: scheduleData.interviewLength,
                interviewDateTime: moment(scheduleData.interviewDate + " " + scheduleData.interviewTime).toISOString()
            }

            let obj = {
                jobId: jobId,
                interviewId: initialData?._id,
                suggestedDateTimeInterview: newSuggestArray,
                finalDateTimeInterview: finalReschedule,
                interviewStatus: "InterviewRescheduled"
            }
            const {
                data: {
                    message
                } } = await rescheduleInterview(obj);
            hideLoader();
            toast.success(message ? message : "Success");
            handleReset();
            updateStatus(jobId, _id, 'reschedule', candidateAction);
            // if(isUpdateScheduleAction){
            // }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    let formattedRank = starRank ? starRank.split("/") : [];

    const handleReset = () => {
        reset({});
        setInterviewVia('Phone Call');
        setDefaultDuration();
        setIsSuccess(false);
        handleClose();
    }

    const handleInterviewVia = (data) => {
        if (!isReschedule) {
            setInterviewVia(data);
        }
    }

    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Popup isOpen={isOpen} isClose={handleReset}>
                <div className={styles.newCss}>
                    <img src={closeJob} onClick={handleReset} alt="" />
                    <Col md={12} className={styles.newBg}>
                        <Row>
                            <Col md={12}>
                                <div className={styles.headReject}>
                                    <h3>{isReschedule ? 'Re-Schedule interview' : 'Schedule an interview time'}</h3>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={styles.interviewPro}>
                                    <div className={styles.newTab}>
                                        <div className={styles.rankNew}>
                                            <span>{formattedRank && formattedRank.length > 0 && formattedRank[0]}<br></br><span className={styles.slashName}>/</span>{formattedRank && formattedRank.length > 0 && formattedRank[1]}</span>
                                        </div>
                                        <div className={styles.profileNew}>
                                            <img src={profilePicUrl ? profilePicUrl : defaultUser} alt="" />
                                            <h3>{candidateName}<span>{candidateDesignationName}</span></h3>
                                        </div>
                                    </div>
                                    <div className={styles.callHistory}>
                                        <div className={styles.btnNew}>
                                            <div className={styles.effNew}></div>
                                            <a onClick={() => handleQuickCall(_id)}>Quick call interview<span></span></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={styles.btnsInterview}>
                                    <ul>
                                        <li>
                                            {interviewVia === 'Phone Call' && <img src={checkBos} alt="" />}
                                            <a
                                                className={`${interviewVia === "Phone Call" ? styles.active : ""} ${isReschedule ? 'pointerNone' : ""}`}
                                                onClick={() => handleInterviewVia("Phone Call")}>
                                                <img src={callInter} alt="" />
                                                On Phone Call
                                            </a>
                                        </li>
                                        <li>
                                            {interviewVia === 'Office' && <img src={checkBos} alt="" />}
                                            <a className={`${interviewVia === "Office" ? styles.active : ""} ${isReschedule ? 'pointerNone' : ""}`}
                                                onClick={() => handleInterviewVia("Office")}>
                                                <img src={officeInter} alt="" />
                                                Office Interview
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className={styles.FormGroup}>
                                    <label>Interview Title</label>
                                    <InputField
                                        className={styles.formControl}
                                        name="interviewTitle"
                                        placeholder="Enter the interview title"
                                        register={register}
                                        validation={{
                                            required: 'Required'
                                        }}
                                        error={errors?.interviewTitle}
                                        disabled={isReschedule}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <Row className={styles.FormGroup}>
                                    <label className="mb-3">Book a time slot (You can give option to the candidate)</label>
                                    <Col md={4}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <div className="dateAndTimePicker">
                                                <Controller
                                                    name="interviewDate"
                                                    control={control}
                                                    render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                        <DatePicker
                                                            label="Interview Date"
                                                            value={moment(value, "YYYY-MM-DD")}
                                                            onChange={(newValue) => {
                                                                onChange(moment(newValue).format("YYYY-MM-DD"));
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    )}
                                                    rules={{ required: 'Required' }}
                                                    onFocus={errors.interviewDate?.ref}
                                                />
                                                {errors !== undefined && errors.interviewDate && (
                                                    <span className="errorMessage">{errors.interviewDate.message}</span>
                                                )}
                                            </div>
                                        </LocalizationProvider>
                                    </Col>
                                    <Col md={4}>
                                        {/* <label>Interview time</label> */}
                                        {/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
                                        <div className="dateAndTimePicker">
                                            <Controller
                                                name="interviewTime"
                                                control={control}
                                                render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <TimePicker
                                                            label="interview Time"
                                                            value={moment(value, "HH:mm:ss")}
                                                            onChange={(newValue) => {
                                                                onChange(moment(newValue).format("HH:mm:ss"));
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            inputRef={ref}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.interviewTime?.ref}
                                            />
                                            {errors !== undefined && errors.interviewTime && (
                                                <span className="errorMessage">{errors.interviewTime.message}</span>
                                            )}
                                        </div>
                                        {/* </LocalizationProvider> */}
                                    </Col>
                                    <Col md={4}>
                                        <div className={styles.FormGroup}>
                                            <Controller
                                                name="interviewLength"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <Dropdown
                                                        isSearchable={true}
                                                        options={Duration}
                                                        placeholder="Interview Duration"
                                                        className={styles.formControl}
                                                        onChange={(e) => onChange(e.value)}
                                                        error={errors?.interviewLength}
                                                        inputRef={ref}
                                                        value={defaultDuration}
                                                        isDisabled={isReschedule}
                                                    />
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.interviewLength?.ref}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} className={styles.FormGroup}>
                                <label>Interview Description</label>
                                <TextArea
                                    name="interviewDescription"
                                    placeholder="Enter the information"
                                    register={register}
                                    // validation={{
                                    //     required: 'Required',
                                    // }}
                                    // error={errors?.jobDescription}
                                    maxLength='300'
                                    value={getValues().interviewDescription}
                                    disabled={isReschedule}
                                />
                            </Col>
                            <Col md={12}>
                                <div className={styles.btnNew}>
                                    <div className={styles.btnCancel} onClick={handleReset}>
                                        <div className={styles.btnEff}></div>
                                        <a>Cancel <span></span></a>
                                    </div>
                                    <div
                                        className={styles.btnReset}
                                        onClick={isReschedule ? handleSubmit(submitReschedule) : handleSubmit(submit)}
                                    >
                                        <div className={styles.effReset}></div>
                                        <a>Send Request<span></span></a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </div>
                <InterviewScheduled
                    isOpen={isSuccess}
                    handleClose={handleReset}
                    finalDateTimeInterview={finalDateTimeInterview}
                    interviewVia={interviewVia}
                />
            </Popup>
        </div>
    );
}







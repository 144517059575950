import { axiosInstance } from "../axiosInstance";
import { endPoints } from "../endPoints";

export const requestOtpForLogin = (data) => {
  return axiosInstance({ method: "post", url: endPoints.otp.requestOtpforLogin, data });
};

export const verifyOtpForLogin = (data) => {
  return axiosInstance({ method: "post", url: endPoints.otp.verifyOtpForLogin, data });
};

export const requestOtpForSignUp = (data) => {
  return axiosInstance({ method: "post", url: endPoints.otp.requestOtpforSignUp, data });
};

export const verifyOtpForSignUp = (data) => {
  return axiosInstance({ method: "post", url: endPoints.otp.verifyOtpForSignUp, data });
};

export const requestOtpEmailPhoneforPreference = (data) => {
  return axiosInstance({ method: "put", url: endPoints.otp.requestOtpEmailPhoneforPreference, data });
}

export const verifyOtpEmailPhoneforPreference = (data) => {
  return axiosInstance({ method: "put", url: endPoints.otp.verifyOtpEmailPhoneforPreference, data });
}
// import { style } from '@mui/system';
import React from 'react';
import { Col, Container, /**FormGroup,*/ Row } from 'react-bootstrap';
import styles from './CompanyTrust.module.scss';
// import proImage from '../../assets/images/userProfile.jpg';
import ClientOne from '../../assets/images/ClientLogo/brand1.png';
import ClientTwo from '../../assets/images/ClientLogo/brand2.png';
import ClientTre from '../../assets/images/ClientLogo/brand3.png';
import ClientFor from '../../assets/images/ClientLogo/brand4.png';
import ClientFiv from '../../assets/images/ClientLogo/brand5.png';
import ClientSi from '../../assets/images/ClientLogo/brand6.png';
import ClientSe from '../../assets/images/ClientLogo/brand7.png';
import ClientEg from '../../assets/images/ClientLogo/brand8.png';
import ClientNin from '../../assets/images/ClientLogo/brand9.png';
import ClientTen from '../../assets/images/ClientLogo/brand10.png';
import ClientElv from '../../assets/images/ClientLogo/brand11.png';
import ClientTwl from '../../assets/images/ClientLogo/brand12.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import React from "react";
import Slider from "react-slick";

function AutoPlay() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };
  
  return (
    <div className="slider-container">
      <Slider style={{ width: 1000, margin: "0 auto" }} className={styles.SliderLogo} {...settings}>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientOne} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientTwo} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientTre} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientFor} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientFiv} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientSi} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientSe} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientEg} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientNin} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientTen} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientElv} alt="" style={{ width: 170 }} />
        </div>
        <div className={styles.CompanyTwoLog} style={{ textAlign:'center'}}>
          <img src={ClientTwl} alt="" style={{ width: 170 }} />
        </div>
      </Slider>
    </div>
  );
}

export default AutoPlay;





// const contentStyle = {
//   height: '160px',
//   color: '#fff',
//   lineHeight: '160px',
//   textAlign: 'center',
//   background: '#364d79',
// };

// const CompanyLogo = () => (
//   <section className={styles.clientTrust}>
//     <Container fluid>
//       <Row>
//         <Col md={12}>
//           <div className={styles.cerasoleTest}>
//             <Carousel className={styles.cerasoleNew} autoplay stopOnHover showThumbs={false} >
//               <div className={styles.CompanyTwoLog}>
//                 <ul>
//                   <li><img src={ClientOne} alt="" /></li>
//                   <li><img src={ClientTwo} alt="" /></li>
//                   <li><img src={ClientTre} alt="" /></li>
//                   <li><img src={ClientFor} alt="" /></li>
//                   <li><img src={ClientSe} alt="" /></li>
//                   <li><img src={ClientEg} alt="" /></li>
//                 </ul>
//               </div>
//               <div className={styles.CompanyTwoLog}>
//                 <ul>
//                   <li><img src={ClientFiv} alt="" /></li>
//                   <li><img src={ClientSi} alt="" /></li>
//                   <li><img src={ClientNin} alt="" /></li>
//                   <li><img src={ClientTen} alt="" /></li>
//                   <li><img src={ClientElv} alt="" /></li>
//                   <li><img src={ClientTwl} alt="" /></li>
//                 </ul>
//               </div>
//             </Carousel>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   </section>
// );

// export default CompanyLogo;
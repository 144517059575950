import React, { useEffect, useState } from 'react';

//styles
import styles from './Signup.module.scss';

//packags
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OtpInput from "otp-input-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";
import { Col, Container, Row } from 'react-bootstrap';

//images
import CompanyImage from '../../assets/images/logoContnt.svg';
// import LoginImage from '../../assets/images/indiaMap.png';
import uploadFile from '../../assets/images/upload.svg';
// import satImag from '../../assets/images/sat.svg';
import satImag from '../../assets/images/satNew.svg';
import preImg from '../../assets/images/present.svg';

//components
import InputField from '../common/InputField/InputField';
import { getCity } from '../../services/api/common';
import { Dropdown } from '../common/Dropdown/Dropdown';
import { hideLoader, setCookie, showLoader } from '../../helpers';
import { employerOnBoard, employerRegister, getProfile } from '../../services/api/employer';
import { requestOtpForSignUp, verifyOtpForSignUp } from '../../services/api/otp';
import { fileUpload } from '../../services/api/upload';

function Signup() {
    const { state } = useLocation();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm();

    //state
    const [cityData, setCityData] = useState([]);
    const [companyLogoObject, setCompanyLogoObject] = useState();
    const [companyLogoName, setCompanyLogoName] = useState();
    const [companyPictureObject, setCompanyPictureObject] = useState();
    const [companyPitureName, setCompanyPictureName] = useState();
    const [panCardProofObject, setPanCardProofObject] = useState();
    const [panCardProofName, setPanCardProofName] = useState();
    const [gstProofObject, setGstProofObject] = useState();
    const [gstProofName, setGstProofName] = useState();
    const [whatsappMobile, setWhatsappMobile] = useState();
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [otpTiming, setOtpTiming] = useState(180);
    const [intervalId, setIntervalId] = useState(0);
    const [otpNumber, setOtpNumber] = useState();
    const [otpError, setOtpError] = useState(false);
    const [userDataForOtp, setUserDataForOtp] = useState({});

    useEffect(() => {
        getCityList();
    }, []);

    // let base64ToString;

    useEffect(() => {
        if(state?.whatsappMobile) {
            setWhatsappMobile(state.whatsappMobile);
            setValue('alternateNo', state.whatsappMobile);
        }
            setValue('campaignSourceURL', state?.sourceData ? state?.sourceData : 'Generic');
    }, [state]);

    const getCityList = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getCity();
            setCityData(
                data && data.length > 0 && data.map(({ stateName, districtName }) => {
                    let data = districtName.map((item) => { return { label: item, value: item, state: stateName } });
                    return {
                        label: stateName,
                        options: data
                    }
                })
            );
        } catch (error) {
        }
    }

    const handleCity = (city, state) => {
        setValue('city', city);
        setValue('state', state);
        setValue('companyAddress', "");
    }

    const handleFileUpload = async ({ target: { files, id } }) => {
        if (id === 'companyLogo' && files && files[0]) {
            setCompanyLogoObject(files[0]);
            setCompanyLogoName(files[0].name);
        } else if (id === 'companyPicture') {
            setCompanyPictureObject(files[0]);
            setCompanyPictureName(files[0].name);
        } else if (id === 'panCardProof') {
            setPanCardProofObject(files[0]);
            setPanCardProofName(files[0].name);
        } else {
            setGstProofObject(files[0]);
            setGstProofName(files[0].name);
        }
        setValue(id, files[0].name)
    }

    const clearFileUpload = (id) => {
        if (id === 'companyLogo') {
            setCompanyLogoObject();
            setCompanyLogoName("");
        } else if (id === 'companyPicture') {
            setCompanyPictureObject();
            setCompanyPictureName("");
        } else if (id === 'panCardProof') {
            setPanCardProofObject();
            setPanCardProofName("");
        } else {
            setGstProofObject();
            setGstProofName("");
        }
        document.getElementById(id).value = null;
    }

    const registerEmployer = async(name) => {
        try {
            showLoader();
            let values = {
                language: "English",
                fullName: name,
                whatsappMobile,
                gender: "Male"
            }
            const {
                data: {
                    data
                }
            } = await employerRegister(values);
            hideLoader();
            let requestOtpData = {
                type: 'employer',
                whatsappMobile,
                userId: data._id
            }
            setUserDataForOtp(requestOtpData);
            await sendOtp(requestOtpData);
        } catch (error) {
            hideLoader();
            const { data: { message } } = error;
            hideLoader();
            toast.error(message ? message : "Failed, Try again");
        }
    }

    const sendOtp = async (values) => {
        try {
        //   showLoader();
          let {
            data: {
              message, status
            }
          } = await requestOtpForSignUp(values);
          setIsOtpSent(true);
          handleOtpTiming();
          hideLoader();
          toast.success(message ? message : "Otp has sent successfully");
        } catch (error) {
            hideLoader();
            const { data: { message } } = error;
            toast.error(message ? message : "Failed, Try again");
        }
      }

      const handleOtpTiming = async() => {
        let interval = setInterval(() => {
              setOtpTiming(otpTiming => otpTiming - 1);
        }, 1000);
        setIntervalId(interval);
      }
    
      useEffect(()=>{
        if(intervalId && otpTiming <= 0) {
          clearInterval(intervalId);
          setOtpTiming(180);
          setIntervalId(0);
        }
      }, [otpTiming]);

      const handleOtpChange = (data) => {
        setOtpNumber(data);
        data ? setOtpError(false) : setOtpError(true);
      }

      const otpSubmit = async () => {
        if (!otpNumber) {
          setOtpError(true);
          return;
        }
        try {
          let submitData = userDataForOtp;
          submitData.otp = otpNumber;
        //   showLoader();
          let {
            data: {
              /**data, message*/
            }
          } = await verifyOtpForSignUp(submitData);
            hideLoader();
            
            clearInterval(intervalId);
            setOtpTiming(180);
            setIntervalId(0);
            let images = [];
                if(companyLogoObject) {
                    images.push({
                        type: "companyLogo",
                        image: companyLogoObject
                    });
                }
                if(companyPictureObject) {
                    images.push({
                        type: "companyPicture",
                        image: companyPictureObject
                    });
                }
                if(panCardProofObject) {
                    images.push({
                        type: "panCardProof",
                        image: panCardProofObject
                    });
                }
                if(gstProofObject) {
                    images.push({
                        type: "gstProof",
                        image: gstProofObject
                    });
                }
            images?.length > 0 && images.map(async(item) => {
             await uploadFiles(item.type, item.image);
            });
            setTimeout(() => {
            onBoard();
            }, 2000);
        } catch (error) {
          const { data: {message}} = error;
          hideLoader();
          toast.error(message?message: "Failed, Try again");
        }
      }

      const handleResendOtp = () => {
        sendOtp(userDataForOtp);
        setOtpNumber("");
      }

    const uploadFiles = async(type, image) => {
        try {
            // showLoader();
            let content = new FormData();
            content.append('fileKey', image);
            const {
                data: {
                    data
                } } = await fileUpload(content);
            setValue(type, data?.fileKey);
            hideLoader();

        } catch (error) {
            hideLoader();
            const { data: { message } } = error;
            hideLoader();
            toast.error(message ? message : "Failed, Try again");
        }
    }

      const onBoard = async() => {
        try {
            let values = getValues();
            // showLoader();
            let params = {
                id: userDataForOtp?.userId
            }
            let {
              data: {
                data, message
              }
            } = await employerOnBoard(values, params);
            if (data?.refreshToken) {
                hideLoader();
                toast.success(message ? message : "Employer onboarded successfully");
                setCookie("token", data.refreshToken);
                let employerData = await getProfileData();
                localStorage.setItem("userData", JSON.stringify(employerData));
                clearInterval(intervalId);
                setOtpTiming(180);
                setIntervalId(0);
                navigate('/dashboard');
              }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
      }

      const getProfileData = async () => {
        try {
          showLoader();
          let {
            data: {
              data
            }
          } = await getProfile();
          hideLoader();
          return data;
    
        } catch (error) {
          hideLoader();
        }
      }

    const submit = (submitValues) => {
        if (!companyPictureObject && !panCardProofObject && !gstProofObject) {
            toast.warning("At least one of the 3 proofs is mandatory, please upload Pan Card and/or GST document and/or Shop Image/Business Photo.");
        } else {
            registerEmployer(submitValues.fullName);
        }
    }

    return (
        <section className={styles.mainBg}>
            <Container>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8} className={styles.containerBg}>
                        <Row>
                            <Col md={4}>
                                <div className={styles.logoNew}>
                                    <img src={CompanyImage} alt=""/>
                                    <img className={styles.prImage} src={preImg} alt=""/>
                                </div>
                            </Col>
                            <Col md={8}>
                                <div className={styles.satImg}>
                                    <ul>
                                        <li className={styles.imagePre}><img src={preImg} alt=""/></li>
                                        <li><img className={styles.satImage} src={satImag} alt=""/></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        {!isOtpSent ? (
                            <Row className={styles.formRegiter}>
                                <Col md={6} className={styles.FormGroup}>
                                    <label>Your Name</label>
                                    <InputField
                                        name="fullName"
                                        type="text"
                                        placeholder="Enter your full name"
                                        register={register}
                                        validation={{
                                            required: 'Required',
                                            pattern: {
                                                value: /^[A-Za-z ]+$/,
                                                message: 'Name should contain only alphabets and spaces'
                                            }
                                        }}
                                        error={errors?.fullName}
                                    />
                                </Col>
                                <Col md={6} className={styles.FormGroup}>
                                    <label>Your Company / Business / Enterprise Name</label>
                                    <InputField
                                        name="companyName"
                                        type="text"
                                        placeholder="Enter your company name"
                                        register={register}
                                        validation={{
                                            required: 'Required',
                                            pattern: {
                                                value: /^[A-Za-z ]+$/,
                                                message: 'company name should contain only alphabets and spaces'
                                            }
                                        }}
                                        error={errors?.companyName}
                                    />
                                </Col>
                                <Col md={6} className={styles.FormGroup}>
                                    <label>Your email address</label>
                                    <InputField
                                        name="companyEmail"
                                        type="text"
                                        placeholder="Enter your company mail"
                                        register={register}
                                        validation={{
                                            required: 'Required',
                                            pattern: {
                                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                message: 'Enter valid mail id'
                                            }
                                        }}
                                        error={errors?.companyEmail}
                                    />
                                </Col>
                                <Col md={6} className={styles.FormGroup}>
                                    <label>Your City / District</label>
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                            <Dropdown
                                                isSearchable={true}
                                                options={cityData}
                                                placeholder="Select your city/district"
                                                className={styles.formControl}
                                                onChange={(e) => handleCity(e.value, e.state)}
                                                error={errors?.jobCity}
                                                inputRef={ref}
                                            />
                                        )}
                                        rules={{ required: true }}
                                        onFocus={errors.city?.ref}
                                    />
                                </Col>
                                <Col md={6} className={styles.FormGroup}>
                                    <label>Upload company logo</label>
                                    <div className={styles.videoUpload}>
                                        {!companyLogoName ? (<span className={styles.noVideo}>
                                            Choose file
                                        </span>) : (
                                            <span className={styles.videoUp}>
                                                {companyLogoName}  <FontAwesomeIcon icon={faX} onClick={() => clearFileUpload('companyLogo')} />
                                            </span>)}
                                        <div className={styles.fileUpload}>
                                            <Controller
                                                name="companyLogo"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        id="companyLogo"
                                                        onChange={handleFileUpload}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.companyLogo?.ref}
                                            />
                                            <img src={uploadFile} alt=""/>
                                        </div>
                                    </div>
                                    {errors && errors.companyLogo && <span className="errorMessage">Required</span>}
                                </Col>
                                <Col md={6} className={styles.FormGroup}>
                                    <label>Upload shop photo/visiting card</label>
                                    <div className={styles.videoUpload}>
                                        {!companyPitureName ? (<span className={styles.noVideo}>
                                            Choose file
                                        </span>) : (
                                            <span className={styles.videoUp}>
                                                {companyPitureName}  <FontAwesomeIcon icon={faX} onClick={() => clearFileUpload('companyPicture')} />
                                            </span>)}
                                        <div className={styles.fileUpload}>
                                            <Controller
                                                name="companyPicture"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        id="companyPicture"
                                                        onChange={handleFileUpload}
                                                    />
                                                )}
                                                // rules={{ required: true }}
                                                onFocus={errors.companyLogo?.ref}
                                            />
                                            <img src={uploadFile} alt=""/>
                                        </div>
                                    </div>
                                    {errors && errors.companyPicture && <span className="errorMessage">Required</span>}
                                </Col>
                                <Col md={6} className={styles.FormGroup}>
                                    <label>Upload PAN card pic</label>
                                    <div className={styles.videoUpload}>
                                        {!panCardProofName ? (<span className={styles.noVideo}>
                                            Choose file
                                        </span>) : (
                                            <span className={styles.videoUp}>
                                                {panCardProofName}  <FontAwesomeIcon icon={faX} onClick={() => clearFileUpload('panCardProof')} />
                                            </span>)}
                                        <div className={styles.fileUpload}>
                                            <Controller
                                                name="panCardProof"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg, application/pdf"
                                                        id="panCardProof"
                                                        onChange={handleFileUpload}
                                                    />
                                                )}
                                                // rules={{ required: true }}
                                                onFocus={errors.companyLogo?.ref}
                                            />
                                            <img src={uploadFile} alt=""/>
                                        </div>
                                    </div>
                                    {errors && errors.panCardProof && <span className="errorMessage">Required</span>}
                                </Col>
                                <Col md={6} className={styles.FormGroup}>
                                    <label>Upload GST certificate</label>
                                    <div className={styles.videoUpload}>
                                        {!gstProofName ? (<span className={styles.noVideo}>
                                            Choose file
                                        </span>) : (
                                            <span className={styles.videoUp}>
                                                {gstProofName}  <FontAwesomeIcon icon={faX} onClick={() => clearFileUpload('gstProof')} />
                                            </span>)}
                                        <div className={styles.fileUpload}>
                                            <Controller
                                                name="gstProof"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg, application/pdf"
                                                        id="gstProof"
                                                        onChange={handleFileUpload}
                                                    />
                                                )}
                                                // rules={{ required: true }}
                                                onFocus={errors.companyLogo?.ref}
                                            />
                                            <img src={uploadFile} alt=""/>
                                        </div>
                                    </div>
                                    {errors && errors.gstProof && <span className="errorMessage">Required</span>}
                                </Col>
                                <Col md={12}>
                                    <div className={styles.btnsave}>
                                    {/* <div className={styles.btnNew} type="submit"> */}
                                        <div className={styles.btnNew} type="submit" onClick={handleSubmit(submit)}>
                                            <div className={styles.effNew}></div>
                                            <button>Register<span></span></button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                        <Row>
                            {/* <Col md={6} className={styles.padZero}>
                                <div className={styles.indiaMap}>
                                <img src={LoginImage} alt='' />
                                <div className={styles.satCon}>
                                    <h3>Hire Staff with<p>S.A.T.</p>
                                    <span>Speed, Accuracy, Trust</span></h3>
                                </div>
                                </div>
                            </Col> */}
                            <Col md={12}>
                                <div className={styles.otpsubmit}>
                                    <div className={styles.cntntForm}>
                                        {/* <img src={CompanyImage} alt='' /> */}
                                        <p>Please enter the OTP,<br />sent to your Phone Number</p>
                                        <span>{whatsappMobile}
                                            {/* <a onClick={() => setIsOtpSent(!isOtpSent)}><img src={editIcon} alt='' /></a> */}
                                            {/* <a onClick={handleResendOtp}>Resend Otp</a> */}
                                        </span>
                                    </div>
                                    <div className={styles.submitorm}>
                                        <div className={styles.inpOtp}>
                                            <OtpInput
                                                value={otpNumber}
                                                onChange={handleOtpChange}
                                                autoFocus
                                                OTPLength={6}
                                                otpType="number"
                                                disabled={false}
                                                placeholder="------"
                                            />

                                            {otpError && <span className='errorMessage mt-3'>Otp numer is required</span>}
                                        </div>
                                        {intervalId > 0 ? (
                                            <p><span>{otpTiming}</span> seconds remaining</p>
                                        ) : (
                                            <p>Haven’t receive the code? <span><a className={styles.resendOtp} onClick={handleResendOtp}>Resend Otp</a></span></p>
                                        )}
                                        <div className={styles.btnsave} onClick={otpSubmit}>
                                    {/* <div className={styles.btnNew} type="submit"> */}
                                        <div className={styles.btnNew}>
                                            <div className={styles.effNew}></div>
                                            <button>submit<span></span></button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                           )}
                    </Col>
                </Row>
            </Container>
            <Container maxwidth="sm" className={styles.newFoot}>
                <Row>
                    <Col md={12}>
                        <div className={styles.footerHead}>
                            <p>© 2022 Jobsgaar. All rights reserved.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Signup;
import styles from './TextArea.module.scss';
import { Fragment, useEffect, useState } from 'react';

export const TextArea = ({
  label,
  type = "text",
  placeholder = "Type here",
  register = null,
  defaultValue = "",
  value = "",
  name = "",
  maxLength = null,
  readOnly = false,
  viewOnly = false,
  disabled = false,
  error,
  onClick = () => {
    return null;
  },
  onKeyUp = () => {
    return null;
  },
  onChange = () => {
    return null;
  },
  message = {},
  className = "",

  validation = {},
  borderRadius = "none",
  backgroundColor = "#ffffff",
  borderBottom,
  id,
  valueAsNumber
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(value ? value.length : 0)
  }, [value]);

  const getCount = ({ target: { name, value } }) => {
    setCount(value.length);
  }

  return (
    <div>
      {viewOnly ? (
        <Fragment>
          <label>{label}</label>
          <br />
          <span>{value}</span>
        </Fragment>
      ) : (
        <Fragment>
          <label>{label}</label>
          {register === null ? (
            <textarea
              type={type}
              placeholder={placeholder}
              value={value}
              name={name}
              maxLength={maxLength}
              onChange={onChange}
              disabled={disabled}
              readOnly={readOnly}
              onClick={onClick}
              onKeyUp={getCount}
              aria-invalid={error !== undefined ? true : false}
              autoComplete="off"
              onWheel={(e) => type === "number" && e.target.blur()}
              id={id}
              className={`${styles.formControl} ${className}`}
            />
          ) : (
            <textarea
              {...register(name, validation, valueAsNumber)}
              type={type}
              name={name}
              placeholder={placeholder}
              defaultValue={defaultValue}
              maxLength={maxLength}
              disabled={disabled}
              readOnly={readOnly}
              onKeyUp={getCount}
              aria-invalid={error !== undefined ? true : false}
              autoComplete="off"
              onWheel={(e) => type === "number" && e.target.blur()}
              id={id}
              className={`${styles.formControl} ${className}`}
            />
          )}
        </Fragment>
      )}
      <span className={styles.worldLenth}>{count}/{maxLength}</span>
      {error !== undefined && (
        <span className="errorMessage">{error.message}</span>
      )}
    </div>
  );
};

export default TextArea;
import { useState, Fragment } from 'react';
import styles from './CollapsibleTable.module.scss';
import { Row } from 'react-bootstrap';
//materiaui
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Collapse, Paper } from '@mui/material';

import moment from 'moment';
import JobExpandedView from '../../Jobs/JobExpandedView/JobExpandedView';
import { currencySymbol } from '../../../common/CurrencySymbol';

const CollapsibleTable = ({
  tableHeading,
  tableRows,
  fetchJobs = () => {
    return null;
  }
}) => {
  const [state, setState] = useState({});

  const collapseTrigger = (index) => {
    state[index] && state[index] === 'open' ? setState({ [index]: 'close' }) : setState({ [index]: 'open' });
  }

  return (
    <Row>
      <div className={styles.scrollbarContainer}>
        <div className={styles.scrollContainer}>
          <div className={styles.scrollcontent}>
            <TableContainer component={Paper} sx={{ overflowX: "hidden" }} className={styles.tableWrapper}>
              <Table className='table-fixed' stickyHeader aria-label="collapsible table">
                <TableHead className={styles.tableHeader}>
                  <TableRow>
                    {tableHeading && tableHeading.length > 0 && tableHeading.map(({ name }) => {
                      return (
                        <TableCell className={styles.tableCellHeader} key={name}>
                          {name}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className={styles.body}>
                  {tableRows && tableRows.length > 0 && tableRows.map((item, index) => {
                    let currency = currencySymbol[item?.salaryCurrency];
                    return (
                      <Fragment key={index}>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => collapseTrigger(index)} className={`${styles.tableRow} ${state[index] === 'open' ? styles.blurBackground : ""}`}>
                          <TableCell className={styles.tableCell} scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {moment(item?.postDateTime).format("DD MMM'YY")}
                          </TableCell>
                          <TableCell className={styles.tableCell}>{item?.position}</TableCell>
                          <TableCell className={styles.tableCell}>
                            {currency}{item?.jobSalary} - {currency}{item?.jobSalaryMax}</TableCell>
                          <TableCell className={styles.tableCell}>{item?.jobExperience}</TableCell>
                          <TableCell className={styles.tableCell}>{item?.jobEducation}</TableCell>
                          <TableCell className={styles.tableCell}>{item?.jobCity}</TableCell>
                          <TableCell className={styles.tableCell}>{item?.appliedCount}</TableCell>
                        </TableRow>
                        <TableRow className={styles.CollapseTab}>
                          <TableCell className={styles.tableCellCollapse} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                            <Collapse in={state[index] === 'open'} timeout="auto" unmountOnExit>
                              <JobExpandedView state={state} index={index} data={item} currency={currency} fetchJobs={fetchJobs} />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                        {/* <TableRow className={styles.CollapseTab}>
                      <TableCell className={styles.tableCellCollapse} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={state[index] === 'open'} timeout="auto" unmountOnExit>
                          <Row>
                            <Col md={2} className={styles.colWidth}>
                              <div className={styles.rowData}>
                                <h3><span>Job Title</span>{jobTitle}</h3>
                                <h3><span>Job Type</span>{jobType}</h3>
                                <h3><span>Shift</span>{jobShift}</h3>
                                <h3><span>Working From</span>{workingFrom}</h3>
                                <h3><span>No. of Vacancies</span>{jobVacancy}</h3>
                                <h3><span>Working Time</span>{workFrom} am - {workTo} pm</h3>
                              </div>
                            </Col>
                            <Col md={2} className={styles.colWidth}>
                              <div className={styles.rowData}>
                                <h3><span>Contact Person</span>{jobContactPerson}</h3>
                                <h3><span>Incentive</span>
                                {monthlyIncentive ? `Monthly Incentive: ${currency} ${monthlyIncentive}` : oneTimeIncentive ? `One Time Incentive: ${currency} ${oneTimeIncentive}` : 'No'}
                                </h3>
                                <h3><span>Any Deposit/Charge</span>
                                {jobChargeOneTime ? `One Time Charge: ${currency} ${jobChargeOneTime}` : jobChargeRecursive ? `One Time Incentive: ${currency} ${jobChargeRecursive}` : 'No'}
                                </h3>
                                <h3><span>Degree</span>{jobDegree ? jobDegree : "-"}</h3>
                                <h3><span>Branch / Stream</span>{jobBranch ? jobBranch : "-"}</h3>
                                <h3><span>Gender</span>{jobGender}</h3>
                              </div>
                            </Col>
                            <Col md={3} className={styles.colWidth}>
                              <div className={styles.rowData}>
                                <h3><span>Age</span>{agefrom} - {ageTo}</h3>
                                <h3><span>Language</span>{jobLanguage}</h3>
                                <h3>
                                  <span>Driving Requirements</span>
                                  <ul className={styles.moterVeh}>
                                    <li className={styles.newDrive}><FontAwesomeIcon icon={isLicense ? faCircleCheck : faCircleXmark} />Driver’s License</li>
                                    <li className={styles.newDrive}><FontAwesomeIcon icon={isOwnBike ? faCircleCheck : faCircleXmark} />Motorcycle / 2 Wheeler</li>
                                    <li  className={styles.newDrive}><FontAwesomeIcon icon={isOwnCar ? faCircleCheck : faCircleXmark} />Car / 4 Wheeler</li>
                                  </ul>
                                </h3>
                                <h3>
                                  <span>Welcome Video</span>
                                  <i className="far fa-play-circle" aria-hidden="true"></i> video.mp4
                                </h3>

                              </div>
                            </Col>
                            <Col md={2} className={styles.colWidth}>
                            <div className={styles.rowData}>
                              <h3><span>Office Address</span>{jobAddress}</h3>
                              <h3><span>Job Desciription</span>{jobDescription}</h3>
                            </div>
                            </Col>
                            <Col md={3} className={styles.colWidth}>
                            <div className={styles.rowData}>
                              <h3>
                                <span>Working Days</span>
                                <span className={`${styles.borderedSpan} ${isMonday ? styles.activeContent : ''}`}>Mon</span>
                                <span className={`${styles.borderedSpan} ${isTuesday ? styles.activeContent : ''}`}>Tue</span>
                                <span className={`${styles.borderedSpan} ${isWednesday ? styles.activeContent : ''}`}>Wen</span>
                                <span className={`${styles.borderedSpan} ${isThursday ? styles.activeContent : ''}`}>Thu</span>
                                <span className={`${styles.borderedSpan} ${isFriday ? styles.activeContent : ''}`}>Fri</span>
                                <span className={`${styles.borderedSpan} ${isSaturday ? styles.activeContent : ''}`}>Sat</span>
                                <span className={`${styles.borderedSpan} ${isSunday ? styles.activeContent : ''}`}>Sun</span>
                              </h3>
                              <h3 className="">
                                <span>Specialization</span>
                                {specializations && specializations.length > 0 && specializations.map((item, index) => {
                                  return(
                                  <span className={styles.specializationSpan} key={index}>{item}</span>
                                  )
                                })}
                              </h3>
                            </div>
                            </Col> 
                            <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6">
                                <div className={styles.actionButtons}>
                                  <div className={styles.btnNew} onClick={() => navigate(`/jobs/edit/${_id}`)}>
                                    <div className={styles.effNew}></div>
                                    <a><FontAwesomeIcon icon={faPencil} /> Edit Job Post<span></span></a>
                                  </div>

                                  <div className={styles.viewNew}>
                                    <div className={styles.effView}></div>
                                    <a><FontAwesomeIcon icon={faUser} /> View Candidates<span></span></a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 pt-2">
                                <ChangeJobStatus />
                              </div>
                            </div>
                          </div>
                          </Row>
                        </Collapse>
                      </TableCell>
                    </TableRow> */}
                      </Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </Row>
  )
}

export default CollapsibleTable;
// import { style } from "@mui/system";
import { Col, Container, Row } from "react-bootstrap"
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
// import SubHeader from '../../common/SubHeader/SubHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './JobOfferListView.module.scss';
import previewExpand from '../../../assets/images/preview.svg';
// import { Insights } from "@mui/icons-material";
// import moment from "moment";




export default function JobOfferListView() {
  
    return (
        <HomeLayout>
          
          <section className={styles.offerList}>
            {/* <SubHeader /> */}
            <Container fluid className={styles.subHeader}>
                <Row>
                    <Col md={5}>
                        <div className={styles.subHeader}>
                            <h3>3 Offers Pending <p>(*Click on an offer to get the detail)</p></h3>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className={styles.offerTable}>
                            <div className={styles.scrollbarContainer}>
                                <div className={styles.scrollContainer}>
                                    <div className={styles.scrollcontent}>
                                        <table className={styles.tableJonOffer}>
                                            <thead className={styles.tableHeader}>
                                                <tr  className={styles.tableCellHeader}>
                                                    <th>S.no. </th>
                                                    <th>Sent on </th>
                                                    <th>Accepted on</th>
                                                    <th>Candidate’s name</th>
                                                    <th>Job Position </th>
                                                    <th>Salary</th>
                                                    <th>Job City</th>
                                                    <th>Joining Date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className={styles.tableRow}>
                                                    <td>1.</td>
                                                    <td>22 Jul’22</td>
                                                    <td>22 Jul’22</td>
                                                    <td>Jobgendra Kumar Sharma</td>
                                                    <td>Supervisor</td>
                                                    <td>₹ 14,000</td>
                                                    <td>Lucknow</td>
                                                    <td>17 Aug’22</td>
                                                    <td><img src={previewExpand} alt="" /></td>
                                                </tr>
                                                <tr className={styles.tableRow}>
                                                    <td>2.</td>
                                                    <td>22 Jul’22</td>
                                                    <td>22 Jul’22</td>
                                                    <td>Jobgendra Kumar Sharma</td>
                                                    <td>Supervisor</td>
                                                    <td>₹ 14,000</td>
                                                    <td>Lucknow</td>
                                                    <td>17 Aug’22</td>
                                                    <td><img src={previewExpand} alt="" /></td>
                                                </tr>
                                                <tr className={styles.tableRow}>
                                                    <td>3.</td>
                                                    <td>22 Jul’22</td>
                                                    <td>22 Jul’22</td>
                                                    <td>Jobgendra Kumar Sharma</td>
                                                    <td>Supervisor</td>
                                                    <td>₹ 14,000</td>
                                                    <td>Lucknow</td>
                                                    <td>17 Aug’22</td>
                                                    <td><img src={previewExpand} alt="" /></td>
                                                </tr>
                                                <tr className={styles.tableRow}>
                                                    <td>4.</td>
                                                    <td>22 Jul’22</td>
                                                    <td>22 Jul’22</td>
                                                    <td>Jobgendra Kumar Sharma</td>
                                                    <td>Supervisor</td>
                                                    <td>₹ 14,000</td>
                                                    <td>Lucknow</td>
                                                    <td>17 Aug’22</td>
                                                    <td><img src={previewExpand} alt="" /></td>
                                                </tr>
                                                <tr className={styles.tableRow}>
                                                    <td>5.</td>
                                                    <td>22 Jul’22</td>
                                                    <td>22 Jul’22</td>
                                                    <td>Jobgendra Kumar Sharma</td>
                                                    <td>Supervisor</td>
                                                    <td>₹ 14,000</td>
                                                    <td>Lucknow</td>
                                                    <td>17 Aug’22</td>
                                                    <td><img src={previewExpand} alt="" /></td>
                                                </tr>
                                                <tr className={styles.tableRow}>
                                                    <td>6.</td>
                                                    <td>22 Jul’22</td>
                                                    <td>22 Jul’22</td>
                                                    <td>Jobgendra Kumar Sharma</td>
                                                    <td>Supervisor</td>
                                                    <td>₹ 14,000</td>
                                                    <td>Lucknow</td>
                                                    <td>17 Aug’22</td>
                                                    <td><img src={previewExpand} alt="" /></td>
                                                </tr>
                                                <tr className={styles.tableRow}>
                                                    <td>7.</td>
                                                    <td>22 Jul’22</td>
                                                    <td>22 Jul’22</td>
                                                    <td>Jobgendra Kumar Sharma</td>
                                                    <td>Supervisor</td>
                                                    <td>₹ 14,000</td>
                                                    <td>Lucknow</td>
                                                    <td>17 Aug’22</td>
                                                    <td><img src={previewExpand} alt="" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
          </section>
          
        </HomeLayout>

    )
}
import React from "react";
import { useState, useEffect, useRef } from "react";
import { Col, Row, Table } from "react-bootstrap";
import styles from './AllPostedJobs.module.scss'

import closeJob from '../../../../assets/images/closeIcon.svg';
import checkIcon from '../../../../assets/images/chkIcon.svg';
import searchImag from '../../../../assets/images/searchicon.svg';
import leftArrow from '../../../../assets/images/arrowLeft.svg';
import rightArrow from '../../../../assets/images/arrowRight.svg';
// import SmarySec from '../../../../assets/images/attentionBg.svg';


import Popup from "../Popup";
// import { Controller, useForm } from "react-hook-form";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { TextField } from "@mui/material";
import InputField from '../../../common/InputField/InputField';
import { Dropdown } from "../../../../common/DropdownOptions";
import { /**getLocalTime,*/ hideLoader, showLoader } from "../../../../helpers";
import moment from "moment";
import JobOfferJobFilter from "../JobOfferJobFilter/JobOfferJobFilter";
import { getAllJobs } from "../../../../services/api/employer";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";


const AllPostedJobsNew = ({
    isOpen,
    handleClose,
    cityData,
    handleJobData,
    setIsExistingJob,

}) => {
    const [jobsData, setJobsData] = useState(false);
    const [searchData, setSearchData] = useState(false);
    const [searchedVal, setSearchedVal] = useState("");
    const [selectedIndex, setSelectedIndex] = useState("");
    const [resetData, setResetData] = useState([]);
    const [jobPosition, setJobPosition] = useState();
    const [jobItem, setJobItem] = useState({});
    const [isError, setIsError] = useState(false);

    const [scrollX, setscrollX] = useState(0);
    const [scrollEnd, setscrollEnd] = useState(false);
    const [isPositionFilter, setIsPositionFilter] = useState(false);
    useEffect(() => {
        const getJobsData = async () => {
            try {
                showLoader();
                let jobStatus = 'Live';
                let params = {
                    status: jobStatus
                };
                const {
                    data: {
                        data /** ,message*/
                    }
                } = await getAllJobs(params);
                setJobsData(data);
                setSearchData(data)
                // toast.success(message ? message: "success")
                hideLoader();
            } catch (error) {
                hideLoader();
                toast.error("Failed");
            }
        }
        getJobsData();
    }, []);

    const searchFilter = (e) => {
        // setSearchedVal(e.target.value)
        const searchResult = JSON.stringify(searchData);
        let parseData = JSON.parse(searchResult);
        if (jobsData.length !== 0) {
            if (e.key === 'Enter') {
                if (searchedVal === "") {
                    setJobsData(parseData)
                } else {
                    const result = parseData.filter((row, index) => {
                        return row.jobTitle.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                            row.jobCity.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                            row.jobEducation.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                            row.jobExperience.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                            row.jobSalary.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                            moment(row.postDateTime).format('DD MMM. YY').toString().toLowerCase().includes(searchedVal.toString().toLowerCase())

                        // note that I've incorporated the searchedVal length check here
                        //  !searchedVal.length || row.jobTitle.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())

                    })
                    setJobsData(result)
                }
            } else {
                setJobsData(parseData)
            }
        }

        //console.log("result----------->", result)
    }

    const handleJobFilter = (position, education, date, city, experience) => {
        let searchObj = {};
        if (education?.length !== 0) {
            searchObj.jobEducation = education.value;
        }
        if (date) {
            searchObj.filterDate = date;
        }
        if (city?.length !== 0) {
            searchObj.jobCity = city.value;
        }
        if (experience?.length !== 0) {
            searchObj.jobExperience = experience.value;
        }
        if (position) {
            searchObj.position = position;
        }
        if (Object.keys(city).length === 0 && Object.keys(education).length === 0 && Object.keys(experience).length === 0 && !date && !position) {
            setIsPositionFilter(false);
            setJobPosition("");
            if (resetData?.length > 0) {
                setJobsData(resetData)
            }
        } else if (Object.keys(city).length === 0 && Object.keys(education).length === 0 && Object.keys(experience).length === 0 && !date && position) {
            setIsPositionFilter(true);
            let final = jobsData.filter(i => i.position.toLowerCase().includes(position));
            if (final.length > 0) {
                setResetData(jobsData);
                toast.success("Success")
            } else {
                setResetData(jobsData);
                toast.error("No data found")
            }


            setJobsData(final);
        } else {
            let toFilterData = [];
            if (resetData?.length > 0) {
                toFilterData = resetData;
            } else {
                toFilterData = jobsData;
                setResetData(jobsData);
            }
            toFilterData = toFilterData.map(({ ...item }) => ({ ...item, filterDate: (moment(item.postDateTime).format('YYYY-MM-DD')) }));
            let final = toFilterData.filter(function (item) {
                for (var key in searchObj) {
                    if (item[key] === undefined || searchObj[key] === undefined || item[key] !== searchObj[key])
                        return false;
                }
                return true;
            });
            setJobsData(final);
        }
    }

    const selectedJob = (index, item) => {
        setJobItem(item)
        setSelectedIndex(index)
        console.log("coming here")
    }
    const submit = () => {
        if (Object.keys(jobItem).length > 0) {
            setIsError(false);
            handleJobData(jobItem);
            setJobItem({});
            setJobsData("");
            setIsPositionFilter(false);
        } else {
            if (jobsData.length === 0) {
                setIsError(true);
            } else {
                setIsError(false);
            }

        }
    }

    const handleReset = () => {
        setJobItem({});
        setIsError(false);
        handleClose();
        setIsExistingJob(false)
    }

    return (<Popup isOpen={isOpen}>
        <div className={styles.newCss}>
            <img src={closeJob} alt='' onClick={() => handleReset()} />
            <Col md={12} className={styles.newBg}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headReject}>
                            <h3>All your posted jobs <span></span> </h3>
                            <p>Please select job position to send an offer</p>
                        </div>
                    </Col>
                    <Col md={12}>

                        <div className={styles.newdataList}>
                            <JobOfferJobFilter setSearchedVal={setSearchedVal} searchFilter={searchFilter}
                                cityData={cityData} handleJobFilter={handleJobFilter} searchedVal={searchedVal} setIsError={setIsError} />
                            {jobsData?.length > 0 && <div className={styles.jobList}>
                                <Table className={styles.TableJobpost}>
                                    <TableHead className={styles.tableHeadJobpost}>
                                        <TableRow className={styles.rowTableJobpost}>
                                            <TableCell className={styles.tableHead}></TableCell>
                                            <TableCell className={styles.tableHead}>Date</TableCell>
                                            <TableCell className={styles.tableHead}>Job Position</TableCell>
                                            <TableCell className={styles.tableHead}>Job Title</TableCell>
                                            <TableCell className={styles.tableHead}>City</TableCell>
                                            <TableCell className={styles.tableHead}>Education</TableCell>
                                            <TableCell className={styles.tableHead}>Experience</TableCell>
                                            <TableCell className={styles.tableHead}>Salary</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={styles.tBodyData}>

                                        {jobsData.map((item, index) => {
                                            const { position, postDateTime, jobTitle, jobCity, jobState, jobEducation, jobExperience, jobSalary } = item;
                                            return (<TableRow className={styles.tRowData} key={"job" + index} style={{ cursor: "pointer" }} selected={selectedIndex === index} onClick={() => selectedJob(index, item)}>
                                                <TableCell className={styles.RowdataTable}> {selectedIndex === index && <img className={styles.checkImg} src={checkIcon} />}</TableCell>
                                                <TableCell className={styles.RowdataTable}>{moment(postDateTime).format('DD MMM. YY')}</TableCell>
                                                <TableCell className={styles.RowdataTable}>{position}</TableCell>
                                                <TableCell className={styles.RowdataTable}>{jobTitle}</TableCell>
                                                <TableCell className={styles.RowdataTable}>{jobCity}</TableCell>
                                                <TableCell className={styles.RowdataTable}>{jobEducation}</TableCell>
                                                <TableCell className={styles.RowdataTable}>{jobExperience}</TableCell>
                                                <TableCell className={styles.RowdataTable}>{jobSalary.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                            </TableRow>)
                                        }
                                        )}
                                    </TableBody>
                                </Table>
                            </div>}
                        </div>
                        {jobsData?.length === 0 && <div>No jobs found</div>}
                        {isError && (
                            <p className="errorMessage alignCenter">Please select any job</p>
                        )}
                    </Col>

                    <Col md={12}>
                        <div className={styles.btnNew}>
                            <div className={styles.btnNo}>
                                {/* <div className={styles.effNo}></div> */}
                                <button onClick={handleReset}>Back</button>
                            </div>

                            <div className={styles.btnReset}>
                                {/* <div className={styles.effReset}></div> */}
                                <button onClick={() => submit()}>Select</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>

        </div></Popup>)

}

export default AllPostedJobsNew;
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './InterviewFeedback.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import defaultUser from '../../../../assets/images/profile.svg';
import checkBos from '../../../../assets/images/checkNew.svg';
import phoneCall from '../../../../assets/images/selectOp.svg';
import rejectCall from '../../../../assets/images/notAnswer.svg';
import perfectImg from '../../../../assets/images/perfect.svg';
import goodImg from '../../../../assets/images/good.svg';
import averageImg from '../../../../assets/images/average.svg';
import badImg from '../../../../assets/images/bad.svg';
import starImg from '../../../../assets/images/star.svg';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { useForm } from "react-hook-form";

//components
import TextArea from "../../TextArea/TextArea";
import Popup from "../Popup";
import { toast } from "react-toastify";
import { hideLoader, showLoader } from "../../../../helpers";
import { updateInterviewFeedback } from "../../../../services/api/employer";
import InterviewFeedbackSubmit from "../InterviewFeedbackSubmit/InterviewFeedbackSubmit";

export default function InterviewFeedback({
    isOpen,
    handleClose,
    data,
    updateStatus,
    handleCallHistory,
    handleInReviewOption
}) {
    const {
        starRank,
        profilePicUrl,
        candidateName,
        candidateDesignationName,
        jobId,
        // candidateId,
        // employerId,
        // candidateDetails,
        _id,
        candidateAction,
        interviewId,
        employerAction
    } = data
    //console.log("data---------------->", data)
    const { register, handleSubmit, /**formState: { errors },*/ reset, setValue } = useForm();

    let formattedRank = starRank ? starRank.split("/") : [];

    const [candidateInteriewVia, setcandidateInteriewVia] = useState();
    const [showForm, setShowForm] = useState(false);
    const [interviewWas, setInterviewWas] = useState();
    const [starRating, setStarRating] = useState(0);
    const [chanceToHire, setChanceToHire] = useState(0);
    const [candidateAttended, setCandidateAttended] = useState(false);
    const [error, setError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleInterviewVia = (data, isAttended) => {
        setcandidateInteriewVia(data);
        setCandidateAttended(isAttended);
        if (!isAttended) {
            setInterviewWas("");
            setStarRating(0);
            setChanceToHire(0);
            setValue('interviewComments', '');
            setError(false);
        }
        setShowForm(true);
    }

    const handleClickBack = () => {
        setShowForm(false);
        toast.warning('To move next simply choose again same option or any')
    }

    const handleInterviewWas = (data) => {
        setInterviewWas(data);
    }

    const handleStarRating = (data) => {
        setStarRating(data);
    }

    const handleChanceToHire = (e, value) => {
        setChanceToHire(value);
    }

    const StyledSlider = styled(Slider)({
        color: '#5E77BB',
        height: '10px',
        '& .MuiSlider-track': {
            border: '1px solid #fff',
        },
        '& .MuiSlider-thumb': {
            height: 20,
            width: 20,
            backgroundColor: '#fff',
            border: '2px solid #ff6600',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            //   lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 5,
            width: 40,
            height: 25,
            border: '1px solid #ff6600',
            backgroundColor: '#fff',
            color: '#ff6600',
            fontWeight: '600'
        },
    });

    const submit = async (submitData) => {
        submitData.candidateInteriewVia = candidateInteriewVia;
        submitData.candidateAttended = candidateAttended;
        submitData.interviewWas = interviewWas;
        submitData.candidateWas = starRating;
        submitData.chanceToHire = chanceToHire;
        try {
            if (((candidateAttended && !interviewWas) || !starRating || !chanceToHire) && !submitData.interviewComments) {
                setError(true);
                return;
            } else {
                setError(false);
                let params = {
                    interviewId: interviewId
                }

                showLoader();
                const {
                    data: {
                        /** data, message*/
                    } } = await updateInterviewFeedback(submitData, params);
                hideLoader();
                toast.success(<div>
                    <h5>Feedback Submitted</h5>
                    <p>Your interview feedback has been submitted, we’ll review how <br />do you feel about the candidate.</p>
                </div>);
                //updateStatus(jobId, _id, 'positive', candidateAction, employerAction);
                // handleReset();
                //setIsSuccess(true);
                handleReset();
                handleInReviewOption();

            }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
            handleReset();
        }
    }

    const handleReset = () => {
        setInterviewWas("");
        setStarRating(0);
        setcandidateInteriewVia("");
        setChanceToHire(0);
        setShowForm(false);
        reset();
        handleClose();
        setIsSuccess(false);
    }

    return (
        <Popup isOpen={isOpen} isClose={handleReset} id="feedBackForm">
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleReset} alt='' />
                <Col md={12} className={styles.newBg}>
                    <form onSubmit={handleSubmit(submit)}>
                        {!showForm && <Row className={styles.rowMar}>
                            <Col md={12}>
                                <div className={styles.headReject}>
                                    <h3>Interview Feedback</h3>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={styles.userDetail}>
                                    <div className={styles.rankImg}>
                                        <div className={styles.rankNew}>
                                            <span>{formattedRank && formattedRank.length > 0 && formattedRank[0]}<br></br><span className={styles.slashName}>/</span>{formattedRank && formattedRank.length > 0 && formattedRank[1]}</span>
                                        </div>
                                        <div className={styles.userInfo}>
                                            <img src={profilePicUrl ? profilePicUrl : defaultUser} alt='' />
                                            <h3>{candidateName}<span>{candidateDesignationName}</span></h3>
                                        </div>
                                    </div>
                                    <div className={styles.callHistory}>
                                        <div className={styles.btnNew} onClick={handleCallHistory}>
                                            <div className={styles.effNew}></div>
                                            <button>Call  history<span></span></button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={styles.selectOpt}>
                                    <h3>Did you interview the candidate<span></span></h3>
                                    <div className={styles.interviewFeedOp}>
                                        <ul>
                                            <li className={styles.marRight}>
                                                {candidateInteriewVia === 'Phone Call' && candidateAttended && <img className={styles.checkImage} src={checkBos} alt='' />}
                                                <a
                                                    onClick={() => handleInterviewVia('Phone Call', true)}
                                                    className={(candidateInteriewVia === 'Phone Call' && candidateAttended) ? styles.phoneCallSelected : " "}>
                                                    <img className={styles.phoneCall} src={phoneCall} alt='' />
                                                    Yes, via Phone Call
                                                </a>
                                            </li>
                                            <li>
                                                {candidateInteriewVia === 'Office' && candidateAttended && <img className={styles.checkImage} src={checkBos} alt='' />}
                                                <a
                                                    onClick={() => handleInterviewVia('Office', true)}
                                                    className={(candidateInteriewVia === 'Office' && candidateAttended) ? styles.phoneCallSelected : ""}>
                                                    <img className={styles.phoneCall} src={phoneCall} alt='' />
                                                    Yes, attended physically
                                                </a>
                                            </li>
                                            <li className={styles.marRight}>
                                                {candidateInteriewVia === 'Phone Call' && !candidateAttended && <img className={styles.checkImage} src={checkBos} alt='' />}
                                                <a
                                                    onClick={() => handleInterviewVia('Phone Call', false)}
                                                    className={candidateInteriewVia === 'Phone Call' && !candidateAttended ? styles.didShowupSelected : ""}>
                                                    <img className={styles.didShowup} src={rejectCall} alt='' />
                                                    No! The candidate didn’t answer the call
                                                </a>
                                            </li>
                                            <li>
                                                {candidateInteriewVia === 'Office' && !candidateAttended && <img className={styles.checkImage} src={checkBos} alt='' />}
                                                <a
                                                    onClick={() => handleInterviewVia('Office', false)}
                                                    className={(candidateInteriewVia === 'Office' && !candidateAttended) ? styles.didShowupSelected : ""}>
                                                    <img className={styles.didShowup} src={rejectCall} alt='' />
                                                    No! The candidate didn’t show up
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>}
                        {/* second profileImage                     */}
                        {showForm && <Row className={styles.rowMar}>
                            <Col md={12}>
                                <div className={styles.headReject}>
                                    <h3>What do you think about the candidate</h3>
                                </div>
                            </Col>
                            {/* <Col md={12}>
                            <div className={styles.userDetail}>
                                <div className={styles.rankImg}>
                                    <div className={styles.rankNew}>
                                        <span>{formattedRank && formattedRank.length > 0 && formattedRank[0]}<br></br><span className={styles.slashName}>/</span>{formattedRank && formattedRank.length > 0 && formattedRank[1]}</span>
                                    </div>
                                    <div className={styles.userInfo}>
                                        <img src={profilePicUrl ? profilePicUrl : defaultUser} />
                                        <h3>{candidateName}<span>{candidateDesignationName}</span></h3>
                                    </div>
                                </div>
                                <div className={styles.callHistory}>
                                    <div className={styles.btnNew}>
                                        <div className={styles.effNew}></div>
                                        <button>Call  history<span></span></button>
                                    </div>
                                </div>
                            </div>
                        </Col> */}
                            <Col md={12}>
                                {candidateAttended && <div className={styles.howInterview}>
                                    <label>How was the interview?</label>
                                    <ul>
                                        <li>
                                            {interviewWas === 'Perfect' && <img className={styles.checkImage} src={checkBos} alt='' />}
                                            <a
                                                onClick={() => handleInterviewWas('Perfect')}
                                                className={interviewWas === 'Perfect' ? styles.perfectImgSelected : ""}>
                                                <img className={styles.perfectImg} src={perfectImg} alt='' />
                                                Perfect
                                            </a>
                                        </li>
                                        <li>
                                            {interviewWas === 'Good' && <img className={styles.checkImage} src={checkBos} alt='' />}
                                            <a
                                                onClick={() => handleInterviewWas('Good')}
                                                className={interviewWas === 'Good' ? styles.goodImgSelected : ""}>
                                                <img className={styles.goodImg} src={goodImg} alt='' />
                                                Good
                                            </a>
                                        </li>
                                        <li>
                                            {interviewWas === 'Average' && <img className={styles.checkImage} src={checkBos} alt='' />}
                                            <a
                                                onClick={() => handleInterviewWas('Average')}
                                                className={interviewWas === 'Average' ? styles.averageImgSelected : ""}>
                                                <img className={styles.averageImg} src={averageImg} alt='' />
                                                Average
                                            </a>
                                        </li>
                                        <li>
                                            {interviewWas === 'Bad' && <img className={styles.checkImage} src={checkBos} alt='' />}
                                            <a
                                                onClick={() => handleInterviewWas('Bad')}
                                                className={interviewWas === 'Bad' ? styles.badImgSelected : ""}>
                                                <img className={styles.badImg} src={badImg} alt='' />
                                                Bad
                                            </a>
                                        </li>

                                    </ul>
                                    {error && !interviewWas && <p className="errorMessage mt-1 mb-0">Required</p>}
                                </div>}
                                <div className={styles.rateCandidate}>
                                    <label>Rate the Candidate</label>
                                    <ul>
                                        <li>
                                            <a
                                                onClick={() => handleStarRating(1)}
                                                className={starRating >= 1 ? styles.startImgSelected : ""}>
                                                <img src={starImg} alt='' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => handleStarRating(2)}
                                                className={starRating >= 2 ? styles.startImgSelected : ""}>
                                                <img src={starImg} alt='' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => handleStarRating(3)}
                                                className={starRating >= 3 ? styles.startImgSelected : ""}>
                                                <img src={starImg} alt='' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => handleStarRating(4)}
                                                className={starRating >= 4 ? styles.startImgSelected : ""}>
                                                <img src={starImg} alt='' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => handleStarRating(5)}
                                                className={starRating >= 5 ? styles.startImgSelected : ""}>
                                                <img src={starImg} alt='' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => handleStarRating(6)}
                                                className={starRating >= 6 ? styles.startImgSelected : ""}>
                                                <img src={starImg} alt='' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => handleStarRating(7)}
                                                className={starRating >= 7 ? styles.startImgSelected : ""}>
                                                <img src={starImg} alt='' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => handleStarRating(8)}
                                                className={starRating >= 8 ? styles.startImgSelected : ""}>
                                                <img src={starImg} alt='' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => handleStarRating(9)}
                                                className={starRating >= 9 ? styles.startImgSelected : ""}>
                                                <img src={starImg} alt='' />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                onClick={() => handleStarRating(10)}
                                                className={starRating === 10 ? styles.startImgSelected : ""}>
                                                <img src={starImg} alt='' />
                                            </a>
                                        </li>
                                    </ul>
                                    {error && !starRating && <p className="errorMessage mt-1 mb-0">Required</p>}
                                </div>

                                <div className={styles.chanceHire}>
                                    <label>Chance to hire the candidate</label>
                                    <div className={styles.progresBorder}>
                                        <span className={styles.startPoint}>0%</span>
                                        <span className={styles.endPoint}>100%</span>
                                        {/* <div className={styles.progresBar}> */}
                                        {/* <div className={styles.proCount}> 
                                        </div> */}
                                        {/* <span className={styles.pointerScroll}></span> */}
                                        {/* </div> */}
                                        <StyledSlider
                                            value={chanceToHire}
                                            aria-label="Custom marks"
                                            // defaultValue={20}
                                            step={10}
                                            valueLabelDisplay="on"
                                            valueLabelFormat={(value) => `${value}%`}
                                            onChange={handleChanceToHire}
                                        />

                                    </div>
                                    {error && !chanceToHire && <p className="errorMessage mt-1 mb-0">Required</p>}
                                </div>

                                <div className={styles.FormGroup}>
                                    <label>Any additional information you want to share</label>
                                    <TextArea
                                        name="interviewComments"
                                        placeholder="Write your comment here"
                                        maxLength='300'
                                        register={register}
                                    />
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className={styles.btnNew}>
                                    <div className={styles.btnCancel} onClick={handleClickBack}>
                                        <div className={styles.btnEff}></div>
                                        <button>Back <span></span></button>
                                    </div>
                                    <div className={styles.btnReset} onClick={handleSubmit(submit)}>
                                        <div className={styles.effReset}></div>
                                        <button type="submit">Send feedback<span></span></button>
                                    </div>
                                </div>
                            </Col>
                        </Row>}
                    </form>

                </Col>
            </div>
            {isSuccess && <InterviewFeedbackSubmit
                isOpen={isSuccess}
                handleClose={handleReset}
            />}
        </Popup>
    );
}







import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from './AcceptOrReschedule.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import Popup from "../Popup";
import moment from 'moment';
import { getLocalTimeFormatted, hideLoader /** ,showLoader*/ } from "../../../../helpers";
import { rescheduleInterview } from "../../../../services/api/common";
import { toast } from "react-toastify";


// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function AcceptOrReschedule({
  isOpen,
  handleClose,
  candidateInterviewData: {
    finalDateTimeInterview,
    interviewVia,
    suggestedDateTimeInterview,
    _id
  },
  editScheduleInterview,
  // candidateData: {
  //   jobId,
  //   // _id,
  //   candidateAction,
  //   employerAction
  // },
  candidateData,
  isInterviewTimePassed,
  updateStatus
}) {

  const handleAccept = async() => {
    try {
      // showLoader();
      let findApproveObj = suggestedDateTimeInterview.findIndex(i=> i.suggestedDate === finalDateTimeInterview.interviewDate && i.suggestedTime === finalDateTimeInterview.interviewTime);
      let newSuggest = [...suggestedDateTimeInterview];
      newSuggest[findApproveObj]['approved'] = true;
      let approveObj = {
        jobId: candidateData?.jobId,
        interviewId: _id,
        suggestedDateTimeInterview: newSuggest,
        finalDateTimeInterview,
        interviewStatus: "InterviewAccepted"
      }
      const {
        data: {
          /**data,*/ message
        } } = await rescheduleInterview(approveObj);
      hideLoader();
      toast.success(message ? message : "Success");
      updateStatus(candidateData?.jobId, candidateData?._id, 'positive', candidateData?.candidateAction, candidateData?.employerAction);
      handleClose();
      // if(isUpdateScheduleAction){
      // }
    } catch (error) {
      const {
        data: {
          message
        }
      } = error;
      hideLoader();
      toast.error(message ? message : "Failed");
    }
  }

  return (
          <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
            <img src={closeJob} onClick={handleClose} alt=''/>
            <Col md={12} className={styles.newBg}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headReject}>
                            <h3>
                              Interview Scheduled
                              <span>{finalDateTimeInterview?.interviewDate ? (moment(finalDateTimeInterview.interviewDate).format("DD MMM`YY")): '-'}</span>
                            </h3>
                            
                            <div className={styles.interviewset}>
                                <h3><span>Time:</span>{finalDateTimeInterview?.interviewTime ? getLocalTimeFormatted(finalDateTimeInterview.interviewTime): "-"}</h3>
                                <h3><span>Duration:</span>{finalDateTimeInterview?.interviewLength ?? '-'}</h3>
                                <h3><span>Interview:</span>{interviewVia ? interviewVia : '-'}</h3>
                            </div>
                            <p>A new interview date and time has been sent to you by this candidate,<br/>Please check your availability, then accept the interview</p>
                            <div className={styles.schedBtn}>
                              <div className={styles.editInter}>
                                <div className={styles.effNew}></div>
                                <button onClick={editScheduleInterview}>Reschedule<span></span></button>
                              </div>
                              {!isInterviewTimePassed && <div className={styles.okInter}>
                                <div className={styles.effNew}></div>
                                <button onClick={handleAccept}>accept<span></span></button>
                              </div>}
                            </div>
                        </div>
                    </Col> 
                </Row> 
            </Col>
            </div>
        </Popup>
  );
}







// import { style } from '@mui/system';
// import { useState, useEffect } from 'react';
// import { Container } from 'react-bootstrap';

import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import HomeLayout from '../../layouts/HomeLayout/HomeLayout';
import SubHeader from '../common/SubHeader/SubHeader';

import styles from './JobOffer.module.scss';

// import { getAllInterview, getCandidatesByStatus, updateCandidateJobStatus } from '../../services/api/common';
// import { checkIsInterviewTimePassed, hideLoader, showLoader } from '../../helpers';
// import { toast } from 'react-toastify';
// import { useLocation, useParams } from 'react-router-dom';
// import NoDataFound from '../common/NoDataFound/NoDataFound';
// import moment from 'moment';
// import JobOfferListView from './JobOfferListView/JobOfferListView';
// import JobOfferCardView from './JobOfferCardView/JobOfferCardView';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }




export default function Candidates() {
    // let userData = localStorage.getItem('userData');
    // userData = JSON.parse(userData);
    // const { path } = useParams();
    // const { state } = useLocation();

 
   

   
    return (
        <HomeLayout>
            <section className={styles.candidateBg}>
                <SubHeader/>
            </section>
            
        </HomeLayout>
    );
}
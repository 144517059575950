// import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './BillingDetail.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';

import Popup from "../Popup";
// import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { useForm } from "react-hook-form";
import InputField from "../../../common/InputField/InputField";
import * as React from 'react';
// import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import {
//    Duration
// } from '../../../../common/DropdownOptions';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function BillingDetail() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { register, /**handleSubmit,*/ formState: { errors } /** ,getValues, control, setValue */} = useForm();

  return (
    <div>
       <Button onClick={handleOpen}>Open modal</Button>
          <Popup isOpen={open} isClose={handleClose}>
            <div className={styles.newCss}>
            <img src={closeJob} onClick={handleClose} alt=''/>
            <Col md={12} className={styles.newBg}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headReject}>
                            <h3>Enter the billing details<span></span></h3>
                        </div>
                    </Col>  
                </Row> 
                <Row className={styles.formBg}>
                    <Col md={4} className={styles.FormGroup}>
                        <label>Company name for invoice</label>
                        <InputField
                            className={styles.formControl}
                            name="companyName"
                            placeholder="Enter the company name"
                            register={register}
                            valueAsNumber={{valueAsNumber: true}}
                            validation={{required: 'Required',pattern: {value: /^[0-9]*$/,message: 'vacancy should contain only numbers'}}}
                            error={errors?.jobVacancy}
                        />
                    </Col>
                    <Col md={4} className={styles.FormGroup}>
                        <label>Company address for invoice</label>
                        <InputField
                            className={styles.formControl}
                            name="inviceAddress"
                            placeholder="Enter the company address"
                            register={register}
                            valueAsNumber={{valueAsNumber: true}}
                            validation={{required: 'Required',pattern: {value: /^[0-9]*$/,message: 'vacancy should contain only numbers'}}}
                            error={errors?.jobVacancy}
                        />
                    </Col>
                    <Col md={4} className={styles.FormGroup}>
                        <label>Select your District/City</label>
                        <InputField
                            className={styles.formControl}
                            name="jobCity"
                            placeholder="Choose your city/district"
                            register={register}
                            valueAsNumber={{valueAsNumber: true}}
                            validation={{required: 'Required',pattern: {value: /^[0-9]*$/,message: 'vacancy should contain only numbers'}}}
                            error={errors?.jobVacancy}
                        />
                    </Col>
                    <Col md={4} className={styles.FormGroup}>
                        <label>City Pincode</label>
                        <InputField
                            className={styles.formControl}
                            name="cityPin"
                            placeholder="Enter the city pin code"
                            register={register}
                            valueAsNumber={{valueAsNumber: true}}
                            validation={{required: 'Required',pattern: {value: /^[0-9]*$/,message: 'vacancy should contain only numbers'}}}
                            error={errors?.jobVacancy}
                        />
                    </Col>
                    <Col md={4} className={styles.FormGroup}>
                        <label>Mobile Number</label>
                        <InputField
                            className={styles.formControl}
                            name="mobileNumber"
                            placeholder="Enter the mobile"
                            register={register}
                            valueAsNumber={{valueAsNumber: true}}
                            validation={{required: 'Required',pattern: {value: /^[0-9]*$/,message: 'vacancy should contain only numbers'}}}
                            error={errors?.jobVacancy}
                        />
                    </Col>
                    <Col md={4} className={styles.FormGroup}>
                        <label>GST Number (Optional)</label>
                        <InputField
                            className={styles.formControl}
                            name="gstNumber"
                            placeholder="Enter the GST no."
                            register={register}
                            valueAsNumber={{valueAsNumber: true}}
                            validation={{required: 'Required',pattern: {value: /^[0-9]*$/,message: 'vacancy should contain only numbers'}}}
                            error={errors?.jobVacancy}
                        />
                    </Col>
                    
                    <Col md={12}> 
                        <div className={styles.btnNew}>
                            <div className={styles.btnCancel}>
                                <div className={styles.effNew}></div>
                                <button>Cancel <span></span></button>
                            </div>
                            <div className={styles.btnReset} >
                                <div className={styles.effReset}></div>
                                <button>Submit<span></span></button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            </div>
        </Popup>
        </div>
  );
}







// import { useState, useEffect } from "react";
// import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './JobOffersRight.module.scss';
import JobOffer from '../../../../../assets/images/jobOffer.svg';
import CandidateResponds from '../../../../../assets/images/responsePeding.svg';


export default function JobOffersRight({
  type
}) {

  if(type === 'waiting') {
    return (
      <div className={styles.paddRight}>
        <div className={styles.tooltipcssWaiting}>
          <span tooltip="Please wait for the candidate to accept the offer" flow="left">
            <img src={CandidateResponds} alt='' />
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.paddRight}>
        <div className={styles.tooltipcssAccepted}>
          <span tooltip="Hire this candidate" flow="left">
            <img src={JobOffer} alt='' />
          </span>
        </div>
      </div>
    );
  }
}







import { useState, /**Fragment*/ } from 'react';
import styles from './JobExpandedView.module.scss';
import { Row, Col /** ,Button */} from 'react-bootstrap';
//materiaui
// import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Collapse, Box, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark, /**faPencil, faUser, faUsers, faIndianRupeeSign, faVideo,*/ faPlayCircle /** ,faCirclePlay */} from '@fortawesome/free-solid-svg-icons';
import ChangeJobStatus from '../../common/ChangeJobStatus/ChangeJobStatus';
import { useLocation, useNavigate } from 'react-router-dom';
// import videoIcon from '../../../assets/images/videoIcon.svg';
import infoImage from '../../../assets/images/infoStatus.svg';
import MultiMediaPlayer from '../../common/Popup/MultiMediaPlayer/MultiMediaPlayer';
import moment from 'moment';
import { getLocalDateTimeFormatted } from '../../../helpers';

function JobExpandedView({
    // state,
    index,
    currency,
    data: {
        _id,
        position,
        jobSalary,
        // salaryCurrency,
        // jobExperience,
        jobEducation,
        jobCity,
        appliedCount,
        postDateTime,
        jobTitle,
        jobType,
        jobShift,
        workingFrom,
        jobVacancy,
        workingTime,
        workingDays,
        specializations,
        jobContactPerson,
        jobIncentive,
        jobCharges,
        jobDegree,
        jobBranch,
        jobGender,
        jobAge,
        jobLanguage,
        drivingLicense,
        welcomeVideoUrl,
        jobAddress,
        jobDescription,
        welcomeVideo,
        updatedAt,
        jobStatus
    },
    onClick = () => {
        return null;
    },
    isCard = false,
    fetchJobs = () => {
        return null;
    }
}) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    //state
    const [isOpenVideo, setIsOpenVideo] = useState(false);

    let isLicense, isOwnBike, isOwnCar, isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday = false;
    let agefrom, ageTo, workFrom, workTo, monthlyIncentive, oneTimeIncentive, jobChargeOneTime, jobChargeRecursive;

    if (drivingLicense) {
        // const { dl, ownBike, ownCar } = drivingLicense;
        isLicense = drivingLicense.dl;
        isOwnBike = drivingLicense.ownBike;
        isOwnCar = drivingLicense.ownCar;
    }
    if (jobAge) {
        // const { fromAge, toAge } = jobAge;
        agefrom = jobAge.fromAge;
        ageTo = jobAge.toAge;
    }
    if (workingTime) {
        // const { from, to } = workingTime;
        workFrom = workingTime.from;
        workTo = workingTime.to;
    }
    if (workingDays) {
        // const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = workingDays;
        isMonday = workingDays.monday;
        isTuesday = workingDays.tuesday;
        isWednesday = workingDays.wednesday;
        isThursday = workingDays.thursday;
        isFriday = workingDays.friday;
        isSaturday = workingDays.saturday;
        isSunday = workingDays.sunday;
    }
    if (jobIncentive) {
        // const { monthly, oneTime } = jobIncentive;
        monthlyIncentive = jobIncentive.monthly;
        oneTimeIncentive = jobIncentive.oneTime;
    }
    if (jobCharges) {
        // const { oneTimeCharge, recursiveCharge } = jobCharges;
        jobChargeOneTime = jobCharges[0].oneTimeCharge;
        jobChargeRecursive = jobCharges[0].recursiveCharge;
    }
    return (
        <Col md={12}>
            <Row className={styles.careSpace}>
                <Col md={12}>
                    <Row>
                       {isCard && <Col md={12} className={`${styles.appListData} pointer`}  onClick={() => onClick(index)}>
                            <div className={styles.tableNewData}>
                                <table>
                                    <tr>
                                        <td>
                                            <div className={styles.listNewData}>
                                                <h3><span>Posted On</span>{postDateTime ? moment(postDateTime).format('DD MMM YYYY') : "-"}</h3>
                                            </div>
                                        </td>
                                        <td>
                                        <div className={styles.listNewData}>
                                            <h3><span>Job Position </span>{position ? position : "-"}</h3>
                                        </div>
                                        </td>
                                        <td>
                                            <div className={styles.listNewData}>
                                                <h3><span>Salary</span>{jobSalary ? `${currency}${jobSalary}` : "-"}</h3>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.listNewData}>
                                                <h3><span>Education </span>{jobEducation ? jobEducation : "-"}</h3>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.listNewData}>
                                                <h3><span>City</span>{jobCity ? jobCity : "-"}</h3>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.listNewData}>
                                                <h3 className={styles.colorChange}>
                                                    <span>Applied</span>{appliedCount ? appliedCount : "-"}</h3>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </Col>}
                        <Col md={2} className={styles.colWidth}>
                            <div className={styles.rowData}>
                                <h3><span>Job Title</span>{jobTitle}</h3>
                                <h3><span>Job Type</span>{jobType}</h3>
                                <h3><span>Shift</span>{jobShift}</h3>
                                <h3><span>Working From</span>{workingFrom}</h3>
                                <h3><span>No. of Vacancies</span>{jobVacancy}</h3>
                                <h3><span>Working Time</span>{workFrom} - {workTo}</h3>
                            </div>
                        </Col>
                        <Col md={2} className={styles.colWidth}>
                            <div className={styles.rowData}>
                                <h3><span>Contact Person</span>{jobContactPerson}</h3>
                                <h3><span>Incentive</span>
                                    {monthlyIncentive ? `Monthly Incentive: ${currency} ${monthlyIncentive}` : oneTimeIncentive ? `One Time Incentive: ${currency} ${oneTimeIncentive}` : 'No'}
                                </h3>
                                <h3><span>Any Deposit/Charge</span>
                                    {jobChargeOneTime ? `One Time Charge: ${currency} ${jobChargeOneTime}` : jobChargeRecursive ? `One Time Incentive: ${currency} ${jobChargeRecursive}` : 'No'}
                                </h3>
                                <h3><span>Degree</span>{jobDegree ? jobDegree : "-"}</h3>
                                <h3><span>Branch / Stream</span>{jobBranch ? jobBranch : "-"}</h3>
                                <h3><span>Gender</span>{jobGender}</h3>
                            </div>
                        </Col>
                        <Col md={3} className={styles.colWidth}>
                            <div className={styles.rowData}>
                                <h3><span>Age</span>{agefrom} - {ageTo}</h3>
                                <h3><span>Language</span>{jobLanguage}</h3>
                                <h3>
                                    <span>Driving Requirements</span>
                                    <ul className={styles.moterVeh}>
                                        <li className={styles.newDrive}><FontAwesomeIcon icon={isLicense ? faCircleCheck : faCircleXmark} />Driver’s License</li>
                                        <li className={styles.newDrive}><FontAwesomeIcon icon={isOwnBike ? faCircleCheck : faCircleXmark} />Motorcycle / 2 Wheeler</li>
                                        <li className={styles.newDrive}><FontAwesomeIcon icon={isOwnCar ? faCircleCheck : faCircleXmark} />Car / 4 Wheeler</li>
                                    </ul>
                                    {/* <span className={styles.newDrive}><FontAwesomeIcon icon={isLicense ? faCircleCheck : faCircleXmark} />Driver’s License</span>
                                <span className={styles.newDrive}><FontAwesomeIcon icon={isOwnBike ? faCircleCheck : faCircleXmark} />Motorcycle / 2 Wheeler</span>
                                <span className={styles.newDrive}><FontAwesomeIcon icon={isOwnCar ? faCircleCheck : faCircleXmark} />Car / 4 Wheeler</span> */}
                                </h3>
                                <h3>
                                    <span>Welcome Video</span>
                                    {(welcomeVideo && welcomeVideo !== "Not Available" && welcomeVideo !== "null") ? (
                                        <>
                                            {/* <img src={videoIcon} alt='' /> */}
                                            <a target="_blank" onClick={() => setIsOpenVideo(true)} className={styles.videoNew}> <FontAwesomeIcon icon={isLicense ? faPlayCircle : faCircleXmark} />{welcomeVideo}</a>
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </h3>

                            </div>
                        </Col>
                        <Col md={2} className={styles.colWidth}>
                            <div className={styles.rowData}>
                                <h3><span>Office Address</span>{jobAddress}</h3>
                                <h3><span>Job Desciription</span>{jobDescription}</h3>
                            </div>
                        </Col>
                        <Col md={3} className={styles.colWidth}>
                            <div className={styles.rowData}>
                                <h3>
                                    <span>Working Days</span>
                                    <span className={`${styles.borderedSpan} ${isMonday ? styles.activeContent : ''}`}>Mon</span>
                                    <span className={`${styles.borderedSpan} ${isTuesday ? styles.activeContent : ''}`}>Tue</span>
                                    <span className={`${styles.borderedSpan} ${isWednesday ? styles.activeContent : ''}`}>Wen</span>
                                    <span className={`${styles.borderedSpan} ${isThursday ? styles.activeContent : ''}`}>Thu</span>
                                    <span className={`${styles.borderedSpan} ${isFriday ? styles.activeContent : ''}`}>Fri</span>
                                    <span className={`${styles.borderedSpan} ${isSaturday ? styles.activeContent : ''}`}>Sat</span>
                                    <span className={`${styles.borderedSpan} ${isSunday ? styles.activeContent : ''}`}>Sun</span>
                                </h3>
                                <h3 className="">
                                    <span>Specialization</span>
                                    {specializations && specializations.length > 0 && specializations.map((item, index) => {
                                        return (
                                            <span className={styles.specializationSpan} key={index}>{item}</span>
                                        )
                                    })}
                                </h3>
                            </div>
                        </Col>
                        {pathname === '/jobs/closed' ? (
                            <div className="row">
                            <div className="col-md-7">
                            <div className={styles.closedJobStatud}>
                                <p><img src={infoImage} alt=""/>{`This job was closed on ${getLocalDateTimeFormatted(updatedAt)}, and cannot be reopened.`}</p>
                            </div>
                            </div>
                            </div>
                        ) : (
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className={styles.actionButtons}>
                                            <div className={styles.btnNew} onClick={() => navigate(`/jobs/edit/${_id}`)}>
                                                <div className={styles.effNew}></div>
                                                <a>
                                                    {/* <FontAwesomeIcon icon={faPencil} /> */}
                                                    Edit Job Post<span></span>
                                                </a>
                                            </div>


                                            {pathname === '/jobs/live' && <div className={styles.viewNew}
                                                onClick={() => navigate('/candidates/matched', {
                                                    state: {
                                                        jobTitle
                                                    }
                                                })}>
                                                <div className={styles.effView}></div>
                                                <a>
                                                    {/* <FontAwesomeIcon icon={faUser} />  */}
                                                    View Candidates<span></span></a>
                                            </div>}
                                        </div>

                                    </div>

                                    <div className="col-md-5">
                                        <ChangeJobStatus jobStatus={jobStatus} jobId={_id} fetchJobs={fetchJobs}/>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Row>
                </Col>
            </Row>
            <MultiMediaPlayer
                isOpen={isOpenVideo}
                handleClose={() => setIsOpenVideo(false)}
                source={welcomeVideoUrl ? welcomeVideoUrl : ""}
                fileName={welcomeVideo}
                title="Welcome Video"
            />
        </Col>

    )
}
export default JobExpandedView;
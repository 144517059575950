// import { style } from "@mui/system";
import { Col, Container, /**FormGroup,*/ Row } from "react-bootstrap"
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
// import SubHeader from '../../common/SubHeader/SubHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark, /**faPencil, faUser, faUsers, faIndianRupeeSign, faVideo, faPlayCircle, faCirclePlay*/ } from '@fortawesome/free-solid-svg-icons';
import styles from './JobOfferExpend.module.scss';
import previewExpand from '../../../assets/images/previewActive.svg';
import userProfile from '../../../assets/images/userProfile.jpg';

// import { Insights } from "@mui/icons-material";
// import moment from "moment";




export default function JobOfferExpend() {

    return (
        <HomeLayout>

            <section className={styles.offerList}>
                {/* <SubHeader /> */}
                <Container fluid className={styles.subHeader}>
                    <Row>
                        <Col md={5}>
                            <div className={styles.subHeader}>
                                <h3>3 Offers Accepted <p>(*Click on an offer to get the detail)</p></h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row className={styles.TableExpand}>
                        <Col md={12}>
                            <div className={styles.offerTable}>
                                <table className={styles.JonOfferExpend}>
                                    <thead className={styles.tableHeader}>
                                        <tr className={styles.tableCellHeader}>
                                            <th>S.no. </th>
                                            <th>Sent on </th>
                                            <th>Accepted on</th>
                                            <th>Candidate’s name</th>
                                            <th>Job Position </th>
                                            <th>Salary</th>
                                            <th>Job City</th>
                                            <th>Joining Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className={styles.tableRow}>
                                            <td>1.</td>
                                            <td>22 Jul’22</td>
                                            <td className={styles.colorActive}>22 Jul’22</td>
                                            <td>Jobgendra Kumar Sharma</td>
                                            <td>Supervisor</td>
                                            <td>₹ 14,000</td>
                                            <td>Lucknow</td>
                                            <td className={styles.colorActive}>17 Aug’22</td>
                                            <td><img src={previewExpand} alt="" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col md={12}>
                            <Row className={styles.detailBg}>
                                <Col md={12}>
                                    <div className={styles.candidateDetails}>
                                        <h3>Candidate Detail <span></span></h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.proLink}>
                                        <img src={userProfile} alt="" />
                                        <h3>Arun Kumar</h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>WhatsApp No.</span> 95829 80399</h3>
                                        <h3><span>Email Address</span> arun@jobsgaar.com</h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>City</span> Hanumangarh Town</h3>
                                        <h3><span>Age</span> 24</h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>Education</span> Graduate</h3>
                                        <h3><span>Experience</span> 3 - 4 years</h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>Candidate have</span></h3>
                                        <ul>
                                            <li><FontAwesomeIcon icon={faCircleCheck} />Driver’s License</li>
                                            <li><FontAwesomeIcon icon={faCircleCheck} />Motorcycle</li>
                                            <li><FontAwesomeIcon icon={faCircleXmark} />Car</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>Address</span> F8G7, Moti Jheel, Harsh Nagar, Kanpur, Uttar Pradesh 208002</h3>
                                    </div>
                                </Col>
                            </Row>

                            <Row className={styles.detailBg}>
                                <Col md={12}>
                                    <div className={styles.candidateDetails}>
                                        <h3>Offer Detail <span></span></h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>Job title</span> Guidance and support</h3>
                                        <h3><span>Bonus period</span> Within 1 month</h3>
                                        <h3><span>Bonus amount</span> ₹ 2,000/- </h3>
                                        <h3><span>Notice period</span> 45 Days </h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>Job shift</span> Day shift</h3>
                                        <h3><span>Job type</span> Full time</h3>
                                        <h3><span>Language</span> Hindi</h3>
                                        <h3><span>Work from</span> Office</h3>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>Shift timing</span> 09:00 am to 05:00 pm</h3>
                                        <h3><span>Driving Requirements</span></h3>
                                        <ul>
                                            <li><FontAwesomeIcon icon={faCircleCheck} />Driver’s License</li>
                                            <li><FontAwesomeIcon icon={faCircleCheck} />Motorcycle/Scooty</li>
                                            <li><FontAwesomeIcon icon={faCircleXmark} />Car</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>Working Days</span></h3>
                                        <button>Monday</button>
                                        <button>Tuesday</button>
                                        <button>Wednesday</button>
                                        <button>Thursday</button>
                                        <button>Friday</button>
                                        <button>Saturday</button>
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>Additional info.</span>A perspiciatis omnis is voluptatem remqu laudantium so aperiameaque adipisci velit Is numquam.</h3>

                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div className={styles.detailCa}>
                                        <h3><span>Job Address</span> N88 Market Kakadeo, Kanpur, Uttar Pradesh 208025</h3>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

        </HomeLayout>

    )
}
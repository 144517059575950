import styles from './ChangeJobStatus.module.scss';
// import { useState } from 'react';
// import Select, { StylesConfig } from 'react-select';
import { Dropdown } from "../../common/Dropdown/Dropdown";
import { updateJobStatus } from '../../../services/api/common';
import { hideLoader, showLoader } from '../../../helpers';
import { toast } from 'react-toastify';

const ChangeJobStatus = ({
  jobStatus,
  jobId,
  fetchJobs = () => {
    return null;
  }
}) => {
  let dataSet, defaultValue;
  if (jobStatus === 'Live') {
    dataSet = [{
      label: "Suspend",
      value: "Suspended"
    }, {
      label: "Close",
      value: "Closed"
    }];
    defaultValue = [{
      label: "Live",
      value: "Live"
    }];
  } else if (jobStatus === "Pending") {
    dataSet = [{
      label: "Live",
      value: "Live"
    }, {
      label: "Suspend",
      value: "Suspended"
    }, {
      label: "Close",
      value: "Closed"
    }];
    defaultValue = [{
      label: "Pending",
      value: "Pending"
    }];
  } else if (jobStatus === "Suspended") {
    dataSet = [{
      label: "Live",
      value: "Live"
    }, {
      label: "Close",
      value: "Closed"
    }];
    defaultValue = [{
      label: "Suspend",
      value: "Suspended"
    }];
  } else if (jobStatus === "CustomQuote") {
    dataSet = [{
      label: "Live",
      value: "Live"
    }, {
      label: "Close",
      value: "Closed"
    }];
    defaultValue = [{
      label: "CustomQuote",
      value: "CustomQuote"
    }];
  }

  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });
  const colourStyles = {
    control: (styles, { data }) => ({ ...styles, backgroundColor: '#455377', color: data && data.value === 'live' ? '#1abc9c' : data && data.value === 'suspend' ? '#e6c22a' : '#e74c5b' }),
    option: (styles, { data, isDisabled, /**isFocused, isSelected*/ }) => {
      let dataColor = data && data.value === 'live' ? '#1abc9c' : data && data.value === 'suspend' ? '#e6c22a' : '#e74c5b'
      return {
        ...styles,
        ...dot(dataColor),
        backgroundColor: '#455377',
        color: dataColor,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
    input: (styles,) => ({ ...styles }),
    placeholder: (styles, data) => ({ ...styles, color: data && data.value === 'live' ? '#1abc9c' : data && data.value === 'suspend' ? '#e6c22a' : '#e74c5b' }),
    // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  const onChange = async ({ value }) => {
    try {
      showLoader();
      let submitData = {
        jobId,
        jobStatus: value
      }

      let {
        data: {
              /**data,*/ message
        }
      } = await updateJobStatus(submitData);
      hideLoader();
      toast.success(message ? message : "Success");
      fetchJobs();
    } catch (error) {
      const {
        data: {
          message
        }
      } = error;
      hideLoader();
      toast.error(message ? message : "Failed");
    }
  }

  return (
    <div className={styles.jobStatus}>
      <p>Change status of this Job post</p>
      <div className={styles.dropdown}>
        <Dropdown
          value={defaultValue}
          options={dataSet}
          styles={colourStyles}
          placeholder="Select"
          className={styles.formControlselect}
          onChange={onChange}
          isDisabled={jobStatus === "CustomQuote" ? true : false}
        />
      </div>
      {/* <Select className={styles.dropdown}
                defaultValue={status}
                options={dataSet}
                styles={colourStyles}
            /> */}
    </div>
  )
}

export default ChangeJobStatus;
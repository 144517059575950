import { useState, useEffect } from "react";
import { Col, Container, /**FormGroup,*/ Row } from "react-bootstrap";
import styles from './EmployerProfile.module.scss';
import HomeLayout from "../../layouts/HomeLayout/HomeLayout";
import videoIcon from '../../assets/images/videoemp.svg';
import Profile from '../../assets/images/profile.svg';
import userCheck from '../../assets/images/check.svg';

import { getProfile } from "../../services/api/employer";
import { hideLoader, showLoader } from "../../helpers";
import MultiMediaPlayer from "../common/Popup/MultiMediaPlayer/MultiMediaPlayer";
import { useNavigate } from "react-router-dom";

export default function EmployerProfile() {

    const navigate = useNavigate();

    //state
    const [data, setData] = useState();
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [isOpenCompanyImage, setIsOpenCompanyImage] = useState(false);

    useEffect(() => {
        getProfileData();
    }, []);

    /**
   * API-> https://api.jobsgaar.com/v1/employer/get/my/profile
   *This method is used to get the user information from the DB
   */
    const getProfileData = async () => {
        try {
            showLoader();
            let {
                data: {
                    data
                }
            } = await getProfile();
            hideLoader();
            setData(data);

        } catch (error) {
            hideLoader();
        }
    }


    return (
        <HomeLayout>
            <section className={styles.editProfile}>
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <div className={styles.headEdit}>
                                <h3>Profile</h3>
                                <div className={styles.editPro} onClick={() => navigate("/editprofile", { state: { data } })}>
                                    <div className={styles.effeditPro}></div>
                                    <button>Edit Profile <span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className={styles.editList}>
                                <div className={styles.imageUpdate}>
                                    {/* <h3>Profile Images</h3> */}
                                    <div className={styles.bgimageUp}>
                                        <div className={styles.imageUpload}>
                                            <div className={styles.profileImage}>
                                                <img src={data && data.representativeProfileUrl ? data.representativeProfileUrl : Profile} alt="" />
                                            </div>
                                            <button className={styles.uploadButton} style={{ cursor: "auto" }}>
                                                {data && data.representativeName ? data.representativeName : "-"}
                                            </button>
                                        </div>
                                        <div className={styles.imageUpload}>
                                            <div className={styles.profileImage}>
                                                <img src={data && data.companyLogoUrl ? data.companyLogoUrl : Profile} alt="" />
                                            </div>
                                            <button className={styles.uploadButton} style={{ cursor: "auto" }}>
                                                {data && data.companyName ? data.companyName : "-"}
                                            </button>
                                        </div>
                                        <div className={styles.profileContent}>
                                            <Row>
                                                <Col md={3}>
                                                    <div className={styles.textMain}>
                                                        <h3><span>Employer’s Email Address</span>
                                                            {data && data.representativeEmail ? data.representativeEmail : "-"}
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.textMain}>
                                                        <h3><span>Employer’s WhatsApp Number</span>
                                                            {data && data.whatsappMobile ? data.whatsappMobile : "-"}
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.textMain}>
                                                        <h3><span>Employer’s Role</span>
                                                            {data && data.representativeRole ? data.representativeRole : "-"}
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.empVideo}>
                                                        <h3><span>Employer’s Video for candidates </span>
                                                            {/* <img src={videoIcon}/> */}
                                                            {data?.welcomeVideo && data?.welcomeVideo !== "Not Available" && data?.welcomeVideo !== "null" ? (
                                                                <>
                                                                    <a target="_blank" onClick={() => setIsOpenVideo(true)} className={styles.videoNew}> <img src={videoIcon} alt='' />{data.welcomeVideo}</a>
                                                                </>
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.textMain}>
                                                        <h3><span>Company’s Email Address</span>
                                                            {data && data.companyEmail ? data.companyEmail : "-"}
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.textMain}>
                                                        <h3><span>Company’s Phone Number</span>
                                                            {data && data.alternateNo ? data.alternateNo : "-"}
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.textMain}>
                                                        <h3><span>Main Business</span>
                                                            {data && data.companyBusiness && data.companyBusiness.length > 0 ? (
                                                                data.companyBusiness.map((item, index) => {
                                                                    return (
                                                                        data.companyBusiness.length === index + 1 ? item : item + " /"
                                                                    )
                                                                })
                                                            ) : ("-")
                                                            }

                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.textMain}>
                                                        <h3><span>Company Size</span>
                                                            {data && data.companySize ? data.companySize : "-"}
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.imageColor}>
                                                        <h3><span>Business/photo/document </span>
                                                            {data?.companyPicture && data?.companyPicture !== "Not Available" && data?.companyPicture !== "null" ? (
                                                                <a className={styles.docuName} onClick={() => setIsOpenCompanyImage(true)}>
                                                                    <img src={userCheck} alt='' />
                                                                    {data.companyPicture}</a>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.textMain}>
                                                        <h3><span>District / City</span>
                                                            {data && data.city} {data && data.state ? "(" + data.state + ")" : ""}
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.textMain}>
                                                        <h3><span>Office Address</span>
                                                            {data && data.companyAddress ? data.companyAddress : "-"}
                                                        </h3>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={styles.textMain}>
                                                        <h3><span>About Company/Business Information s</span>
                                                            {data && data.companyAbout ? data.companyAbout : "-"}
                                                        </h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <MultiMediaPlayer
                                isOpen={isOpenVideo}
                                handleClose={() => setIsOpenVideo(false)}
                                source={data && data.welcomeVideoUrl ? data.welcomeVideoUrl : ""}
                                fileName={data?.welcomeVideo}
                                title="Employer’s Video for candidates"
                            />
                            <MultiMediaPlayer
                                isOpen={isOpenCompanyImage}
                                handleClose={() => setIsOpenCompanyImage(false)}
                                source={data?.companyPictureUrl ? data.companyPictureUrl : ""}
                                fileName={data?.companyPicture}
                                title="Business/photo/document"
                            />

                            {/* <div className={styles.btnMain}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnCancel}>
                                    <div className={styles.effNew}></div>
                                    <button>Cancel <span></span></button>
                                </div>
                                <div className={styles.btnReset}>
                                    <div className={styles.effReset}></div>
                                    <button>Reset<span></span></button>
                                </div>
                            </div>
                            <div className={styles.btnSubmit}>
                                <div className={styles.effSubmit}></div>
                                <button>Submit<span></span></button>
                            </div>
                        </div> */}
                        </Col>
                    </Row>
                </Container>
            </section>
        </HomeLayout>
    );
}







import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//components
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import Dashboard from '../components/Dashboard/Dashboard';
import Login from '../components/Login/Login';

import EditJob from '../components/Jobs/EditJob/EditJob';
import Candidates from '../components/Candidates/Candidates';
import ProtectedRoute from './ProtectedRoute';
import AddJob from '../components/Jobs/AddJob/AddJob';
import JobList from '../components/Jobs/JobList/JobList';
import Notifications from '../components/Notifications/Notifications';
import Signup from '../components/Signup/Signup';
import BillingDetail from '../components/common/Popup/BillingDetail/BillingDetail';
import EditProfile from '../components/EditProfile/EditProfile';
import EmployerProfile from '../components/EmployerProfile/EmployerProfile';
import CandidateFilter from '../components/common/Popup/CandidateFilter/CandidateFilter';
import JobsFilter from '../components/common/Popup/JobsFilter/JobsFilter';
import ChangeNumberOTP from '../components/common/Popup/ChangeNumberOTP/ChangeNumberOTP';
import Preferences from '../components/Preferences/Preferences';
import JobOfferLetter from '../components/JobOfferLetter/JobOfferLetter';
import ChoosIntereviewOption from '../components/common/Popup/ChoosIntereviewOption/ChoosIntereviewOption';
import JobOffer from '../components/JobOffer/JobOffer';
import JobOfferListView from '../components/JobOffer/JobOfferListView/JobOfferListView';
import JobOfferCardView from '../components/JobOffer/JobOfferCardView/JobOfferCardView';
import JobOfferExpend from '../components/JobOffer/JobOfferExpend/JobOfferExpend';
import AllPostedJobs from '../components/common/Popup/AllPostedJobs/AllPostedJobs';
import JobOfferJobFilter from '../components/common/Popup/JobOfferJobFilter/JobOfferJobFilter';
import NewCandidate from '../components/common/Popup/NewCandidate/NewCandidate';
import JobOfferSpecialization from '../components/common/Popup/JobOfferSpecialization/JobOfferSpecialization';
import TestimonialsLogin from '../components/TestimonialsLogin/TestimonialsLogin';
import CompanyTrust from '../components/CompanyTrust/CompanyTrust';
import CompanytrustTwo from '../components/CompanyTrust/CompanyTrustTwo';
import NewLogin from '../components/NewLogin/NewLogin';

function RouterComponent() {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path='/' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path='/employerprofile' element={<ProtectedRoute><EmployerProfile/></ProtectedRoute>}/>
                <Route path='/editprofile' element={<ProtectedRoute><EditProfile/></ProtectedRoute>}/>
                <Route path='/notifications' element={<ProtectedRoute><Notifications/></ProtectedRoute>}/>
                <Route path='/preferences' element={<ProtectedRoute><Preferences/></ProtectedRoute>}/>
                <Route path='/billingdetail' element={<BillingDetail/>}/>
                <Route path='/candidatefilter' element={<CandidateFilter/>}/>
                <Route path='/jobsfilter' element={<JobsFilter/>}/>
                <Route path='/changenumber' element={<ChangeNumberOTP/>}/>
                <Route path='/choosintereviewoption' element={<ChoosIntereviewOption/>}/>
                <Route path='/joboffer' element={<JobOffer/>}/>
                <Route path='/jobofferlistview' element={<JobOfferListView/>}/>
                <Route path='/joboffercardview' element={<JobOfferCardView/>}/>
                <Route path='/jobofferexpend' element={<JobOfferExpend/>}/>
                <Route path='/allpostedjobs' element={<AllPostedJobs/>}/>
                <Route path='/jobfilter' element={<JobOfferJobFilter/>}/>
                <Route path='/newcandidate' element={<NewCandidate/>}/>
                <Route path='/jobofferspecialization' element={<JobOfferSpecialization/>}/>
                <Route path='/testimonials' element={<TestimonialsLogin/>}/>
                <Route path='/companyTrust' element={<CompanyTrust/>}/>
                <Route path='/companytrustTwo' element={<CompanytrustTwo/>}/>

                <Route path='/jobs'>
                    <Route index element={<ProtectedRoute><JobList /></ProtectedRoute>} />
                    <Route path=':path' element={<ProtectedRoute><JobList /></ProtectedRoute>} />
                    <Route path='add' element={<ProtectedRoute><AddJob /></ProtectedRoute>} />
                    <Route path='edit/:id' element={<ProtectedRoute><EditJob /></ProtectedRoute>} />
                </Route>
                <Route path='/login' element={<Login />} />
                <Route path='/newLogin' element={<NewLogin/>}/>
                <Route path='/signup' element={<Signup/>}/>
                <Route path='/jobofferletter' element={<JobOfferLetter/>}/>
                
                <Route path='/candidates'>
                    <Route index element={<ProtectedRoute><Candidates /></ProtectedRoute>} />
                    <Route path=':path' element={<ProtectedRoute><Candidates /></ProtectedRoute>} />
                </Route>

            </Routes>
        </Router>
    );
}

export default RouterComponent;
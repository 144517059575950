// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footerSection__El5\\+F {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #1B2744;
  z-index: 9999;
  padding: 5px 4px 5px 4px;
}

.Footer_footerNav__bP\\+yb {
  display: inline-block;
}
.Footer_footerNav__bP\\+yb ul {
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
}
.Footer_footerNav__bP\\+yb ul li {
  display: inline-block;
  margin-right: 15px;
}
.Footer_footerNav__bP\\+yb ul li a {
  color: #B0B3B7;
  font-size: 13px;
  text-decoration: none;
  font-weight: 400;
}

.Footer_copyRight__\\+34Ba {
  display: inline-block;
  float: right;
}
.Footer_copyRight__\\+34Ba p {
  font-size: 13px;
  letter-spacing: 0.03em;
  font-weight: 400;
  color: #6C81D0;
  margin: 3px 0px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,eAAA;EACA,SAAA;EACA,mBAAA;EAEA,aAAA;EACA,wBAAA;AAAJ;;AAGA;EACI,qBAAA;AAAJ;AACI;EACI,gBAAA;EACA,YAAA;EACA,kBAAA;AACR;AAAQ;EACI,qBAAA;EACA,kBAAA;AAEZ;AADY;EACI,cAAA;EACA,eAAA;EACA,qBAAA;EACA,gBAAA;AAGhB;;AAGA;EACI,qBAAA;EACA,YAAA;AAAJ;AACI;EACI,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AACR","sourcesContent":[".footerSection {\n    width: 100%;\n    position: fixed;\n    bottom: 0;\n    background: #1B2744;\n    // height: 40px;\n    z-index: 9999;\n    padding: 5px 4px 5px 4px;\n}\n\n.footerNav {\n    display: inline-block;\n    ul {\n        list-style: none;\n        padding: 0px;\n        margin-bottom: 0px;\n        li {\n            display: inline-block;\n            margin-right: 15px;\n            a {\n                color: #B0B3B7;\n                font-size: 13px;\n                text-decoration: none;\n                font-weight: 400;\n            }\n        }\n    }   \n}\n\n.copyRight {\n    display: inline-block;\n    float: right;\n    p {\n        font-size: 13px;\n        letter-spacing: 0.03em;\n        font-weight: 400;\n        color: #6C81D0;\n        margin: 3px 0px 0px;\n    }\n}\n\n@media screen and (min-width: 1440px){\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerSection": `Footer_footerSection__El5+F`,
	"footerNav": `Footer_footerNav__bP+yb`,
	"copyRight": `Footer_copyRight__+34Ba`
};
export default ___CSS_LOADER_EXPORT___;

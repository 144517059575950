import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './NewCandidate.module.scss';
import closeJob from '../../../../assets/images/closeIcon.svg';

import Popup from "../Popup";
import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { useForm, Controller } from "react-hook-form";
import InputField from "../../../common/InputField/InputField";
import moment from "moment";
import {
    genderOption,
} from '../../../../common/DropdownOptions';
import _ from "underscore";
import { getEducationList, getExperienceList } from "../../../../services/api/common";
import { DatePicker } from "@mui/x-date-pickers";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import TextArea from "../../TextArea/TextArea";

export default function NewCandidate({
    isOpen,
    handleClose,
    cityData,
    // mobileNumber,
    // email,
    inputValues,
    setCandidateData = () => {
        return null;
    }
}) {

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        getValues: getValues2,
        control: control2,
        setValue: setValue2,
        clearErrors: clearErrors2 } = useForm();
    const { educationDegree, educationBranch } = getValues2();

    //state
    const [educationData, setEducationData] = useState([]);
    const [dropdownData, setDropdownData] = useState({ educationType: { value: 0, label: "Choose the education" } });
    const [degreeData, setDegreeData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [degreeBranchDefaultValue, setDegreeBranchDefaultValue] = useState({});
    const [drivingRequirements, setDrivingRequirements] = useState({
        drivingLicense: false,
        bikeOwner: false,
        ownCar: false
    });
    const [experienceData, setExperienceData] = useState([]);

    useEffect(() => {
        // console.log('mobile', mobileNumber, email)
        getEducation();
        getExperience();
    }, []);

    useEffect(() => {
        setValue2('whatsappMobile', inputValues?.mobileNumber);
        setValue2('email', inputValues?.email);
        setValue2('fullName', inputValues?.fullName);
    }, [inputValues]);


    const getEducation = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getEducationList();
            let educationGroup = data && _.groupBy(data, 'education');
            let forDegree = [], forBranch = [];
            for (let [key, value] of Object.entries(educationGroup)) {
                if (value && value.length > 0) {
                    forDegree[key] = value.map((item) => {
                        if (item.degree) {
                            if (item.branch && item.branch.length > 0) {
                                // forBranch[item.degree] = item.branch;
                                let key = item.degree;
                                forBranch.push({ [key]: item.branch })
                            }
                            return {
                                label: item.degree,
                                value: item.degree
                            }

                        } else {
                            return null;
                        }

                    });
                }
            }
            let formattedBranch = []
            forBranch && forBranch.length > 0 && forBranch.map((item) => {
                for (let [key, value] of Object.entries(item)) {
                    if (value && value.length > 0) {
                        formattedBranch[key] = value.map((item) => {
                            return {
                                label: item,
                                value: item
                            }
                        });
                    }
                }

            });

            let educationContent = data && data.length > 0 && data.map(({ education, educationSort }) => {
                return {
                    label: education,
                    value: educationSort
                }
            });
            educationContent = educationContent.reduce((unique, o) => {
                if (!unique.some(obj => obj.label === o.label)) {
                    unique.push(o);
                }
                return unique;
            }, []);

            setEducationData(educationContent);
            setDegreeData(forDegree);
            setBranchData(formattedBranch);
        } catch (error) {
        }
    }

    const getExperience = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getExperienceList();
            setExperienceData(
                data && data.length > 0 && data.map(({ experience, experienceYears }) => {
                    return {
                        label: experience,
                        value: experienceYears
                    }
                })
            );
        } catch (error) {
        }
    }

    const handleMultipleOptions = (name, { label, value }) => {
        if (name === "candidateExperience") {
            setValue2('candidateExperience', label);
            setValue2('yearsOfExperience', value)
            setDropdownData({
                ...dropdownData,
                [name]: {
                    label: label,
                    value: value
                }
            });
        } else if (name === "educationType") {
            setValue2('educationType', label);
            setValue2('candidateEducationSort', value)
            setDropdownData({
                ...dropdownData,
                [name]: {
                    label: label,
                    value: value
                },
                'educationBranch': "",
                'educationDegree': ""
            });
            setValue2('educationDegree', '');
            setValue2('educationBranch', '');
            // setDropdownData({
            //     ...dropdownData,
            //     'educationDegree': "",
            //     'educationBranch': ''
            // });
            setDegreeBranchDefaultValue({
                educationDegree: null,
                educationBranch: null
            });
            clearErrors2("educationBranch");
        } else if (name === "gender") {
            setValue2('gender', label);
            setDropdownData({
                ...dropdownData,
                [name]: {
                    label: value,
                    value: value
                }
            });
        }
    }

    const handleDropdown = (name, value) => {
        setDropdownData({
            ...dropdownData,
            [name]: value
        })
        if (name === 'educationDegree' || name === 'educationBranch') {
            setDegreeBranchDefaultValue({
                ...degreeBranchDefaultValue,
                [name]: {
                    label: value,
                    value: value
                }
            })
        }
        // else if (name === 'fromAge') {
        //     getAgeData(value);
        // } else if (name === 'minSalary') {
        //     getSalaryData(value);
        // }
        return value;
    }


    const handleCity = (city, state) => {
        setValue2('city', city);
        setValue2('jobState', state);
        setDropdownData({
            ...dropdownData,
            city: {
                label: city,
                value: city
            }
        });
    }

    const handleDriving = (name, value) => {
        setDrivingRequirements({
            ...drivingRequirements,
            [name]: !value
        })
        return drivingRequirements;
    }

    const submit = (values) => {
        values.drivingLicense = drivingRequirements.drivingLicense;
        values.bikeOwner = drivingRequirements.bikeOwner;
        values.ownCar = drivingRequirements.ownCar;
        values.deviceToken = "";
        values.campaignSourceURL = "CreatedByWebJobOffer";
        values.timeZone = "Asia/Kolkata";
        values.language = "English";
        setCandidateData(values, false);

    }

    return (
        <Popup isOpen={isOpen}>
            <div className={styles.jobsFilter}>
                <img src={closeJob} onClick={handleClose} alt="" />
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>New Candidate<span></span></h3>
                                <p>We are not able to find the candidate you are looking for, please fill the candidate’s details</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className={styles.frmWidth}>
                        <Col md={4} className={styles.FormGroup}>
                            <label>WhatsApp number<span>*</span></label>
                            <InputField
                                name="whatsappMobile"
                                isSearchable={true}
                                placeholder="Enter WhatsApp No."
                                className={styles.formControlselect}
                                type="number"
                                register={register2}
                                validation={{
                                    required: 'Required',
                                    maxLength: {
                                        value: 10,
                                        message: "Maximum allowed numbers 10"
                                    }
                                }}
                                error={errors2?.whatsappMobile}
                            />
                        </Col>
                        <Col md={4} className={styles.FormGroup}>
                            <label>Email address<span>*</span></label>
                            <InputField
                                name="email"
                                placeholder="Enter your Email"
                                className={styles.formControl}
                                register={register2}
                                validation={{
                                    required: 'Required',
                                    pattern: {
                                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                        message: 'Enter valid mail id'
                                    }
                                }}
                                error={errors2?.email}
                            />
                        </Col>
                        <Col md={4} className={styles.FormGroup}>
                            <label>Candidate’s name<span>*</span></label>
                            <InputField
                                name="fullName"
                                placeholder="Enter Candidate Name"
                                className={styles.formControlselect}
                                register={register2}
                                validation={{
                                    required: 'Required'
                                }}
                                error={errors2?.fullName}
                            />
                        </Col>
                        <Col md={4} className={styles.FormGroup}>
                            <label>Education<span>*</span></label>
                            <Controller
                                name="educationType"
                                control={control2}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        options={educationData}
                                        placeholder="Choose the education"
                                        className={styles.formControl}
                                        onChange={(e) => handleMultipleOptions('educationType', e)}
                                        error={errors2?.educationType}
                                        value={dropdownData.educationType}
                                        inputRef={ref}
                                        isLightTheme={true}
                                    />
                                )}
                                rules={{ required: 'Required' }}
                                onFocus={errors2.educationType?.ref}
                            />
                        </Col>
                        <Col md={4} className={styles.FormGroup}>
                            <label>Degree</label>
                            <Controller
                                name="educationDegree"
                                control={control2}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        options={degreeData[dropdownData.educationType.label] && degreeData[dropdownData.educationType.label].length > 1 ? degreeData[dropdownData.educationType.label] : []}
                                        placeholder="Choose the degree"
                                        className={styles.formControl}
                                        onChange={(e) => onChange(handleDropdown('educationDegree', e.value))}
                                        isSearchable={true}
                                        value={degreeBranchDefaultValue.educationDegree}
                                        isLightTheme={true}
                                    />
                                )}
                                onFocus={errors2.educationDegree?.ref}
                            />
                        </Col>
                        <Col md={4} className={styles.FormGroup}>
                            <label>Branch/Stream</label>
                            <Controller
                                name="educationBranch"
                                control={control2}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        name="educationBranch"
                                        options={branchData[dropdownData.educationDegree] ? branchData[dropdownData.educationDegree] : []}
                                        placeholder="Choose the branch/stream"
                                        className={styles.formControl}
                                        onChange={(e) => onChange(handleDropdown('educationBranch', e.value))}
                                        isSearchable={true}
                                        error={errors2?.educationBranch}
                                        value={degreeBranchDefaultValue.educationBranch}
                                        isLightTheme={true}
                                    />
                                )}
                                onFocus={errors2.educationBranch?.ref}
                                rules={{
                                    required: (educationDegree && !educationBranch) ? true : false
                                }}
                            />
                        </Col>
                        <Col md={4} className={styles.FormGroup}>
                            <label>Experience<span>*</span></label>
                            <Controller
                                name="candidateExperience"
                                control={control2}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        options={experienceData}
                                        placeholder="Choose min & max exp."
                                        className={styles.formControl}
                                        onChange={(e) => handleMultipleOptions('candidateExperience', e)}
                                        error={errors2?.candidateExperience}
                                        value={dropdownData.candidateExperience}
                                        inputRef={ref}
                                        isLightTheme={true}
                                    />
                                )}
                                rules={{ required: 'Required' }}
                                onFocus={errors2.candidateExperience?.ref}
                            />
                        </Col>
                        <Col md={4} className={styles.FormGroup}>
                            <label>Birth Date</label>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <div className="dateAndTimePickerLightTheme">
                                    <Controller
                                        name="birthDate"
                                        control={control2}
                                        render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                            <DatePicker
                                                // label="Birth Date"
                                                value={moment(value, "YYYY-MM-DD")}
                                                onChange={(newValue) => {
                                                    onChange(moment(newValue).format("YYYY-MM-DD"));
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        )}
                                        //   rules={{ required: 'Required' }}
                                        onFocus={errors2.birthDate?.ref}
                                    />
                                    {errors2 !== undefined && errors2.birthDate && (
                                        <span className="errorMessage">{errors2.birthDate.message}</span>
                                    )}
                                </div>
                            </LocalizationProvider>
                        </Col>
                        <Col md={4} className={styles.FormGroup}>
                            <label>Gender<span>*</span></label>
                            <Controller
                                name="gender"
                                control={control2}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        isSearchable={true}
                                        options={genderOption}
                                        placeholder="Choose candidate gender"
                                        className={styles.formControl}
                                        onChange={(e) => handleMultipleOptions('gender', e)}
                                        error={errors2?.gender}
                                        value={dropdownData.gender}
                                        inputRef={ref}
                                        isLightTheme={true}
                                    />
                                )}
                                rules={{ required: 'Required' }}
                                onFocus={errors2.gender?.ref}
                            />
                        </Col>

                        <Col md={12} className={styles.FormGroup}>
                            <Row className={styles.drivingRequir}>
                                <Col md={4}>
                                    <ul>
                                        <li>
                                            <label htmlFor="drivingLicense">Candidate have driving license</label>
                                            <label className={styles.checkbox}>
                                                <input type="checkbox" name="drivingLicense" id="drivingLicense" onChange={() => handleDriving('drivingLicense', drivingRequirements.drivingLicense)} checked={drivingRequirements.drivingLicense} className={styles.checkboxInput} />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                    </ul>
                                </Col>
                                <Col md={4}>
                                    <ul>
                                        <li>
                                            <label htmlFor="bikeOwner">Candidate have a motorcycle</label>
                                            <label className={styles.checkbox}>
                                                <input type="checkbox" name="bikeOwner" id="bikeOwner" onChange={() => handleDriving('bikeOwner', drivingRequirements.bikeOwner)} checked={drivingRequirements.bikeOwner} className={styles.checkboxInput} />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                    </ul>
                                </Col>
                                <Col md={4}>
                                    <ul>
                                        <li>
                                            <label htmlFor="ownCar">Candidate have a car</label>
                                            <label className={styles.checkbox}>
                                                <input type="checkbox" name="ownCar" id="ownCar" onChange={() => handleDriving('ownCar', drivingRequirements.ownCar)} checked={drivingRequirements.ownCar} className={styles.checkboxInput} />
                                                <span className={styles.checkboxInner} />
                                            </label>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4} className={styles.FormGroup}>
                            <label>Candidate’s city<span>*</span></label>
                            <Controller
                                name="city"
                                control={control2}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        isSearchable={true}
                                        options={cityData}
                                        placeholder="Select your city/district"
                                        className={styles.formControl}
                                        onChange={(e) => handleCity(e.value, e.state)}
                                        value={dropdownData.city}
                                        error={errors2?.city}
                                        inputRef={ref}
                                        isLightTheme={true}
                                    />
                                )}
                                rules={{ required: true }}
                                onFocus={errors2.city?.ref}
                            />
                        </Col>
                        <Col md={8} className={styles.FormGroup}>
                            <label>Candidate Address<span>*</span></label>
                            <TextArea
                                name="address"
                                placeholder="Address"
                                register={register2}
                                validation={{
                                    required: 'Required',
                                }}
                                error={errors2?.address}
                                maxLength='100'
                                className={styles.formControl}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className={styles.textCenter}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnCancel} onClick={handleClose}>
                                    <div className={styles.effNew}></div>
                                    <button>Cancel <span></span></button>
                                </div>
                                <div className={styles.btnReset} onClick={handleSubmit2(submit)}>
                                    <div className={styles.effReset}></div>
                                    <button>continue<span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}







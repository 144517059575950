import { Col, Container, Row } from 'react-bootstrap';
import HomeLayout from '../../../layouts/HomeLayout/HomeLayout';
import SubHeader from '../../common/SubHeader/SubHeader';
// import styles from './JobList.module.scss';

import { useState, useEffect } from 'react';
import CollapsibleTable from '../../common/CollapsibleTable/CollapsibleTable';
// import CustomPagination from '../../common/CustomPagination/CustomPagination';
import JobCardView from '../JobCardView/JobCardView';
import { getAllJobs } from '../../../services/api/employer';
import { hideLoader, showLoader } from '../../../helpers';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import NoDataFound from '../../common/NoDataFound/NoDataFound';
import JobsFilter from '../../common/Popup/JobsFilter/JobsFilter';
import { getCity, getPositionList } from '../../../services/api/common';
import moment from 'moment';
// import { object } from 'underscore';

const JobList = () => {

    let tableHeadData = [
        {
            name: "S.no."
        },
        {
            name: "Posted On"
        },
        {
            name: "Job Position"
        },
        {
            name: "Salary Range"
        },
        {
            name: "Experience"
        },
        {
            name: "Education"
        },
        {
            name: "City"
        },
        {
            name: "Applied"
        }
    ];

    const { path } = useParams();

    const [jobsData, setJobsData] = useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [recordsCount, setRecordsCount] = useState(10);
    const [viewType, setViewType] = useState('listView');
    const [isJobFilterOpen, setIsJobFilterOpen] = useState(false);
    const [positionData, setPositionData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [resetData, setResetData] = useState([]);
    const [filterdata, setFilterData] = useState([]);


    useEffect(() => {
        // let jobStatus = path === 'pending' ? 'Pending' : path === 'suspended' ? 'Suspended' : path === 'closed' ? 'Closed' : 'Live';
        getJobsData(path);
        setResetData([]);
    }, [path]);

    // const onPageChange = (page) => {
    //     // setCurrentPage(page);
    // }

    // const recordsChange = (data) => {
    // }
    const handleViewType = (data) => {
        setViewType(data);
    }
    const getJobsData = async (path) => {
        try {
            showLoader();
            let jobStatus = path === 'pending' ? 'Pending' : path === 'suspended' ? 'Suspended' : path === 'closed' ? 'Closed' : path === 'CustomQuote' ? 'CustomQuote' : 'Live';
            let params = {
                status: jobStatus
            };
            const {
                data: {
                    data /**,message*/
                }
            } = await getAllJobs(params);
            setJobsData(data);
            // toast.success(message ? message: "success")
            hideLoader();
        } catch (error) {
            hideLoader();
            toast.error("Failed");
        }
    }

    // const fetchJobs = () => {
    //     let jobStatus = path === 'pending' ? 'Pending' : path === 'suspended' ? 'Suspended' : path === 'closed' ? 'Closed' : 'Live';
    //     getJobsData(jobStatus);
    // }

    const getJobPosition = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getPositionList();
            setPositionData(
                data && data.length > 0 && data.map(({ position, positionId, positionUid }) => {
                    return {
                        label: position,
                        value: positionId,
                        positionUid
                    }
                })
            );
        } catch (error) {
        }
    }

    const getCityList = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getCity();
            setCityData(
                data && data.length > 0 && data.map(({ stateName, districtName }) => {
                    let data = districtName.map((item) => { return { label: item, value: item, state: stateName } });
                    return {
                        label: stateName,
                        options: data
                    }
                })
            );
        } catch (error) {
        }
    }

    const openJobFilter = () => {
        getJobPosition();
        getCityList();
        setIsJobFilterOpen(true);
    }

    const handleJobFilter = (position, date, city) => {
        let searchObj = {};
        if (position?.length !== 0) {
            searchObj.positionId = position.value;
        }
        if (date) {
            searchObj.filterDate = date;
        }
        if (city?.length !== 0) {
            searchObj.jobCity = city.value;
        }
        if (Object.keys(city).length === 0 && Object.keys(position).length === 0 && !date) {
            if (resetData?.length > 0) {
                setJobsData(resetData)
            }
        } else {
            let toFilterData = [];
            if (resetData?.length > 0) {
                toFilterData = resetData;
            } else {
                toFilterData = jobsData;
                setResetData(jobsData);
            }
            toFilterData = toFilterData.map(({ ...item }) => ({ ...item, filterDate: (moment(item.postDateTime).format('YYYY-MM-DD')) }));
            let final = toFilterData.filter(function (item) {
                for (var key in searchObj) {
                    if (item[key] === undefined || searchObj[key] === undefined || item[key] !== searchObj[key])
                        return false;
                }
                return true;
            });
            setJobsData(final);
        }
    }

    const getSearchArray = (obj, val) => {
        const filterObj = JSON.parse(obj);
        let searchArray = [];
        for (let i = 0; i < filterObj.length; i++) {
            const jobPosition = filterObj[i].position.toLowerCase();
            const jobSalary = filterObj[i].jobSalary;
            const jobSalaryMax = filterObj[i].jobSalaryMax;
            const jobExperience = filterObj[i].jobExperience.toLowerCase();
            const jobEducation = filterObj[i].jobEducation.toLowerCase();
            const jobCity = filterObj[i].jobCity.toLowerCase();
            const jobAppliedCount = filterObj[i].appliedCount;
            const jobPostedOn = filterObj[i].postDateTime.toLowerCase();
            if (jobPosition.includes(val.toLowerCase()) || jobSalary === parseInt(val) || jobSalaryMax === parseInt(val) ||
                jobEducation.includes(val.toLowerCase()) || jobExperience.includes(val.toLowerCase()) ||
                jobCity.includes(val.toLowerCase()) || jobAppliedCount === parseInt(val) ||
                moment(jobPostedOn).format("DD MMM'YY").toLowerCase().includes(val.toLowerCase())) {

                searchArray.push(filterObj[i])
            }
        }
        return searchArray;
    }

    const searchFilter = (e) => {
        let searchData = JSON.stringify(jobsData);
        if (e.key === 'Enter') {
            if (e.target.value) {
                const newArryay = getSearchArray(searchData, e.target.value);
                setFilterData(newArryay)
            } else {
                setFilterData([])
                setJobsData(jobsData)
            }
        } else {
            setFilterData([])
            setJobsData(jobsData)
        }
    }

    return (
        <HomeLayout>
            {/* <div className='pageContentWrapper'> */}
            <section className='mainContent'>
                <SubHeader
                    handleType={handleViewType}
                    count={jobsData ? jobsData.length : 0}
                    handleFilter={openJobFilter}
                    searchFilter={searchFilter}
                />
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            {jobsData && jobsData.length === 0 ? (
                                <NoDataFound
                                    description="Looks like there is no jobs in this section. Please add a Job then you will be able to see your posts here"
                                    type="job" />
                            ) : (
                                <>
                                    {viewType === "listView" && <div>
                                        <CollapsibleTable tableHeading={tableHeadData} tableRows={filterdata.length === 0 ? jobsData : filterdata} fetchJobs={() => getJobsData(path)} />
                                    </div>}
                                    {viewType === "cardView" && <>
                                        <JobCardView data={filterdata.length === 0 ? jobsData : filterdata} fetchJobs={() => getJobsData(path)} />
                                    </>}
                                </>
                            )
                            }
                        </Col>
                    </Row>
                    <JobsFilter
                        isOpen={isJobFilterOpen}
                        handleClose={() => setIsJobFilterOpen(false)}
                        jobPositionData={positionData}
                        cityData={cityData}
                        handleJobFilter={handleJobFilter}
                        path={path}
                    />
                </Container>
            </section>
            {/* {jobsData && jobsData.length > 0 && <CustomPagination count={jobsData.length} recordPerPage={recordsCount} page={currentPage} showFirstButton={false} showLastButton={false} changePage={onPageChange} totalRecords={(e) => setRecordsCount(e.target.value)} />
            } */}

            {/* </div> */}
        </HomeLayout>
    )
}

export default JobList;
// import { useState, useEffect } from "react";
// import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './ShortlistNormalRight.module.scss';
import ScheduleInterviewImage from '../../../../../assets/images/interviewSch.svg';


export default function ShortlistExpandedRight({ type }) {
  let buttonHeight, buttonWidth;
  if (type === "cardView") {
    buttonHeight = "35px";
    buttonWidth = "120px";
  } else {
    buttonHeight = "38px";
    buttonWidth = "160px";
  }

  return (
    <div className={styles.actionButtons}>
      <div className={styles.ShotlistButton} style={{ width: buttonWidth, height: buttonHeight }}>
        <div className={styles.ShotlisteffView}></div>
        <button style={{ lineHeight: buttonHeight }}><img src={ScheduleInterviewImage} alt='' /> Schedule interview <span></span> </button>
      </div>
    </div>
  );
}







import { Col, Container, Row } from 'react-bootstrap';
import styles from './JobOfferLetter.module.scss';
import companyLogo from '../../assets/images/jobsgaarLogo.svg';
import jobOfferbg from '../../assets/images/offerleterBack.svg';
import infoIcon from '../../assets/images/infoIcon.svg';
import jobDetl from '../../assets/images/jobDetail.svg';
import offerExp from '../../assets/images/offerExp.svg';
import salaryIcon from '../../assets/images/salaryIcon.svg';
import officeInfo from '../../assets/images/officeInfo.svg'
import specializatons from '../../assets/images/addSpecial.svg';
import defaultPro from '../../assets/images/defaultprofile.svg';
import croseImage from '../../assets/images/croseIcon.svg';
import candiArrow from '../../assets/images/candiArrow.svg';


import { useState, useEffect } from 'react';
import InputField from '../common/InputField/InputField';
import { Dropdown } from "../common/Dropdown/Dropdown";
import { hideLoader, showLoader } from '../../helpers';
import { toast } from 'react-toastify';
import { /**useLocation,*/ useNavigate } from 'react-router-dom';
import {
    JobOfferOneTimeBonus,
    jobTypeOption,
    languageOptions,
    workingFromOption,
    workingShiftOption,
    genderOption
} from '../../common/DropdownOptions';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { checkExistingCandidate, createJobMatch, getCity, getPositionList, getSpecializationList } from '../../services/api/common';
import NewCandidate from '../common/Popup/NewCandidate/NewCandidate';
import AllPostedJobsNew from '../common/Popup/AllPostedJobs/AllPostedJobsNew';
import JobOfferSpecialization from '../common/Popup/JobOfferSpecialization/JobOfferSpecialization';
import TextArea from '../common/TextArea/TextArea';
import { createJob, createJobOffer } from '../../services/api/employer';
import JobOfferPreview from '../common/Popup/JobOfferPreview/JobOfferPreview';
import { candidateOnBoard, candidateRegister } from '../../services/api/candidate';



const JobOfferLetter = () => {

    // const { state } = useLocation();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, getValues, control, reset, setValue, setError, clearErrors } = useForm();
    const { jobPosition, bonusPeriod, bonusAmount, email } = getValues();

    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);

    const [cityData, setCityData] = useState([]);
    // const [candidateData, setCandidateData] = useState({});
    const [isOpenCandidatePopup, setIsOpenCandidatePopup] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [dropDownDefaultValues, setDropdownDefaultValues] = useState({});
    const [isExistingJob, setIsExistingJob] = useState(false);
    const [isOpenJobs, setIsOpenJobs] = useState(false);
    const [positionData, setPositionData] = useState([]);

    const [isOpenSpecialization, setIsOpenSpecialization] = useState(false);
    const [specializationData, setSpecializationData] = useState([]);
    const [jobSpecialization, setJobSepecialization] = useState(null);
    const [jobSpecializationDefaultValue, setSpecializationDefaultValue] = useState([]);
    const [workingTime, setWorkingTime] = useState({});
    const [workingDays, setWorkingDays] = useState({
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false
    });
    const [drivingLicense, setDrivingLicense] = useState({
        dl: false,
        ownBike: false,
        ownCar: false
    });
    const [candidateCreateData, setCandidateCreateData] = useState({});
    const [isExistingCandidate, setIsExistingCandidate] = useState(false);
    const [candidateResetData, setCandidateResetData] = useState({});
    const [isOpenJobOfferPreview, setIsOpenJobOfferPreview] = useState(false);
    const [previewCandidateData, setPreviewCandidateData] = useState({});
    const [previewJobData, setPreviewJobData] = useState({});
    const [previewOfferData, setPreviewOfferData] = useState({});
    const [candidateId, setCandidateId] = useState();
    const [jobId, setJobId] = useState();
    const [ishighlight, setIshighlight] = useState(false);
    const [candidateSalary, setCandidateSalary] = useState("")
    const [bonusMoney, setBonusMoney] = useState("")
    const [specializationError, setSpecializationError] = useState(false);
    const candidateCheckError = {
        phone: {
            required: false,
            validation: false
        },
        email: {
            required: false,
            validation: false
        }
    };
    const [candidateRecord, setCandidateRecord] = useState({});


    useEffect(() => {
        // state?.candidateData ? setCandidateData(state.candidateData) : setCandidateData({});
        // state?.jobSummaryData ? setJobOfferData(state.jobSummaryData) : setJobOfferData({});
        getCityList();
        getJobPosition();
    }, [candidateSalary]);


    const getCityList = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getCity();
            setCityData(
                data && data.length > 0 && data.map(({ stateName, districtName }) => {
                    let data = districtName.map((item) => { return { label: item, value: item, state: stateName } });
                    return {
                        label: stateName,
                        options: data
                    }
                })
            );
        } catch (error) {
        }
    }

    const getJobPosition = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getPositionList();
            setPositionData(
                data && data.length > 0 && data.map(({ position, positionId, positionUid }) => {
                    return {
                        label: position,
                        value: positionId,
                        positionUid
                    }
                })
            );
        } catch (error) {
        }
    }

    const checkCandidate = async (phoneNumber, email = "") => {
        try {
            showLoader();
            let params = {
                phoneNumber,
                email
            }
            const {
                data: {
                    data
                }
            } = await checkExistingCandidate(params);
            hideLoader();
            toast.success("Congratulations, the candidate already exists on our platform");
            setIsExistingCandidate(true);
            setDropdownDefaultValues({});
            if (data && data[0]) {
                const { whatsappMobile, fullName, email, city, address, education, educationDegree, educationBranch, experience, experienceYears, gender, _id, candidateEducationSort, state, specialization, language, ownBike, ownCar, expectedSalary, drivingLicense } = data[0];

                let candidateValue = {
                    mobileNumber: whatsappMobile,
                    fullName: fullName,
                    email: getValues("email") ? getValues("email") : email,
                    city: city,
                    address: address,
                    educationType: education,
                    educationDegree: educationDegree ? educationDegree : "",
                    jobBranch: educationBranch ? educationBranch : "",
                    jobExperience: experience,
                    jobExperienceYears: experienceYears,
                    jobGender: gender,
                    profileGender: gender,
                    candidateId: _id,
                    jobEducation: education,
                    jobEducationSort: candidateEducationSort,
                    jobDegree: educationDegree ? educationDegree : "",
                    jobState: state ? state : city,
                    jobSpecialization: specialization,
                    candidateLanguage: language
                }
                let values = {
                    candidateLanguage: language,
                    candidateSpecializations: specialization,
                    candidateGender: gender,
                    profileGender: gender,
                    candidateCity: city,
                    candidateName: fullName,
                    candidateEducation: education,
                    candidateEducationSort: candidateEducationSort,
                    candidateExperience: experience,
                    candidateExperienceYears: experienceYears,
                    candidateDL: drivingLicense,
                    candidateOwnBike: ownBike,
                    candidateOwnCar: ownCar,
                    candidateDesiredSalary: expectedSalary,
                    candidateEmail: email
                }
                setInputValues({
                    ...inputValues,
                    mobileNumber: whatsappMobile,
                    email: getValues("email") ? getValues("email") : email
                })
                setCandidateData(candidateValue, true, values);
                setCandidateId(_id);
                hideLoader();
            }
            // setIsCandidateExist(true);
        } catch (error) {
            hideLoader();
            setIsOpenCandidatePopup(true);
        }
    }

    const setCandidateData = (data, oldCandidate, values) => {
        if (!oldCandidate) {
            setCandidateCreateData(data);
        } else {
            setCandidateRecord(values);
        }
        // const {mobileNumber, fullName, email, city, address} = data;
        // reset(data);
        let dataObj = data;
        let resetObj = {};
        let currentValues = getValues();
        const { gender, educationType, educationDegree, educationBranch, candidateEducationSort, candidateExperience, yearsOfExperience, fullName, mobileNumber, email, address, drivingLicense, bikeOwner, ownCar, city } = data;

        if (!oldCandidate) {
            dataObj.jobGender = gender;
            // dataObj.jobCity = city;
            dataObj.jobEducation = educationType;
            dataObj.jobDegree = educationDegree;
            dataObj.jobBranch = educationBranch;
            dataObj.jobEducationSort = candidateEducationSort;
            dataObj.jobExperience = candidateExperience;
            dataObj.jobExperienceYears = yearsOfExperience;

            let values = {
                candidateLanguage: null,
                candidateSpecializations: null,
                candidateGender: gender,
                candidateCity: city,
                candidateName: fullName,
                candidateEducation: educationType,
                candidateEducationSort,
                candidateExperience,
                candidateExperienceYears: yearsOfExperience,
                candidateDL: drivingLicense,
                candidateOwnBike: bikeOwner,
                candidateOwnCar: ownCar,
                candidateDesiredSalary: null,
                candidateEmail: email
            }
            setCandidateRecord(values);
        }
        setDropdownDefaultValues({
            ...dropDownDefaultValues,
            jobCity: {
                label: data?.city,
                value: data?.city
            },
            profileGender: {
                label: data?.jobGender,
                value: data?.jobGender
            }
        });
        dataObj.jobCity = data?.city;
        dataObj.profileGender = data?.jobGender;
        let nameAndCity = {
            fullName,
            jobCity: city,
            address
        }

        let setFormData = isExistingJob ? { ...dataObj, ...currentValues, ...nameAndCity } : { ...currentValues, ...dataObj, ...nameAndCity };
        reset(setFormData);
        resetObj = {
            fullName,
            mobileNumber,
            email,
            jobCity: data?.city,
            profileGender: data?.jobGender,
            address
        }
        setCandidateResetData(resetObj);

        setIsOpenCandidatePopup(false);
    }


    const getJobSpecializtion = async (positionId) => {
        try {
            let params = {
                positionId
            }
            const {
                data: {
                    data
                }
            } = await getSpecializationList(params);
            setSpecializationData(
                data && data[0] && data[0].specialization && data[0].specialization.length > 0 && data[0].specialization.map((item) => {
                    return {
                        label: item,
                        value: item,
                        isSelected: getValues("jobSpecialization").includes(item) ? true : false
                    }
                })
            );
        } catch (error) {
        }
    }

    const handleSpecialization = (data) => {
        setSpecializationDefaultValue(data);
        let values = [];
        if (data && data.length > 0) {
            let array = data.filter(i => i.isSelected === true);
            values = array.map((item) => {
                return item.value;
            });
        }
        setJobSepecialization(values)
        setValue('jobSpecialization', values);
    }

    const handleworkingDay = (day) => {
        setWorkingDays({
            ...workingDays,
            [day]: !workingDays[day]
        });
    }

    const handleSalary = (e) => {
        //console.log("e.value=======>", e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        if (/^[0-9,]*$/.test(e.target.value)) {
            setValue(e.target.name, e.target.value.replace(",", ""))
            if (e.target.name !== "bonusAmount") {
                setCandidateSalary(e.target.value.replace(",", ""))
            } else {
                setBonusMoney(e.target.value.replace(",", ""))
            }
        }
    }

    // const { mobileNumber, email } = getValues();
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const mailOnChange = () => {
        const { mobileNumber, email } = getValues();
        // if (inputValues?.mobileNumber && inputValues?.email) {
        if (mobileNumber && mobileNumber?.length !== 10) {
            setError('mobileNumber', { type: "maxLength", message: "Maximum allowed numbers 10" });
        } else if (!mobileNumber) {
            setError('mobileNumber', { type: "Required", message: "Required" });
        } else if (!/^[6-9]\d{9}$/.test(mobileNumber)) {
            setError('mobileNumber', { type: "pattern", message: "Enter valid phone number" })
        } else {
            clearErrors('mobileNumber');
        }
        // if (email && !validateEmail(email)) {
        //     setError('email', { type: "pattern", message: "Enter valid mail id" });
        // } else if (!email) {
        //     setError('email', { type: "Required", message: "Required" });
        // } else {
        //     clearErrors('email');
        // }
        if (mobileNumber?.length === 10 && /^[6-9]\d{9}$/.test(mobileNumber)) {
            // setTimeout(() => {

            checkCandidate(mobileNumber, email);
            //}, 100)

        }
        // if(inputValues.mobileNumber.length != 10 && !validateEmail(inputValues?.email)) {
        //     console.log('phone true')
        //     setCandidateCheckError({
        //         ...candidateCheckError,
        //         phone:{
        //             validation: true,
        //             required: false
        //         },
        //         email: {
        //             validation: true,
        //             required: false
        //         }
        //     });
        // } else if(inputValues.mobileNumber.length != 10 && validateEmail(inputValues?.email)) {
        //     console.log('phone false')
        //     setCandidateCheckError({
        //         ...candidateCheckError,
        //         phone:{
        //             validation: true,
        //             required: false
        //         },
        //         email: {
        //             validation: false,
        //             required: false
        //         }
        //     });
        // } else if(inputValues.mobileNumber.length === 10 && !validateEmail(inputValues?.email)) {
        //     console.log('phone false')
        //     setCandidateCheckError({
        //         ...candidateCheckError,
        //         phone:{
        //             validation: false,
        //             required: false
        //         },
        //         email: {
        //             validation: true,
        //             required: false
        //         }
        //     })
        // } else if(inputValues.mobileNumber.length === 10 && validateEmail(inputValues?.email)) {
        //     console.log('phone false')
        //     setCandidateCheckError({
        //         ...candidateCheckError,
        //         phone:{
        //             validation: false,
        //             required: false
        //         },
        //         email: {
        //             validation: false,
        //             required: false
        //         }
        //     });
        // checkCandidate(inputValues?.mobileNumber, inputValues?.email)
        // }
        // setCandidateCheckError(false);
        // } else {
        //     setError('mobileNumber',{ type: "Required", message: "Required"});
        //     setError('email',{ type: "Required", message: "Required"});
        // setCandidateCheckError({
        //     phone: {
        //         required: true
        //     },
        //     email : {
        //         required: true
        //     }
        // });
        // }
    }

    const inputChange = (name, e) => {
        let value = e.target.value
        // if (name !== 'address') {

        //     setInputValues({
        //         ...inputValues,
        //         [name]: value
        //     });
        // }
        if (name === 'mobileNumber') {
            if (inputValues.mobileNumber !== value) {
                setValue("fullName", "")
                setValue("address", "")
                setValue("jobCity", "")
                setDropdownDefaultValues({
                    ...dropDownDefaultValues,
                    jobCity: {
                        label: "",
                        value: ""
                    }
                });
                setInputValues({ ...inputValues, jobCity: "" })
                if (document.querySelector("#react-select-3-input")) {
                    document.querySelector("#react-select-3-input").parentNode.parentElement.childNodes[0].setAttribute("class", "select__placeholder css-1nwujnn-placeholder")
                    document.querySelector("#react-select-3-input").parentNode.parentElement.childNodes[0].innerHTML = "Select candidate's city";
                }
                setCandidateRecord({});
            }
            clearErrors('mobileNumber');
            value = value.replace(/\D/g, '')
            setInputValues({
                ...inputValues,
                [name]: value
            });
        } else if (name === 'address') {
            setCandidateResetData({
                ...candidateResetData,
                address: value
            });
        } else {
            setInputValues({
                ...inputValues,
                [name]: value
            });
        }
    }


    const changeJobOfferType = (type) => {
        reset(candidateResetData)
        setDropdownDefaultValues({
            jobCity: {
                label: candidateResetData?.jobCity,
                value: candidateResetData?.jobCity
            },
            profileGender: {
                label: candidateResetData?.profileGender,
                value: candidateResetData?.profileGender
            }
        });
        setInputValues({
            ...inputValues,
            profileGender: candidateResetData?.profileGender
        });
        let jobResetObj = {
            jobTitle: "",
            jobSalary: "",
            bonusAmount: "",
            timeFrom: null,
            timeTo: null,
            jobDescription: "",
            joiningDate: null,
            noticePeriod: "",
            expireDate: null,
            expireTime: null
        }
        let newResetObj = { ...candidateResetData, ...jobResetObj }
        reset(newResetObj);
        setWorkingDays({
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
            sunday: false
        });
        setDrivingLicense({
            dl: false,
            ownBike: false,
            ownCar: false
        });
        setJobSepecialization(null);
        if (type === 'existing') {
            setIsExistingJob(true);
            // getJobsData();
            setIsOpenJobs(true);
        } else {
            setIsExistingJob(false);
            setJobId('');
        }
    }

    const selectJob = (type) => {
        if (type === 'existing') {
            setIsExistingJob(true);
            // getJobsData();
            setIsOpenJobs(true);
        } else {
            setIsExistingJob(false);
            setJobId('');
        }
    }

    const handleJobPosition = ({ label, value, positionUid }) => {
        document.getElementById("mainContent").scrollTo(0, 500)
        if (getValues('jobPositionId') === value) {
            setIsOpenSpecialization(!isOpenSpecialization);
        } else {
            // handleDropdown('jobPosition', label);
            setValue('jobPosition', label);
            setValue('jobPositionId', value);
            setValue('positionUid', positionUid);
            setValue('jobSpecialization', []);
            // setJobPositionId(value);
            setJobSepecialization([]);
            getJobSpecializtion(value);
            setSpecializationDefaultValue([]);
            setTimeout(() => {
                setIsOpenSpecialization(!isOpenSpecialization);
            }, 1000);
            setDropdownDefaultValues({
                ...dropDownDefaultValues,
                jobPosition: {
                    label,
                    value
                }
            });
        }
    }

    const removeSpecialization = (index, item) => {
        if (index > -1) {
            jobSpecialization.splice(index, 1);
            setJobSepecialization([...jobSpecialization]);
            jobSpecializationDefaultValue.map(i => i.value === item ? i.isSelected = false : i);
            // jobSpecializationDefaultValue[index]['isSelected'] = false;
            setSpecializationDefaultValue([...jobSpecializationDefaultValue]);
        }
    }

    const handleTime = (name, value) => {
        if (value) {
            setWorkingTime({
                ...workingTime,
                [name]: moment(value).format("H")
            })
            return moment(value).format("hh:mm a");
        }
    }

    const handleTimeDisable = (timeValue, clockType) => {
        if (clockType === 'hours' && timeValue <= workingTime.timeFrom) {
            return true;
        }
    }

    const handleDriving = (name, value) => {
        setDrivingLicense({
            ...drivingLicense,
            [name]: !value
        })
        return drivingLicense;
    }

    const handleJobData = (data) => {
        setIsOpenJobs(false);
        setData(data)
    }

    const setData = (data) => {
        let dataObj = data;
        const { drivingLicense, email, jobAge, jobSalary, jobExpiredDate, jobGender, jobCharges, jobType, jobLanguage,
            workingFrom, jobShift, workingTime, workingDays, jobIncentive, position, positionId, specializations, _id } = data;

        setWorkingDays(workingDays);
        // jobIncentive?.monthly > 0 || jobIncentive?.oneTime > 0 ? setIsIncentive(true) : setIsIncentive(false)
        // jobCharges[0]?.oneTimeCharge > 0 || jobCharges[0]?.recursiveCharge > 0 ? setIsJobCharge(true) : setIsJobCharge(false);
        //all fields
        setDropdownDefaultValues({
            // ...dropDownDefaultValues,
            jobType: {
                label: jobType === 'FullTime' ? 'Full Time' : 'Part Time',
                value: jobType
            },
            jobLanguage: {
                label: jobLanguage,
                value: jobLanguage
            },
            workingFrom: {
                label: workingFrom,
                value: workingFrom
            },
            jobShift: {
                label: jobShift,
                value: jobShift
            },
            jobCity: {
                label: candidateResetData?.jobCity,
                value: candidateResetData?.jobCity
            },
            jobPosition: {
                label: position,
                value: position
            },
            bonusPeriod: {
                label: jobIncentive?.monthly > 0 ? 'Monthly' : jobIncentive?.oneTime ? 'One Time' : "",
                value: jobIncentive?.monthly > 0 ? 'monthly' : jobIncentive?.oneTime ? 'oneTime' : "",
            },
            profileGender: {
                label: jobGender,
                value: jobGender
            }
        });

        //     profileGender: {
        //         label: candidateResetData?.profileGender,
        //         value: candidateResetData?.profileGender
        //     }
        // });


        dataObj.jobPosition = position;
        dataObj.jobPositionId = positionId;
        dataObj.fromAge = jobAge?.fromAge;
        dataObj.toAge = jobAge?.toAge;
        dataObj.timeFrom = workingTime?.from;
        dataObj.timeTo = workingTime?.to;
        dataObj.bonusAmount = jobIncentive?.monthly > 0 ? jobIncentive.monthly : jobIncentive?.oneTime > 0 ? jobIncentive?.oneTime : "";
        dataObj.bonusPeriod = jobIncentive?.monthly > 0 ? 'Monthly' : jobIncentive?.oneTime ? 'One Time' : "";
        dataObj.oneTimeCharge = jobCharges[0]?.oneTimeCharge;
        dataObj.recursiveCharge = jobCharges[0]?.recursiveCharge;
        dataObj.jobCity = candidateResetData?.jobCity;
        dataObj.address = candidateResetData?.address;
        dataObj.profileGender = jobGender;
        dataObj.jobSalary = jobSalary;
        dataObj.expireDate = moment(jobExpiredDate).format('YYYY-MM-DD');
        dataObj.email = inputValues.email ? inputValues.email : email;
        setCandidateSalary(jobSalary)
        setInputValues({
            ...inputValues,
            profileGender: jobGender
        });
        reset(dataObj);

        setJobId(_id);

        setDrivingLicense(drivingLicense);
        setWorkingTime({ timeFrom: moment(moment(workingTime?.from, "hh:mm a")).format("h") });
        getJobSpecializtion(positionId, specializations);
        handleSpecialization(specializations.map((item) => { return { value: item, isSelected: true } }));


    }

    const createCandidate = async () => {
        try {
            showLoader();
            const { jobSpecialization, jobPositionId, positionUid, jobPosition, jobState } = getValues();
            let values = candidateCreateData;
            values.city = values.jobCity;
            let registerData = {
                language: values.language,
                fullName: values.fullName,
                whatsappMobile: values.whatsappMobile,
                gender: values.gender
            }
            values.specialization = jobSpecialization;
            values.designationId = jobPositionId;
            values.designationUid = positionUid;
            values.desiredDesignation = jobPosition;
            values.state = jobState;

            // return false;
            const {
                data: {
                    data
                }
            } = await candidateRegister(registerData);
            onBoardingCandidate(data?._id, values);
            // setCandidateId(data?._id);
            hideLoader();
            return data?._id;

        } catch (error) {
            const { data: { message } } = error;
            hideLoader();
            toast.error(message ? message : "Failed, Try again");
        }
    }


    const onBoardingCandidate = async (id, values) => {
        try {

            showLoader();
            let params = {
                id
            }
            let {
                data: {
                /**data,*/ message
                }
            } = await candidateOnBoard(values, params);
            // if (data?.refreshToken) {
            hideLoader();
            toast.success(message ? message : "Candidate created successfully");
            //   }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }


    const getJobExpireDateAndTime = (expiredate, expiretime) => {
        let dateString = expiredate + " " + expiretime,
            dateTimeParts = dateString.split(' '),
            timeParts = dateTimeParts[1].split(':'),
            dateParts = dateTimeParts[0].split('-'),
            date;

        date = new Date(dateParts[0], parseInt(dateParts[1], 10) - 1, dateParts[2], timeParts[0], timeParts[1]);
        return date;
    }

    const submitCreateJob = async () => {
        try {

            showLoader();

            const { profileGender, email, jobTitle, jobAddress, jobDescription, bonusPeriod, bonusAmount,
                jobCity, jobType, jobShift, workingFrom, minSalary, maxSalary, jobGender, jobLanguage, expireTime, expireDate,
                jobExperience, jobEducation, jobDegree, jobBranch, jobPosition, timeFrom, timeTo, jobPositionId,
                positionUid, jobEducationSort, jobExperienceYears, jobSpecialization, planStatus, jobState, jobContactPerson, jobSalary } = getValues();
            let incentiveContent = {};
            if (bonusPeriod && bonusAmount) {
                incentiveContent = {
                    [bonusPeriod]: bonusAmount.replace(",", "")
                }
            }
            let submitData = {
                employerId: userData?._id,
                employerName: userData?.fullName,
                jobCity: jobCity,
                jobType: jobType,
                jobTitle: jobTitle,
                position: jobPosition,
                positionId: jobPositionId,
                positionUid: positionUid,
                specializations: jobSpecialization,
                jobSalary: isExistingJob ? minSalary : jobSalary.replace(",", ""),
                jobSalaryMax: isExistingJob ? maxSalary : jobSalary.replace(",", ""),
                jobVacancy: 1,
                jobGender: profileGender ? profileGender : jobGender,
                jobAge: {
                    fromAge: 18,
                    toAge: 65
                },
                workingFrom: workingFrom,
                jobShift: jobShift,
                workingTime: {
                    from: timeFrom ? timeFrom : "",
                    to: timeTo ? timeTo : ""
                },
                jobOfferExpireDateTime: getJobExpireDateAndTime(expireDate, expireTime),
                jobExperience: jobExperience,
                jobExperienceYears: jobExperienceYears ? jobExperienceYears : 0,
                jobLanguage: jobLanguage,
                workingDays: workingDays,
                jobEducation: jobEducation,
                jobEducationSort: jobEducationSort,
                jobDegree: jobDegree,
                jobBranch: jobBranch,
                jobAddress: jobAddress,
                jobDescription: jobDescription,
                jobContactPerson: jobContactPerson ? jobContactPerson : userData?.representativeName,
                drivingLicense: drivingLicense,
                jobIncentive: incentiveContent,
                jobCharges: [
                    {
                        oneTimeCharge: "",
                        recursiveCharge: ""
                    }
                ],
                companyAddressOnInvoice: " ",
                companyCityOnInvoice: " ",
                companyNameOnInvoice: " ",
                companyZipOnInvoice: " ",
                jobPlan: 15,
                jobState,
                certificateDiploma: false,
                certificateDiplomaName: "",
                gstOnInvoice: "",
                mobileOnInvoice: "",
                planStatus,
                jobURL: "",
                jobEmail: email,
                jobCompanyProof: "",
                jobPostedBy: userData.fullName,
                workingComments: "",
                callVia: {
                    phone: false,
                    whatsapp: false,
                    appPhone: false,
                    dontcall: false,
                    appVideo: false
                },
                jobStatusDescription: "",
                jobFrom: "dashboard",
                salaryCurrency: "INR",
                jobValid: true,
                jobExpiredDate: moment().add(7, 'days').format('YYYY-MM-DD'),
                jobImage: []
            }

            const {
                data: {
                    data
                }
            } = await createJob(submitData);
            hideLoader();
            // setJobId(data?._id);
            return data?._id;
            // toast.success(message ? message : "Success");

        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const handleJobOfferPreview = () => {
        // return

        let { profileGender, jobGender, jobAddress, jobPosition, jobTitle, jobShift, jobType,
            workingFrom, jobLanguage, jobContactPerson, jobSpecialization,
            timeFrom, timeTo, joiningDate, noticePeriod, expireDate, expireTime,
            jobSalary, bonusPeriod, bonusAmount, fullName, mobileNumber, email,
            jobCity, address, jobDescription } = getValues();
        let jobData = {
            employerName: userData?.fullName,
            jobAddress,
            position: jobPosition,
            jobGender: profileGender ? profileGender : jobGender,
            jobTitle,
            jobShift,
            jobType,
            workingFrom,
            workingTime: {
                from: timeFrom ? timeFrom : "",
                to: timeTo ? timeTo : ""
            },
            jobLanguage,
            workingDays,
            specializations: jobSpecialization,
            jobContactPerson: jobContactPerson ? jobContactPerson : userData?.representativeName,
            // _id: jobId
        }
        let offerData = {
            joiningDate,
            noticePeriod,
            expireDate,
            expireTime,
            salary: jobSalary,
            bonusPeriod,
            bonusAmount,
            comments: jobDescription,
            jobLocation: jobAddress,
            salaryCurrency: 'INR'
        }
        let offerCandidateData = {
            fullName,
            whatsappMobile: mobileNumber,
            email,
            city: jobCity,
            fullAddress: address,
            // _id: candidateId
        }
        setPreviewJobData(jobData);
        setPreviewCandidateData(offerCandidateData);
        setPreviewOfferData(offerData);
        setIsOpenJobOfferPreview(true);
    }
    const createJobOfferLetter = async () => {
        try {
            if (jobSpecializationDefaultValue.length === 0) {
                setSpecializationError(true);
                setIsOpenSpecialization(!isOpenSpecialization)
            } else if (Object.keys(candidateRecord).length === 0) {
                setIsOpenCandidatePopup(true);
            } else {
                showLoader();
                let newCandidateId, newJobId;
                if (!isExistingCandidate && !candidateId) {
                    newCandidateId = await createCandidate();
                }
                if (!isExistingJob && !jobId) {
                    newJobId = await submitCreateJob();
                }
                let { jobAddress, jobTitle, jobShift, jobType, workingFrom, timeFrom, timeTo, joiningDate, noticePeriod, jobSalary, bonusPeriod, bonusAmount, jobDescription, expireDate, expireTime } = getValues();
                let submitObj = {};
                let idCandidate = isExistingCandidate ? candidateId : newCandidateId;
                let idJob = isExistingJob ? jobId : newJobId;
                if (idCandidate && idJob) {
                    submitObj = {
                        jobId: idJob,
                        candidateId: idCandidate,
                        salary: candidateSalary ? candidateSalary : parseInt(jobSalary.replace(",", "")),
                        salaryCurrency: 'INR',
                        oneTimeBonus: {
                            bonusPeriod,
                            bonusAmount: bonusAmount.replace(",", "")
                        },
                        joiningDate,
                        noticePeriod,
                        jobType,
                        jobTitle,
                        shift: jobShift,
                        workingFrom,
                        workingDays,
                        workingHours: {
                            from: timeFrom ? timeFrom : "",
                            to: timeTo ? timeTo : ""
                        },
                        jobLocation: jobAddress,
                        comments: jobDescription,
                        offerStatus: "Pending",
                        offerSent: true,
                        jobOfferExpireDateTime: getJobExpireDateAndTime(expireDate, expireTime),
                        information: jobDescription
                    }
                    const {
                        data: {
                            /**data, message*/
                        } } = await createJobOffer(submitObj);
                    hideLoader();
                    jobMatchCreation(idCandidate, idJob);
                }
            }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const jobMatchCreation = async (idCandidate, idJob) => {
        try {

            const { jobTitle, jobAddress, jobDescription, jobCity, jobType, jobShift, jobGender, jobExperience, jobEducation, jobPosition, timeFrom, timeTo, jobPositionId, positionUid, jobEducationSort, jobExperienceYears, jobSpecialization, jobSalary, jobSalaryMax, jobLanguage } = getValues();
            const { candidateLanguage,
                candidateSpecializations,
                candidateGender,
                candidateCity,
                candidateName,
                candidateEducation,
                candidateEducationSort,
                candidateExperience,
                candidateExperienceYears,
                candidateDL,
                candidateOwnBike,
                candidateOwnCar,
                candidateDesiredSalary } = candidateRecord;
            const { dl, ownBike, ownCar } = drivingLicense;

            let positionSalary = jobSalary;
            if (typeof positionSalary === 'string') {
                positionSalary = parseInt(jobSalary.replace(",", ""))
            }
            showLoader();
            let values = {
                candidateNotifications: [],
                employerNotifications: [],
                candidateActionHistory: [],
                employerActionHistory: [],
                card: "Ace",
                cardNumber: 14,
                candidateOk2FuelPumpOprator: false,
                candidateNotificationsSent: false,
                employerNotificationsSent: false,
                candidateNotifierStatus: "",
                employerNotifierStatus: "",
                employerAction: 'JobOffer',
                candidateAction: "Apply",
                career: "Well done on job offer, take the opportunity and accept",
                opportunity: "Well done on job offer",
                cardShape: "Heart",
                immediateInterviewCallSID: "",
                immediateInterviewsStatus: "",
                candidateSpecializations: candidateSpecializations ? candidateSpecializations : jobSpecialization,
                jobSpecializations: jobSpecialization,
                candidateLanguage: candidateLanguage ? candidateLanguage : jobLanguage,
                jobCity,
                candidateCity,
                candidateId: idCandidate,
                candidateName,
                candidateGender,
                jobRequestedGender: jobGender,
                candidateEducation,
                candidateEducationSort,
                jobEducation,
                jobEducationSort,
                candidateExperience,
                candidateExperienceYears: candidateExperienceYears ? candidateExperienceYears : 0,
                jobExperience,
                jobExperienceYears: jobExperienceYears ? jobExperienceYears : 0,
                candidateDL: candidateDL ? true : false,
                jobDL: dl ? true : false,
                candidateOwnBike: candidateOwnBike ? true : false,
                jobOwnBike: ownBike ? true : false,
                candidateOwnCar: candidateOwnCar ? true : false,
                jobOwnCar: ownCar ? true : false,
                candidateDesignationId: jobPositionId,
                candidateDesignationName: jobPosition,
                jobPositionId,
                jobPositionName: jobPosition,
                employerId: userData?._id,
                jobId: idJob,
                jobTitle,
                jobDescription,
                jobCompanyName: userData?.companyName,
                jobAddress,
                candidateDesiredSalary: candidateDesiredSalary ? candidateDesiredSalary : positionSalary,
                candidateSalaryCurrency: "INR",
                jobSalaryCurrency: "INR",
                candidatePositionUID: positionUid,
                jobPositionUID: positionUid,
                jobType,
                jobShift,
                workingTime: {
                    from: timeFrom,
                    to: timeTo
                },
                jobOfferedSalary: positionSalary,
                jobOfferedSalaryMax: jobSalaryMax
            }
            const {
                data: {
                    /**data,*/ message
                } } = await createJobMatch(values);
            hideLoader();
            toast.success(message ? message : "Success");
            setIsOpenJobOfferPreview(false);
            navigate('/dashboard');
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const handleDropdownDefault = (name, data) => {
        setDropdownDefaultValues({
            ...dropDownDefaultValues,
            [name]: data
        });
        return data?.value;
    }

    const setNoticePeriod = (evt) => {
        {
            if (/^[0-9]+$/.test(evt.target.value)) {
                setValue("noticePeriod", evt.target.value)
            } else {
                setValue("noticePeriod", evt.target.value.replace(/\D/g, ''))
            }
        }
    }

    const setJobName = (e) => {
        if (/^[A-Za-z ]+$/.test(e.target.value)) {
            setValue("jobTitle", e.target.value)
        } else {
            setValue("jobTitle", e.target.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
        }
    }

    return (
        <section className={styles.jobOffer}>
            <img src={jobOfferbg} alt="" />
            <form>
                <Container className={styles.cntntJobOffer}>
                    <Row>
                        <Col md={6}>
                            <div className={styles.profileName}>
                                <img src={userData?.representativeProfileUrl ?? defaultPro} alt="" />
                                <h3>{userData?.representativeName}<span>{userData?.representativeRole}</span></h3>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={styles.offerDate}>
                                <h3>{moment().format('DD MMM YYYY')}<span>Job Offer Sending Date</span></h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className={styles.offerContent} id="mainContent">
                            <Col md={12}>
                                <div className={styles.compName}>
                                    <img src={companyLogo} alt="" onClick={() => navigate('/dashboard')} />
                                </div>
                            </Col>
                            <Col md={12}>
                                <Row>
                                    <Col md={7}>
                                        <Row>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className={styles.FormGroup}>
                                                            <label>Phone number:</label>
                                                        </div>
                                                    </Col>
                                                    <Col md={9}>
                                                        <div className={styles.FormGroup} onBlur={() => mailOnChange()}>
                                                            <InputField
                                                                name="mobileNumber"
                                                                placeholder="Enter phone number"
                                                                className={styles.formControl}
                                                                register={register}
                                                                type="number"
                                                                validation={{
                                                                    required: 'Required',
                                                                    maxLength: {
                                                                        value: 10,
                                                                        message: "Maximum allowed numbers 10"
                                                                    },
                                                                    minLength: {
                                                                        value: 10,
                                                                        message: "Phone number should be 10"
                                                                    },
                                                                    pattern: {
                                                                        value: /^[6-9]\d{9}$/,
                                                                        message: 'Enter valid phone number'
                                                                    }

                                                                }}
                                                                error={errors?.mobileNumber}
                                                                onChange={(e) => inputChange('mobileNumber', e)}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className={styles.FormGroup}>
                                                            <label>Email address:</label>
                                                        </div>
                                                    </Col>
                                                    <Col md={9}>
                                                        <div className={styles.FormGroup}>
                                                            <InputField
                                                                name="email"
                                                                placeholder="Enter email address"
                                                                className={styles.formControl}

                                                                register={register}
                                                                validation={{
                                                                    required: 'Required',
                                                                    pattern: {
                                                                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                                        message: 'Enter valid mail id'
                                                                    }
                                                                }}
                                                                error={errors?.email}
                                                                onChange={(e) => inputChange('email', e)}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                    </Col>
                                                    <Col md={9} className="mt-1" style={{ textAlign: "center" }}>
                                                        {/* {candidateCheckError && (
                                                            <p className="errorMessage mt-1 mb-2">Enter phone number and mail address</p>
                                                        )} */}
                                                        <a className={styles.checkCandidate} onClick={mailOnChange}>check for candidate  <img src={candiArrow} /></a>
                                                        {/* <a className={styles.checkCandidate} onClick={mailOnChange}>check for candidate</a>
                                                        <a className={styles.checkCandidate} onClick={() => setIshighlight(!ishighlight)}>&nbsp;
                                                            <img src={infoIcon} alt="alternative text" title="If the candidate exists on our platform we will link this to candidate, but if the candidate doesn’t exists, we will request you to fill more details about the candidate." /></a> */}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className={styles.FormGroup}>
                                                            <label>Name:</label>
                                                        </div>
                                                    </Col>
                                                    <Col md={9}>
                                                        <div className={styles.FormGroup}>
                                                            <InputField
                                                                name="fullName"
                                                                placeholder="Enter candidate's name"
                                                                className={styles.formControl}
                                                                register={register}
                                                                validation={{
                                                                    required: 'Required',
                                                                    pattern: {
                                                                        value: /^[a-zA-Z ]*$/, message: "Spacial character not allowed"
                                                                    }
                                                                }}
                                                                error={errors?.fullName}
                                                                onChange={(e) => inputChange('fullName', e)}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={5}>
                                        <div className={styles.detailOffer} style={{ border: ishighlight ? '3px solid #ff6600' : "1px solid #ff6600" }}>
                                            <h3><img src={infoIcon} alt="" />Candidate’s details for offer letter!<span></span></h3>
                                            <p>If the candidate exists on our platform we will link this to candidate, but if the candidate doesn’t exists, we will request you to fill more details about the candidate.</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={styles.marginTop}>
                                    <Col md={7}>
                                        <Row>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className={styles.FormGroup}>
                                                            <label>City:</label>
                                                        </div>
                                                    </Col>
                                                    <Col md={9}>
                                                        <div className={styles.FormGroup}>
                                                            <Controller
                                                                name="jobCity"
                                                                control={control}
                                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                    <Dropdown
                                                                        isSearchable={true}
                                                                        options={cityData}
                                                                        placeholder="Select candidate's city"
                                                                        className={styles.formControl}
                                                                        onChange={(e) => onChange(handleDropdownDefault('jobCity', e))}
                                                                        error={errors?.jobCity}
                                                                        inputRef={ref}
                                                                        isLightTheme={false}
                                                                        value={dropDownDefaultValues.jobCity}
                                                                    />
                                                                )}
                                                                rules={{ required: true }}
                                                                onFocus={errors.jobCity?.ref}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={1} className={styles.nameNewLabel}>
                                                        <div className={styles.FormGroup}>
                                                            <label>Full address:</label>
                                                        </div>
                                                    </Col>
                                                    <Col md={11} className={styles.inputNewweb}>
                                                        <div className={styles.FormGroup}>
                                                            <InputField
                                                                name="address"
                                                                placeholder="Enter candidate's address"
                                                                className={styles.formControl}
                                                                register={register}
                                                                validation={{
                                                                    required: 'Required'
                                                                }}
                                                                error={errors?.address}
                                                                onChange={(e) => inputChange('address', e)}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={12}>
                                                <div className={styles.textContnt}>
                                                    <p>We have all really enjoyed speaking with you and getting to know you over the interactions we had.<br />We are impressed with your background and approach and would love to formally offer you a position at</p>
                                                </div>
                                            </Col>
                                            <Col md={12} className={styles.headNew}>
                                                <h3>{userData?.companyName}</h3>
                                                <Row>
                                                    <Col md={1} className={styles.nameNewLabel}>
                                                        <div className={styles.FormGroup}>
                                                            <label>Job location:</label>
                                                        </div>
                                                    </Col>
                                                    <Col md={11} className={styles.inputNewweb}>
                                                        <div className={styles.FormGroup}>
                                                            <InputField
                                                                name="jobAddress"
                                                                placeholder="Enter your job full address"
                                                                className={styles.formControl}
                                                                register={register}
                                                                validation={{
                                                                    required: 'Required'
                                                                }}
                                                                error={errors?.jobAddress}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col md={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className={styles.selectOffer}>
                                                            <h3>Select an option for your new offer letter.</h3>
                                                            <Row className={styles.selectIcon}>
                                                                <Col md={6} className={styles.FormGroup}>
                                                                    <div className={styles.radio}>
                                                                        <label className={styles.posInput}>
                                                                            <input className={styles.inRadio} type="radio" name="radio-input" onClick={() => selectJob('existing')} onChange={() => changeJobOfferType('existing')} checked={isExistingJob} />Existing job’s offer letter
                                                                            <span className={styles.checkmark} />
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} className={styles.FormGroup}>
                                                                    <div className={styles.radio}>
                                                                        <label className={styles.posInput}>
                                                                            <input className={styles.inRadio} type="radio" name="radio-input" onChange={() => changeJobOfferType('new')} checked={!isExistingJob} /> Entirety new offer letter
                                                                            <span className={styles.checkmark} />
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>


                                            <Col md={7} className={styles.headNewJob}>
                                                <h3><img src={jobDetl} alt="" /> Job details</h3>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Job title:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.FormGroup}>
                                                                    <InputField
                                                                        name="jobTitle"
                                                                        type="text"
                                                                        placeholder="Enter the job name"
                                                                        register={register}
                                                                        onChange={e => setJobName(e)}
                                                                        validation={{
                                                                            required: 'Required',
                                                                            pattern: {
                                                                                value: /^[A-Za-z ]+$/,
                                                                                message: 'Job name should contain only alphabets and spaces'
                                                                            }
                                                                        }}
                                                                        error={errors?.jobTitle}
                                                                        className={styles.formControl}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Position name:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.FormGroup}>
                                                                    <Controller
                                                                        name="jobPosition"
                                                                        control={control}

                                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                            <Dropdown
                                                                                isSearchable={true}
                                                                                options={positionData}
                                                                                placeholder="Choose your job position"
                                                                                className={styles.formControl}
                                                                                onChange={(e) => handleJobPosition(e)}
                                                                                error={errors?.jobPosition}
                                                                                inputRef={ref}
                                                                                value={dropDownDefaultValues.jobPosition}
                                                                                isLightTheme={false}
                                                                            />
                                                                        )}
                                                                        rules={{ required: 'Required' }}
                                                                        onFocus={errors.jobPosition?.ref}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={10}    >
                                                        <Row>
                                                            <Col md={3} className={styles.nameNew}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Specialization:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9} className={styles.inputNew}>
                                                                <div className={styles.FormGroup}>
                                                                    <div className={styles.formControl}>
                                                                        {jobSpecialization && jobSpecialization.length > 0 && <div className={styles.specialBtn}>
                                                                            {/* <label>Specialization</label> */}
                                                                            {jobSpecialization.map((item, index) => {
                                                                                return (
                                                                                    <span key={index}>{item}<img onClick={() => removeSpecialization(index, item)} src={croseImage} alt="" /></span>
                                                                                )
                                                                            })}
                                                                        </div>}
                                                                        <img className={styles.addSpeci} src={specializatons} alt="" onClick={() => setIsOpenSpecialization(!isOpenSpecialization)} />
                                                                    </div>
                                                                    {specializationError && <span class="errorMessage">Required</span>}
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={5}>
                                                <div className={styles.detailposi}>
                                                    <h3><img src={infoIcon} alt="" />Position Name and Job Title is Mandatory<span></span></h3>
                                                    <p>Please select from drop down, else type, and we will create the new job for you.</p>
                                                </div>
                                            </Col>
                                            <Col md={7} className={styles.headNewJob}>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Date of joining:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.formControl}>
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <div className="dateAndTimePickerLightTheme">
                                                                            <Controller
                                                                                name="joiningDate"
                                                                                control={control}
                                                                                render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                                                    <DatePicker
                                                                                        //   label="Date of Joining"
                                                                                        ampm={false}
                                                                                        value={value ? moment(value, "YYYY-MM-DD") : null}
                                                                                        onChange={(newValue) => {
                                                                                            onChange(moment(newValue).format("YYYY-MM-DD"));
                                                                                        }}
                                                                                        renderInput={(params) => <TextField {...params} />}
                                                                                    />
                                                                                )}
                                                                                rules={{ required: 'Required' }}
                                                                                onFocus={errors.joiningDate?.ref}
                                                                            />
                                                                            {errors !== undefined && errors.joiningDate && (
                                                                                <span className="errorMessage">{errors.joiningDate.message}</span>
                                                                            )}
                                                                        </div>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Notice period:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.FormGroup}>
                                                                    <InputField
                                                                        className={styles.formControl}
                                                                        name="noticePeriod"
                                                                        placeholder="Job notice period in days"
                                                                        register={register}
                                                                        onChange={evt => setNoticePeriod(evt)}
                                                                        maxLength="2"
                                                                        validation={{
                                                                            required: 'Required',
                                                                            pattern: {
                                                                                value: /^[0-9]+$/,
                                                                                message: 'Notice period should contain only numbers'
                                                                            }
                                                                        }}
                                                                        error={errors?.noticePeriod}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <h3><img src={offerExp} alt="" /> Offer letter expire</h3>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Expire date:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.formControl}>
                                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                        <div className="dateAndTimePickerLightTheme">
                                                                            <Controller
                                                                                name="expireDate"
                                                                                control={control}
                                                                                render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                                                    <DatePicker
                                                                                        // label="Expire Date"
                                                                                        value={value ? moment(value, "YYYY-MM-DD") : null}
                                                                                        onChange={(newValue) => {
                                                                                            onChange(moment(newValue).format("YYYY-MM-DD"));
                                                                                        }}
                                                                                        renderInput={(params) => <TextField {...params} />}
                                                                                    />
                                                                                )}
                                                                                rules={{ required: 'Required' }}
                                                                                onFocus={errors.expireDate?.ref}
                                                                            />
                                                                            {errors !== undefined && errors.expireDate && (
                                                                                <span className="errorMessage">{errors.expireDate.message}</span>
                                                                            )}
                                                                        </div>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Expire time:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.formControl}>
                                                                    <div className="dateAndTimePickerLightTheme">
                                                                        <Controller
                                                                            name="expireTime"
                                                                            control={control}
                                                                            render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                    <TimePicker
                                                                                        label="24 hours"
                                                                                        // label="Expire Time"
                                                                                        value={value ? moment(value, "HH:mm:ss") : null}
                                                                                        onChange={(newValue) => {
                                                                                            onChange(moment(newValue).format("HH:mm:ss"));
                                                                                        }}
                                                                                        renderInput={(params) => <TextField {...params} />}
                                                                                        inputRef={ref}
                                                                                        ampm={false}
                                                                                    />
                                                                                </LocalizationProvider>
                                                                            )}
                                                                            rules={{ required: 'Required' }}
                                                                            onFocus={errors.expireTime?.ref}
                                                                        />
                                                                        {errors !== undefined && errors.expireTime && (
                                                                            <span className="errorMessage">{errors.expireTime.message}</span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={7} className={styles.headNewJob}>
                                                <h3><img src={salaryIcon} alt="" /> Salary & Bonus</h3>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Monthly Salary:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className={styles.FormGroup} >
                                                                    <input
                                                                        {...register("jobSalary", { required: true })}
                                                                        className={styles.formControl}
                                                                        value={candidateSalary.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        name="jobSalary"
                                                                        onChange={(e) => handleSalary(e)}
                                                                        placeholder="Salary"
                                                                        validation={{
                                                                            required: 'Required',
                                                                            pattern: {
                                                                                value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                                message: 'Salary should contain only numbers'
                                                                            }
                                                                        }}
                                                                        error={errors?.jobSalary}
                                                                        type="text"
                                                                    />
                                                                    {errors?.jobSalary && <span class="errorMessage">Required</span>}
                                                                </div>
                                                            </Col>
                                                            <Col md={3} className={styles.inrStyle}>
                                                                INR/Month
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Bonus period:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.FormGroup}>
                                                                    <Controller
                                                                        name="bonusPeriod"
                                                                        control={control}
                                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                            <Dropdown
                                                                                isSearchable={true}
                                                                                options={JobOfferOneTimeBonus}
                                                                                placeholder="Bonus period"
                                                                                className={styles.formControl}
                                                                                onChange={(e) => onChange(handleDropdownDefault('bonusPeriod', e))}
                                                                                error={errors?.bonusPeriod}
                                                                                inputRef={ref}
                                                                                value={dropDownDefaultValues.bonusPeriod}
                                                                                isLightTheme={false}
                                                                            />
                                                                        )}
                                                                        onFocus={errors.bonusPeriod?.ref}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Bonus amount:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.FormGroup}>
                                                                    <input
                                                                        {...register("bonusAmount")}
                                                                        className={styles.formControl}
                                                                        name="bonusAmount"
                                                                        onChange={(e) => handleSalary(e)}
                                                                        value={bonusMoney.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        placeholder="Bonus Amount"
                                                                        validation={{
                                                                            pattern: {
                                                                                value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                                message: 'Bonus amount should contain only numbers'
                                                                            }
                                                                        }}
                                                                        error={errors?.bonusAmount}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={7} className={styles.headNewJob}>
                                                <h3><img src={officeInfo} alt="" /> Office Information</h3>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Job shift:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.FormGroup}>
                                                                    <Controller
                                                                        name="jobShift"
                                                                        control={control}
                                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                            <Dropdown
                                                                                options={workingShiftOption}
                                                                                placeholder="Choose working shift"
                                                                                className={styles.formControl}
                                                                                onChange={(e) => onChange(handleDropdownDefault('jobShift', e))}
                                                                                error={errors?.jobShift}
                                                                                inputRef={ref}
                                                                                value={dropDownDefaultValues.jobShift}
                                                                                isLightTheme={false}
                                                                            />
                                                                        )}
                                                                        rules={{ required: 'Required' }}
                                                                        onFocus={errors.jobShift?.ref}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Job type:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.FormGroup}>
                                                                    <Controller
                                                                        name="jobType"
                                                                        control={control}
                                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                            <Dropdown
                                                                                isSearchable={true}
                                                                                options={jobTypeOption}
                                                                                placeholder="Choose your job type"
                                                                                className={styles.formControl}
                                                                                onChange={(e) => onChange(handleDropdownDefault('jobType', e))}
                                                                                error={errors?.jobType}
                                                                                inputRef={ref}
                                                                                value={dropDownDefaultValues.jobType}
                                                                                isLightTheme={false}
                                                                            />
                                                                        )}
                                                                        rules={{ required: 'Required' }}
                                                                        onFocus={errors.jobType?.ref}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Work from:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.FormGroup}>
                                                                    <Controller
                                                                        name="workingFrom"
                                                                        control={control}
                                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                            <Dropdown
                                                                                options={workingFromOption}
                                                                                placeholder="Choose work from"
                                                                                className={styles.formControl}
                                                                                onChange={(e) => onChange(handleDropdownDefault('workingFrom', e))}
                                                                                error={errors?.workingFrom}
                                                                                inputRef={ref}
                                                                                value={dropDownDefaultValues.workingFrom}
                                                                                isLightTheme={false}
                                                                            />
                                                                        )}
                                                                        rules={{ required: 'Required' }}
                                                                        onFocus={errors.workingFrom?.ref}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.mrBo}>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Shift timing:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className={styles.FormGroup}>
                                                                            <Controller
                                                                                className={styles.newWidth}
                                                                                name="timeFrom"
                                                                                defaultValue={"09:00:00 am"}
                                                                                ampmInClock
                                                                                control={control}
                                                                                render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                        <TimePicker
                                                                                            // placeholder="From"
                                                                                            label="From"
                                                                                            value={value ? moment(value, "hh:mm a") : moment("09:00:00 am", "hh:mm a")}
                                                                                            onChange={(newValue) => {
                                                                                                onChange(handleTime('timeFrom', newValue));
                                                                                            }}
                                                                                            renderInput={(params) => <TextField {...params} />}
                                                                                            inputRef={ref}
                                                                                        />
                                                                                    </LocalizationProvider>

                                                                                )}
                                                                                rules={{ required: 'Required' }}
                                                                                onFocus={errors.timeFrom?.ref}
                                                                            />
                                                                            {errors !== undefined && errors.timeFrom && (
                                                                                <span className="errorMessage">{errors.timeFrom.message}</span>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <div className={styles.FormGroup}>
                                                                            <Controller
                                                                                className={styles.newWidth}
                                                                                name="timeTo"
                                                                                control={control}
                                                                                defaultValue={"06:00:00 pm"}
                                                                                render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                                                        <TimePicker
                                                                                            label="To"
                                                                                            // placeholder="To"
                                                                                            value={value ? moment(value, "hh:mm a") : moment("06:00:00 pm", "hh:mm a")}
                                                                                            onChange={(newValue) => {
                                                                                                onChange(moment(newValue).format("hh:mm a"));
                                                                                            }}
                                                                                            renderInput={(params) => <TextField {...params} />}
                                                                                            inputRef={ref}
                                                                                            shouldDisableTime={handleTimeDisable}
                                                                                        />
                                                                                    </LocalizationProvider>

                                                                                )}
                                                                                rules={{ required: 'Required' }}
                                                                                onFocus={errors.timeTo?.ref}
                                                                            />
                                                                            {errors !== undefined && errors.timeTo && (
                                                                                <span className="errorMessage">{errors.timeTo.message}</span>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.FormGroup}>
                                                                    <label>Language:</label>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.FormGroup}>
                                                                    <Controller
                                                                        name="jobLanguage"
                                                                        control={control}
                                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                            <Dropdown
                                                                                options={languageOptions}
                                                                                placeholder="Choose language"
                                                                                className={styles.formControl}
                                                                                onChange={(e) => onChange(handleDropdownDefault('jobLanguage', e))}
                                                                                error={errors?.jobLanguage}
                                                                                inputRef={ref}
                                                                                value={dropDownDefaultValues.jobLanguage}
                                                                                isLightTheme={false}
                                                                            />
                                                                        )}
                                                                        rules={{ required: 'Required' }}
                                                                        onFocus={errors.jobLanguage?.ref}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={1} className={styles.nameNewLabel}>
                                                <div className={styles.FormGroup}>
                                                    <label>Working days:</label>
                                                </div>
                                            </Col>
                                            <Col md={11} className={styles.inputNewweb}>
                                                <div className={styles.workingDay}>
                                                    <span className={workingDays.monday ? styles.active : ""} onClick={() => handleworkingDay('monday')}>Mon</span>
                                                    <span className={workingDays.tuesday ? styles.active : ""} onClick={() => handleworkingDay('tuesday')}>Tue</span>
                                                    <span className={workingDays.wednesday ? styles.active : ""} onClick={() => handleworkingDay('wednesday')}>Wed</span>
                                                    <span className={workingDays.thursday ? styles.active : ""} onClick={() => handleworkingDay('thursday')}>Thu</span>
                                                    <span className={workingDays.friday ? styles.active : ""} onClick={() => handleworkingDay('friday')}>Fri</span>
                                                    <span className={workingDays.saturday ? styles.active : ""} onClick={() => handleworkingDay('saturday')}>Sat</span>
                                                    <span className={workingDays.sunday ? styles.active : ""} onClick={() => handleworkingDay('sunday')}>Sun</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={1} className={styles.nameNewLabel}>
                                                <div className={styles.FormGroup}>
                                                    <label>Requirements:</label>
                                                </div>
                                            </Col>
                                            <Col md={11} className={styles.inputNewweb}>
                                                <div className={styles.requirDetail}>
                                                    <span className={drivingLicense.dl ? styles.active : ""} onClick={() => handleDriving('dl', drivingLicense.dl)}>Driving license</span>
                                                    <span className={drivingLicense.ownBike ? styles.active : ""} onClick={() => handleDriving('ownBike', drivingLicense.ownBike)}>Bike / Scooty</span>
                                                    <span className={drivingLicense.ownCar ? styles.active : ""} onClick={() => handleDriving('ownCar', drivingLicense.ownCar)}>Car</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={1} className={styles.nameNewLabel}>
                                                <div className={styles.FormGroup}>
                                                    <label>Gender:</label>
                                                </div>
                                            </Col>
                                            <Col md={11} className={styles.inputNewweb}>
                                                <div className={styles.FormGroup}>
                                                    <Col md={3}>
                                                        <Controller
                                                            name="profileGender"
                                                            control={control}
                                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                <Dropdown
                                                                    options={genderOption}
                                                                    placeholder="Choose Gender"
                                                                    className={styles.formControl}
                                                                    onChange={(e) => onChange(handleDropdownDefault('profileGender', e))}
                                                                    error={errors?.profileGender}
                                                                    inputRef={ref}
                                                                    value={dropDownDefaultValues.profileGender}
                                                                    isLightTheme={false}
                                                                />
                                                            )}
                                                            rules={{ required: 'Required' }}
                                                            onFocus={errors.profileGender?.ref}
                                                        />
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={1} className={styles.nameNewLabel}>
                                                <div className={styles.FormGroup}>
                                                    <label>Information:</label>
                                                </div>
                                            </Col>
                                            <Col md={11} className={styles.inputNewweb}>
                                                <div className={styles.FormGroup}>
                                                    <TextArea
                                                        className={styles.formControl}
                                                        name="jobDescription"
                                                        placeholder="Enter the information"
                                                        register={register}
                                                        maxLength='1000'
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className={styles.contentStyle}>
                                                    <p>Please note that this is only a provisional offer of employment/professional services and is not being considered as an appointment letter. An appointment letter would be issued to you on your accepting this provisional offer.</p>
                                                    <span>We look forward to welcoming you on board.</span>
                                                    <h3>{userData?.representativeName} - {userData?.representativeRole}</h3>
                                                    <h3>{userData?.companyName}</h3>
                                                    <h3>{userData?.companyAddress}</h3>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Row>
                    <NewCandidate
                        isOpen={isOpenCandidatePopup}
                        handleClose={() => setIsOpenCandidatePopup(false)}
                        cityData={cityData}
                        // mobileNumber={mobileNumber}
                        // email={email}
                        candidateRecord={candidateRecord}
                        setCandidateData={setCandidateData}
                        inputValues={inputValues}
                    />
                    {isOpenJobs && <AllPostedJobsNew
                        isOpen={isOpenJobs}
                        setIsExistingJob={setIsExistingJob}
                        //  data={jobsData}
                        handleClose={() => setIsOpenJobs(false)}
                        cityData={cityData}
                        handleJobData={handleJobData}
                    />}
                    <JobOfferSpecialization
                        isOpen={isOpenSpecialization}
                        data={specializationData}
                        jobPosition={jobPosition}
                        handleClose={() => setIsOpenSpecialization(!isOpenSpecialization)}
                        handleSpecialization={handleSpecialization}
                    />
                    <JobOfferPreview
                        isOpenOfferLetter={isOpenJobOfferPreview}
                        handleCloseOfferLetter={() => setIsOpenJobOfferPreview(false)}
                        jobData={previewJobData}
                        offerData={previewOfferData}
                        candidateData={previewCandidateData}
                        createJobOfferLetter={createJobOfferLetter}
                    />
                </Container>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className={styles.btnsNew}>
                                <div className={styles.btnCancel} onClick={() => navigate('/dashboard')}>
                                    <div className={styles.effCancel}></div>
                                    <button>Cancel Offer letter <span></span></button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={styles.btnsRight}>
                                <div className={styles.btnReset} onClick={handleSubmit(handleJobOfferPreview)}>
                                    <div className={styles.effReset}></div>
                                    <button>Preview offer letter <span></span></button>
                                </div>
                                <div className={styles.btnSave} onClick={handleSubmit(createJobOfferLetter)}>
                                    <div className={styles.effSave}></div>
                                    <button>Send Offer to candidate <span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </form>
            <Container fluid className={styles.footerBg}>
                <Row>
                    <Col md={6}>
                        <div className={styles.newUl}>
                            <ul>
                                <li><a href="https://www.jobsgaar.com/#section02" target='_blank'>About</a></li>
                                <li><a href="https://jobsgaar.com/blog/" target='_blank'>Blog</a></li>
                                <li><a href="https://www.jobsgaar.com/#section06" target='_blank'>Investor</a></li>
                                <li><a href="https://www.jobsgaar.com/contact" target='_blank'>Contact Us</a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={styles.powerBy}>
                            <p>Powered by<a href="https://www.jobsgaar.com/" target='_blank'><img src={companyLogo} alt="" /></a></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default JobOfferLetter;
import { Col, Container, Row } from 'react-bootstrap';
import HomeLayout from '../../layouts/HomeLayout/HomeLayout';
// import SubHeader from '../common/SubHeader/SubHeader';
import styles from './Dashboard.module.scss';
import notavailableVideo from '../../assets/images/videonotAvailable.svg';
import rocketSpeed from '../../assets/images/rocketSpeed.svg';
// import listImag from '../../assets/images/userProfile.jpg';
import defaultPro from '../../assets/images/defaultprofile.svg';
// import videoImg from '../../assets/images/videophoto.svg';
// import play from '../../assets/images/playIcon.svg';
// import rightArrow from '../../assets/images/arrowsliRight.svg';
// import leftArrow from '../../assets/images/arrowSlidLeft.svg';

import { useState, useEffect/** , useContext */ } from 'react';
//import CustomPagination from '../common/CustomPagination/CustomPagination';
import { hideLoader, showLoader } from '../../helpers';
//import { toast } from 'react-toastify';
//import { useParams } from 'react-router-dom';
// import NoDataFound from '../common/NoDataFound/NoDataFound';
import { getDashboard } from '../../services/api/employer';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';

import { fetchNewCareerTips, getSignedUrl, updateCareerTips } from '../../services/api/common';

const Dashboard = () => {
    // const { path } = useParams();
    // const [viewType, setViewType] = useState('listView');
    const [data, setData] = useState();
    const [careerData, setCareerdata] = useState([]);
    const [currentIndex, setcurrentIndex] = useState(0);

    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);

    useEffect(() => {
        getData();
        fetchCareerData();
    }, []);

    /**
     * API->https://api.jobsgaar.com/v1/employer/dashboard/{id}?id=63bbefc4c84fe0832aa942d6
     * This method is used to get the user information based on id
     */
    const getData = async () => {
        try {
            showLoader();
            let params = {
                id: userData?._id
            }
            let {
                data: {
                    data
                }
            } = await getDashboard(params);
            hideLoader();
            setData(data);

        } catch (error) {
            hideLoader();
        }
    }

    /**
     * API-> https://api.jobsgaar.com/v1/common/get/signedUrl/file1660896342632.mp4
     * This method returns carrier tips first video
     */
    const fetchCareerData = async () => {
        try {
            showLoader();
            let newArray = [];
            let params = {
                id: "file1660896342632.mp4"
            }
            let {
                data: {
                    appStatus
                }
            } = await getSignedUrl(params);
            newArray.push({
                careerTipVideoUrl: appStatus,
                videoTitle: "Staff hiring descriptions"
            });
            let {
                data: {
                    data
                }
            } = await fetchNewCareerTips();
            let sortedData = data.sort((item1, item2) => item1.viewed - item2.viewed); // Showing viewed:false videos first
            hideLoader();
            let newData = newArray.concat(sortedData);
            setCareerdata(newData);

        } catch (error) {
            hideLoader();
        }
    }
    /**Update the carrer tips */
    const updateCareerData = async (careerTipId) => {
        try {
            showLoader();
            let params = {
                "careerTipId": careerTipId,
                "like": true
            }
            let {
                data: {
                    data
                }
            } = await updateCareerTips(params);
            hideLoader();
        } catch (error) {
            hideLoader();
        }
    }

    // const handleViewType = (data) => {
    //     setViewType(data);
    // }

    const changeVideo = async (index) => {
        setcurrentIndex(index);
        if (careerData[index].careerTipId) {
            await updateCareerData(careerData[index].careerTipId);
        }
    }

    /**
     * This method return counts for candidates,offer letter, matched candidate,review, total, live jobs
     */
    const candidateImageCount = (data) => {
        if (data) {
            return (
                <div className={styles.imglist}>
                    {data > 0 && <img src={defaultPro} alt="" />}
                    {data > 1 && <img src={defaultPro} alt="" />}
                    {data > 2 && <img src={defaultPro} alt="" />}
                    {data > 3 && <div className={styles.pluseNo}>+{data - 3}</div>}
                </div>
            )
        }
    }

    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

    const RenderVideo = ({ url, isSelected }) => {
        return (
            <ReactPlayer className={styles.classHeight} width="100%" height="100%" url={url} playing={false} controls muted={false} />
        )
    }
    return (
        <HomeLayout>
            <div className='pageContentWrapper'>
                <section className={styles.DashboardContent}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <div className={styles.headDashboard}>
                                    <h3>Home</h3>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className={styles.dashboardBoxes}>
                    <Container fluid>
                        <Row>
                            <Col md={12} lg={8} className={styles.tabDashboard}>
                                <Row>
                                    <Col md={12} lg={6}>
                                        <div className={styles.interviewTab}>
                                            <h3> <span>Waiting for an interview</span> {data?.interview} Candidates </h3>
                                            <div className={styles.candidateList}>
                                                {candidateImageCount(data?.interview ?? 0)}
                                            </div>
                                            <div className={styles.listIcon}></div>
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <div className={styles.offletterTab}>
                                            <h3> <span>Waiting for offer letter acceptance</span> {data?.jobOffer} Candidates </h3>
                                            <div className={styles.candidateList}>
                                                {candidateImageCount(data?.candidateList ?? 0)}
                                            </div>
                                            <div className={styles.listIcon}></div>
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <div className={styles.waitactionTab}>
                                            <h3>  <span>Matched candidate waiting for action</span> {data?.pending} Candidates </h3>
                                            <div className={styles.candidateList}>
                                                {candidateImageCount(data?.pending ?? 0)}
                                            </div>
                                            <div className={styles.listIcon}></div>
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <div className={styles.inreviewTab}>
                                            <h3> <span>In Review</span> {data?.waitingList} Candidates </h3>
                                            <div className={styles.candidateList}>
                                                {candidateImageCount(data?.waitingList ?? 0)}
                                            </div>
                                            <div className={styles.listIcon}></div>
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <div className={styles.livejobsTab}>
                                            <h3> <span>Total live jobs</span>{data?.live} Jobs</h3>
                                            <div className={styles.listIcon}></div>
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <div className={styles.hiredTab}>
                                            <h3> <span>Total hired till now </span> {data?.hired} Candidates </h3>
                                            <div className={styles.candidateList}>
                                                {candidateImageCount(data?.hired ?? 0)}
                                            </div>
                                            <div className={styles.listIcon}> </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={4} className={styles.videoStall}>
                                <div className={styles.videoData}>
                                    {
                                        careerData?.length > 0 ? (
                                            <div className={styles.videoAvlbl}>
                                                <div className={styles.videoList}>
                                                    <Carousel className={styles.cersoleStyle} infiniteLoop stopOnHover renderItem={customRenderItem} showThumbs={false} onChange={(index) => changeVideo(index)}>
                                                        {careerData?.length > 0 && careerData.map((item, index) => (
                                                            <RenderVideo url={item.careerTipVideoUrl} key={index} />
                                                        )
                                                        )}
                                                    </Carousel>
                                                    {careerData?.length > 0 && <div className={styles.videoCont}>
                                                        <h3>{careerData[currentIndex].videoTitle}</h3>
                                                    </div>}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={styles.videonotAvailable}>
                                                <p>We are posting every week, <br />you will see some amazing insight here!</p>
                                                <img src={notavailableVideo} alt="" />
                                            </div>
                                        )
                                    }
                                </div>
                            </Col>

                            <Col md={12} className={styles.padSide}>
                                <div className={styles.interviewSpeed}>
                                    <Row>
                                        <Col md={6} lg={6} xl={3}>
                                            <div className={styles.rocketspeed}>
                                                <p>Fastest Application</p>
                                                <h3>
                                                    <span>{data?.candidateAppliedMinHours}</span> seconds
                                                </h3>
                                                <img src={rocketSpeed} alt="" />
                                            </div>
                                        </Col>
                                        <Col md={6} lg={6} xl={3}>
                                            <div className={styles.rocketspeed}>
                                                <p>Quickest Interview</p>
                                                <h3>
                                                    <span>{data?.interviewAcceptedMinHours}</span> minutes
                                                </h3>
                                                <img src={rocketSpeed} alt="" />
                                            </div>
                                        </Col>
                                        <Col md={6} lg={6} xl={3}>
                                            <div className={styles.rocketspeed}>
                                                <p>Successful Interviews</p>
                                                <h3>
                                                    <span>{data?.totalInterviewsOccurred}</span> interviews
                                                </h3>
                                                <img src={rocketSpeed} alt="" />
                                            </div>
                                        </Col>
                                        <Col md={6} lg={6} xl={3}>
                                            <div className={styles.rocketspeed}>
                                                <p>Average Interview Time</p>
                                                <h3>
                                                    <span>{data?.interviewAcceptedAvgHours}</span> minutes
                                                </h3>
                                                <img src={rocketSpeed} alt="" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </HomeLayout>
    )
}

export default Dashboard;
import { Col, Row } from "react-bootstrap";
import styles from './InterviewTime.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import Popup from "../Popup";
import moment from "moment";
import { getLocalTimeFormatted } from "../../../../helpers";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function InterviewTime({
  isOpen,
  handleClose,
  candidateData,
  handleScheduleInterview,
  data: {
    finalDateTimeInterview,
    interviewVia,
    _id
  },
  handleInterviewCall
}) {
  const candidateDetails = candidateData?.candidateDetails
  const alternatenumber = candidateDetails && candidateDetails.aadhaarMobile
  const whatsappnumber = candidateDetails && candidateDetails.whatsappMobile

  let phone1 = alternatenumber;
  let phone2 = whatsappnumber;
  let phone1Text = "Call Phone 1";
  let phone2Text = "Call Phone 2"

  if (alternatenumber && whatsappnumber) {
    phone1 = alternatenumber
    phone2 = whatsappnumber
  }
  if (!alternatenumber && whatsappnumber) {
    phone1 = whatsappnumber;
    phone2 = ""
    phone1Text = "Call Phone"
  }
  if (alternatenumber && !whatsappnumber) {
    phone1 = alternatenumber;
    phone2 = ""
    phone1Text = "Call Phone"
  }
  if (!alternatenumber && !whatsappnumber) {
    phone1 = "";
    phone2 = "";
    phone1Text = ""
  }

  return (
    <Popup isOpen={isOpen} isClose={handleClose}>
      <div className={styles.newCss}>
        <img src={closeJob} onClick={handleClose} alt="" />
        <Col md={12} className={styles.newBg}>
          <Row>
            <Col md={12}>
              <div className={styles.headReject}>
                <h3>Time for your interview
                  <span>{finalDateTimeInterview?.interviewDate ? (moment(finalDateTimeInterview.interviewDate).format("DD MMM`YY")) : '-'}</span>
                </h3>

                <div className={styles.interviewset}>
                  <h3><span>Time:</span>{finalDateTimeInterview?.interviewTime ? getLocalTimeFormatted(finalDateTimeInterview.interviewTime) : "-"}</h3>
                  <h3><span>Duration:</span>{finalDateTimeInterview?.interviewLength ?? '-'}</h3>
                  <h3><span>Interview:</span>{interviewVia ? interviewVia : '-'}</h3>
                </div>
                <p>Our smart secretary will now try to connect you with your candidate.<br />Stay tuned and answer the call from <span>08046808028</span></p>

                <div className={styles.schedBtnNew}>
                  {phone1 && <div className={styles.editInter}>
                    <div className={styles.effNew}></div>
                    <a href={`tel:+91${phone1}`}>{phone1Text}</a>
                  </div>}
                  {phone2 && <div className={styles.okInter}>
                    <div className={styles.effNew}></div>
                    <a href={`tel:+91${phone2}`}>{phone2Text}</a>
                  </div>}
                </div>

                <div className={styles.schedBtn}>
                  <div className={styles.editInter}>
                    <div className={styles.effNew}></div>
                    <button onClick={() => handleScheduleInterview()}>Schedule Interview<span></span></button>
                  </div>
                  <div className={styles.okInter}>
                    <div className={styles.effNew}></div>
                    <button onClick={() => handleInterviewCall(_id)}>Start Call<span></span></button>
                  </div>
                </div>


              </div>
            </Col>
          </Row>
        </Col>
      </div>
    </Popup>
  );
}







import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from '../AllPostedJobs//AllPostedJobs.module.scss';
import searchImag from '../../../../assets/images/searchicon.svg';
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from "@mui/material";
import { Dropdown } from '../../Dropdown/Dropdown';
import moment from "moment";
import {

} from '../../../../common/DropdownOptions';
import { DatePicker } from "@mui/x-date-pickers";
import { getEducationList, getExperienceList } from "../../../../services/api/common";
import { faSearch, /**faList,*/ faFilter, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { borderRadius } from "@mui/system";

export default function JobOfferJobFilter({
    cityData = [],
    handleJobFilter,
    setSearchedVal,
    searchFilter,
    searchedVal,
    setIsError
}) {

    const { formState: { errors }, control } = useForm();


    const [educationValue, setEducationValue] = useState([]);
    const [cityValue, setCityValue] = useState([]);
    const [jobDate, setJobDate] = useState(null);
    const [experienceData, setExperienceData] = useState([]);
    const [educationData, setEducationData] = useState([]);
    const [experienceValue, setExperienceValue] = useState([]);
    const [applyText, setApplyText] = useState("Apply")

    useEffect(() => {
        getExperience();
        getEducation();
    }, [])


    const getExperience = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getExperienceList();
            setExperienceData(
                data && data.length > 0 && data.map(({ experience, experienceYears }) => {
                    return {
                        label: experience,
                        value: experience
                    }
                })
            );
        } catch (error) {
        }
    }

    const getEducation = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getEducationList();

            let educationContent = data && data.length > 0 && data.map(({ education, educationSort }) => {
                return {
                    label: education,
                    value: education
                }
            });
            educationContent = educationContent.reduce((unique, o) => {
                if (!unique.some(obj => obj.label === o.label)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            setEducationData(educationContent);
        } catch (error) {
        }
    }

    const handleApply = (val) => {
        if (val.trim().toLowerCase() === 'apply') {
            if (cityValue?.length === 0 && educationValue?.length === 0 && experienceValue?.length === 0 && !jobDate) {
                setIsError(true);
            } else {
                setIsError(false);
                handleJobFilter(null, educationValue, jobDate, cityValue, experienceValue);
                setApplyText("Reset");
            }
        } else {
            handleReset();
            setApplyText("Apply");
        }
    }

    const handleReset = () => {
        setCityValue([]);
        setEducationValue([]);
        setJobDate(null);
        setExperienceValue([]);
        handleJobFilter(null, {}, null, {}, {});
    }

    return (
        <Row className={styles.RowForm}>
            <Col md={10} className={styles.pdZero} >
                <Row className={styles.FormRow}>
                    <Col md={2} >
                        <div className={styles.FormGroup}>
                            <label><strong>Date</strong></label>
                            <LocalizationProvider dateAdapter={AdapterMoment} className={styles.formControl}  >
                                <div className="dateAndTimePickerLightTheme" style={{ width: '100%', height: '35px', border: '1px solid #ccc', borderRadius: '8px', fontSize: '13px', padding: '0px 0px 0px 0px', outline: 'none', color: '#626669' }}>
                                    <Controller
                                        name="jobDate"
                                        control={control}
                                        render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                            <DatePicker
                                                className={styles.formControl}
                                                value={jobDate}
                                                onChange={(newValue) => {
                                                    setJobDate(moment(newValue).format("YYYY-MM-DD"));
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        )}
                                    />
                                </div>
                            </LocalizationProvider>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={styles.FormGroup}>
                            <label><strong>City</strong></label>
                            <Dropdown
                                name="city"
                                isSearchable={true}
                                options={cityData}
                                placeholder="Select City"
                                className={styles.formControl}
                                onChange={setCityValue}
                                value={cityValue}
                                isLightTheme={true}
                            />
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={styles.FormGroup}>
                            <label><strong>Education</strong></label>
                            <Controller
                                name="jobEducation"
                                control={control}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        options={educationData}
                                        placeholder="Education type"
                                        className={styles.formControl}
                                        onChange={setEducationValue}
                                        error={errors?.jobEducation}
                                        inputRef={ref}
                                        value={educationValue}
                                        isLightTheme={true}
                                    />
                                )}
                            />
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={styles.FormGroup}>
                            <label><strong>Experience</strong></label>
                            <Controller
                                name="jobExperience"
                                control={control}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        options={experienceData}
                                        placeholder="Work Exp."
                                        className={styles.formControl}
                                        onChange={setExperienceValue}
                                        error={errors?.jobExperience}
                                        inputRef={ref}
                                        value={experienceValue}
                                        isLightTheme={true}
                                    />
                                )}
                            />
                        </div>
                    </Col>

                    <Col md={2}>
                        <div className={styles.appDive}>
                            <button className={styles.btnApply} onClick={(e) => handleApply(applyText)}>{applyText}</button>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={2}>
                <div className={styles.FormGroupNew}>
                    <input placeholder="Search here…" id="searchField" name='search' onChange={(e) => { setSearchedVal(e.target.value); setIsError(false); }} onKeyDown={e => searchFilter(e)} value={searchedVal} />
                    <button className={styles.secchIcon} name="search" onClick={(e) => { e.preventDefault(); searchFilter({ key: "Enter" }) }}><img src={searchImag} /></button>
                </div>
            </Col>
            {/* <input onChange={(e) => setSearchedVal(e.target.value)} /> */}
        </Row>
    );
}







// import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './InterviewHistory.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import Popup from "../Popup";
import moment from 'moment';
import { getLocalTimeFormatted } from "../../../../helpers";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function InterviewHistory({
    isOpen,
    handleClose,
    data,
    candidateData: {
        jobDescription
    }
}) {

    return (
        <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleClose} alt=""/>
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Interview history<span></span></h3>
                                <p><span>{data.jobDetails?.jobTitle ?? ''}</span>{jobDescription ? jobDescription : ""}</p>
                                    <div className={styles.scrollbarContainer}>
                                        <div className={styles.scrollContainer}>
                                            <div className={styles.scrollcontent}>
                                            {data && data.suggestedDateTimeInterview && data.suggestedDateTimeInterview.length > 0 && data.suggestedDateTimeInterview.map(({
                                                    approved,
                                                    interviewLength,
                                                    suggestedBy,
                                                    suggestedDate,
                                                    suggestedTime
                                                }, index) => {
                                                    return (
                                                        <div className={styles.interviewset} key={index}>
                                                            <h3><span>Scheduled:</span>{suggestedBy === 'employer' ? 'By Employer' : "By Candidate"}</h3>
                                                            <h3><span>Date:</span>{moment(suggestedDate).format('DD MMM`YY')} </h3>
                                                            <h3><span>Time:</span>{getLocalTimeFormatted(suggestedTime)}</h3>
                                                            <h3><span>Duration:</span>{interviewLength}</h3>
                                                            <h3><span>Interview:</span>{data?.interviewVia ?? '-'}</h3>
                                                            {approved ? (<h3 className={styles.interAccept}><p>Accepted</p></h3>) : (<h3 className={styles.notresPonds}><p>Interview Reschedule</p></h3>)}
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    </div>
                               
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}







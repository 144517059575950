import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
// import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { /**faGear, faBell, faAlignRight, faArrowCircleRight, faArrowRight,*/ faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import { faCircleCheck, faCircleXmark, faPencil, faUser, faUsers, faIndianRupeeSign, faAnglesDown, faAngleDown, faAnglesUp, faAngleUp } from '@fortawesome/free-solid-svg-icons';

//styles
import styles from './Header.module.scss';

//images
// import CompanyImage from '../../../assets/images/company-logo.jpeg';
import Profile from '../../../assets/images/profile.svg';
import Theme from '../../../assets/images/theme.svg';
import Signout from '../../../assets/images/signout.svg';
import pref from '../../../assets/images/preferences.svg';
import noti from '../../../assets/images/bell.svg';
import setNew from '../../../assets/images/set.svg';
import newCandidate from '../../../assets/images/newCandi.svg';
// import joboffer from '../../../assets/images/joboffer.svg';
import Logo from '../../../assets/images/logo.svg';
import { clearCookie } from '../../../helpers';
import { toast } from 'react-toastify';
import defaultUser from '../../../assets/images/profile.svg';
// import { style } from '@mui/system';
import moment from 'moment';



const Header = ({
    userData
}) => {
    const navigate = useNavigate();
    let notificationData = [
        {
            title: "Welcome to Employers Portal",
            date: moment().format('DD MMMM YYYY'),
            message: "With Jobsgaar employers portal it is now much easier to manage and control your posted jobs and applicants."
        },
        {
            title: "Coming Soon",
            date: moment().format('DD MMMM YYYY'),
            message: "Notifications will come soon to the employer's platform, meanwhile you can see notifications in App"
        }
    ];
    const [isShowGearOptions, setIsShowGearOptions] = useState(false);
    const [isShowNotification, setIsShowNotification] = useState(false);
    // const [notificationData, setNotificationData] = useState(getNotificationData);

    const trigger = (type) => {
        if(type === 'settings') {
            setIsShowGearOptions(!isShowGearOptions);
            if(isShowNotification) {
                setIsShowNotification(false);
            }
        } else {
            setIsShowNotification(!isShowNotification);
            if(isShowGearOptions) {
                setIsShowGearOptions(false);
            }
        }
    }
    const setRef = useRef(null);
    const notificationRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", handleSettingsOutside, false);
        document.addEventListener("click", handleNotificationOutside, false);

        return () => {
          document.removeEventListener("click", handleSettingsOutside, false);
        document.addEventListener("click", handleNotificationOutside, false);
        };
      }, []);

      const handleSettingsOutside = event => {
        if (setRef.current && !setRef.current.contains(event.target)) {
            setIsShowGearOptions(false);
        }
      };

      const handleNotificationOutside = event => {
        if (notificationRef.current && !notificationRef.current.contains(event.target)) {
            setIsShowNotification(false);
        }
      };

      const handleLogout = () => {
        clearCookie('token');
        localStorage.removeItem('userData');
        toast.success("Logged out successfully");
        navigate('/login');
      }

    return (
        <section className={styles.headerSection}>
            <nav className='navbar navbar-expand-lg'>
                <Container fluid>
                    <div className={styles.profile}>
                        <img src={userData && userData.representativeProfileUrl ? userData.representativeProfileUrl : defaultUser} alt='' />
                        <h3>Welcome, {userData && userData.representativeName ? userData.representativeName : userData.fullName}<span>{userData && userData.representativeRole ? userData.representativeRole : ""}</span></h3>
                    </div>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        </ul>
                        <div className={`${styles.dropMenu} d-flex`}>
                            <ul>
                                <li className={styles.jobOfferBtn}>
                                    <Tooltip className={styles.toolTip} title="Comming Soon" arrow>
                                        <div className={styles.btnCancel} onClick={()=>navigate('/jobofferletter')}>
                                            <div className={styles.btnEff}></div>
                                            <button><FontAwesomeIcon icon={faPlus} />  New offer letter <span></span></button>
                                        </div>
                                    </Tooltip>
                                    {/* <div className={styles.btnCancel}>
                                        <div className={styles.btnEff}></div>
                                        <button><FontAwesomeIcon icon={faPlus} />  New offer letter <span></span></button>
                                    </div> */}
                                    {/* <div className={styles.paddRight}>
                                        <div className={styles.tooltipcss}>
                                            <span tooltip="Please wait for the candidate to respond" flow="left">
                                            </span>
                                        </div>
                                    </div> */}
                                    <div className={styles.btnReset} onClick={()=>navigate('/jobs/add')}>
                                        <div className={styles.effReset}></div>
                                        <button><FontAwesomeIcon icon={faPlus} /> ADD NEW JOB<span></span></button>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.settingsMenu}>
                                        <div className={styles.dropBox} onClick={() => trigger('settings')} ref={setRef}>
                                            {/* <FontAwesomeIcon icon={faGear} /> */}
                                            <img src={setNew} alt=''/>
                                        </div>
                                        <ul className={isShowGearOptions ? 'displayBlock' : 'displayNone'}>
                                            <li className="home">
                                                <div className={styles.arrow}></div>
                                                <button onClick={()=>navigate('/employerprofile')}>
                                                    
                                                    <img className="svg" src={Profile} alt='' />
                                                    <span>Profile</span>
                                                </button>
                                            </li>
                                            <li className={styles.pre}>
                                                <button onClick={()=>navigate('/preferences')}>
                                                    <img className="svg" src={pref} alt='' />
                                                    <span>Preferences</span>
                                                </button>
                                            </li>
                                            <li className={styles.the}>
                                                    <h2 className={styles.commingSoon}>
                                                        Comming Soon
                                                    </h2>
                                                <button>
                                                    <img className="svg" src={Theme} alt='' />
                                                    <span>Theme</span>
                                                </button>
                                            </li>
                                            <li className="settings" onClick={handleLogout}>
                                                <button>
                                                    <img className="svg" src={Signout} alt='' />
                                                    <span>Sign Out</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li className={styles.notificationIcon}>
                                    <div className={styles.settingsMenu} ref={notificationRef}>
                                        <div className={styles.dropBox} onClick={() => trigger('notification')} >
                                            {/* <FontAwesomeIcon icon={faBell} /> */}
                                            <img src={noti} alt=''/>
                                            <div className={styles.notNo}>
                                                <h3>02</h3>
                                            </div>
                                        </div>
                                        <ul className={isShowNotification ? 'displayBlock' : 'displayNone'}>
                                            {notificationData && notificationData.length > 0 && notificationData.map(({ title, date, message }, index) => {
                                                return (
                                                    <li className={styles.notificationList} key={index}>
                                                        {index === 0 && <div className={styles.arrow}></div>}
                                                        <img src={newCandidate} alt='' />
                                                        <div className={styles.notifiText}>
                                                            <span>{date}</span>
                                                            <h3>{title}</h3>
                                                            <p>{message}</p>
                                                        </div>
                                                    </li>
                                                    // <li className={styles.notificationList} key={index}>
                                                    //     {index === 0 && <div className="arrow"></div>}
                                                    //     <h3>{title} <span>{date}</span></h3>
                                                    //     <p>{message}</p>
                                                    // </li>
                                                )

                                            })}
                                            {/* <li className={styles.notificationList}>
                                                <img src={joboffer} alt='' />
                                                <div className={styles.notifiText}>
                                                    <span>27 April 2022</span>
                                                    <h3>Got a new candidate</h3>
                                                    <p>Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                                </div>
                                            </li> */}
                                            
                                            <Link className={styles.viewAllNotification} to="/notifications">See All Notification <FontAwesomeIcon icon={faArrowRightLong} /></Link>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <Link to="/dashboard"><img src={Logo} alt='' /></Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </Container>
            </nav>
        </section>
    )
}

export default Header;
// import { style } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Col, Container, /**FormGroup,*/ Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Accordion from 'react-bootstrap/Accordion';

import styles from './NewLogin.module.scss';
import newOne from '../../assets/images/NewLogo/new1.png';
import newTwo from '../../assets/images/NewLogo/new2.png';
import newTre from '../../assets/images/NewLogo/new3.png';
import newFor from '../../assets/images/NewLogo/new4.png';
import newFiv from '../../assets/images/NewLogo/new5.png';
import newSi from '../../assets/images/NewLogo/new6.png';
import newSe from '../../assets/images/NewLogo/new7.png';
import newEg from '../../assets/images/NewLogo/new8.png';
import newNin from '../../assets/images/NewLogo/new9.png';
import newTen from '../../assets/images/NewLogo/new10.png';
import jobOne from '../../assets/images/jobsLink/job1.png';
import jobTwo from '../../assets/images/jobsLink/job2.png';
import jobThr from '../../assets/images/jobsLink/job3.png';
import jobFor from '../../assets/images/jobsLink/job4.png';
import jobFiv from '../../assets/images/jobsLink/job5.png';
import jobSix from '../../assets/images/jobsLink/job6.png';
import jobSev from '../../assets/images/jobsLink/job7.png';
import jobEig from '../../assets/images/jobsLink/job8.png';
import jobNin from '../../assets/images/jobsLink/job9.png';

import jobsearch from '../../assets/images/jobs-success.avif';
import handshake from '../../assets/images/handshake.avif';
import Empomn from '../../assets/images/empowerment.avif';
import jobPostWeb from '../../assets/images/jobPostNew.svg';
import whatsAppImg from '../../assets/images/whatsAppimage.svg';
// import dashboardView from '../../assets/images/dashboardImg.svg';
import dashboardImg from '../../assets/images/dashbordImg.svg';
import hindiImage from '../../assets/images/hindi_white.png';
import qrCode from '../../assets/images/frameImg.svg';
import playStore from '../../assets/images/googlePlay.png';
import whatsApp from '../../assets/images/whatsappbot.png';
import avlDashboard from '../../assets/images/availDash.png';
import govt1 from '../../assets/images/govt1.png';
import govt2 from '../../assets/images/govt2.png';
import govt3 from '../../assets/images/govt3.png';
import govt4 from '../../assets/images/govt4.png';

import LoginImage from '../../assets/images/indiaMap.svg';
import CompanyImage from '../../assets/images/logo.svg';
import logomainNew from '../../assets/images/logocolor.png';
import whatsappIcon from '../../assets/images/whatsappIcon.png';
import satImage from '../../assets/images/satImage.svg';
import editIcon from '../../assets/images/edit.svg';
import { useForm } from "react-hook-form";
import { login } from '../../services/api/common';
import OtpInput from "otp-input-react";
import { toast } from 'react-toastify';
import { showLoader, hideLoader, setCookie } from '../../helpers/index';
import { requestOtpForLogin, verifyOtpForLogin } from '../../services/api/otp';
import { useLocation, useNavigate /** ,useParams*/ } from 'react-router-dom';
import { getProfile } from '../../services/api/employer';
import Attention from '../common/Popup/Attention/Attention';
import TestimonialBlock from '../TestimonialsLogin/TestimonialsLogin';
import CompanyTrust from '../CompanyTrust/CompanyTrust';
import CompanyLogo from '../CompanyTrust/CompanyTrustTwo';


function NewLogin() {
    const [currentImage, setCurrentImage] = useState(jobPostWeb);

  const changeImage = (newImage) => {
    setCurrentImage(newImage);
  };
  return (

    <section className={styles.bgMain}>
        <Navbar  collapseOnSelect expand="lg" className={styles.mainNavbar}>
            <Container>
                <Navbar.Brand  className={styles.brandLogo} href="#home"><img src={logomainNew} alt='' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    {/* <Nav.Link className={styles.navLink} href="#features">Jobs</Nav.Link>
                    <Nav.Link className={styles.navLink} href="#pricing">Database</Nav.Link> */}
                </Nav>
                <Nav>
                    <Nav.Link className={styles.navLink} href="#deets">Looking for a job?</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <Container fluid className={styles.bgMode}>
            <div className={styles.bgDyna}></div>
            <Row className={styles.mainCont}>
                <Col></Col>
                <Col md={5}>
                    <div className={styles.headCon}>
                        <span>INDIA’S #1 HIRING PLATFORM</span>
                        <h3>Find the right<br/> candidate. Fast.</h3>
                        <p>Trusted by 5 Cr+ Candidates | 4 Lakh+ Employers</p>
                    </div>
                </Col>
                <Col md={5}>
                    <div className={styles.neBox}>
                        <div className={styles.newotpform}>
                            <div className={styles.cntntForm}>
                                <img src={logomainNew} alt='' />
                                <p>Login or SignUp with your WhatsApp number</p>
                                <form className={styles.formWidth}>
                                    <div className={styles.iconweb}>
                                        <img src={whatsappIcon} alt='' />
                                        <span></span>
                                        <p>+91</p>
                                    </div>
                                    <input className={styles.formControl} placeholder="WhatsApp Number" maxLength="10" />
                                    <span className="errorMessage">Whatsapp number is required</span>
                                    <div className={styles.btnNew} >
                                        <div className={styles.effNew}></div>
                                        <button>Send OTP <span></span></button>
                                    </div>
                                    <p className={styles.notMember}>Not a member? Don't worry!<br /> Just fill your WhatsApp contact and we will sign you up quickly.</p>
                                </form>
                            </div>
                            
                            <div className={styles.otpnewsubmit}>
                                <div className={styles.otpcntntForm}>
                                    <img src={logomainNew} alt='' />
                                    <p>Please enter the OTP,<br />sent to your Phone Number</p>
                                    <span>9998887654 <a href=''><img src={editIcon} alt='' /></a></span>
                                </div>
                                <form className={styles.submitorm} >
                                    <div className={styles.inpOtpp}>
                                        <div className={styles.inputBoxx}>
                                            <input type="tel" maxlength="1" class="formControl" data-testid="input" placeholder="-" value="" />
                                            <input type="tel" maxlength="1" class="formControl" data-testid="input" placeholder="-" value="" />
                                            <input type="tel" maxlength="1" class="formControl" data-testid="input" placeholder="-" value="" />
                                            <input type="tel" maxlength="1" class="formControl" data-testid="input" placeholder="-" value="" />
                                            <input type="tel" maxlength="1" class="formControl" data-testid="input" placeholder="-" value="" />
                                            <input type="tel" maxlength="1" class="formControl" data-testid="input" placeholder="-" value="" />
                                        </div>

                                        <span className='errorMessage mt-3'>Otp numer is required</span>
                                    </div>
                                        {/* <p><span>{otpTiming}</span> seconds remaining</p> */}
                                    
                                        <p>Haven’t receive the code? <span><a className={styles.resendOtp}>Resend Otp</a></span></p>
                                    
                                    <div className={styles.btnNew}>
                                        <div className={styles.effNew}></div>
                                        <button type="submit">Submit<span></span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                        
                    </div>
                </Col>
                <Col></Col>
            </Row>
        </Container>
        <Container fluid className={styles.jobPostID}>
            <Row>
                <Col md={5}>
                    <div className={styles.imgpost}>
                        {/* <img src={jobPostWeb}/> */}
                        <img src={currentImage} alt="Current Image" />
                    </div>
                </Col>
                <Col md={7}>
                    <div className={styles.jobpostCont}>
                        <span>GET STARTED WITH Jobsgaar</span>
                        <h3>Post a job in minutes</h3>
                        <p>Revolutionize your hiring with our AI-powered algorithm.</p>
                        <ul>
                            <li>Get unlimited applications</li>
                            <li>10x higher relevancy</li>
                            <li>Simplified job posting</li>
                            <li>40% better ROI than market</li>
                        </ul>
                        <button onClick={() => changeImage(jobPostWeb)} className={styles.playStorename}><img src={playStore} alt="Play Store" /></button>
                        <button onClick={() => changeImage(whatsAppImg)} className={styles.whatsAppbtn}><img src={whatsApp} alt="WhatsApp" /></button>
                        <button onClick={() => changeImage(dashboardImg)} className={styles.playStorename}><img src={avlDashboard} alt="Dashboard"/></button>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className={styles.clientName}>
            <Row>
                <Col md={12}>
                    <div className={styles.headNew}>
                        <span>#BelieveInUs</span>
                        <h3>COMPANIES THAT HIRE FROM US</h3>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={styles.companyLogoImg}>
                        <CompanyLogo/>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className={styles.jobsList}>
            <Row>
                <Col md={12}>
                    <div className={styles.headmode}>
                        <span>#OUR SUCCESS</span>
                        <h3>Why companies hire from Jobsgaar?</h3>
                        <p>From startups to SMEs to established enterprises, Jobsgaar revolutionizes the way businesses find high-quality talent quickly & effortlessly.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={4} className={styles.CategoryBlock}>
                    <div className={styles.innerBox}>
                        <div className={styles.Content}>
                            <span className={styles.Icon}><img src={jobOne}/></span>
                            <h4><a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Accountant</a></h4>
                            <p>(189 open positions)</p>
                            <div className={styles.jobTypeApply}>
                                <div className={styles.applyBtn}>
                                    <a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Apply with <br/> Digital CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4} className={styles.CategoryBlock}>
                    <div className={styles.innerBox}>
                        <div className={styles.Content}>
                            <span className={styles.Icon}><img src={jobTwo}/></span>
                            <h4><a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Receptionist</a></h4>
                            <p>(276 open positions)</p>
                            <div className={styles.jobTypeApply}>
                                <div className={styles.applyBtn}>
                                    <a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Apply with <br/> Digital CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4} className={styles.CategoryBlock}>
                    <div className={styles.innerBox}>
                        <div className={styles.Content}>
                            <span className={styles.Icon}><img src={jobThr}/></span>
                            <h4><a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Teachers</a></h4>
                            <p>(235 open positions)</p>
                            <div className={styles.jobTypeApply}>
                                <div className={styles.applyBtn}>
                                    <a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Apply with <br/> Digital CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4} className={styles.CategoryBlock}>
                    <div className={styles.innerBox}>
                        <div className={styles.Content}>
                            <span className={styles.Icon}><img src={jobFor}/></span>
                            <h4><a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Delivery</a></h4>
                            <p>(290 open positions)</p>
                            <div className={styles.jobTypeApply}>
                                <div className={styles.applyBtn}>
                                    <a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Apply with <br/> Digital CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4} className={styles.CategoryBlock}>
                    <div className={styles.innerBox}>
                        <div className={styles.Content}>
                            <span className={styles.Icon}><img src={jobFiv}/></span>
                            <h4><a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">HR Excutive</a></h4>
                            <p>(365 open positions)</p>
                            <div className={styles.jobTypeApply}>
                                <div className={styles.applyBtn}>
                                    <a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Apply with <br/> Digital CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4} className={styles.CategoryBlock}>
                    <div className={styles.innerBox}>
                        <div className={styles.Content}>
                            <span className={styles.Icon}><img src={jobSix}/></span>
                            <h4><a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Customer Support</a></h4>
                            <p>(366 open positions)</p>
                            <div className={styles.jobTypeApply}>
                                <div className={styles.applyBtn}>
                                    <a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Apply with <br/> Digital CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4} className={styles.CategoryBlock}>
                    <div className={styles.innerBox}>
                        <div className={styles.Content}>
                            <span className={styles.Icon}><img src={jobSev}/></span>
                            <h4><a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Telecalling</a></h4>
                            <p>(210 open positions)</p>
                            <div className={styles.jobTypeApply}>
                                <div className={styles.applyBtn}>
                                    <a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Apply with <br/> Digital CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4} className={styles.CategoryBlock}>
                    <div className={styles.innerBox}>
                        <div className={styles.Content}>
                            <span className={styles.Icon}><img src={jobEig}/></span>
                            <h4><a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Computer Operator</a></h4>
                            <p>(222 open positions)</p>
                            <div className={styles.jobTypeApply}>
                                <div className={styles.applyBtn}>
                                    <a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Apply with <br/> Digital CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4} className={styles.CategoryBlock}>
                    <div className={styles.innerBox}>
                        <div className={styles.Content}>
                            <span className={styles.Icon}><img src={jobNin}/></span>
                            <h4><a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Sales & marketing</a></h4>
                            <p>(440 open positions)</p>
                            <div className={styles.jobTypeApply}>
                                <div className={styles.applyBtn}>
                                    <a href="https://mycv.jobsgaar.com/login?source=MTBqYW4yNA==" target="_blank">Apply with <br/> Digital CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className={styles.jobsgaarInmedia}>
            <Row>
                <Col md={12}>
                    <div className={styles.headmode}>
                        <span>Jobsgar IN NEWS</span>
                        <h3>Media & Mentions</h3>
                    </div>
                </Col>
                 <Col md={3}>
                    <div className={styles.newImage}>
                        <img src={newOne} alt="" />
                    </div>
                </Col>
                <Col md={3}>
                    <div className={styles.newImage}>
                        <img src={newTwo} alt="" />
                    </div>
                </Col>
                <Col md={3}>
                    <div className={styles.newImage}>
                    <img src={newTre} alt="" />
                    </div>
                </Col>
                <Col md={3}>
                    <div className={styles.newImage}>
                        <img src={newFor} alt="" />
                    </div>
                </Col>
                <Col md={3}>
                    <div className={styles.newImage}>
                        <img src={newFiv} alt="" />  
                    </div>
                </Col>
                <Col md={3}>
                    <div className={styles.newImage}>
                        <img src={newSi} alt="" />
                    </div>
                </Col>
                <Col md={3}>
                    <div className={styles.newImage}>
                        <img src={newSe} alt="" />
                    </div>
                </Col>
                <Col md={3}>
                    <div className={styles.newImage}>
                        <img src={newEg} alt="" />
                    </div>
                </Col>
                <Col></Col>
                <Col md={3}>
                    <div className={styles.newImage}>
                        <img src={newNin} alt="" />
                    </div>
                </Col>
                <Col md={3}>
                    <div className={styles.newImage}>
                        <img src={newTen} alt="" />
                    </div>
                </Col> 
                <Col></Col>
            </Row>
        </Container>
       
        <Container fluid className={styles.faqSec}>
            <Row>
                <Col md={12}>
                    <div className={styles.headmode}>
                        <span>FAQ</span>
                        <h3>Frequently asked questions.</h3>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={styles.acorLet}>
                    <Accordion className={styles.acoridanMemo}>
                        <Accordion.Item eventKey="0" className={styles.acorfirst}>
                            <Accordion.Header className={styles.headAcor}>How can I hire the best candidates for my job?</Accordion.Header>
                            <Accordion.Body>
                            Jobsgaar app has over 5 Crore active job seekers across 70+ job categories. Our AI algorithm selects the best-fit candidates for your job role, helping employers like you connect directly with candidates who have relevant experience and the desired skills.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className={styles.acorfirst}>
                            <Accordion.Header>Bulk hiring costs a lot of money, how do I save my business expenses?</Accordion.Header>
                            <Accordion.Body>
                            On Jobsgaar, you can either post a job & get unlimited applications or search apnaDatabase using smart filters to find top-notch candidates.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className={styles.acorfirst}>
                            <Accordion.Header>How is Jobsgaar different from other traditional portals?</Accordion.Header>
                            <Accordion.Body>
                            Traditional hiring is long, expensive, and inefficient. Jobsgaar simplifies the process by connecting employers directly with job seekers with relevant skills and experience.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className={styles.acorfirst}>
                            <Accordion.Header>I have special hiring requirements. Whom should I reach out to?</Accordion.Header>
                            <Accordion.Body>
                            I have special hiring requirements. Whom should I reach out to?
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className={styles.footerSec}>
            
            <Row>
                <Col md={12}>
                    <div className={styles.logoImg}>
                        <img src={CompanyImage}/>
                        <img className={styles.newImg} src={hindiImage}/>
                    </div>
                </Col>
                <Col md={4}>
                    <div className={styles.aboitFooter}>
                        <h3>A Little About Us</h3>
                        <p>Jobsgaar is India's first #CareerTech platform for the workforce looking for #walktowork jobs in 'भारत'. <br/> Our innovative technology will help you get better jobs in your city. With Jobsgaar, you can find the best matching jobs for your profile which will take your career in a new direction.</p>
                    </div>
                </Col>
                <Col md={2}>
                    <div className={styles.companyNav}>
                        <h3>Company</h3>
                        <ul>
                            <li><a href="about.php" target="_blank">About</a></li>
                            <li><a target="_blank" href="https://www.jobsgaar.com/JobYatrareport.pdf">Job Yatra </a></li>
                            <li><a href="https://jobsgaar.com/blog/" target="_blank">Blog</a></li>
                            <li><a href="#section04">Investor</a></li>
                            <li><a href="contact" target="_blank">Contact Us</a></li>
                        </ul>
                    </div>
                </Col>
                <Col md={2}>
                    <div className={styles.companyNav}>
                        <h3>Follow Us</h3>
                        <ul>
                            <li><a href="https://www.facebook.com/jobsgaar/" target="_blank">Facebook</a></li>
                            <li><a href="https://twitter.com/jobsgaar" target="_blank">Twitter</a></li>
                            <li><a href="https://www.linkedin.com/company/jobsgaar/" target="_blank">Linkedin</a></li>
                            <li><a href="https://www.instagram.com/jobsgaar/" target="_blank">Instagram</a></li>
                            <li><a href="https://www.youtube.com/results?search_query=jobsgaar" target="_blank">Youtube</a></li>
                        </ul>
                    </div>
                </Col>
                <Col md={2}>
                    <div className={styles.companyNav}>
                        <h3>Support</h3>
                        <ul>
                            <li><a href="https://www.jobsgaar.com/support" target="_blank">FAQs</a></li>
                            <li><a href="https://www.jobsgaar.com/privacy" target="_blank">Privacy Policy</a></li>
                            <li><a href="https://www.jobsgaar.com/terms&amp;condtion" target="_blank">Term &amp; Conditions</a></li>
                            <li><a href="https://www.jobsgaar.com/refund" target="_blank">Cancellation &amp; Refund</a></li>
                            <li><a href="assets/img/FY-2020-21.pdf" target="_blank">Annual Return 2020-21</a></li>
                            <li><a href="assets/img/2021-22.pdf" target="_blank">Annual Return 2021-22</a></li>
                        </ul>
                    </div>
                </Col>
                <Col md={2}>
                    <div className={styles.companyNav}>
                        <h3>Download App</h3>
                        <img src={qrCode}/>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={styles.jobCity}>
                        <h3>Jobs in Bharat </h3>
                        <ul>
                            <li><a href="https://www.jobsgaar.com/Jobs-in-agra.php" target="_blank">Jobs in Agra</a></li>
                            <li><a href="https://www.jobsgaar.com/Jobs-in-lucknow.php" target="_blank">Jobs in Lucknow</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-Kanpur.php" target="_blank">Jobs in Kanpur</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-meerut.php" target="_blank">Jobs in Meerut</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-noida.php" target="_blank">Jobs in Noida</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-ghaziabad.php" target="_blank">Jobs in Ghaziabad</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-varanasi.php" target="_blank">Jobs in Varanasi</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-prayagraj.php" target="_blank">Jobs in Paryagraj</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-muzaffarnagar.php" target="_blank">Jobs in Muzaffarnagar</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-faizabad.php" target="_blank">Jobs in Faizabad</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-bareilly.php" target="_blank">Jobs in Bareilly</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-saharanpur.php" target="_blank">Jobs in Saharanpur</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-gorakhpur.php" target="_blank">Jobs in Gorakhpur</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-raebareli.php" target="_blank">Jobs in Raebareli</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-moradabad.php" target="_blank">Jobs in Moradabad</a></li>
                            <li><a href="https://www.jobsgaar.com/jobs-in-aligarh.php" target="_blank">Jobs in Aligarh</a></li>
                            <li><a href="https://www.jobsgaar.com/sewayojan-up">Jobsgaar or Sewayojan UP</a></li>
                        </ul>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={styles.adnCopy}>
                        <div class={styles.andApp}>
                            <a href="https://play.google.com/store/apps/details?id=com.jobsgaar.jobs" target="_blank"><li><img src={playStore}/></li></a>
                            <a href="https://api.whatsapp.com/send?phone=+919069866986&amp;text=Hi" target="_blank"><li><img src={whatsApp}/></li></a>
                            <div className={styles.startupIndia}>
                                <ul>
                                    <li><img src={govt1}/></li>
                                    <li><img src={govt2}/></li>
                                    <li><img src={govt3}/></li>
                                    <li><img src={govt4}/></li>
                                </ul>
                            </div>
                            <span className={styles.copyRight}> © 2023 Jobsgaar. All rights reserved.</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>

  )
}

export default NewLogin;
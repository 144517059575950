// import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './InterviewSetTime.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import Popup from "../Popup";
import moment from 'moment';
import { getLocalTimeFormatted } from "../../../../helpers";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function InterviewSetTime({
  isOpen,
  handleClose,
  data: {
    finalDateTimeInterview,
    interviewVia
  },
  editScheduleInterview
}) {

  return (
          <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
            <img src={closeJob} onClick={handleClose} alt=""/>
            <Col md={12} className={styles.newBg}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headReject}>
                            <h3>
                            Interview Scheduled
                            <span>{finalDateTimeInterview?.interviewDate ? (moment(finalDateTimeInterview.interviewDate).format("DD MMM`YY")): '-'}</span>
                            </h3>
                            
                            <div className={styles.interviewset}>
                                <h3><span>Time:</span>{finalDateTimeInterview?.interviewTime ? getLocalTimeFormatted(finalDateTimeInterview.interviewTime): "-"}</h3>
                                <h3><span>Duration:</span>{finalDateTimeInterview?.interviewLength ?? '-'}</h3>
                                <h3><span>Interview:</span>{interviewVia ? interviewVia : '-'}</h3>
                            </div>
                            <p>Candidate haven’t taken an action to your interview time slot. <br />Please wait for the candidate to respond</p>
                            <div className={styles.schedBtn}>
                              <div className={styles.editInter}>
                                <div className={styles.effNew}></div>
                                <button onClick={editScheduleInterview}>Edit interview<span></span></button>
                              </div>
                              <div className={styles.okInter}>
                                <div className={styles.effNew}></div>
                                <button onClick={handleClose}>Ok<span></span></button>
                              </div>
                            </div>
                        </div>
                    </Col> 
                </Row> 
            </Col>
            </div>
        </Popup>
  );
}







import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './JobOfferPreview.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import companyLogo from '../../../../assets/images/jobsgaarLogo.svg';
// import listImag from '../../../../assets/images/userProfile.jpg';
import jobOfferbg from '../../../../assets/images/offerleterBack.svg';
// import offerEdit from '../../../../assets/images/offeredit.svg'

import jobDetl from '../../../../assets/images/jobDetail.svg';
import offerExp from '../../../../assets/images/offerExp.svg';
import salaryIcon from '../../../../assets/images/salaryIcon.svg';
import officeInfo from '../../../../assets/images/officeInfo.svg';

import Popup from "../Popup";
import moment from "moment";
import { hideLoader, showLoader } from "../../../../helpers";
import { createJobOffer } from "../../../../services/api/employer";
import { toast } from "react-toastify";
import OfferLetterSent from "../OfferLetterSent/OfferLetterSent";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function JobOfferPreview({
    isOpenOfferLetter,
    handleCloseOfferLetter,
    jobData: { employerName, /**jobAddress,*/ position, jobTitle, jobShift, jobType, workingFrom, workingTime, jobLanguage, workingDays, specializations, jobContactPerson, _id, jobGender },
    offerData: { joiningDate, noticePeriod, expireDate, expireTime, salary, bonusPeriod, bonusAmount, comments, jobLocation, salaryCurrency },
    jobMatchData,
    candidateData,
    updateStatus = () => {
        return null
    },
    handleReset = () => {
        return null
    },
    updateJobMatchAction = false,
    createJobOfferLetter = () => {
        return null;
    }
}) {
    const [isSuccess, setIsSuccess] = useState(false);

    //   const { fullName, whatsappMobile, email, city, fullAddress } = candidateData;
    //   const { employerName, jobAddress, position, jobTitle, jobShift, jobType, workingFrom, workingTime, jobLanguage, workingDays, specializations, jobContactPerson} = jobData;
    //   const { joiningDate, noticePeriod, expireDate, expireTime, salary, bonusPeriod, bonusAmount, comments, jobLocation } = offerData;

    const closeSuccess = () => {
        setIsSuccess(false);
        handleReset();

    }
    const submit = async () => {
        try {
            let submitObj = {
                jobId: _id,
                candidateId: candidateData?._id,
                salary,
                salaryCurrency,
                oneTimeBonus: {
                    bonusPeriod,
                    bonusAmount
                },
                joiningDate,
                noticePeriod,
                jobType,
                shift: jobShift,
                workingFrom,
                workingDays,
                workingHours: workingTime,
                jobLocation,
                comments,
                offerStatus: "Pending",
                offerSent: true
            }
            showLoader();
            const {
                data: {
                    /**data, message*/
                } } = await createJobOffer(submitObj);
            hideLoader();
            // const {
            //     _id,
            //     candidateAction,
            //     jobId,
            //     candidateId
            // } = jobMatchData;
            if (updateJobMatchAction) {
                updateStatus(jobMatchData?.jobId, jobMatchData?._id, 'positive', jobMatchData?.candidateAction, jobMatchData?.employerAction, "JobOffer");
            }
            setIsSuccess(true);

        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }
    const { fullName, whatsappMobile, email, city, fullAddress } = candidateData;
    return (
        <Popup isOpen={isOpenOfferLetter} isClose={handleCloseOfferLetter}>
            <div className={styles.jobOffer}>
                <img className={styles.jobofferNew} src={jobOfferbg} alt="" />
                <img src={closeJob} onClick={handleCloseOfferLetter} alt="" />
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Preview offer letter<span></span></h3>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={6}>
                            <div className={styles.profileName}>
                                <img src={listImag}/>
                                <h3>Arun Kumar<span>HR Manager</span></h3>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={styles.offerDate}>
                                <h3>29th July 2022<span>Job Offer Sending Date</span></h3>
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <div className={styles.offerContent}>
                            <Col md={12}>
                                <div className={styles.compName}>
                                    <img src={companyLogo} alt="" />
                                    {/* <div className={styles.editInter}>
                                        <div className={styles.effNew}></div>
                                        <button><img src={offerEdit}/> Edit offer letter<span></span></button>
                                    </div> */}
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={styles.userDetail}>
                                    <h3><span>Name:</span>{fullName}</h3>
                                    <h3><span>Phone number:</span>{whatsappMobile ? whatsappMobile : "-"}</h3>
                                    <h3><span>Email Id:</span>{email ? email : "-"}</h3>

                                    <div>
                                        <h3><span>Gender:</span>{jobGender ? jobGender : "-"}</h3>
                                        <h3><span>City:</span>{city ? city : "-"}</h3>
                                        <h3><span>Address:</span>{fullAddress ? fullAddress : "-"}</h3>
                                    </div>
                                    <p>We have all really enjoyed speaking with you and getting to know you over the interactions we had.<br /> We are impressed with your background and approach and would love to formally offer you a position at</p>

                                    <div className={styles.topmargin}>
                                        <h3>{employerName}</h3>
                                        <h3>{jobLocation}</h3>
                                    </div>
                                </div>
                                <div className={styles.jobDetail}>
                                    <h2><img src={jobDetl} alt="" /> Job details</h2>

                                    <h3><span>Position name:</span> {position}</h3>
                                    <h3><span>Job title:</span>{jobTitle}</h3>
                                    <h3><span>Date of joining:</span>{moment(joiningDate).format('DD MMM YYYY')}</h3>
                                    <h3><span>Notice period:</span>{noticePeriod} Days</h3>
                                </div>
                                <div className={styles.offerDetail}>
                                    <h2><img src={offerExp} alt="" />Offer letter expire</h2>

                                    <h3><span>Expire date:</span> {moment(expireDate).format("DD MMM YYYY")}</h3>
                                    <h3><span>Expire time:</span>{moment(expireTime, "HH:mm:ss").format("hh:mm a")}</h3>
                                </div>
                                <div className={styles.jobSalary}>
                                    <h2><img src={salaryIcon} alt="" />Salary & Bonus</h2>

                                    <h3><span>Salary (per month):</span> {salary ? `₹ ${salary}/-` : "-"} </h3>
                                    {bonusPeriod ? <h3><span>Bonus period:</span>{bonusPeriod}</h3> : ""}
                                    {bonusAmount ? <h3><span>Bonus amount:</span>{bonusAmount ? `₹ ${bonusAmount}/-` : "-"} </h3> : ""}
                                </div>
                                <div className={styles.jobAddress}>
                                    <h2><img src={officeInfo} alt="" />Office Informations</h2>

                                    <h3><span>Job shift:</span>{jobShift}</h3>
                                    <h3><span>Job type:</span> {jobType} </h3>
                                    <h3><span>Work from:</span>{workingFrom}</h3>
                                    <h3><span>Shift timing:</span>{workingTime?.from && workingTime?.to ? `${workingTime.from} to ${workingTime.to}` : "-"}</h3>
                                    <h3><span>Language:</span> {jobLanguage}</h3>
                                    <h3><span>Working days:</span>
                                        {workingDays?.monday ? 'Monday' : ""}
                                        {workingDays?.tuesday ? ' | Tuesday' : ""}
                                        {workingDays?.wednesday ? ' | Wednesday' : ""}
                                        {workingDays?.thursday ? ' | Thursday' : ""}
                                        {workingDays?.friday ? ' | Friday' : ""}
                                        {workingDays?.saturday ? ' | Saturday' : ""}
                                        {workingDays?.sunday ? ' | Sunday' : ""}
                                    </h3>
                                    <h3><span>Requirements:</span>
                                        {specializations && specializations.length > 0 && specializations.map((item, index) => {
                                            return (
                                                <span key={index} style={{ fontSize: "13px", fontWeight: "410", color: "black" }}>{`${item} ${specializations.length !== index + 1 ? '|' : ""}`}</span>
                                            )
                                        })} </h3>
                                    <h3><span>Information:</span>
                                        <pre style={{ margin: "-16px 0px 0px 83px", fontSize: "13px", fontWeight: "410", color: "black", fontFamily: "Poppins", overflow: "inherit" }}>{comments}</pre>
                                    </h3>

                                    <p>Please note that this is only a provisional offer of employment/professional services and is not being considered as an appointment letter.<br />An appointment letter would be issued to you on your accepting this provisional offer.</p>

                                    <p className={styles.newPera}>We look forward to welcoming you on board.</p>

                                    <h3>{jobContactPerson} - HR Manager</h3>
                                    <h3>{employerName}</h3>
                                    <h3>{jobLocation}</h3>
                                </div>
                            </Col>
                        </div>
                        <Col md={12}>
                            <div className={styles.btnUser}>
                                <div className={styles.editInter} onClick={handleCloseOfferLetter}>
                                    <div className={styles.effNew}></div>
                                    <button>Back<span></span></button>
                                </div>
                                <div className={styles.sendOffer} onClick={updateJobMatchAction ? submit : createJobOfferLetter}>
                                    <div className={styles.effOffer}></div>
                                    <button>Send Offer Letter <span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
            <OfferLetterSent
                isOpen={isSuccess}
                handleClose={closeSuccess}
            />
        </Popup>
    );
}







import { Col, Row } from "react-bootstrap";
import styles from './SmartSecretary.module.scss'
// import closeJob from '../../../../assets/images/closeIcon.svg';
import SmarySec from '../../../../assets/images/smartsec.svg';
import Popup from "../Popup";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function SmartSecretary({
  isOpen,
  handleClose
}) {

  return (
          <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
            {/* <img src={closeJob} onClick={handleClose}/> */}
            <Col md={12} className={styles.newBg}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headReject}>
                            <img src={SmarySec} alt=""/>
                            <h3>Smart Secretary</h3>
                            <p>Our smart secretary will now try to connect you with your candidate.<br/>
Stay tuned and answer the call from 08046808028</p>
                        </div>
                    </Col> 
                </Row> 
            </Col>
            </div>
        </Popup>
  );
}







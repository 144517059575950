// import { style } from '@mui/system';
import React from 'react';
import { Col, Container, /**FormGroup,*/ Row } from 'react-bootstrap';
import styles from './CompanyTrust.module.scss';
// import proImage from '../../assets/images/userProfile.jpg';
import ClientOne from '../../assets/images/ClientLogo/Cl1.png';
import ClientTwo from '../../assets/images/ClientLogo/Cl2.png';
import ClientTre from '../../assets/images/ClientLogo/Cl3.png';
import ClientFor from '../../assets/images/ClientLogo/Cl4.png';
import ClientFiv from '../../assets/images/ClientLogo/Cl5.png';
import ClientSi from '../../assets/images/ClientLogo/Cl6.png';
import ClientSe from '../../assets/images/ClientLogo/Cl7.png';
import ClientEg from '../../assets/images/ClientLogo/Cl8.png';
import ClientNin from '../../assets/images/ClientLogo/Cl9.png';
import ClientTen from '../../assets/images/ClientLogo/Cl10.png';
import ClientElv from '../../assets/images/ClientLogo/Cl11.png';
import ClientTwl from '../../assets/images/ClientLogo/Cl12.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



// const contentStyle = {
//   height: '160px',
//   color: '#fff',
//   lineHeight: '160px',
//   textAlign: 'center',
//   background: '#364d79',
// };

const CompanyLogo = () => (
  <section className={styles.clientTrust}>
    <Container fluid>
      <Row>
        <Col md={12}>
          <div className={styles.cerasoleTest}>
            <h3>Trusted by companies</h3>
            <Carousel className={styles.cerasoleNew} autoplay stopOnHover showThumbs={false} >
              <div className={styles.CompanyLog}>
                <ul>
                  <li><img src={ClientOne} alt="" /></li>
                  <li><img src={ClientTwo} alt="" /></li>
                  <li><img src={ClientTre} alt="" /></li>
                  <li><img src={ClientFor} alt="" /></li>
                </ul>
              </div>
              <div className={styles.CompanyLog}>
                <ul>
                  <li><img src={ClientFiv} alt="" /></li>
                  <li><img src={ClientSi} alt="" /></li>
                  <li><img src={ClientSe} alt="" /></li>
                  <li><img src={ClientEg} alt="" /></li>
                </ul>
              </div>
              <div className={styles.CompanyLog}>
                <ul>
                  <li><img src={ClientNin} alt="" /></li>
                  <li><img src={ClientTen} alt="" /></li>
                  <li><img src={ClientElv} alt="" /></li>
                  <li><img src={ClientTwl} alt="" /></li>
                </ul>
              </div>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default CompanyLogo;
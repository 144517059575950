// import { style } from '@mui/system';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './TestimonialsLogin.module.scss';
import One from '../../assets/images/testOne.jpg';
import Two from '../../assets/images/testTwo.jpg';
import Three from '../../assets/images/testThree.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// const contentStyle = {
//   height: '160px',
//   color: '#fff',
//   lineHeight: '160px',
//   textAlign: 'center',
//   background: '#364d79',
// };

const TestimonialsLogin = () => (
  <section className={styles.Testimonial}>
    <Container fluid>
      <Row>
        <Col md={12}>
          <div className={styles.cerasoleTest}>
            <h3>Testimonials</h3>
              <Carousel className={styles.cerasoleNew} autoplay showThumbs={false}>
                <div className={styles.TestimonialSec}>
                 <img src={One} alt=""/>
                 <div className={styles.contentTest}>
                    <h3>Shreen Gupta, <span>HR</span></h3>
                    <h4>Neumart Pvt. Ltd</h4>
                    <p>Thanks to Jobsgaar, meri hiring requirement 5 easy-steps me<br/>complete ho gayi vo bhi bina resume shortlist kare. Great process!</p>
                 </div>
                </div>
                <div className={styles.TestimonialSec}>
                 <img src={Three} alt=""/>
                 <div className={styles.contentTest}>
                    <h3>Pratiek Rawat, <span>HR Head</span></h3>
                    <h4>Perchsoft Technologies</h4>
                    <p>Chhote shehro me hiring karna ek bada challenge tha mere liye, lekin<br/> Jobsgaar neisse apne bhot hi easy process se jaldi close kar diya.<br /> Thank you Jobsgaar!</p>
                 </div>
                </div>
                <div className={styles.TestimonialSec}>
                 <img src={Two} alt=""/>
                 <div className={styles.contentTest}>
                    <h3>Nishant Alok, <span>Manager</span></h3>
                    <h4>Ramprashta Marketing pvt ltd</h4>
                    <p>Baaki apps se roz ke 100-200 resumes shortlist kar kar ke thakk gaya tha <br/> mai, lekin Jobsgaar ke saath aisa nahi karna pada. Muje mile seedhe <br /> shortlisted aur verified candidates hiring ke liye.</p>
                 </div>
                </div>
            </Carousel>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default TestimonialsLogin;
// import { style } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Col, Container, /**FormGroup,*/ Row } from 'react-bootstrap';

import styles from './Login.module.scss';

import LoginImage from '../../assets/images/indiaMap.svg';
import CompanyImage from '../../assets/images/logo.svg';
import whatsappIcon from '../../assets/images/whatsappIcon.png';
import satImage from '../../assets/images/satImage.svg';
import editIcon from '../../assets/images/edit.svg';
import { useForm } from "react-hook-form";
import { login } from '../../services/api/common';
import OtpInput from "otp-input-react";
import { toast } from 'react-toastify';
import { showLoader, hideLoader, setCookie } from '../../helpers/index';
import { requestOtpForLogin, verifyOtpForLogin } from '../../services/api/otp';
import { useLocation, useNavigate /** ,useParams*/ } from 'react-router-dom';
import { getProfile } from '../../services/api/employer';
import Attention from '../common/Popup/Attention/Attention';
import TestimonialBlock from '../TestimonialsLogin/TestimonialsLogin';
import CompanyTrust from '../CompanyTrust/CompanyTrust';

function Login() {

  // const greeting = "greeting";
  // const displayAction = false;
  const navigate = useNavigate();

  //state
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const [otpNumber, setOtpNumber] = useState();
  const [otpError, setOtpError] = useState(false);
  const [userDataForOtp, setUserDataForOtp] = useState({});
  const [otpTiming, setOtpTiming] = useState(180);
  const [intervalId, setIntervalId] = useState(0);
  const [isOpenAttention, setIsOpenAttention] = useState(false);
  // const [isAllowLogin, setIsAllowLogin] = useState(false);

  let message = {
    required: 'Whatsapp number is required',
    pattern: 'Name should contain only numbers',
    maxLength: "Maximum allowed numbers 10"
  }

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let source = query.get("source");
  source = source ? window.atob(source) : "";

  /** 
   * Check the user against the database ie api is https://api.jobsgaar.com/v1/common/signIn
   * @param {"whatsappMobile":"9524794421"} data 
   * if user found, asking OTP
   * if user not found ask them to register using modal window
  */
  const submit = async (submitData) => {
    try {
      showLoader();
      const {
        data: {
          data, status
        }
      } = await login(submitData);
      if (status === 200 && data.userType === "employer") {
        sendOtp({ userId: data._id, type: data.userType, whatsappMobile: data.whatsappMobile });
        setUserDataForOtp({
          type: data.userType,
          whatsappMobile: data.whatsappMobile,
          userId: data._id
        });
      } else if (status === 200 && data.userType === "candidate") {
        toast.error("Employer doesn't exist on this platform");
      } else {
        // toast.error("failed");
        setUserDataForOtp({
          whatsappMobile: submitData.whatsappMobile
        });
        setIsOpenAttention(true);
      }
      hideLoader();
      // toast.success("success")
    } catch (errors) {
      const {
        data: {
          /**message*/
        }
      } = errors;
      hideLoader();
      // toast.error(message ? message : "Failed");
      // navigate('/signup', {
      //   state: {
      //     whatsappMobile: submitData.whatsappMobile
      //   }
      // });
      setUserDataForOtp({
        whatsappMobile: submitData.whatsappMobile
      });
      setIsOpenAttention(true);
    }
  }

  /**
   * API -> https://api.jobsgaar.com/v1/otp/requestOtpForLogin
   * @param {"userId":"63bbefc4c84fe0832aa942d6","type":"employer","whatsappMobile":"9524794421"} data 
   * send OTP to the user
   */
  const sendOtp = async (data) => {
    try {
      showLoader();
      let {
        data: {
          message, status
        }
      } = await requestOtpForLogin(data);
      setMobileNumber(data.whatsappMobile);
      setIsOtpSent(true);
      handleOtpTiming();
      hideLoader();
      toast.success(message ? message : "Otp has sent successfully");
    } catch (errors) {
      const {
        data: {
          message
        }
      } = errors;
      hideLoader();
      toast.error(message ? message : "Failed");
    }
  }

  /** This event triggered when user typing the OTP */
  const handleOtpChange = (data) => {
    setOtpNumber(data);
    data ? setOtpError(false) : setOtpError(true);
  }

  /**
   * API -> https://api.jobsgaar.com/v1/otp/verifyOtpForLogin
   * @param {
      "type": "employer",
      "whatsappMobile": "9524794421",
      "userId": "63bbefc4c84fe0832aa942d6",
      "otp": "654646",
      "deviceToken": "EmployersPortal"
  }
  * This method is used to Verify the OTP
  * Once the verify otp success, then drop the "token" cookie and store the user details
  */
  const otpSubmit = async () => {
    if (!otpNumber) {
      setOtpError(true);
      return;
    }
    try {
      let submitData = userDataForOtp;
      submitData.otp = otpNumber;
      submitData.deviceToken = "EmployersPortal";
      showLoader();
      let {
        data: {
          data
        }
      } = await verifyOtpForLogin(submitData);
      if (data.refreshToken) {
        hideLoader();
        toast.success(message ? message : "SignIn Successfull");
        var now = new Date();
        now.setTime(now.getTime() + 8 * 3600 * 1000); //expire time 8 hours
        let cookieOptions = {
          expires: now //seconds to expire
        }
        setCookie("token", data.refreshToken, cookieOptions);
        let employerData = await getProfileData();
        localStorage.setItem("userData", JSON.stringify(employerData));
        clearInterval(intervalId);
        setOtpTiming(180);
        setIntervalId(0);
        navigate('/dashboard');
      }
    } catch (error) {
      const { data: { message } } = error;
      hideLoader();
      toast.error(message ? message : "Failed, Try again");
    }
  }

  /** This event is triggered when click the resend otp link */
  const handleResendOtp = () => {
    sendOtp(userDataForOtp);
    setOtpNumber("");
  }

  const getProfileData = async () => {
    try {
      showLoader();
      let {
        data: {
          data
        }
      } = await getProfile();
      hideLoader();
      return data;

    } catch (error) {
      hideLoader();
    }
  }

  const handleOtpTiming = async () => {
    let interval = setInterval(() => {
      setOtpTiming(otpTiming => otpTiming - 1);
    }, 1000);
    setIntervalId(interval);
  }

  useEffect(() => {
    if (intervalId && otpTiming <= 0) {
      clearInterval(intervalId);
      setOtpTiming(180);
      setIntervalId(0);
    }
  }, [otpTiming]);

  const handleSignup = () => {
    navigate('/signup', {
      state: {
        whatsappMobile: userDataForOtp?.whatsappMobile,
        sourceData: source ? source : ""
      }
    });
  }

  const handleOnChange = (e) => {
    console.log("1111111-------->")
    let value = e.target.value;
    value = value.replace(/\D/g, '')
    setValue(e.target.name, value);
  }

  return (

    <section className={styles.bgMain}>
      <Container className={styles.containerBg} maxwidth="sm">
        <Row>
          <Col md={6} className={styles.padZero}>
            <div className={styles.indiaMap}>
              <img src={LoginImage} alt='' />
              <div className={styles.satCon}>
                <img src={satImage} alt='' />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className={styles.bgIndidaMap}>
              {
                !isOtpSent ? (<div className={styles.otpform}>
                  <div className={styles.cntntForm}>
                    <img src={CompanyImage} alt='' />
                    <p>Login or SignUp with your WhatsApp number</p>
                  </div>
                  <form className={styles.formWidth} onSubmit={handleSubmit(submit)}>
                    <div className={styles.iconSpc}>
                      <img src={whatsappIcon} alt='' />
                      <span></span>
                      <p>+91</p>
                    </div>
                    <input
                      className={styles.formControl}
                      placeholder="WhatsApp Number"
                      maxLength="10"
                      {...register(
                        "whatsappMobile", {
                        required: true,
                        pattern: /^[0-9]*$/,
                        maxLength: 10,
                        onChange: (e) => handleOnChange(e)
                      })} />
                    {errors.whatsappMobile && <span className="errorMessage">{message[errors.whatsappMobile.type]}</span>}
                    <div className={styles.btnNew} onClick={handleSubmit(submit)}>
                      <div className={styles.effNew}></div>
                      <button>Send OTP <span></span></button>
                    </div>
                    <p className={styles.notMember}>Not a member? Don't worry!<br /> Just fill your WhatsApp contact and we will sign you up quickly.
                      {/* <a href="https://www.jobsgaar.com/hirenow" target={"_blank"}>Sign Up Here</a> */}
                    </p>
                  </form>
                  <Attention
                    isOpen={isOpenAttention}
                    handleSignup={handleSignup}
                    handleRedirect={() => window.location.replace('https://www.jobsgaar.com/job')} />
                </div>
                ) : (

                  <div className={styles.otpsubmit}>
                    <div className={styles.cntntForm}>
                      <img src={CompanyImage} alt='' />
                      <p>Please enter the OTP,<br />sent to your Phone Number</p>
                      <span>{mobileNumber}
                        <a onClick={() => setIsOtpSent(!isOtpSent)}><img src={editIcon} alt='' /></a>
                        {/* <a onClick={handleResendOtp}>Resend Otp</a> */}
                      </span>
                    </div>
                    <form className={styles.submitorm} onSubmit={handleSubmit(otpSubmit)}>
                      <div className={styles.inpOtp}>
                        <OtpInput
                          value={otpNumber}
                          onChange={handleOtpChange}
                          autoFocus
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          placeholder="------"
                        />

                        {otpError && <span className='errorMessage mt-3'>Otp numer is required</span>}
                      </div>
                      {intervalId > 0 ? (
                        <p><span>{otpTiming}</span> seconds remaining</p>
                      ) : (
                        <p>Haven’t receive the code? <span><a className={styles.resendOtp} onClick={handleResendOtp}>Resend Otp</a></span></p>
                      )}
                      <div className={styles.btnNew} onClick={handleSubmit(otpSubmit)}>
                        <div className={styles.effNew}></div>
                        <button type="submit">Submit<span></span></button>
                      </div>
                    </form>
                  </div>
                )
              }
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className={styles.bgImpo}>
        <Row>
          <Col md={6}>
            <CompanyTrust />
          </Col>
          <Col md={6}>
            <div className={styles.cntentWrite}>
              <TestimonialBlock />
            </div>
          </Col>
        </Row>
      </Container>

      <Container maxwidth="sm" className={styles.newFoot}>
        <Row>
          <Col md={12}>
            <div className={styles.footerHead}>
              <p>© 2023 Jobsgaar. All rights reserved.</p>
            </div>
          </Col>
        </Row>


      </Container>
    </section>

  )
}

export default Login;
import { axiosInstance } from "../axiosInstance";
import { endPoints } from "../endPoints";

export const normalCall = (data) => {
  return axiosInstance({ method: "post", url: endPoints.interview.normalCall, data });
};

export const quickCall = (data) => {
  return axiosInstance({ method: "post", url: endPoints.interview.quickCall, data });
};

export const normalCallPassThru = (params={}) => {
  return axiosInstance({ method: "get", url: endPoints.interview.normalCallPassThru, params });
};

export const quickCallPassThru = (params={}) => {
    return axiosInstance({ method: "get", url: endPoints.interview.quickCallPassThru, params });
  };
// import { style } from "@mui/system";
import { Col, Container, Row } from "react-bootstrap"
import HomeLayout from "../../layouts/HomeLayout/HomeLayout";
import newCandidate from '../../assets/images/newCandi.svg';
// import CardRoj from '../../assets/images/cardRoj.svg';
import interviewReq from '../../assets/images/interviewReq.svg';
// import joboffer from '../../assets/images/jobofferNoti.svg';
// import newinsight from '../../assets/images/newinsight.svg';
// import hiredNew from '../../assets/images/hirednew.svg';
// import offerReject from '../../assets/images/offerReject.svg';
// import cardFront from '../../assets/images/cardFront.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { /**faCircleCheck, faCircleXmark, faPencil, faUser, faUsers, faIndianRupeeSign, faAnglesDown,*/ faAngleDown /** ,faAnglesUp, faAngleUp*/ } from '@fortawesome/free-solid-svg-icons';
import styles from './Notifications.module.scss';
// import { Insights } from "@mui/icons-material";
import moment from "moment";




export default function Notifications() {
  
    return (
        <HomeLayout>
           <section className={styles.mainNew}>
                <Container fluid className="p-0">
                   <Row>
                       <Col md={12}>
                            <div className={styles.jobPostHead}>
                                <h3>All Notifications</h3>
                            </div> 
                       </Col>
                   </Row> 
               </Container>
               <Container fluid className="p-0">
                   <Row>
                       <Col md={12}>
                            <div className={styles.scrollbarContainer}>
                                <div className={styles.scrollContainer}>
                                    <div className={styles.scrollcontent}>
                                        <div className={styles.accordianMain}>
                                            <h3>{moment().format('DD MMMM YYYY')}</h3>
                                            <div className={styles.unRead}>
                                                <div className={styles.unreadHead}>
                                                    <span></span>
                                                    <div className={styles.unreadAccor}>
                                                        <img src={newCandidate} alt=""/>
                                                        
                                                        <h3>
                                                        Welcome to Employers Portal <FontAwesomeIcon icon={faAngleDown} />
                                                            <p>With Jobsgaar employers portal it is now much easier to manage and control your posted jobs and applicants</p>
                                                        </h3>
                                                    </div>
                                                    <div className={styles.openContent}>

                                                    </div>
                                                </div> 
                                            </div>
                                            {/* <div className={styles.readCont}>
                                                <div className={styles.readHead}>
                                                    <span></span>
                                                    <div className={styles.readAccor}>
                                                        <img src={CardRoj}/>
                                                        <h3>
                                                            Rojgaar Card - Now in your city <FontAwesomeIcon icon={faAngleDown} /> 
                                                            <p>At enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
                                                        </h3>
                                                    </div>
                                                    <div className={styles.openContent}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.imgCard}>
                                                                    <img src={cardFront}/>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.openNew}>
                                                                    <h3>Get your own Rojgaar card that could eprehenderit in voluptate velit cillum fugiat.
                                                                        <p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem euthat
                                                                            fugiat quo voluptas nulla pariatur</p>
                                                                    </h3>
                                                                    <div className={styles.actionButtons}>
                                                                        <div className={styles.btnNew}>
                                                                            <div className={styles.effNew}></div>
                                                                            <a>Buy Rojgaar Card Now<span></span></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div> 
                                            </div> */}
                                            
                                        </div>

                                        <div className={styles.accordianMain}>
                                            <h3>{moment().format('DD MMMM YYYY')}</h3>
                                            <div className={styles.unRead}>
                                                <div className={styles.unreadHead}>
                                                    <span></span>
                                                    <div className={styles.unreadAccor}>
                                                        <img src={interviewReq} alt=""/>
                                                        
                                                        <h3>
                                                        Coming Soon <FontAwesomeIcon icon={faAngleDown} />
                                                            <p>Notifications will come soon to the employer's platform, meanwhile you can see notifications in App</p>
                                                        </h3>
                                                    </div>
                                                    <div className={styles.InterviewReq}>

                                                    </div>
                                                </div> 
                                            </div>

                                            {/* <div className={styles.unRead}>
                                                <div className={styles.unreadHead}>
                                                    <span></span>
                                                    <div className={styles.unreadAccor}>
                                                        <img src={joboffer}/>
                                                        
                                                        <h3>
                                                            Job offer - Accepted!! <FontAwesomeIcon icon={faAngleDown} />
                                                            <p>At enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
                                                        </h3>
                                                    </div>
                                                    <div className={styles.openContent}>

                                                    </div>
                                                </div> 
                                            </div> */}
                                        </div>


                                        {/* <div className={styles.accordianMain}>
                                            <h3>23 April 2022</h3>
                                            <div className={styles.readCont}>
                                                <div className={styles.readHead}>
                                                    <span></span>
                                                    <div className={styles.readAccor}>
                                                        <img src={newinsight}/>
                                                        <h3>
                                                            New insight by Jobsgaar <FontAwesomeIcon icon={faAngleDown} /> 
                                                            <p>At enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
                                                        </h3>
                                                    </div>
                                                    <div className={styles.openContent}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.imgCard}>
                                                                    <img src={cardFront}/>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.openNew}>
                                                                    <h3>Get your own Rojgaar card that could eprehenderit in voluptate velit cillum fugiat.
                                                                        <p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem euthat
                                                                            fugiat quo voluptas nulla pariatur</p>
                                                                    </h3>
                                                                    <div className={styles.actionButtons}>
                                                                        <div className={styles.btnNew}>
                                                                            <div className={styles.effNew}></div>
                                                                            <a>Buy Rojgaar Card Now<span></span></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div> 
                                            </div>
                                            
                                        </div> */}
                                        
                                        {/* <div className={styles.accordianMain}>
                                            <h3>26 April 2022</h3>
                                            <div className={styles.unRead}>
                                                <div className={styles.unreadHead}>
                                                    <span></span>
                                                    <div className={styles.unreadAccor}>
                                                        <img src={offerReject}/>
                                                        
                                                        <h3>
                                                            Job Offer - Rejected!! <FontAwesomeIcon icon={faAngleDown} />
                                                            <p>At enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
                                                        </h3>
                                                    </div>
                                                    <div className={styles.InterviewReq}>

                                                    </div>
                                                </div> 
                                            </div>

                                            <div className={styles.readCont}>
                                                <div className={styles.readHead}>
                                                    <span></span>
                                                    <div className={styles.readAccor}>
                                                    <img src={hiredNew}/>
                                                        <h3>
                                                            You hired a new candidate <FontAwesomeIcon icon={faAngleDown} /> 
                                                            <p>At enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
                                                        </h3>
                                                    </div>
                                                    <div className={styles.openContent}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.imgCard}>
                                                                    <img src={cardFront}/>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.openNew}>
                                                                    <h3>Get your own Rojgaar card that could eprehenderit in voluptate velit cillum fugiat.
                                                                        <p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem euthat
                                                                            fugiat quo voluptas nulla pariatur</p>
                                                                    </h3>
                                                                    <div className={styles.actionButtons}>
                                                                        <div className={styles.btnNew}>
                                                                            <div className={styles.effNew}></div>
                                                                            <a>Buy Rojgaar Card Now<span></span></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div> */}

                                        {/* <div className={styles.accordianMain}>
                                            <h3>20 April 2022</h3>
                                            <div className={styles.readCont}>
                                                <div className={styles.readHead}>
                                                    <span></span>
                                                    <div className={styles.readAccor}>
                                                    <img src={newCandidate}/>
                                                        <h3>
                                                            Got a new candidate <FontAwesomeIcon icon={faAngleDown} /> 
                                                            <p>At enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur</p>
                                                        </h3>
                                                    </div>
                                                    <div className={styles.openContent}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <div className={styles.imgCard}>
                                                                    <img src={cardFront}/>
                                                                </div>
                                                            </Col>
                                                            <Col md={9}>
                                                                <div className={styles.openNew}>
                                                                    <h3>Get your own Rojgaar card that could eprehenderit in voluptate velit cillum fugiat.
                                                                        <p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem euthat
                                                                            fugiat quo voluptas nulla pariatur</p>
                                                                    </h3>
                                                                    <div className={styles.actionButtons}>
                                                                        <div className={styles.btnNew}>
                                                                            <div className={styles.effNew}></div>
                                                                            <a>Buy Rojgaar Card Now<span></span></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div> 
                                            </div>
                                            
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                       </Col>
                   </Row>
               </Container>
           </section>
        </HomeLayout>

    )
}
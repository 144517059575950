import { useState, useEffect } from "react";
import { Col, Container, /**FormGroup,*/ Row } from "react-bootstrap";
import styles from './Preferences.module.scss';
import HomeLayout from "../../layouts/HomeLayout/HomeLayout";
import veryfiIcon from "../../assets/images/veryfiIcon.svg";
// import closeJob from '../../assets/images/closeIcon.svg';
import cardStyle from '../../assets/images/cardView.svg';
import cardHover from '../../assets/images/cardViewActive.svg';
import Newmeric from '../../assets/images/numeric.svg';
import checkBos from '../../assets/images/checkNew.svg';
import demoImage from '../../assets/images/demoPro.svg';
import whatsApp from '../../assets/images/whatsapp.svg';
import mobile from '../../assets/images/mobile.svg';
import email from '../../assets/images/email.svg';
import rightArrow from '../../assets/images/rightArrow.svg';
import NumericHover from '../../assets/images/numerichover.svg';



import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Dropdown } from "../../components/common/Dropdown/Dropdown";
import InputField from "../../components/common/InputField/InputField";
// import TextArea from "../../components/common/TextArea/TextArea";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Creatable, { useCreatable } from 'react-select/creatable';
// import { faX } from "@fortawesome/free-solid-svg-icons";
// import TextField from '@mui/material/TextField';

import { useForm, Controller } from "react-hook-form";
import * as React from 'react';
// import { style } from "@mui/system";
import PropTypes from 'prop-types';

import moment from "moment";
import {
    language,
    timezoneOptions
} from '../../common/DropdownOptions';
// import { ResetTv, StayPrimaryLandscape } from "@mui/icons-material";
import Activelylooking from "../common/Popup/Activelylooking/Activelylooking";
import SelectTimeDay from "../common/Popup/SelectTimeDay/SelectTimeDay";
import { getUtcTime, hideLoader, showLoader } from "../../helpers";
import SuspendNotification from "../common/Popup/SuspendNotification/SuspendNotification";
import DeactivateAccount from "../common/Popup/DeactivateAccount/DeactivateAccount";
import VerifyEmailPhone from "../common/Popup/VerifyEmailAndPhone/VerifyEmailPhone"
import { updatePreferences } from "../../services/api/common";
import { toast } from "react-toastify";
import { getProfile } from "../../services/api/employer";
import { useNavigate } from "react-router-dom";
import { requestOtpEmailPhoneforPreference } from "../../services/api/otp"

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

/** setting id's for each tab */
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




export default function Preferences() {
    const navigate = useNavigate();
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);

    const { register, handleSubmit, formState: { errors }, getValues, control, setValue, reset } = useForm();

    const [value, setTabValue] = useState(0);
    const [cardsTheme, setCardsTheme] = useState();
    const [jobNotification, setjobNotification] = useState({
        shortlisted: false,
        interviewRequest: false,
        requestCall: false,
        jobOffer: false,
        hired: false,
        rejected: false,
        jobApplied: false,
        interviewConfirmed: false,
        confirmCall: false,
        jobOfferApproved: false
    });
    const [communication, setCommunication] = useState({
        immediateJob: false,
        openForJob: false,
        suspend: false
    });
    const [isOpenCommunicationOption, setIsOpenCommunicationOption] = useState(false);
    const [matchJobBy, setMatchJobBy] = useState();
    const [alertInterval, setAlertInterval] = useState();
    const [communicationTitle, setCommunicationTitle] = useState();
    const [dropDownDefaultValues, setDropdownDefaultValues] = useState({});
    const [isopenTimeDay, setIsOpenTimeDay] = useState(false);
    const [timeSchedule, setTimeSchedule] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        fromTime: "",
        toTime: ""
    });
    const [workingTime, setWorkingTime] = useState();
    const [isOpenSuspendNotification, setIsOpenSuspendNotification] = useState(false);
    const [appNotification, setAppNotification] = useState({
        whatsapp: false,
        mobileApp: false,
        vibrate: false,
        email: false,
        sms: false
    });
    const [isOpenDeactivateAccount, setIsOpenDeactivateAccount] = useState(false);
    const [disableEmail, setDisableEmail] = useState(false);
    const [disablePhone, setDisablePhone] = useState(false);
    const [verifyPopup, setVerifyPopup] = useState(false);
    const [isUserVerified, setIsUserVerified] = useState(false);
    let oldEmail = userData.companyEmail;
    let oldPhone = userData.alternateNo;
    //let isUserVerified = false

    useEffect(() => {
        setData('all');
    }, []);

    const handleReset = () => {
        if (value === 0) {
            setValue("companyEmail", "")
            setValue("alternateNo", "")
        }
    }
    /** setting the user data to local state */
    const setData = (type) => {
        let obj = {
            alternateNo: userData?.alternateNo,
            companyEmail: userData?.companyEmail,
            cardsTheme: userData?.cardsTheme,
            language: userData?.language,
            timeZone: userData?.timeZone,
            appNotification: userData?.appNotification,
            communication: userData?.communication,
            matchJobBy: userData?.matchJobBy,
            alertInterval: userData?.alertInterval,
            timeSchedule: userData?.timeSchedule,
            jobNotification: userData?.jobNotification,
            cardsTheme: userData?.cardsTheme
        }
        if (type && type === 'all') {
            reset(obj);
            setCardsTheme(userData?.cardsTheme);
            setCommunication(userData?.communication);
            setMatchJobBy(userData?.matchJobBy);
            setAlertInterval(userData?.alertInterval);
            setjobNotification(userData?.jobNotification);
            if (userData?.language) {
                dropDownDefaultValues.language = {
                    label: userData?.language,
                    value: userData?.language
                }
            }
            if (userData?.timeZone) {
                let obj = timezoneOptions.find(i => i.value === userData.timeZone);
                dropDownDefaultValues.timeZone = obj;
            }
            setDropdownDefaultValues({
                ...dropDownDefaultValues
            });
            setTimeSchedule(userData?.timeSchedule);
            setAppNotification(userData?.appNotification);
        } else if (type === 'communication') {
            setCommunication(userData?.communication);
            setMatchJobBy(userData?.matchJobBy);
            setAlertInterval(userData?.alertInterval);
            setjobNotification(userData?.jobNotification);
            setIsOpenCommunicationOption(false)
        } else if (type === 'timeSchedule') {
            setTimeSchedule(userData?.timeSchedule);
            setIsOpenTimeDay(false);
        } else if (type === 'suspendNotification') {
            setCommunication(userData?.communication);
            setIsOpenSuspendNotification(false);
        }

    }

    /**This event is used is used for switch between tabs */
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCommunication = (type) => {
        if (!communication[type] && type !== 'suspend') {
            resetCommunication();
            setCommunication({
                immediateJob: false,
                openForJob: false,
                suspend: false,
                [type]: !communication[type]
            });
        }
        if (type === 'suspend') {
            setIsOpenSuspendNotification(true);
        } else {
            setIsOpenCommunicationOption(true);
        }
        type === 'immediateJob' ? setCommunicationTitle('Actively looking for Immediate Candidate') : setCommunicationTitle('Open to hire New Candidate');

    }

    const handleJobNotification = (type) => {
        setjobNotification({
            ...jobNotification,
            [type]: !jobNotification[type]
        })
    }

    const resetCommunication = () => {
        setMatchJobBy();
        setAlertInterval();
        setjobNotification({
            shortlisted: false,
            interviewRequest: false,
            requestCall: false,
            jobOffer: false,
            hired: false,
            rejected: false,
            jobApplied: false,
            interviewConfirmed: false,
            confirmCall: false,
            jobOfferApproved: false
        });
    }

    const handleDropdownDefault = (name, data) => {
        setDropdownDefaultValues({
            ...dropDownDefaultValues,
            [name]: data
        });
        return data?.value;
    }

    const handleTime = (name, value) => {
        if (value) {
            setWorkingTime({
                ...workingTime,
                [name]: moment(value).format("H")
            })
            setTimeSchedule({
                ...timeSchedule,
                [name]: getUtcTime(moment(value).format("HH:mm:ss"))
            })
            // return moment(value).format("hh:mm a");
        }
    }

    const handleworkingDay = (day) => {
        setTimeSchedule({
            ...timeSchedule,
            [day]: !timeSchedule[day]
        });
    }

    const handleAlertInterval = (data) => {
        setAlertInterval(data);
        if (data === 1) {
            setIsOpenTimeDay(true);
        }
    }

    const handleSuspendNotification = () => {
        setCommunication({
            immediateJob: false,
            openForJob: false,
            suspend: true
        });
        setIsOpenSuspendNotification(false);
    }

    const handleAppNotification = (name) => {
        setAppNotification({
            ...appNotification,
            [name]: !appNotification[name]
        })
    }

    const getProfileData = async () => {
        try {
            //   showLoader();
            let {
                data: {
                    data
                }
            } = await getProfile();
            hideLoader();
            return data;

        } catch (error) {
            hideLoader();
        }
    }

    const sentOTP = async () => {
        try {
            showLoader();
            const requestObj = {
                "userType": "employer",
                "oldEmail": oldEmail,
                "newEmail": getValues("companyEmail"),
                "oldWhatsappMobile": oldPhone,
                "newWhatsappMobile": getValues("alternateNo")
            }
            let {
                data: {
                    data, message
                }
            } = await requestOtpEmailPhoneforPreference(requestObj);
            setVerifyPopup(true)
            hideLoader();
            toast.success(message ? message : "Success");
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const submit = async (submitData) => {
        try {
            let reqObj = getValues();
            showLoader();
            if (value === 0 && ((oldEmail !== getValues("companyEmail")) || oldPhone !== getValues("alternateNo")) && !isUserVerified) {
                sentOTP(reqObj)
                hideLoader();
                return false;
            } else {
                let values = {
                    language: submitData?.language,
                    timeZone: submitData?.timeZone,
                    appNotification,
                    communication,
                    alertInterval,
                    timeSchedule,
                    jobNotification,
                    cardsTheme,
                    companyEmail: reqObj.companyEmail,
                    alternateNo: reqObj.alternateNo
                }

                let {
                    data: {
                        message
                    }
                } = await updatePreferences(values);
                hideLoader();
                // getProfileData();
                let employerData = await getProfileData();
                localStorage.setItem("userData", JSON.stringify(employerData));
                setDisableEmail(false)
                setDisablePhone(false)
                setIsUserVerified(false);
                toast.success(message ? message : "Success");
            }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            setDisableEmail(false)
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const disableMobileEmail = (name, val) => {
        if (name === 'mobile') {
            const value = val.replace(/\D/g, '')
            setValue("alternateNo", value);
            setValue("companyEmail", oldEmail);
            setDisableEmail(true);
            setDisablePhone(false);
        } else {
            setValue("alternateNo", oldPhone);
            setDisableEmail(false);
            setDisablePhone(true);
        }
    }

    const handleVerifyPopup = () => {
        // if (!isUserVerified) {
        //     setValue("alternateNo", oldPhone)
        //     setValue("companyEmail", oldEmail)
        // } else {
        setValue("alternateNo", getValues("alternateNo"))
        setValue("companyEmail", getValues("companyEmail"))
        //}

        setDisableEmail(false)
        setDisablePhone(false)
        setVerifyPopup(false)
    }

    const checkUserverified = (val) => {
        //isUserVerified = val;
        setIsUserVerified(val)
    }

    return (
        <HomeLayout>
            <section className={styles.preferenceMain}>
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <div className={styles.headEdit}>
                                <h3>Preferences</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className={styles.tabView}>
                                <Box className={styles.tabStyle} sx={{ width: '100%' }} p={0}>
                                    <Box className={styles.boxTab} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs className={styles.tabList} value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab className={styles.tabName} label="Account" {...a11yProps(0)} />
                                            <Tab className={styles.tabName} label="Language & Timezone" {...a11yProps(1)} />
                                            <Tab className={styles.tabName} label="Card Style" {...a11yProps(2)} />
                                            <Tab className={styles.tabName} label="Type of Communication" {...a11yProps(3)} />
                                            <Tab className={styles.tabName} label="Notifications" {...a11yProps(4)} />
                                            <Tab className={styles.tabName} label="About Us" {...a11yProps(5)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel className={styles.tabContent} value={value} index={0}>
                                        <Row>
                                            <Col md={12}>
                                                <Row className={styles.accountVeryfi}>
                                                    <Col md={4} className={styles.FormGroup}>
                                                        <label>Mobile Number (Edit your secondary mobile number)</label>
                                                        <button className={styles.verifyBtn}>Verify</button>
                                                        <img src={veryfiIcon} alt="" />
                                                        <InputField
                                                            className={styles.formControl}
                                                            name="alternateNo"
                                                            maxLength="10"
                                                            disabled={disablePhone}
                                                            placeholder="Enter your secondary mobile no."
                                                            onChange={(e) => disableMobileEmail("mobile", e.target.value)}
                                                            register={register}
                                                            validation={{
                                                                required: 'Required',
                                                                maxLength: {
                                                                    value: 10,
                                                                    message: "Maximum allowed numbers 10"
                                                                },
                                                                minLength: {
                                                                    value: 10,
                                                                    message: "Phone number should be 10"
                                                                },
                                                                pattern: {
                                                                    value: /^[6-9]\d{9}$/,
                                                                    message: 'Enter valid phone number'
                                                                }

                                                            }}
                                                            error={errors?.alternateNo}
                                                        />
                                                    </Col>
                                                    <Col md={4} className={styles.FormGroup}>
                                                        <label>Email Address (Edit your Email Id)</label>
                                                        <button className={styles.verifyBtn}>Verify</button>
                                                        <img src={veryfiIcon} alt="" />
                                                        <InputField
                                                            className={styles.formControl}
                                                            name="companyEmail"
                                                            disabled={disableEmail}
                                                            placeholder="Enter your email address"
                                                            onChange={(e) => disableMobileEmail("email", e.target.value)}
                                                            type="email"
                                                            register={register}
                                                            validation={{
                                                                required: 'Required',
                                                                pattern: {
                                                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                                    message: 'Enter valid mail id'
                                                                }
                                                            }}
                                                            error={errors?.companyEmail}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className={styles.deactiveAccount}>
                                                    <Col md={12}>
                                                        <div className={styles.deactiCont}>
                                                            <h3>Deactivate Account</h3>
                                                            <p>Your Jobsgaar account will disable your profile and remove your jobs/profile form Jobsgaar.</p>
                                                            <div className={styles.btnReset} onClick={() => setIsOpenDeactivateAccount(true)}>
                                                                <div className={styles.effReset}></div>
                                                                <button>Deactivate<span></span></button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                    <TabPanel className={styles.tabContent} value={value} index={1}>
                                        <Row className={styles.marBottom}>
                                            <Col md={12}>
                                                <Row className={styles.languageSelect}>
                                                    <Col md={4} className={styles.FormGroup}>
                                                        <label>Your Language</label>
                                                        <Controller
                                                            name="language"
                                                            control={control}
                                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                <Dropdown
                                                                    name="language"
                                                                    options={language}
                                                                    placeholder="Select your language"
                                                                    className={styles.formControlselect}
                                                                    onChange={(e) => onChange(handleDropdownDefault('language', e))}
                                                                    isSearchable={true}
                                                                    error={errors?.language}
                                                                    value={dropDownDefaultValues.language}
                                                                />
                                                            )}
                                                            onFocus={errors.language?.ref}
                                                        // rules={{
                                                        //     required: true
                                                        // }}
                                                        />
                                                    </Col>
                                                    <Col md={4} className={styles.FormGroup}>
                                                        <label>Your Timezone</label>
                                                        <Controller
                                                            name="timeZone"
                                                            control={control}
                                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                <Dropdown
                                                                    name="timeZone"
                                                                    options={timezoneOptions}
                                                                    placeholder="Select your timezone"
                                                                    className={styles.formControlselect}
                                                                    onChange={(e) => onChange(handleDropdownDefault('timeZone', e))}
                                                                    isSearchable={true}
                                                                    error={errors?.timeZone}
                                                                    value={dropDownDefaultValues.timeZone}
                                                                />
                                                            )}
                                                            onFocus={errors.timeZone?.ref}
                                                        // rules={{
                                                        //     required: true
                                                        // }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                    <TabPanel className={styles.tabContent} value={value} index={2}>
                                        <Row className={styles.cardandNumeric}>
                                            <Col md={12}>
                                                <div className={styles.tabCon}>
                                                    <Row>
                                                        <Col md={3}>
                                                            <div className={styles.cardStyle}>
                                                                <ul>
                                                                    <li>
                                                                        {cardsTheme === 1 && <img className={styles.checkList} src={checkBos} alt="" />}
                                                                        <a
                                                                            className={`${styles.cardView} ${cardsTheme === 1 ? styles.cardViewSelected : ""}`}
                                                                            onClick={() => setCardsTheme(1)}>
                                                                            <img src={cardStyle} alt="" />
                                                                            Card View
                                                                        </a>
                                                                    </li>
                                                                    <li className={styles.mrTop}>
                                                                        {cardsTheme === 0 && <img className={styles.checkList} src={checkBos} alt="" />}
                                                                        <a
                                                                            className={`${styles.numericView} ${cardsTheme === 0 ? styles.numericViewSelected : ""}`}
                                                                            onClick={() => setCardsTheme(0)}>
                                                                            <img src={Newmeric} alt="" />
                                                                            Numerical View
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                        <Col md={9}>
                                                            <div className={styles.cardCont}>
                                                                <h3>{cardsTheme === 0 ? 'Numerical Preview' : cardsTheme === 1 ? 'Card Preview' : ""}</h3>
                                                                <div className={styles.cardCdr}>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><img src={cardsTheme === 0 ? NumericHover : cardsTheme === 1 ? cardHover : ""} alt="" /></td>
                                                                                <td className={styles.profileName}>
                                                                                    <img src={demoImage} alt="" />
                                                                                    <h3><span>Name</span>John Doe</h3>
                                                                                </td>
                                                                                <td>
                                                                                    <h3><span>City</span>Arkala</h3>
                                                                                </td>
                                                                                <td>
                                                                                    <h3><span>Education</span>Whitebridge</h3>
                                                                                </td>
                                                                                <td>
                                                                                    <h3><span>Occupation</span>Teacher</h3>
                                                                                </td>

                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                    <TabPanel className={styles.tabContent} value={value} index={3}>
                                        <Row>
                                            <Col md={12}>
                                                <div className={styles.typeCommuni}>
                                                    <div className={styles.commiList}>
                                                        <a
                                                            onClick={() => handleCommunication('immediateJob')}
                                                            className={communication.immediateJob ? styles.communicationSelected : ""}>
                                                            {communication.immediateJob && <img className={styles.checkList} src={checkBos} alt="" />}
                                                            Actively looking for Immediate Candidate
                                                        </a>
                                                        <p>Select this option if you want to search for a candidate that is actively looking for a job now.<br /> We will match you with candidates that can start work immediately. </p>
                                                    </div>
                                                    <div className={styles.commiList}>
                                                        <a
                                                            onClick={() => handleCommunication('openForJob')}
                                                            className={communication.openForJob ? styles.communicationSelected : ""}>
                                                            {communication.openForJob && <img className={styles.checkList} src={checkBos} alt="" />}
                                                            Open to <br />hire New Candidate
                                                        </a>
                                                        <p>Select this option if you want to hire those talented candidates, that might start work for you, for the<br /> right offer. Those will not be immediate candidate, but chance to hire is high.</p>
                                                    </div>
                                                    <div className={styles.commiList}>
                                                        <a
                                                            onClick={() => handleCommunication('suspend')}
                                                            className={communication.suspend ? styles.communicationSelected : ""}>
                                                            {communication.suspend && <img className={styles.checkList} src={checkBos} alt="" />}
                                                            Suspend all notifications <br />for me</a>
                                                        <p>Mark this if you want to shut off all notifications. You will not receive any new notifications on any<br /> platform (No WhatsApp, No in App Notification and No Emails). </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                    <TabPanel className={styles.tabContent} value={value} index={4}>
                                        <Row>
                                            <Col md={12}>
                                                <div className={styles.notification}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className={styles.notList}>
                                                                {appNotification.whatsapp && <img className={styles.checkList} src={checkBos} alt="" />}
                                                                <a
                                                                    className={`${styles.whatsApp} ${appNotification.whatsapp ? styles.whatsAppSelected : ""}`}
                                                                    onClick={() => handleAppNotification('whatsapp')}>
                                                                    <img src={whatsApp} alt="" />
                                                                    WhatsApp
                                                                </a>
                                                                <p>Notification of new job, interviews, and other status that you post on the app.</p>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className={styles.notList}>
                                                                {appNotification.mobileApp && <img className={styles.checkList} src={checkBos} alt="" />}
                                                                <a
                                                                    className={`${styles.mobielApp} ${appNotification.mobileApp ? styles.mobielAppSelected : ""}`}
                                                                    onClick={() => handleAppNotification('mobileApp')}>
                                                                    <img src={mobile} alt="" />
                                                                    Mobile App
                                                                </a>
                                                                <p>Push notifications for more engage with us so you won’t miss any opportunity</p>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className={styles.notList}>
                                                                {appNotification.email && <img className={styles.checkList} src={checkBos} alt="" />}
                                                                <a
                                                                    className={`${styles.EmailApp} ${appNotification.email ? styles.EmailAppSelected : ""}`}
                                                                    onClick={() => handleAppNotification('email')}>
                                                                    <img src={email} alt="" />
                                                                    Email
                                                                </a>
                                                                <p>Information about any changes or updates to a job or work, any other features, or scheduled interview, etc</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                    <TabPanel className={styles.tabContent} value={value} index={5}>
                                        <Row>
                                            <Col md={12}>
                                                <div className={styles.legelPost}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className={styles.aboutTab}>
                                                                <a href="https://www.jobsgaar.com/about.php" target="_blank" rel="noreferrer">About Jobsgaar <img src={rightArrow} alt="" /></a>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className={styles.aboutTab}>
                                                                <a href="https://www.jobsgaar.com/privacy" target="_blank" rel="noreferrer">Privacy Policy <img src={rightArrow} alt="" /></a>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className={styles.aboutTab}>
                                                                <a href="https://www.jobsgaar.com/terms&condtion" target="_blank" rel="noreferrer">Terms and Conditions <img src={rightArrow} alt="" /></a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                </Box>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className={styles.btnManage}>
                                <div className={styles.btnNew}>
                                    <div className={styles.btnCancel} onClick={() => navigate(-1)}>
                                        <div className={styles.effNew}></div>
                                        <button>Cancel <span></span></button>
                                    </div>
                                    <div className={styles.btnReset} onClick={() => handleReset()}>
                                        <div className={styles.effReset}></div>
                                        <button>Reset<span></span></button>
                                    </div>
                                </div>
                                <div className={styles.btnSunmit} onClick={handleSubmit(submit)}>
                                    <div className={styles.effSubmit}></div>
                                    <button>Submit<span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Activelylooking
                        isOpen={isOpenCommunicationOption}
                        handleClose={() => setIsOpenCommunicationOption(false)}
                        matchJobBy={matchJobBy}
                        setMatchJob={setMatchJobBy}
                        jobNotification={jobNotification}
                        setNotification={handleJobNotification}
                        alertInterval={alertInterval}
                        setInterval={handleAlertInterval}
                        title={communicationTitle}
                        handleCancel={() => setData('communication')}
                    />
                    <SelectTimeDay
                        isOpen={isopenTimeDay}
                        handleCancel={() => setData('timeSchedule')}
                        handleTime={handleTime}
                        timeSchedule={timeSchedule}
                        handleworkingDay={handleworkingDay}
                        handleClose={() => setIsOpenTimeDay(false)}
                    />
                    <SuspendNotification
                        isOpen={isOpenSuspendNotification}
                        handleApply={handleSuspendNotification}
                        handleClose={() => setIsOpenSuspendNotification(false)}
                        communication={communication}
                    />
                    <DeactivateAccount
                        isOpen={isOpenDeactivateAccount}
                        handleClose={() => setIsOpenDeactivateAccount(false)}
                    />
                    {verifyPopup && <VerifyEmailPhone
                        isOpen={verifyPopup}
                        userData={userData}
                        fullName={userData.fullName ? userData.fullName : ""}
                        userId={userData._id ? userData._id : ""}
                        verifyOption={!disableEmail ? getValues("companyEmail") : getValues("alternateNo")}
                        newEmail={getValues("companyEmail")}
                        newPhone={getValues("alternateNo")}
                        checkUserverified={checkUserverified}
                        handleClose={() => handleVerifyPopup()}
                        sentOTP={sentOTP}
                    />}
                </Container>
            </section>
        </HomeLayout>
    );
}







import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './SubHeader.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, /**faList,*/ faFilter, faCalendar } from '@fortawesome/free-solid-svg-icons';
import CardView from '../../../assets/images/card-view.svg';
import CardViewOpen from '../../../assets/images/card-view-open.svg';
import ListView from '../../../assets/images/list-view.svg';
import ListViewOpen from '../../../assets/images/list-view-open.svg';

import { useState } from 'react';
import DatePicker from 'react-date-picker';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';


const SubHeader = ({
    handleType,
    count,
    handleFilter,
    searchFilter
}) => {
    const { pathname } = useLocation();

    const [startDate, setStartDate] = useState(new Date());
    const [viewType, setViewType] = useState('listView');
    const tooltiptext = "Hit enter key after typing, to search OR click again the search icon";
    const [open, setOpen] = React.useState(false);

    const handleViewType = (data) => {
        setViewType(data);
        handleType(data);
    }
    function capitalizeFirstLetter(string) {
        return string[0].toUpperCase() + string.slice(1);
    }
    let pageName = pathname.split("/");
    let pageType = capitalizeFirstLetter(pageName[1]);
    let currentTab = pageName[2] === 'matched' ? 'Applied' : capitalizeFirstLetter(pageName[2]);
    const searchHandler = (e) => {
        if (e.target.value.length !== 0) {
            handleTooltipOpen(true);
        } else {
            handleTooltipOpen(false);
        }
        searchFilter(e)
    }
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = (val) => {
        setOpen(val);
    };
    return (
        <Container fluid className={styles.subHeader}>
            <Row>
                <Col md={5}>
                    <div className={styles.jobInfo}>
                        <h3>{count ? count : 0} {currentTab} {pageType} <p>(*Click on {pageName[1]} for the details)</p></h3>
                    </div>
                </Col>
                <Col md={7} className='alignRight'>
                    <div className={styles.filterSection}>
                        <div className={styles.filter}>
                            <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} onClick={() => searchFilter({ target: { value: document.querySelector("#searchField").value }, key: "Enter" })} />
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <Tooltip PopperProps={{
                                    disablePortal: true,
                                }}
                                    onClose={handleTooltipClose}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={tooltiptext} placement="top">
                                    <input placeholder="Search here…" id="searchField" name='search' onKeyDown={e => searchFilter(e)} onChange={e => searchHandler(e)} />
                                </Tooltip>
                            </ClickAwayListener>
                        </div>
                        <ul>
                            <li onClick={() => handleViewType('cardView')}><img src={viewType === "listView" ? CardView : CardViewOpen} alt='' /></li>
                            <li onClick={() => handleViewType('listView')}><img src={viewType === "cardView" ? ListView : ListViewOpen} alt='' /></li>
                            <li onClick={handleFilter}><FontAwesomeIcon icon={faFilter} /><span>Filter</span></li>
                            <li className={styles.dateIcon}>
                                <FontAwesomeIcon icon={faCalendar} />
                                <DatePicker value={startDate} onChange={(date) => setStartDate(date)} format="d MMM y" calendarIcon={null} clearIcon={null} className={styles.datepicker} />
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default SubHeader;
// import { useState, useEffect } from "react";
import { Col, /**Container, FormGroup,*/ Row } from "react-bootstrap";
import styles from './ChangeNumberOTP.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';

import Popup from "../Popup";
// import { Dropdown } from "../../../common/Dropdown/Dropdown";
// import { useForm /** ,Controller */} from "react-hook-form";
import OtpInput from "otp-input-react";
// import InputField from "../../../common/InputField/InputField";
import * as React from 'react';
// import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import moment from "moment";
// import {
//     Role,
//     genderOption
// } from '../../../../common/DropdownOptions';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function ChangeNumberOTP() {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

//   const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm();

  return (
    <div>
       <Button onClick={handleOpen}>Open modal</Button>
          <Popup isOpen={open} isClose={handleClose}>
            <div className={styles.jobsFilter}>
                <img src={closeJob} onClick={handleClose} alt=''/>
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Change Phone Number<span></span></h3>
                            </div>
                        </Col>  
                    </Row> 
                    <Row className={styles.frmWidth}>
                        <Col md={12}>
                             <div className={styles.otpScreen}>
                                <h3>9999 2222 77</h3>
                                <p>Please enter the OTP, sent to your Phone Number</p>
                                <form className={styles.submitorm}>
                                    <div className={styles.inpOtp} >
                                        <OtpInput
                                            // value={otpNumber}
                                            // onChange={handleOtpChange}
                                            autoFocus
                                            OTPLength={6}
                                            otpType="number"
                                            disabled={false}
                                            placeholder="------"
                                        />
                                        {/* <span className='errorMessage mt-3'>Otp numer is required</span> */}
                                        <p>Didn’t get the OTP,<span><a className={styles.resendOtp}>Resend Otp</a></span></p>
                                    </div>
                                </form>
                             </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className={styles.textCenter}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnReset}>
                                    <div className={styles.effReset}></div>
                                    <button>Submit<span></span></button>
                                </div>
                            </div>  
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
        </div>
  );
}







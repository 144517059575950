// import { useState, useEffect } from "react";
// import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './InterviewAccepted.module.scss';
import interviewAccepted from '../../../../../assets/images/interviewDateTime.svg';


export default function InterviewAcceptedLargeRight(/**{
  children,
  isOpen,
  isClose
 }*/) {

  return (
    <div className={styles.paddRight}>
      <div className={styles.interviewAccepted}>
        <span tooltip="Interview Accepted" flow="left">
          <img src={interviewAccepted} alt='' />
        </span>
      </div>
    </div>
  );
}







import { Col, Container, /**FormGroup,*/ Row } from "react-bootstrap"
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import ChangeJobStatus from "../../common/ChangeJobStatus/ChangeJobStatus";
import newupdate from '../../../assets/images/newupdate.svg';
import croseImage from '../../../assets/images/croseIcon.svg';

import { Dropdown } from "../../common/Dropdown/Dropdown";
import styles from './EditJob.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../../../helpers";
import { updateJob, viewJob } from "../../../services/api/employer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm, Controller, /**set*/ } from "react-hook-form";
import InputField from "../../common/InputField/InputField";
import _ from "underscore";
import TextArea from "../../common/TextArea/TextArea";
import { fileUpload } from "../../../services/api/upload";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import moment from "moment";
import SaveJob from '../SaveJob/SaveJob';
import GoogleAutoComplete from '../../common/GoogleAutoComplete/GoogleAutoComplete'

import {
    jobTypeOption,
    genderOption,
    workingFromOption,
    workingShiftOption,
    languageOptions,
    incentivePeriodOption,
    JobOfferstaffNecessity
} from '../../../common/DropdownOptions';
import { getCity, getEducationList, getExperienceList, getPositionList, getSpecializationList } from "../../../services/api/common";
import SpecializationList from "../../common/Popup/SpecializationList/SpecializationList";


export default function EditJob() {
    const { id } = useParams();
    const { register, handleSubmit, formState: { errors }, /**getValues,*/ control, reset } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, getValues: getValues2, control: control2, reset: reset2, setValue: setValue2, clearErrors: clearErrors2 } = useForm();
    const { jobDegree, jobBranch, jobPosition } = getValues2();


    const navigate = useNavigate();

    const [fileKey, setFileKey] = useState();
    // const [fileUrl, setFileUrl] = useState();
    const [workingDays, setWorkingDays] = useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    });
    const [isIncentive, setIsIncentive] = useState(false);
    // const [incentiveData, setIncentiveData] = useState({});
    const [dropdownData, setDropdownData] = useState({});
    const [isJobCharge, setIsJobCharge] = useState(false);
    const [dropDownDefaultValues, setDropdownDefaultValues] = useState({});
    const [workingTime, setWorkingTime] = useState({});
    const [initialData, setInitialData] = useState();
    const [showSaveJob, setShowSaveJob] = useState(false);
    //2
    const [cityData, setCityData] = useState([]);
    const [experienceData, setExperienceData] = useState([]);
    const [educationData, setEducationData] = useState([]);
    const [degreeData, setDegreeData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [drivingLicense, setDrivingLicense] = useState({
        dl: false,
        ownBike: false,
        ownCar: false
    });
    const [fileName, setFileName] = useState();
    const [positionData, setPositionData] = useState([]);
    const [specializationData, setSpecializationData] = useState([]);
    const [jobSpecialization, setJobSepecialization] = useState(null);
    const [jobSpecializationDefaultValue, setSpecializationDefaultValue] = useState([]);
    const [fromAgeData, setFromAgeData] = useState([]);
    const [toAgeData, setToAgeData] = useState([]);
    const [fromSalaryData, setFromSalaryData] = useState([]);
    const [toSalaryData, setToSalaryData] = useState([]);
    // const [degreeBranchDefaultValue, setDegreeBranchDefaultValue] = useState({});
    const [isOpenSpecialization, setIsOpenSpecialization] = useState(false);
    // const [isOpenChoosePlan, SetIsOpenChoosePlan] = useState(false);
    // const [isJobPosted, setIsJobPosted] = useState(false);
    const [officeAddress, setOfficeAddress] = useState("")
    const [place, setPlace] = useState({});
    const [isAddrEnter, setIsAddrEnter] = useState(false);
    const [jobstatus, setJobStatus] = useState("")

    useEffect(() => {
        getJob(id);
    }, [id]);

    const getJob = async (id) => {
        try {
            showLoader();
            let params = {
                id
            }
            const {
                data: {
                    data /** ,message*/
                }
            } = await viewJob(params);
            hideLoader();
            // toast.success(message ? message : "Success")
            // reset(data);
            setJobStatus(data?.jobStatus);
            if (data?.jobStatus === "Pending") {
                getCityList();
                getExperience();
                getEducation();
                getJobPosition();
                getAgeData();
                getSalaryData();
            }
            setOfficeAddress(data?.jobAddress)
            setInitialData(data);
            setData(data);

        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const setData = (data) => {
        let dataObj = data;
        const { drivingLicense, /**jobAddress,**/ jobAge, jobCharges,
            jobType, /**jobVacancy,*/ jobLanguage, workingFrom, jobShift, workingTime,
          /**jobDescription,*/ workingDays, jobIncentive, jobCity, jobSalary, position,
            positionId, /**positionUid,*/ specializations, /**jobTitle,*/ staffNecessity, jobGender, jobStatus,
            jobExperience, /**jobExperienceYears,*/ jobEducation, /**jobEducationSort,*/ jobDegree, jobBranch, welcomeVideo, jobSalaryMax } = data;
        setWorkingDays(workingDays);
        jobIncentive?.monthly > 0 || jobIncentive?.oneTime > 0 ? setIsIncentive(true) : setIsIncentive(false)
        jobCharges[0]?.oneTimeCharge > 0 || jobCharges[0]?.recursiveCharge > 0 ? setIsJobCharge(true) : setIsJobCharge(false);

        if (jobStatus !== 'Pending') {
            setDropdownDefaultValues({
                ...dropDownDefaultValues,
                jobType: {
                    label: jobType === 'FullTime' ? 'Full Time' : 'Part Time',
                    value: jobType
                },
                jobLanguage: {
                    label: jobLanguage,
                    value: jobLanguage
                },
                workingFrom: {
                    label: workingFrom,
                    value: workingFrom
                },
                jobShift: {
                    label: jobShift,
                    value: jobShift
                },
                jobIncentivePeriod: {
                    label: jobIncentive?.monthly > 0 ? 'Monthly' : 'One Time',
                    value: jobIncentive?.monthly > 0 ? 'monthly' : 'oneTime'
                }
            });

            dataObj.timeFrom = workingTime?.from;
            dataObj.timeTo = workingTime?.to;
            dataObj.jobIncentiveAmount = jobIncentive?.monthly > 0 ? jobIncentive.monthly : jobIncentive?.oneTime;
            dataObj.oneTimeCharge = jobCharges[0]?.oneTimeCharge;
            dataObj.recursiveCharge = jobCharges[0]?.recursiveCharge;
            setWorkingTime({ timeFrom: moment(moment(workingTime?.from, "hh:mm a")).format("h") });
            reset(dataObj);
        } else if (jobStatus === 'Pending') {
            //all fields
            setDropdownDefaultValues({
                // ...dropDownDefaultValues,
                jobType: {
                    label: jobType === 'FullTime' ? 'Full Time' : 'Part Time',
                    value: jobType
                },
                jobLanguage: {
                    label: jobLanguage,
                    value: jobLanguage
                },
                workingFrom: {
                    label: workingFrom,
                    value: workingFrom
                },
                jobShift: {
                    label: jobShift,
                    value: jobShift
                },
                jobIncentivePeriod: {
                    label: jobIncentive?.monthly > 0 ? 'Monthly' : 'One Time',
                    value: jobIncentive?.monthly > 0 ? 'monthly' : 'oneTime'
                },
                jobCity: {
                    label: jobCity,
                    value: jobCity
                },
                minSalary: {
                    label: jobSalary,
                    value: jobSalary
                },
                jobPosition: {
                    label: position,
                    value: position
                },
                staffNecessity: {
                    label: staffNecessity,
                    value: staffNecessity
                },
                jobGender: {
                    label: jobGender,
                    value: jobGender
                },
                fromAge: {
                    label: jobAge?.fromAge,
                    value: jobAge?.fromAge
                },
                toAge: {
                    label: jobAge?.toAge,
                    value: jobAge?.toAge
                },
                jobExperience: {
                    label: jobExperience,
                    value: jobExperience
                },
                jobEducation: {
                    label: jobEducation,
                    value: jobEducation
                },
                jobDegree: {
                    label: jobDegree ? jobDegree : null,
                    value: jobDegree ? jobDegree : null
                },
                jobBranch: {
                    label: jobBranch ? jobBranch : null,
                    value: jobBranch ? jobBranch : null
                },
                jobIncentivePeriod: {
                    label: jobIncentive?.monthly > 0 ? 'Monthly' : jobIncentive?.oneTime ? 'One Time' : "",
                    value: jobIncentive?.monthly > 0 ? 'monthly' : jobIncentive?.oneTime ? 'oneTime' : "",
                },
                maxSalary: {
                    label: jobSalaryMax,
                    value: jobSalaryMax
                },
            });
            let obj = {};
            if (jobEducation) {
                obj.jobEducation = jobEducation;
            }
            if (jobDegree) {
                obj.jobDegree = jobDegree;
            }
            if (jobBranch) {
                obj.jobBranch = jobBranch;
            }
            setDropdownData(obj);

            // dataObj.jobCity = jobCity;
            // dataObj.jobType = jobType;
            // dataObj.jobTitle = jobTitle;
            dataObj.minSalary = jobSalary;
            dataObj.maxSalary = jobSalaryMax;
            dataObj.jobPosition = position;
            dataObj.jobPositionId = positionId;
            // dataObj.positionUid = positionUid;
            // dataObj.jobVacancy = jobVacancy;
            // dataObj.jobGender = jobGender;
            dataObj.fromAge = jobAge?.fromAge;
            dataObj.toAge = jobAge?.toAge;
            // dataObj.workingFrom = workingFrom;
            // dataObj.jobShift = jobShift;

            dataObj.timeFrom = workingTime?.from;
            dataObj.timeTo = workingTime?.to;
            // dataObj.jobExperience = jobExperience;
            // dataObj.jobExperienceYears = jobExperienceYears;
            // dataObj.jobLanguage = jobLanguage;
            // dataObj.jobEducation = jobEducation;
            // dataObj.jobEducationSort = jobEducationSort;

            dataObj.jobIncentiveAmount = jobIncentive?.monthly > 0 ? jobIncentive.monthly : jobIncentive?.oneTime > 0 ? jobIncentive?.oneTime : "";
            dataObj.oneTimeCharge = jobCharges[0]?.oneTimeCharge;
            dataObj.recursiveCharge = jobCharges[0]?.recursiveCharge;
            reset2(dataObj);
            if (welcomeVideo) {
                setFileName(welcomeVideo);
                setFileKey(welcomeVideo);
            }
            setDrivingLicense(drivingLicense);
            setWorkingTime({ timeFrom: moment(moment(workingTime?.from, "hh:mm a")).format("h") });

            getJobSpecializtion(positionId, specializations);
            handleSpecialization(specializations.map((item) => { return { value: item, isSelected: true } }));
            getAgeData(jobAge?.fromAge);
            getSalaryData(jobSalary);
        }


    }

    const handleFileUpload = async ({ target: { files } }) => {
        try {
            showLoader();
            let content = new FormData();
            content.append('fileKey', files[0]);
            const {
                data: {
                    data
                } } = await fileUpload(content);
            setFileKey(data?.fileKey);
            setFileName(data?.fileName);
            hideLoader();

        } catch (error) {
            toast.error("Failed");
            hideLoader();
        }
    }

    const clearFileUpload = () => {
        setFileKey("");
        setFileName("");
        document.getElementById("video").value = null;
    }

    const handleworkingDay = (day) => {
        setWorkingDays({
            ...workingDays,
            [day]: !workingDays[day]
        });
    }

    const handleDropdown = (name, value) => {
        setDropdownData({
            ...dropdownData,
            [name]: value
        });

        // if (name === 'jobDegree' || name === 'jobBranch') {
        //     setDropdownDefaultValues({
        //         ...dropDownDefaultValues,
        //         [name]: {
        //             label: value,
        //             value: value
        //         }
        //     })
        // } else {
        if (name === 'fromAge') {
            getAgeData(value);
        } else if (name === 'minSalary') {
            getSalaryData(value);
        }
        setDropdownDefaultValues({
            ...dropDownDefaultValues,
            [name]: {
                label: value,
                value: value
            }
        });
        // }
        return value;
    }

    // const handleIncentive = () => {
    //     let value = getValues.jobIncentiveAmount;
    //     setIncentiveData({
    //         [dropdownData.jobIncentivePeriod]: value
    //     })
    // }

    const handleDropdownDefault = (name, data) => {
        setDropdownDefaultValues({
            ...dropDownDefaultValues,
            [name]: data
        });
        return data?.value;
    }

    const handleTime = (name, value) => {
        if (value) {
            setWorkingTime({
                ...workingTime,
                [name]: moment(value).format("h")
            })
            return moment(value).format("hh:mm a");
        }
    }

    const handleTimeDisable = (timeValue, clockType) => {
        if (clockType === 'hours' && timeValue <= workingTime.timeFrom) {
            return true;
        }
    }

    const resetForm = () => {
        showLoader();
        setData(initialData);
        if (initialData?.jobStatus === "Pending") { document.getElementById("jobTitle").scrollIntoView() } else { document.getElementById("jobVacancy").scrollIntoView(); }
        // setTimeout(() => {
        // }, 1000);
        setTimeout(() => {
            hideLoader();
        }, 300);
    }

    const submit = async (values) => {
        try {
            if (!officeAddress) {
                setShowSaveJob(!showSaveJob);
                setIsAddrEnter(true)
            } else {
                setIsAddrEnter(false)
                showLoader();
                let params = {
                    jobId: id
                }
                let incentiveContent = {};
                if (isIncentive && dropdownData.jobIncentivePeriod && values.jobIncentiveAmount) {
                    incentiveContent = {
                        [dropdownData.jobIncentivePeriod]: values.jobIncentiveAmount
                    }
                    values.jobIncentive = incentiveContent;
                }
                // else {
                //     values.jobIncentive = {
                //         monthly: 0,
                //         oneTime: 0
                //     };
                // }
                values.jobCharges = [
                    {
                        oneTimeCharge: isJobCharge && values.oneTimeCharge ? values.oneTimeCharge : 0,
                        recursiveCharge: isJobCharge && values.recursiveCharge ? values.recursiveCharge : 0
                    }
                ];
                values.workingTime = {
                    from: values?.timeFrom ?? "",
                    to: values?.timeTo ?? ""
                };
                values.workingDays = workingDays;
                values.jobAddress = officeAddress;
                values.jobGPSCoordinates = {
                    lat: Object.keys(place).length !== 0 && place.lat ? place.lat : 0,
                    lng: Object.keys(place).length !== 0 && place.lng ? place.lng : 0
                }
                const {
                    data: {
                        message
                    }
                } = await updateJob(values, params);
                hideLoader();
                setShowSaveJob(!showSaveJob);
                toast.success(message ? message : "Updated successfully")
            }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }

    }

    //all fields

    const getCityList = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getCity();
            setCityData(
                data && data.length > 0 && data.map(({ stateName, districtName }) => {
                    let data = districtName.map((item) => { return { label: item, value: item, state: stateName } });
                    return {
                        label: stateName,
                        options: data
                    }
                })
            );
        } catch (error) {
        }
    }

    const getExperience = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getExperienceList();
            setExperienceData(
                data && data.length > 0 && data.map(({ experience, experienceYears }) => {
                    return {
                        label: experience,
                        value: experienceYears
                    }
                })
            );
        } catch (error) {
        }
    }

    const getEducation = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getEducationList();
            let educationGroup = data && _.groupBy(data, 'education');
            let forDegree = [], forBranch = [];
            for (let [key, value] of Object.entries(educationGroup)) {
                if (value && value.length > 0) {
                    forDegree[key] = value.map((item) => {
                        if (item.degree) {
                            if (item.branch && item.branch.length > 0) {
                                // forBranch[item.degree] = item.branch;
                                let key = item.degree;
                                forBranch.push({ [key]: item.branch })
                            }
                            return {
                                label: item.degree,
                                value: item.degree
                            }

                        } else {
                            return null;
                        }

                    });
                }
            }
            let formattedBranch = []
            forBranch && forBranch.length > 0 && forBranch.map((item) => {
                for (let [key, value] of Object.entries(item)) {
                    if (value && value.length > 0) {
                        formattedBranch[key] = value.map((item) => {
                            return {
                                label: item,
                                value: item
                            }
                        });
                    }
                }

            });

            let educationContent = data && data.length > 0 && data.map(({ education, educationSort }) => {
                return {
                    label: education,
                    value: educationSort
                }
            });
            educationContent = educationContent.reduce((unique, o) => {
                if (!unique.some(obj => obj.label === o.label)) {
                    unique.push(o);
                }
                return unique;
            }, []);

            setEducationData(educationContent);
            setDegreeData(forDegree);
            setBranchData(formattedBranch);
        } catch (error) {
        }
    }

    const getJobPosition = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getPositionList();
            setPositionData(
                data && data.length > 0 && data.map(({ position, positionId, positionUid }) => {
                    return {
                        label: position,
                        value: positionId,
                        positionUid
                    }
                })
            );
        } catch (error) {
        }
    }

    const getJobSpecializtion = async (positionId, specializations) => {
        try {
            let params = {
                positionId
            }
            const {
                data: {
                    data
                }
            } = await getSpecializationList(params);
            if (specializations?.length > 0) {
                // setSpecializationData(
                let array = [];
                data && data[0] && data[0].specialization && data[0].specialization.length > 0 && data[0].specialization.map((item) => {
                    return (
                        specializations.map((data) => {
                            if (data === item) {
                                array.push({
                                    label: item,
                                    value: item,
                                    isSelected: true
                                });
                            } else {
                                array.push({
                                    label: item,
                                    value: item,
                                    isSelected: false
                                });
                            }
                        })
                    )
                })
                setSpecializationData(array);
                // );
            } else {
                setSpecializationData(
                    data && data[0] && data[0].specialization && data[0].specialization.length > 0 && data[0].specialization.map((item) => {
                        return {
                            label: item,
                            value: item,
                            isSelected: false
                        }
                    })
                );
            }

        } catch (error) {
        }
    }

    const handleSpecialization = (data) => {
        setSpecializationDefaultValue(data);
        let values = [];
        if (data && data.length > 0) {
            let array = data.filter(i => i.isSelected === true);
            values = array.map((item) => {
                return item.value;
            });
            // values = data.filter(function(i) {
            //     if(i.isSelected === true) {
            //         return i.value;
            //     }
            // });
        }
        setJobSepecialization(values)

        setValue2('jobSpecialization', values);
    }

    const getAgeData = (age) => {
        let data = [];
        let startingAge = age ? age + 1 : 18;
        for (let i = startingAge; i <= 62; i++) {
            let obj = {
                label: i,
                value: i
            }
            data.push(obj);
        }
        if (age) {
            setToAgeData(data);
        } else {
            setFromAgeData(data);
            setToAgeData(data);
        }
    }

    const getSalaryData = (salary) => {
        let data = [];
        let startingSalary = salary ? salary + 500 : 5000;
        for (let i = startingSalary; i <= 150000; i += 500) {
            let obj = {
                label: i,
                value: i
            }
            data.push(obj);
        }
        if (salary) {
            setToSalaryData(data);
        } else {
            setFromSalaryData(data);
            setToSalaryData(data);
        }
    }

    const handleCity = (city, state, e) => {
        setValue2('jobCity', city);
        setValue2('jobState', state);
        handleDropdownDefault('jobCity', e);
    }

    const handleJobPosition = ({ label, value, positionUid }) => {
        if (getValues2('jobPositionId') === value) {
            setIsOpenSpecialization(!isOpenSpecialization);
        } else {
            handleDropdown('jobPosition', label);
            setValue2('jobPosition', label);
            setValue2('jobPositionId', value);
            setValue2('positionUid', positionUid);
            setValue2('jobSpecialization', []);
            // setJobPositionId(value);
            setJobSepecialization([]);
            getJobSpecializtion(value, []);
            setSpecializationDefaultValue([]);
            handleDropdownDefault('jobPosition', { label, value });
            setTimeout(() => {
                setIsOpenSpecialization(!isOpenSpecialization);
            }, 1000);
        }

    }

    const removeSpecialization = (index, item) => {
        if (index > -1) {
            jobSpecialization.splice(index, 1);
            setJobSepecialization([...jobSpecialization]);
            jobSpecializationDefaultValue.map(i => i.value === item ? i.isSelected = false : i);
            // jobSpecializationDefaultValue[index]['isSelected'] = false;
            setSpecializationDefaultValue([...jobSpecializationDefaultValue]);
        }
    }

    const handleMultipleOptions = (name, { label, value }) => {
        if (name === "jobExperience") {
            setValue2('jobExperience', label);
            setValue2('jobExperienceYears', value);
            handleDropdownDefault('jobExperience', { label, value });
        } else if (name === "jobEducation") {
            setValue2('jobEducation', label);
            setValue2('jobEducationSort', value)
            setDropdownData({
                ...dropdownData,
                [name]: label,
                'jobBranch': "",
                'jobDegree': ""
            });
            setValue2('jobDegree', '');
            setValue2('jobBranch', '');
            // setDropdownData({
            //     ...dropdownData,
            //     'jobDegree': "",
            //     'jobBranch': ''
            // });
            setDropdownDefaultValues({
                ...dropDownDefaultValues,
                jobEducation: {
                    label,
                    value: label
                },
                jobDegree: null,
                jobBranch: null
            });
            clearErrors2("jobBranch");
        }
    }

    const handleDriving = (name, value) => {
        setDrivingLicense({
            ...drivingLicense,
            [name]: !value
        })
        return drivingLicense;
    }

    const handleSave = () => {
        setShowSaveJob(!showSaveJob);
    }


    const submit2 = async (values) => {
        try {
            if (!officeAddress) {
                setShowSaveJob(!showSaveJob);
                setIsAddrEnter(true)
            } else {
                setIsAddrEnter(false)
                // showLoader();
                let params = {
                    jobId: id
                }

                const { jobTitle, jobAddress, jobVacancy, jobDescription, jobContactPerson, jobIncentiveAmount, jobCity, jobType, jobShift, workingFrom, minSalary, maxSalary, jobGender, fromAge, toAge, jobLanguage, jobExperience, jobEducation, jobDegree, jobBranch, jobPosition, timeFrom, timeTo, oneTimeCharge, recursiveCharge, jobPositionId, positionUid, jobEducationSort, jobExperienceYears, jobSpecialization, staffNecessity, planStatus, jobState, employerId, employerName, jobPostedBy } = values;
                let incentiveContent = {};
                if (isIncentive && dropdownData.jobIncentivePeriod && jobIncentiveAmount) {
                    incentiveContent = {
                        [dropdownData.jobIncentivePeriod]: jobIncentiveAmount
                    }
                }
                let submitData = {
                    employerId,
                    employerName,
                    jobCity: jobCity,
                    jobType: jobType,
                    jobTitle: jobTitle,
                    position: jobPosition,
                    positionId: jobPositionId,
                    positionUid: positionUid,
                    specializations: jobSpecialization,
                    jobSalary: minSalary,
                    jobSalaryMax: maxSalary,
                    jobVacancy: jobVacancy,
                    jobGender: jobGender,
                    jobAge: {
                        fromAge: fromAge ? fromAge : "",
                        toAge: toAge ? toAge : ""
                    },
                    workingFrom: workingFrom,
                    jobShift: jobShift,
                    workingTime: {
                        from: timeFrom ? timeFrom : "",
                        to: timeTo ? timeTo : ""
                    },
                    jobExperience: jobExperience,
                    jobExperienceYears: jobExperienceYears,
                    jobLanguage: jobLanguage,
                    workingDays: workingDays,
                    jobEducation: jobEducation,
                    jobEducationSort: jobEducationSort,
                    jobDegree: jobDegree,
                    jobBranch: jobBranch,
                    jobAddress: officeAddress,
                    jobGPSCoordinates: {
                        lat: Object.keys(place).length !== 0 && place.lat ? place.lat : 0,
                        lng: Object.keys(place).length !== 0 && place.lng ? place.lng : 0
                    },
                    jobDescription: jobDescription,
                    jobContactPerson: jobContactPerson,
                    welcomeVideo: fileKey,
                    drivingLicense: drivingLicense,
                    jobIncentive: incentiveContent,
                    jobCharges: [
                        {
                            oneTimeCharge: oneTimeCharge ? oneTimeCharge : "",
                            recursiveCharge: recursiveCharge ? recursiveCharge : ""
                        }
                    ],
                    companyAddressOnInvoice: " ",
                    companyCityOnInvoice: " ",
                    companyNameOnInvoice: " ",
                    companyZipOnInvoice: " ",
                    jobPlan: 15,
                    jobState,
                    certificateDiploma: false,
                    certificateDiplomaName: "",
                    gstOnInvoice: "",
                    mobileOnInvoice: "",
                    planStatus,
                    jobURL: "",
                    jobEmail: "",
                    jobCompanyProof: "",
                    jobPostedBy,
                    workingComments: "",
                    callVia: {
                        phone: false,
                        whatsapp: false,
                        appPhone: false,
                        dontcall: false,
                        appVideo: false
                    },
                    jobStatusDescription: "",
                    jobFrom: "dashboard",
                    salaryCurrency: "INR",
                    jobValid: true,
                    // jobExpiredDate: moment().add(7, 'days').format('YYYY-MM-DD'),
                    jobImage: [],
                    staffNecessity: staffNecessity
                }

                const {
                    data: {
                    /**data,*/ message
                    }
                } = await updateJob(submitData, params);
                hideLoader();
                setShowSaveJob(!showSaveJob);
                toast.success(message ? message : "Updated successfully");
                getJob(id);
            }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }

    }

    // const handleAmount = (e, state = null, setValue = null) => {
    //     //console.log("e.value=======>", e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    //     if (/^[0-9,]*$/.test(e.target.value)) {
    //         if (setValue) {
    //             setValue(e.target.name, e.target.value.replace(",", ""))
    //         }
    //         if (e.target.name && state) {
    //             state(e.target.value.replace(",", ""))
    //         }
    //     } else {
    //     }
    // }

    return (
        <HomeLayout>
            <section className={styles.mainNew}>
                <Container fluid className="p-0">
                    <Row className="mb-3">
                        <Col md={12} >
                            <div className={styles.jobPostHead}>
                                <h3>Edit the Job Post</h3>
                            </div>
                        </Col>
                    </Row>
                    <div className={styles.content}>
                        <Row>
                            <Col md={12}>
                                <div className={styles.editjobHead}>
                                    <h3><span>{moment(initialData?.createdAt).format("DD MMM' YY")} </span>{initialData?.jobTitle}</h3>
                                </div>
                                <ChangeJobStatus jobStatus={initialData?.jobStatus} jobId={initialData?._id} fetchJobs={() => getJob(id)} />
                            </Col>
                            {initialData?.jobStatus === "Pending" ? (
                                <>
                                    <Col md={12} className={styles.addHead}>
                                        <Row className={styles.marNew}>
                                            <h3>Step 1 Job Details</h3>
                                            <Row className={styles.formBg}>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>City</label>
                                                    <Controller
                                                        name="jobCity"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                isSearchable={true}
                                                                options={cityData}
                                                                placeholder="Select your city/district"
                                                                className={styles.formControl}
                                                                onChange={(e) => handleCity(e.value, e.state, e)}
                                                                error={errors2?.jobCity}
                                                                inputRef={ref}
                                                                value={dropDownDefaultValues.jobCity}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors2.jobCity?.ref}
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Job Type</label>
                                                    <Controller
                                                        name="jobType"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                isSearchable={true}
                                                                options={jobTypeOption}
                                                                placeholder="Choose your job type"
                                                                className={styles.formControl}
                                                                onChange={(e) => onChange(handleDropdownDefault('jobType', e))}
                                                                error={errors2?.jobType}
                                                                inputRef={ref}
                                                                value={dropDownDefaultValues.jobType}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors2.jobType?.ref}
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Job Name</label>
                                                    <InputField
                                                        name="jobTitle"
                                                        type="text"
                                                        placeholder="Enter the job name"
                                                        register={register2}
                                                        validation={{
                                                            required: 'Required',
                                                            pattern: {
                                                                value: /^[A-Za-z ]+$/,
                                                                message: 'Job name should contain only alphabets and spaces'
                                                            }
                                                        }}
                                                        error={errors2?.jobTitle}
                                                        id="jobTitle"
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Salary (By Month)</label>
                                                    <div className={styles.miniDropdown}>
                                                        <Controller className={styles.salDrop}
                                                            name="minSalary"
                                                            control={control2}
                                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                <Dropdown
                                                                    options={fromSalaryData}
                                                                    placeholder="Min salary"
                                                                    className={styles.formControl}
                                                                    onChange={(e) => onChange(handleDropdown('minSalary', e.value))}
                                                                    error={errors2?.minSalary}
                                                                    inputRef={ref}
                                                                    value={dropDownDefaultValues.minSalary}
                                                                />
                                                            )}
                                                            rules={{ required: 'Required' }}
                                                            onFocus={errors2.minSalary?.ref}
                                                        />
                                                        <Controller className={styles.salDrop}
                                                            name="maxSalary"
                                                            control={control}
                                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                <Dropdown
                                                                    name="maxSalary"
                                                                    options={toSalaryData}
                                                                    placeholder="Max salary"
                                                                    className={styles.formControl}
                                                                    onChange={(e) => onChange(e.value)}
                                                                    error={errors2?.maxSalary}
                                                                    inputRef={ref}
                                                                    isOptionDisabled={(option) => (option.value <= dropdownData.minSalary)}
                                                                    value={dropDownDefaultValues.maxSalary}
                                                                />
                                                            )}
                                                            rules={{ required: 'Required' }}
                                                            onFocus={errors2.maxSalary?.ref}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={8} className={styles.FormGroup}>
                                                    <label>Job Position/Designation</label>
                                                    <Controller
                                                        name="jobPosition"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                isSearchable={true}
                                                                options={positionData}
                                                                placeholder="Choose your job position"
                                                                className={styles.formControl}
                                                                onChange={(e) => handleJobPosition(e)}
                                                                error={errors2?.jobPosition}
                                                                inputRef={ref}
                                                                value={dropDownDefaultValues.jobPosition}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors2.jobPosition?.ref}
                                                    />
                                                </Col>
                                                <Col md={12} className={styles.FormGroup}>
                                                    {jobSpecialization && jobSpecialization.length > 0 && <div className={styles.specialBtn}>
                                                        <label>Specialization</label>
                                                        {jobSpecialization.map((item, index) => {
                                                            return (
                                                                <span key={index}>{item}<img onClick={() => removeSpecialization(index, item)} src={croseImage} alt="" /></span>
                                                            )
                                                        })}
                                                    </div>}
                                                </Col>
                                            </Row>
                                        </Row>

                                        <Row className={styles.marNew}>
                                            <h3>Step 2 Requirements</h3>
                                            <Row className={styles.formBg}>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Number of Vacancy</label>
                                                    <InputField
                                                        name="jobVacancy"
                                                        placeholder="How many candidate you want"
                                                        register={register2}
                                                        valueAsNumber={{
                                                            valueAsNumber: true
                                                        }}
                                                        validation={{
                                                            required: 'Required',
                                                            pattern: {
                                                                value: /^[0-9]*$/,
                                                                message: 'vacancy should contain only numbers'
                                                            },
                                                            maxLength: {
                                                                value: 3,
                                                                message: "Maximum length was 3"
                                                            }
                                                        }}
                                                        error={errors2?.jobVacancy}
                                                        disabled={jobstatus === "CustomQuote" ? true : false}
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>How soon you require staff?</label>
                                                    <Controller
                                                        name="staffNecessity"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                isSearchable={true}
                                                                options={JobOfferstaffNecessity}
                                                                placeholder="Choose your requirement"
                                                                className={styles.formControl}
                                                                onChange={(e) => onChange(handleDropdownDefault('staffNecessity', e))}
                                                                error={errors2?.staffNecessity}
                                                                inputRef={ref}
                                                                value={dropDownDefaultValues.staffNecessity}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors2.staffNecessity?.ref}
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Gender</label>
                                                    <Controller
                                                        name="jobGender"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                isSearchable={true}
                                                                options={genderOption}
                                                                placeholder="Choose candidate gender"
                                                                className={styles.formControl}
                                                                onChange={(e) => onChange(handleDropdownDefault('jobGender', e))}
                                                                error={errors2?.jobGender}
                                                                inputRef={ref}
                                                                value={dropDownDefaultValues.jobGender}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors2.jobGender?.ref}
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Age</label>
                                                    <div className={styles.miniDropdown}>
                                                        <Controller
                                                            name="fromAge"
                                                            control={control2}
                                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                <Dropdown
                                                                    name="fromAge"
                                                                    options={fromAgeData}
                                                                    placeholder="From"
                                                                    className={styles.formControl}
                                                                    onChange={(e) => onChange(handleDropdown('fromAge', e.value).toString())}
                                                                    error={errors2?.fromAge}
                                                                    inputRef={ref}
                                                                    value={dropDownDefaultValues.fromAge}
                                                                />
                                                            )}
                                                            rules={{ required: 'Required' }}
                                                            onFocus={errors2.fromAge?.ref}
                                                        />
                                                        <Controller
                                                            name="toAge"
                                                            control={control2}
                                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                                <Dropdown
                                                                    name="toAge"
                                                                    options={toAgeData}
                                                                    placeholder="To"
                                                                    className={styles.formControl}
                                                                    onChange={(e) => onChange(handleDropdownDefault('toAge', e).toString())}
                                                                    error={errors2?.toAge}
                                                                    inputRef={ref}
                                                                    isOptionDisabled={(option) => option.value <= dropDownDefaultValues.fromAge}
                                                                    value={dropDownDefaultValues.toAge}
                                                                />
                                                            )}
                                                            rules={{ required: 'Required' }}
                                                            onFocus={errors2.toAge?.ref}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Working from</label>
                                                    <Controller
                                                        name="workingFrom"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                options={workingFromOption}
                                                                placeholder="Choose work from"
                                                                className={styles.formControl}
                                                                onChange={(e) => onChange(handleDropdownDefault('workingFrom', e))}
                                                                error={errors2?.workingFrom}
                                                                inputRef={ref}
                                                                value={dropDownDefaultValues.workingFrom}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors2.workingFrom?.ref}
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Working shift</label>
                                                    <Controller
                                                        name="jobShift"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                options={workingShiftOption}
                                                                placeholder="Choose working shift"
                                                                className={styles.formControl}
                                                                onChange={(e) => onChange(handleDropdownDefault('jobShift', e))}
                                                                error={errors2?.jobShift}
                                                                inputRef={ref}
                                                                value={dropDownDefaultValues.jobShift}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors2.jobShift?.ref}
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Working time</label>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className={styles.timePicker}>
                                                                {/* <div> */}
                                                                <Controller
                                                                    className={styles.newWidth}
                                                                    name="timeFrom"
                                                                    ampmInClock
                                                                    control={control2}
                                                                    render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                            <TimePicker
                                                                                // placeholder="From"
                                                                                label="From"
                                                                                value={moment(value, "hh:mm a")}
                                                                                onChange={(newValue) => {
                                                                                    onChange(handleTime('timeFrom', newValue));
                                                                                }}
                                                                                renderInput={(params) => <TextField {...params} />}
                                                                                inputRef={ref}
                                                                            />
                                                                        </LocalizationProvider>

                                                                    )}
                                                                    rules={{ required: 'Required' }}
                                                                    onFocus={errors2.timeFrom?.ref}
                                                                />
                                                                {errors !== undefined && errors2.timeFrom && (
                                                                    <span className="errorMessage">{errors2.timeFrom.message}</span>
                                                                )}
                                                            </div>

                                                        </Col>
                                                        <Col md={6}>

                                                            <div className={styles.timePicker}>
                                                                <Controller
                                                                    className={styles.newWidth}
                                                                    name="timeTo"
                                                                    control={control2}
                                                                    render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                                            <TimePicker
                                                                                label="To"
                                                                                // placeholder="To"
                                                                                value={moment(value, "hh:mm a")}
                                                                                onChange={(newValue) => {
                                                                                    onChange(moment(newValue).format("hh:mm a"));
                                                                                }}
                                                                                renderInput={(params) => <TextField {...params} />}
                                                                                inputRef={ref}
                                                                                shouldDisableTime={handleTimeDisable}
                                                                            />
                                                                        </LocalizationProvider>

                                                                    )}
                                                                    rules={{ required: 'Required' }}
                                                                    onFocus={errors2.timeTo?.ref}
                                                                />
                                                                {errors !== undefined && errors2.timeTo && (
                                                                    <span className="errorMessage">{errors2.timeTo.message}</span>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Required Experience</label>
                                                    <Controller
                                                        name="jobExperience"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                options={experienceData}
                                                                placeholder="Choose min & max exp."
                                                                className={styles.formControl}
                                                                onChange={(e) => handleMultipleOptions('jobExperience', e)}
                                                                error={errors2?.jobExperience}
                                                                inputRef={ref}
                                                                value={dropDownDefaultValues.jobExperience}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors2.jobExperience?.ref}
                                                    />
                                                </Col>

                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Your Preferred Language</label>
                                                    <Controller
                                                        name="jobLanguage"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                options={languageOptions}
                                                                placeholder="Choose language"
                                                                className={styles.formControl}
                                                                onChange={(e) => onChange(handleDropdownDefault('jobLanguage', e))}
                                                                error={errors2?.jobLanguage}
                                                                inputRef={ref}
                                                                value={dropDownDefaultValues.jobLanguage}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors2.jobLanguage?.ref}
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Working Day</label>
                                                    <button className={workingDays.monday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('monday')}>Mon</button>
                                                    <button className={workingDays.tuesday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('tuesday')}>Tue</button>
                                                    <button className={workingDays.wednesday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('wednesday')}>Wed</button>
                                                    <button className={workingDays.thursday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('thursday')}>Thu</button>
                                                    <button className={workingDays.friday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('friday')}>Fri</button>
                                                    <button className={workingDays.saturday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('saturday')}>Sat</button>
                                                    <button className={workingDays.sunday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('sunday')}>Sun</button>
                                                </Col>
                                            </Row>
                                        </Row>


                                        <Row className={styles.marNew}>
                                            <h3>Step 3 Education</h3>
                                            <Row className={styles.formBg}>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Education</label>
                                                    <Controller
                                                        name="jobEducation"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                options={educationData}
                                                                placeholder="Choose the education"
                                                                className={styles.formControl}
                                                                onChange={(e) => handleMultipleOptions('jobEducation', e)}
                                                                error={errors2?.jobEducation}
                                                                inputRef={ref}
                                                                value={dropDownDefaultValues.jobEducation}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors2.jobEducation?.ref}
                                                    />
                                                </Col>
                                                {degreeData[dropdownData.jobEducation] && degreeData[dropdownData.jobEducation][0] !== null && <Col md={4} className={styles.FormGroup}>
                                                    <label>Degree</label>
                                                    <Controller
                                                        name="jobDegree"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                options={degreeData[dropdownData.jobEducation] && degreeData[dropdownData.jobEducation].length > 1 ? degreeData[dropdownData.jobEducation] : []}
                                                                placeholder="Choose the degree"
                                                                className={styles.formControl}
                                                                onChange={(e) => onChange(handleDropdown('jobDegree', e.value))}
                                                                isSearchable={true}
                                                                value={dropDownDefaultValues.jobDegree}
                                                            />
                                                        )}
                                                        onFocus={errors2.jobDegree?.ref}
                                                    />
                                                </Col>}
                                                {branchData[dropdownData.jobDegree] && <Col md={4} className={styles.FormGroup}>
                                                    <label>Branch/Stream</label>
                                                    <Controller
                                                        name="jobBranch"
                                                        control={control2}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <Dropdown
                                                                name="jobBranch"
                                                                options={branchData[dropdownData.jobDegree] ? branchData[dropdownData.jobDegree] : []}
                                                                placeholder="Choose the branch/stream"
                                                                className={styles.formControl}
                                                                onChange={(e) => onChange(handleDropdown('jobBranch', e.value))}
                                                                isSearchable={true}
                                                                error={errors2?.jobBranch}
                                                                value={dropDownDefaultValues.jobBranch}
                                                            />
                                                        )}
                                                        onFocus={errors2.jobBranch?.ref}
                                                        rules={{
                                                            required: (jobDegree && !jobBranch) ? true : false
                                                        }}
                                                    />
                                                </Col>}

                                            </Row>
                                        </Row>

                                        <Row className={styles.marNew}>
                                            <h3>Step 4 Other Details</h3>
                                            <Row className={styles.formBg}>
                                                <Col md={12} className={styles.FormGroup}>
                                                    <label>Address (Office Location)</label>
                                                    <GoogleAutoComplete inputName="companyAddress" setOfficeAddress={setOfficeAddress}
                                                        inputVal={officeAddress} setPlace={setPlace} styles={styles.formControl} />
                                                    {isAddrEnter && <span className="errorMessage">Required</span>}
                                                    {/* <TextArea
                                                        name="jobAddress"
                                                        placeholder="Office Address"
                                                        register={register2}
                                                        validation={{
                                                            required: 'Required',
                                                        }}
                                                        error={errors2?.jobAddress}
                                                        maxLength='100'
                                                        value={initialData?.jobAddress}
                                                    /> */}
                                                </Col>
                                                <Col md={12} className={styles.FormGroup}>
                                                    <label>Job Description</label>
                                                    <TextArea
                                                        name="jobDescription"
                                                        placeholder="Job Description"
                                                        register={register2}
                                                        validation={{
                                                            required: 'Required',
                                                        }}
                                                        error={errors2?.jobDescription}
                                                        maxLength='300'
                                                        value={initialData?.jobDescription}
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Contact Person</label>
                                                    <InputField
                                                        name="jobContactPerson"
                                                        type="text"
                                                        placeholder="Enter the representer’s name"
                                                        register={register2}
                                                        validation={{
                                                            required: 'Required',
                                                            pattern: {
                                                                value: /^[A-Za-z ]+$/,
                                                                message: 'Job name should contain only alphabets and spaces'
                                                            }
                                                        }}
                                                        error={errors2?.jobContactPerson}
                                                    />
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Job Video</label>
                                                    <div className={styles.videoUpload}>
                                                        {!fileName ? (<span className={styles.noVideo}>
                                                            Upload your video here
                                                        </span>) : (
                                                            <span className={`${styles.videoUp} ${initialData?.jobStatus === "Pending" ? styles.longVideoName : ""}`}>
                                                                {fileName}  <FontAwesomeIcon icon={faX} onClick={clearFileUpload} />
                                                            </span>)}
                                                        <div className={styles.fileUpload}>
                                                            <input
                                                                type="file"
                                                                onChange={handleFileUpload}
                                                                accept="video/mp4"
                                                                placeholder="Upload your video here"
                                                                id="video"
                                                            />
                                                            <img src={newupdate} alt="" />

                                                            {/* <FontAwesomeIcon icon={faFileUpload} /> */}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <label>Driving Requirement</label>
                                                    <div className={styles.newSelect}>
                                                        <ul>
                                                            <li>
                                                                <label htmlFor="dl">Candidate should have driving license</label>
                                                                <label className={styles.checkbox}>
                                                                    <input type="checkbox" name="dl" id="dl" onChange={() => handleDriving('dl', drivingLicense.dl)} checked={drivingLicense.dl} className={styles.checkboxInput} />
                                                                    <span className={styles.checkboxInner} />
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="ownBike">Candidate should have a motorcycle</label>
                                                                <label className={styles.checkbox}>
                                                                    <input type="checkbox" name="ownBike" id="ownBike" onChange={() => handleDriving('ownBike', drivingLicense.ownBike)} checked={drivingLicense.ownBike} className={styles.checkboxInput} />
                                                                    <span className={styles.checkboxInner} />
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor="ownCar">Candidate should have a car</label>
                                                                <label className={styles.checkbox}>
                                                                    <input type="checkbox" name="ownCar" id="ownCar" onChange={() => handleDriving('ownCar', drivingLicense.ownCar)} checked={drivingLicense.ownCar} className={styles.checkboxInput} />
                                                                    <span className={styles.checkboxInner} />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                                <Col md={4} className={styles.FormGroup}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <label>Yes we are providing an incentive</label>
                                                            <div className={styles.radioButton}>
                                                                <ul>
                                                                    <li>
                                                                        <input type="radio" name="yesIncentive" id="yesIncentive" onChange={() => setIsIncentive(!isIncentive)} checked={isIncentive} />
                                                                        <label htmlFor="yesIncentive">Yes</label>
                                                                        <div className={styles.checkNew}></div>
                                                                    </li>
                                                                    <li>
                                                                        <input
                                                                            type="radio"
                                                                            name="noIncentive"
                                                                            id="noIncentive"
                                                                            onChange={() => setIsIncentive(!isIncentive)}
                                                                            // defaultChecked={!isIncentive}
                                                                            checked={!isIncentive}
                                                                        />
                                                                        <label htmlFor="noIncentive">No</label>
                                                                        <div className={styles.checkNew}></div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                        {isIncentive && <Col md={12}>
                                                            <div className={styles.bgInc}>
                                                                <label>Tell us your incentive time and amount </label>
                                                                <Dropdown
                                                                    name="jobIncentivePeriod"
                                                                    options={incentivePeriodOption}
                                                                    placeholder="Select"
                                                                    className={styles.formControl}
                                                                    onChange={(e) => handleDropdown('jobIncentivePeriod', e.value)}
                                                                    value={dropDownDefaultValues.jobIncentivePeriod}
                                                                />
                                                                <InputField
                                                                    name="jobIncentiveAmount"
                                                                    placeholder="Amount"
                                                                    register={register2}
                                                                    validation={{
                                                                        required: isIncentive ? 'Required' : '',
                                                                        pattern: {
                                                                            value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                            message: 'Incentive should contain only numbers'
                                                                        }
                                                                    }}
                                                                    error={errors2?.jobIncentiveAmount}
                                                                // onChange={handleIncentive}
                                                                />
                                                            </div>
                                                        </Col>}
                                                    </Row>
                                                </Col>
                                                <Col md={8} className={styles.FormGroup}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <label>Any Deposit or charge</label>
                                                            <div className={styles.radioButton}>
                                                                <ul>
                                                                    <li>
                                                                        <input
                                                                            type="radio"
                                                                            name="yesCharge"
                                                                            id="yesCharge"
                                                                            onChange={() => setIsJobCharge(!isJobCharge)}
                                                                            checked={isJobCharge}
                                                                        />
                                                                        <label htmlFor="yesCharge">Yes</label>
                                                                        <div className={styles.checkNew}></div>
                                                                    </li>
                                                                    <li>
                                                                        <input
                                                                            type="radio"
                                                                            name="noCharge"
                                                                            id="noCharge"
                                                                            onChange={() => setIsJobCharge(!isJobCharge)}
                                                                            // defaultChecked={!isJobCharge}
                                                                            checked={!isJobCharge}
                                                                        />
                                                                        <label htmlFor="noCharge">No</label>
                                                                        <div className={styles.checkNew}><div className={styles.inside}></div></div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                        {isJobCharge && <Col md={12}>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className={styles.bgInc}>
                                                                        <label>Charging as One Time Fee (Optional) </label>
                                                                        <InputField
                                                                            name="oneTimeChargeDescription"
                                                                            type="text"
                                                                            placeholder="Description"
                                                                            register={register2}
                                                                        />
                                                                        <InputField
                                                                            name="oneTimeCharge"
                                                                            placeholder="Amount"
                                                                            register={register2}
                                                                            validation={{
                                                                                required: isJobCharge ? 'Required' : '',
                                                                                pattern: {
                                                                                    value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                                    message: 'Charge should contain only numbers'
                                                                                }
                                                                            }}
                                                                            error={errors2?.oneTimeCharge}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className={styles.bgInc}>
                                                                        <label>Charging as Monthly Fee (Optional)</label>
                                                                        <InputField
                                                                            name="recursiveChargeDescription"
                                                                            type="text"
                                                                            placeholder="Office transport"
                                                                            register={register2}
                                                                        />
                                                                        <InputField
                                                                            name="recursiveCharge"
                                                                            placeholder="Amount"
                                                                            register={register2}
                                                                            validation={{
                                                                                required: isJobCharge ? 'Required' : '',
                                                                                pattern: {
                                                                                    value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                                    message: 'Charge should contain only numbers'
                                                                                }
                                                                            }}
                                                                            error={errors2?.recursiveCharge}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>}
                                                    </Row>
                                                </Col>

                                            </Row>
                                        </Row>


                                    </Col>
                                    <SpecializationList
                                        isOpen={isOpenSpecialization}
                                        data={specializationData}
                                        jobPosition={jobPosition}
                                        handleClose={() => setIsOpenSpecialization(!isOpenSpecialization)}
                                        handleSpecialization={handleSpecialization}
                                    />
                                </>
                            ) : (
                                <Col md={12} className={styles.formScroll}>
                                    <Row className={styles.formBg}>
                                        <Col md={4} className={styles.FormGroup}>
                                            <label>Job Type</label>
                                            <Controller
                                                name="jobType"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <Dropdown
                                                        isSearchable={true}
                                                        options={jobTypeOption}
                                                        placeholder="Choose your job type"
                                                        className={styles.formControl}
                                                        onChange={(e) => onChange(handleDropdownDefault('jobType', e))}
                                                        error={errors?.jobType}
                                                        inputRef={ref}
                                                        value={dropDownDefaultValues.jobType}
                                                    />
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.jobType?.ref}
                                            />
                                        </Col>
                                        <Col md={4} className={styles.FormGroup}>
                                            <label>Number of Vacancy</label>
                                            <InputField
                                                name="jobVacancy"
                                                placeholder="How many candidate you want"
                                                register={register}
                                                valueAsNumber={{
                                                    valueAsNumber: true
                                                }}
                                                validation={{
                                                    required: 'Required',
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: 'vacancy should contain only numbers'
                                                    }
                                                }}
                                                error={errors?.jobVacancy}
                                                id="jobVacancy"
                                                disabled={jobstatus === "CustomQuote" ? true : false}
                                            />
                                        </Col>
                                        <Col md={4} className={styles.FormGroup}>
                                            <label>Your Preferred Language</label>
                                            <Controller
                                                name="jobLanguage"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <Dropdown
                                                        options={languageOptions}
                                                        placeholder="Choose language"
                                                        className={styles.formControl}
                                                        onChange={(e) => onChange(handleDropdownDefault('jobLanguage', e))}
                                                        error={errors?.jobLanguage}
                                                        inputRef={ref}
                                                        value={dropDownDefaultValues.jobLanguage}
                                                    />
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.jobLanguage?.ref}
                                            />
                                        </Col>
                                        <Col md={4} className={styles.FormGroup}>
                                            <label>Working from</label>
                                            <Controller
                                                name="workingFrom"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <Dropdown
                                                        options={workingFromOption}
                                                        placeholder="Choose work from"
                                                        className={styles.formControl}
                                                        onChange={(e) => onChange(handleDropdownDefault('workingFrom', e))}
                                                        error={errors?.workingFrom}
                                                        inputRef={ref}
                                                        value={dropDownDefaultValues.workingFrom}
                                                    />
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.workingFrom?.ref}
                                            />
                                        </Col>
                                        <Col md={4} className={styles.FormGroup}>
                                            <label>Working shift</label>
                                            <Controller
                                                name="jobShift"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <Dropdown
                                                        options={workingShiftOption}
                                                        placeholder="Choose working shift"
                                                        className={styles.formControl}
                                                        onChange={(e) => onChange(handleDropdownDefault('jobShift', e))}
                                                        error={errors?.jobShift}
                                                        inputRef={ref}
                                                        value={dropDownDefaultValues.jobShift}
                                                    />
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.jobShift?.ref}
                                            />
                                        </Col>
                                        <Col md={4} className={styles.FormGroup}>
                                            <label>Working time</label>
                                            {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                            <Row>
                                                <Col md={6}>
                                                    <div className={styles.timePicker}>
                                                        <Controller
                                                            name="timeFrom"
                                                            control={control}
                                                            render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <TimePicker
                                                                        label="From"
                                                                        value={moment(value, "hh:mm a")}
                                                                        onChange={(newValue) => {
                                                                            onChange(handleTime('timeFrom', newValue));
                                                                        }}
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                        inputRef={ref}
                                                                    />
                                                                </LocalizationProvider>
                                                            )}
                                                            rules={{ required: 'Required' }}
                                                            onFocus={errors.timeFrom?.ref}
                                                        />
                                                        {errors !== undefined && errors.timeFrom && (
                                                            <span className="errorMessage">{errors.timeFrom.message}</span>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className={styles.timePicker}>
                                                        <Controller
                                                            name="timeTo"
                                                            control={control}
                                                            render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <TimePicker
                                                                        label="To"
                                                                        value={moment(value, "hh:mm a")}
                                                                        onChange={(newValue) => {
                                                                            onChange(moment(newValue).format("hh:mm a"));
                                                                        }}
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                        inputRef={ref}
                                                                        shouldDisableTime={handleTimeDisable}
                                                                    />
                                                                </LocalizationProvider>
                                                            )}
                                                            rules={{ required: 'Required' }}
                                                            onFocus={errors.timeTo?.ref}
                                                        />
                                                        {errors !== undefined && errors.timeTo && (
                                                            <span className="errorMessage">{errors.timeTo.message}</span>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* </LocalizationProvider> */}

                                        </Col>

                                        <Col md={12} className={styles.FormGroup}>
                                            <label>Address (Office Location)</label>
                                            <GoogleAutoComplete inputName="companyAddress" setOfficeAddress={setOfficeAddress}
                                                inputVal={officeAddress} setPlace={setPlace} styles={styles.formControl} />
                                            {isAddrEnter && <span className="errorMessage">Required</span>}
                                            {/* <TextArea
                                                name="jobAddress"
                                                placeholder="Office Address"
                                                register={register}
                                                validation={{
                                                    required: 'Required',
                                                }}
                                                error={errors?.jobAddress}
                                                maxLength='100'
                                                value={initialData?.jobAddress}
                                            /> */}
                                        </Col>
                                        <Col md={12} className={styles.FormGroup}>
                                            <label>Job Description</label>
                                            <TextArea
                                                name="jobDescription"
                                                placeholder="Job Description"
                                                register={register}
                                                validation={{
                                                    required: 'Required',
                                                }}
                                                error={errors?.jobDescription}
                                                maxLength='300'
                                                value={initialData?.jobDescription}
                                            />
                                        </Col>

                                        <Col md={4} className={styles.FormGroup}>
                                            <label>Job Video</label>
                                            <div className={styles.videoUpload}>
                                                <span>
                                                    {fileKey ? fileKey : 'Video not added'}
                                                    {fileKey && <FontAwesomeIcon icon={faX} onClick={clearFileUpload} />}
                                                </span>
                                                <div className={styles.fileUpload}>
                                                    <input
                                                        type="file"
                                                        onChange={handleFileUpload}
                                                        accept="video/*"
                                                        id="video"
                                                    />
                                                    <img src={newupdate} alt="" />

                                                    {/* <FontAwesomeIcon icon={faFileUpload} /> */}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={5} className={styles.FormGroup}>
                                            <label>Working Day</label>
                                            <button className={workingDays.monday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('monday')}>Mon</button>
                                            <button className={workingDays.tuesday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('tuesday')}>Tue</button>
                                            <button className={workingDays.wednesday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('wednesday')}>Wed</button>
                                            <button className={workingDays.thursday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('thursday')}>Thu</button>
                                            <button className={workingDays.friday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('friday')}>Fri</button>
                                            <button className={workingDays.saturday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('saturday')}>Sat</button>
                                            <button className={workingDays.sunday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('sunday')}>Sun</button>
                                        </Col>
                                        <Col md={3}></Col>

                                        <Col md={4} className={styles.FormGroup}>
                                            <Row>
                                                <Col md={12}>
                                                    <label>Yes we are providing an incentive</label>
                                                    <div className={styles.radioButton}>
                                                        <ul>
                                                            <li>
                                                                <input
                                                                    type="radio"
                                                                    name="yesIncentive"
                                                                    id="yesIncentive"
                                                                    onChange={() => setIsIncentive(!isIncentive)}
                                                                    checked={isIncentive}
                                                                />
                                                                <label htmlFor="yesIncentive">Yes</label>
                                                                <div className={styles.checkNew}></div>
                                                            </li>
                                                            <li>
                                                                <input
                                                                    type="radio"
                                                                    name="noIncentive"
                                                                    id="noIncentive"
                                                                    onChange={() => setIsIncentive(!isIncentive)}
                                                                    // defaultChecked={!isIncentive}
                                                                    checked={!isIncentive}
                                                                />
                                                                <label htmlFor="noIncentive">No</label>
                                                                <div className={styles.checkNew}></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                                {isIncentive && <Col md={12}>
                                                    <div className={styles.bgInc}>
                                                        <label>Tell us your incentive time and amount </label>
                                                        <Dropdown
                                                            name="jobIncentivePeriod"
                                                            options={incentivePeriodOption}
                                                            placeholder="Select"
                                                            className={styles.formControl}
                                                            onChange={(e) => handleDropdown('jobIncentivePeriod', e.value)}
                                                            value={dropDownDefaultValues.jobIncentivePeriod}
                                                        />
                                                        <InputField
                                                            name="jobIncentiveAmount"
                                                            placeholder="Amount"
                                                            register={register2}
                                                            validation={{
                                                                required: isIncentive ? 'Required' : '',
                                                                pattern: {
                                                                    value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                    message: 'Incentive should contain only numbers'
                                                                }
                                                            }}
                                                            error={errors2?.jobIncentiveAmount}
                                                        // onChange={handleIncentive}
                                                        />
                                                    </div>
                                                </Col>}
                                            </Row>
                                        </Col>
                                        <Col md={8} className={styles.FormGroup}>
                                            <Row>
                                                <Col md={6}>
                                                    <label>Any Deposit or charge</label>
                                                    <div className={styles.radioButton}>
                                                        <ul>
                                                            <li>
                                                                <input
                                                                    type="radio"
                                                                    name="yesCharge"
                                                                    id="yesCharge"
                                                                    onChange={() => setIsJobCharge(!isJobCharge)}
                                                                    checked={isJobCharge}
                                                                />
                                                                <label htmlFor="yesCharge">Yes</label>
                                                                <div className={styles.checkNew}></div>
                                                            </li>
                                                            <li>
                                                                <input
                                                                    type="radio"
                                                                    name="noCharge"
                                                                    id="noCharge"
                                                                    onChange={() => setIsJobCharge(!isJobCharge)}
                                                                    // defaultChecked={!isJobCharge}
                                                                    checked={!isJobCharge}
                                                                />
                                                                <label htmlFor="noCharge">No</label>
                                                                <div className={styles.checkNew}><div className={styles.inside}></div></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                                {isJobCharge && <Col md={12}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className={styles.bgInc}>
                                                                <label>Charging as One Time Fee (Optional) </label>
                                                                <InputField
                                                                    name="oneTimeChargeDescription"
                                                                    type="text"
                                                                    placeholder="Description"
                                                                    register={register}
                                                                />
                                                                <InputField
                                                                    name="oneTimeCharge"
                                                                    placeholder="Amount"
                                                                    register={register}
                                                                    validation={{
                                                                        required: isIncentive ? 'Required' : '',
                                                                        pattern: {
                                                                            value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                            message: 'Incentive should contain only numbers'
                                                                        }
                                                                    }}
                                                                    error={errors?.oneTimeCharge}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className={styles.bgInc}>
                                                                <label>Charging as Monthly Fee (Optional)</label>
                                                                <InputField
                                                                    name="recursiveChargeDescription"
                                                                    type="text"
                                                                    placeholder="Office transport"
                                                                    register={register}
                                                                />
                                                                <InputField
                                                                    name="recursiveCharge"
                                                                    placeholder="Amount"
                                                                    register={register}
                                                                    validation={{
                                                                        required: isIncentive ? 'Required' : '',
                                                                        pattern: {
                                                                            value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                            message: 'Incentive should contain only numbers'
                                                                        }
                                                                    }}
                                                                    error={errors?.recursiveCharge}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>}
                                            </Row>
                                        </Col>
                                    </Row>

                                </Col>
                            )}

                            <Row>
                                <Col md={6}>
                                    <div className={styles.btnNew}>
                                        <div className={styles.btnCancel} onClick={() => navigate(-1)}>
                                            <div className={styles.effNew}></div>
                                            <a>Cancel <span></span></a>
                                        </div>
                                        <div className={styles.btnReset} onClick={resetForm}>
                                            <div className={styles.effReset}></div>
                                            <a>Reset <span></span></a>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className={styles.btnOp}>
                                        <div className={styles.btnSave} onClick={initialData?.jobStatus === "Pending" ? handleSubmit2(handleSave) : handleSubmit(handleSave)}>
                                            <div className={styles.effSave}></div>
                                            <a>Save <span></span></a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Row>
                    </div>
                    <SaveJob isOpen={showSaveJob} handleClose={() => setShowSaveJob(!showSaveJob)} onClick={initialData?.jobStatus === "Pending" ? handleSubmit2(submit2) : handleSubmit(submit)} />

                </Container>
            </section>
        </HomeLayout>
    )
}
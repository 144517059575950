import { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './AllPostedJobs.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import filterImag from '../../../../assets/images/filterPop.svg';
import searchImag from '../../../../assets/images/searchicon.svg';
import leftArrow from '../../../../assets/images/arrowLeft.svg';
import rightArrow from '../../../../assets/images/arrowRight.svg';
// import SmarySec from '../../../../assets/images/attentionBg.svg';


import Popup from "../Popup";
// import { Controller, useForm } from "react-hook-form";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { TextField } from "@mui/material";
import InputField from '../../../common/InputField/InputField';
// import { Dropdown } from "../../../../common/Dropdown/Dropdown";
import { /**getLocalTime,*/ hideLoader, showLoader } from "../../../../helpers";
import moment from "moment";
import JobOfferJobFilter from "../JobOfferJobFilter/JobOfferJobFilter";
import { getAllJobs } from "../../../../services/api/employer";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function AllPostedJobs({
    isOpen,
    handleClose,
    cityData,
    handleJobData,
    setIsExistingJob
}) {

    const scrlRef = useRef(null);


    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [jobsData, setJobsData] = useState(false);
    const [resetData, setResetData] = useState([]);
    const [jobPosition, setJobPosition] = useState();
    const [jobItem, setJobItem] = useState({});
    const [isError, setIsError] = useState(false);

    const [scrollX, setscrollX] = useState(0);
    const [scrollEnd, setscrollEnd] = useState(false);
    const [isPositionFilter, setIsPositionFilter] = useState(false);

    useEffect(() => {
        const getJobsData = async () => {
            try {
                showLoader();
                let jobStatus = 'Live';
                let params = {
                    status: jobStatus
                };
                const {
                    data: {
                        data /** ,message*/
                    }
                } = await getAllJobs(params);
                setJobsData(data);
                // toast.success(message ? message: "success")
                hideLoader();
            } catch (error) {
                hideLoader();
                toast.error("Failed");
            }
        }
        getJobsData();
    }, []);

    const handleJobFilter = (position, education, date, city, experience) => {
        let searchObj = {};
        if (education?.length !== 0) {
            searchObj.jobEducation = education.value;
        }
        if (date) {
            searchObj.filterDate = date;
        }
        if (city?.length !== 0) {
            searchObj.jobCity = city.value;
        }
        if (experience?.length !== 0) {
            searchObj.jobExperience = experience.value;
        }
        if (position) {
            searchObj.position = position;
        }
        if (Object.keys(city).length === 0 && Object.keys(education).length === 0 && Object.keys(experience).length === 0 && !date && !position) {
            setIsPositionFilter(false);
            setJobPosition("");
            if (resetData?.length > 0) {
                setJobsData(resetData)
            }
        } else if (Object.keys(city).length === 0 && Object.keys(education).length === 0 && Object.keys(experience).length === 0 && !date && position) {
            setIsPositionFilter(true);
            // let toFilterData = [];
            // if (resetData?.length > 0) {
            //     toFilterData = resetData;
            //     toast.success("Success")
            // } else {
            //     toFilterData = jobsData;
            //     setResetData(jobsData);
            //     toast.error("No data found")
            // }
            let final = jobsData.filter(i => i.position.toLowerCase().includes(position));
            if (final.length > 0) {
                setResetData(jobsData);
                toast.success("Success")
            } else {
                setResetData(jobsData);
                toast.error("No data found")
            }


            setJobsData(final);
        } else {
            let toFilterData = [];
            if (resetData?.length > 0) {
                toFilterData = resetData;
            } else {
                toFilterData = jobsData;
                setResetData(jobsData);
            }
            toFilterData = toFilterData.map(({ ...item }) => ({ ...item, filterDate: (moment(item.postDateTime).format('YYYY-MM-DD')) }));
            let final = toFilterData.filter(function (item) {
                for (var key in searchObj) {
                    if (item[key] === undefined || searchObj[key] === undefined || item[key] !== searchObj[key])
                        return false;
                }
                return true;
            });
            setJobsData(final);
        }
    }

    const submit = () => {
        if (Object.keys(jobItem).length > 0) {
            setIsError(false);
            handleJobData(jobItem);
            setJobItem({});
            setJobsData("");
            setIsPositionFilter(false);
        } else {
            setIsError(true);
        }
    }

    const handleReset = () => {
        setJobItem({});
        setIsError(false);
        handleClose();
        setIsExistingJob(false)
    }

    const scroll = (shift) => {
        scrlRef.current.scrollLeft += shift;
        setscrollX(scrollX + shift);

        if (
            Math.floor(scrlRef.current.scrollWidth - scrlRef.current.scrollLeft) <=
            scrlRef.current.offsetWidth
        ) {
            setscrollEnd(true);
        } else {
            setscrollEnd(false);
        }
    };

    const scrollCheck = () => {
        setscrollX(scrlRef.current.scrollLeft);
        if (
            Math.floor(scrlRef.current.scrollWidth - scrlRef.current.scrollLeft) <=
            scrlRef.current.offsetWidth
        ) {
            setscrollEnd(true);
        } else {
            setscrollEnd(false);
        }
    };

    return (
        <Popup isOpen={isOpen}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleReset} alt='' />
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>All your posted jobs <span></span> </h3>
                                <p>Please select job position to send an offer</p>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={styles.filterSearch}>
                                {/* <input type="text" id="search-bar" placeholder="What can I help you with today?"> */}
                                <div className={styles.formGroup}>
                                    <InputField
                                        name="name"
                                        placeholder="Search job position"
                                        className={styles.formControl}
                                        onChange={(e) => setJobPosition(e.target.value)}
                                        value={jobPosition}
                                    // onKeyUp={}
                                    />
                                    {isPositionFilter ? (
                                        <FontAwesomeIcon icon={faX} onClick={() => handleJobFilter("", [], null, [], [])} />
                                    ) : (<img className={styles.searchIcon} src={searchImag} alt="" onClick={() => handleJobFilter(jobPosition, [], null, [], [])} />)}
                                </div>
                                <button className={styles.filterBtn} onClick={() => setIsOpenFilter(true)}><img src={filterImag} alt="" />Filter</button>
                            </div>
                        </Col>

                        <Col md={12}>
                            {jobsData?.length > 0 && <div className={styles.jobList}>
                                {scrollX !== 0 && <img className={styles.leftArrow} src={leftArrow} alt='' onClick={() => scroll(-100)} />}
                                <ul ref={scrlRef} onScroll={scrollCheck}>
                                    {jobsData?.length > 0 && (
                                        jobsData.map((item, index) => {
                                            const { position, postDateTime, jobTitle, jobCity, jobState, jobEducation, jobExperience } = item;
                                            return (
                                                <li className={`${jobItem?._id === item?._id ? styles.activeLi : ""}`} key={index} onClick={() => setJobItem(item)}>
                                                    <h2>{moment(postDateTime).format('DD MMM. YY')}</h2>
                                                    <h3>{position}</h3>
                                                    <p>{jobTitle}<span></span></p>
                                                    <h4>{jobCity} {jobState ? jobState : ""}</h4>
                                                    <h5>{jobEducation}</h5>
                                                    <h6>{jobExperience}</h6>
                                                </li>
                                            )
                                        })
                                    )
                                    }

                                </ul>
                                {!scrollEnd && <img className={styles.rightArrow} src={rightArrow} alt='' onClick={() => scroll(100)} />}
                            </div>}
                            {jobsData.length === 0 && <p>No jobs found</p>}
                            <JobOfferJobFilter
                                isOpen={isOpenFilter}
                                cityData={cityData}
                                handleJobFilter={handleJobFilter}
                                handleClose={() => setIsOpenFilter(false)}
                            />
                        </Col>

                        {isError && (
                            <p className="errorMessage alignCenter">Please select any job</p>
                        )}

                        <Col md={12}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnNo} onClick={handleReset}>
                                    <div className={styles.effNo}></div>
                                    <button>back<span></span></button>
                                </div>

                                <div className={styles.btnReset} onClick={submit}>
                                    <div className={styles.effReset}></div>
                                    <button>Select<span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}








import { React, useState } from 'react';
import styles from './JobCardView.module.scss';
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import JobExpandedView from '../JobExpandedView/JobExpandedView';
import { currencySymbol } from '../../../common/CurrencySymbol';

function JobCardView({
    data,
    fetchJobs = () => {
        return null;
    }
}) {

    const [isShowExpanded, setIsShowExpanded] = useState({});

    const collapseTrigger = (index) => {
        isShowExpanded[index] && isShowExpanded[index] == 'open' ? setIsShowExpanded({ [index]: 'closed' }) : setIsShowExpanded({ [index]: 'open' });
    }

    return (
        <div className={styles.content}>
            <Row className={styles.overflow}>
                {data && data.length > 0 && data.map((item, index) => {
                    let currency = currencySymbol[item?.salaryCurrency];
                    return (
                        isShowExpanded && isShowExpanded[index] === 'open' ? (
                            <JobExpandedView data={item} currency={currency} onClick={(e) => collapseTrigger(index)} isCard={true} fetchJobs={fetchJobs} />
                        ) : (
                            <Col md={4} className="pointer">
                                <div className={styles.card} onClick={() => collapseTrigger(index)}>
                                    <h3> {moment(item?.postDateTime).format("DD MMM'YY")}<span>Applied <span className={styles.applied}>{item?.appliedCount}</span></span></h3>
                                    <h2>{item?.position}</h2>
                                    <Row className={styles.cardData}>
                                        <Col md={6} className={styles.dataItm}>
                                            <span>Salary Range</span>
                                            <h3> {currency}{item?.jobSalary}- {currency}{item?.jobSalaryMax}</h3>
                                        </Col>
                                        <Col md={6} className={styles.dataItm}>
                                            <span>Experience </span>
                                            <h3>{item?.jobExperience}</h3>
                                        </Col>
                                        <Col md={6} className={styles.dataItm}>
                                            <span>Education</span>
                                            <h3>{item?.jobEducation}</h3>
                                        </Col>
                                        <Col md={6} className={styles.dataItm}>
                                            <span>City</span>
                                            <h3>{item?.jobCity}</h3>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        )

                    )

                })}
            </Row>
        </div>

    )
}
export default JobCardView;
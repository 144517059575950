import { React } from 'react';
import { Col,/** Container, FormGroup,*/ Row } from 'react-bootstrap';
import styles from './CandidateListView.module.scss';
// import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
// import Fade from '@mui/material/Fade';
// import Zoom from '@mui/material/Zoom';
// import RejectImage from '../../../assets/images/reject.svg';
// import ShortlistImage from '../../../assets/images/shortlist.svg';
// import RejectImage from '../../../assets/images/newReject.svg';
// import ShortlistImage from '../../../assets/images/shortlist.svg';

import downArrow from '../../../assets/images/downArrow.svg';
import defaultUser from '../../../assets/images/profile.svg';
// import proRank from '../../../assets/images/rankTen.svg';
// import videoIcon from '../../../assets/images/videoIcon.svg';
// import userClose from '../../../assets/images/close.svg';
// import userCheck from '../../../assets/images/check.svg';

// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleCheck, faCircleXmark, faPencil, faUser, faUsers, faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';

// import Typography from '@mui/material/Typography';

// import CustomPagination from '../../common/CustomPagination/CustomPagination';
// import { StylesContext } from '@material-ui/styles';
// import { style } from '@mui/system';
import CandidateExpandedView from '../CandidateExpandedView/CandidateExpandedVIew';
//import RejectResponse from '../RejectResponse/RejectResponse';
import { currencySymbol } from '../../../common/CurrencySymbol';
// import ScheduleInterview from '../../common/Popup/ScheduleInterview/ScheduleInterview';
// import JobOfferSummary from '../../common/Popup/JobOfferSummary/JobOfferSummary';
import moment from 'moment';
// import CandidatePopup from '../../common/Popup/CandidatePopup/CandidatePopup';
// import { toast } from 'react-toastify';
import { checkIsInterviewTimePassed, getLocalTimeFormatted } from '../../../helpers';
import WaitForCandidateListView from '../../common/Buttons/ScheduleButton/WaitForcandidate/WaitForCandidateListView';
//import WaitForCandidateCardView from '../../common/Buttons/ScheduleButton/WaitForcandidate/WaitForCandidateCardView';
import WaitForcandidateExpendedView from '../../common/Buttons/ScheduleButton/WaitForcandidate/WaitForcandidateExpendedView'
import InterviewAcceptedLargeRight from '../../common/Buttons/ScheduleButton/InterviewAccepted/InterviewAcceptedLargeRight';
import InterviewAcceptedExpend from '../../common/Buttons/ScheduleButton/InterviewAccepted/InterviewAcceptedExpend';
import CandidateCallLargeRight from '../../common/Buttons/ScheduleButton/CandidateCall/CandidateCallLargeRight';
import CandidateCallExpendRight from '../../common/Buttons/ScheduleButton/CandidateCall/CandidateCallExpendRight';
import InterviewCompletedLargeRight from '../../common/Buttons/ScheduleButton/InterviewCompleted/InterviewCompletedLargeRight';
import InterviewCompletedExpendRight from '../../common/Buttons/ScheduleButton/InterviewCompleted/InterviewCompletedExpendRight';
import RejectLarge from '../../common/Buttons/ScheduleButton/RejectLarge';
import JobOffersRight from '../../common/Buttons/JobOffers/JobOffersRight/JobOffersRight';
import JobOfferExpandedRight from '../../common/Buttons/JobOffers/JobOffersRight/JobOfferExpandedRight';

import ShortlistNormalRight from '../../common/Buttons/ShortlistButton/ShortlistNormalRight/ShortlistNormalRight';
import ShortlistExpandedRight from '../../common/Buttons/ShortlistButton/ShortlistNormalRight/ShortlistExpandedRight';
import InReviewRight from '../../common/Buttons/InReview/InReviewRight/InReviewRight';
import InReviewExpandedRight from '../../common/Buttons/InReview/InReviewRight/InReviewExpandedRight';

export default function CandidateListView({
    data,
    item,
    isMatched,
    handleExpanded,
    showExpanded,
    value,
    handleDrop,
    onDragOver,
    //handleClick,
    onDragStart,
    // isOpenRejectResponse,
    // handleRejectResponse,
    // isOpenScheduleInterview,
    // handleScheduleInterview,
    // updateStatus,
    // isOpenJobOffer,
    // handleJobOffer,
    path,
    showButton,
    handleInterviewData,
    // setCandidateData,
    // handleWaitingForCandidate,
    // editScheduleInterview,
    // handleAcceptInterview,
    // handleCallPopup,
    handleAction,
    rightButton,
    leftButton,
    rightButtonExpanded,
    leftButtonExpanded,
    showQuickCallButton,
    handleQuickCall
}) {
    //console.log("leftButton--------->", leftButton)
    return (
        <div>

            {data && data.length > 0 && data && data.map((content, contentIndex) => {
                return (
                    content && content[item] && content[item].length > 0 && content[item].map((item, dataIndex) => {
                        const { candidateName,
                            candidateExperience,
                            candidateEducation,
                            currentSalary,
                            candidateDesiredSalary,
                            candidateCity,
                            educationDegree,
                            educationBranch,
                            profilePicUrl,
                            jobId,
                            _id,
                            candidateAction,
                            starRank,
                            candidateSalaryCurrency,
                            candidateDesignationName,
                            // candidateId,
                            // employerId,
                            // candidateDetails,
                            employerAction,
                            interviewOccurred,
                            finalDateTimeInterview,
                            //interviewVia,
                            interviewId,
                            // jobDescription
                            // : {
                            //     interviewDate,
                            //     interviewLength,
                            //     interviewTime
                            // }
                        } = item;
                        let isPointerLabel = ((isMatched && dataIndex === 0) || (!isMatched)) ? true : false;
                        let formattedRank = starRank ? starRank.split("/") : "";
                        let currency = candidateSalaryCurrency ? currencySymbol[candidateSalaryCurrency] : currencySymbol['INR'];
                        // let scheduleData = {
                        //     starRank, profilePicUrl, candidateName, candidateDesignationName, jobId, candidateId, employerId, candidateDetails, _id, candidateAction, interviewId, employerAction
                        // }
                        let isTimepassed = false;
                        if (finalDateTimeInterview && finalDateTimeInterview.interviewDate && finalDateTimeInterview.interviewTime) {
                            isTimepassed = checkIsInterviewTimePassed(finalDateTimeInterview?.interviewDate, finalDateTimeInterview?.interviewTime);
                        }
                        let statusInfo;
                        if (path === 'interview') {
                            leftButton = <RejectLarge />
                            if ((candidateAction === 'Apply' && employerAction === 'Interview' && !interviewOccurred) || (candidateAction === 'WaitingToAccept' && employerAction === 'InterviewRescheduled')) {
                                statusInfo = 'Candidate has not respond to your time, Please wait for some time so the candidate can respond.';
                                rightButton = <WaitForCandidateListView />;
                                rightButtonExpanded = <WaitForcandidateExpendedView />;
                            } else if (candidateAction === 'InterviewRescheduled' && (employerAction === 'WaitingToAccept' || employerAction === 'Interview')) {
                                statusInfo = 'New interview date and time has been sent to you by the candidate, Please check your availability.'
                                rightButton = <InterviewAcceptedLargeRight />;
                                rightButtonExpanded = <InterviewAcceptedExpend />;
                            } else if ((candidateAction === 'InterviewAccepted' || candidateAction === 'OfferAccepted' || employerAction === 'InterviewAccepted') && !interviewOccurred) {
                                statusInfo = isTimepassed ? 'Your interview call time it’s time for you to take the interview of this candidate.' : 'Interview has been set, date and time has been accepted for the interview.';
                                if (employerAction === "InReview" && candidateAction !== 'OfferAccepted') {
                                    rightButton = <InReviewRight />
                                    rightButtonExpanded = <InReviewExpandedRight type="expandedView" />
                                } else if (candidateAction === 'OfferAccepted') {
                                    statusInfo = 'Your interview call time it’s time for you to take the interview of this candidate.';
                                    rightButton = <CandidateCallLargeRight />;
                                    rightButtonExpanded = <CandidateCallExpendRight />;
                                } else {
                                    rightButton = isTimepassed ? <CandidateCallLargeRight /> : <InterviewAcceptedLargeRight />;
                                    rightButtonExpanded = isTimepassed ? <CandidateCallExpendRight /> : <InterviewAcceptedExpend />;
                                }
                            } else if (interviewOccurred) {
                                if (employerAction === "InReview") {
                                    statusInfo = 'How was the interview, please tell us a few details about the interview.';
                                    rightButton = <InReviewRight />
                                    rightButtonExpanded = <InReviewExpandedRight type="expandedView" />
                                } else {
                                    statusInfo = 'How was the interview, please tell us a few details about the interview.';
                                    rightButton = <InterviewCompletedLargeRight />;
                                    rightButtonExpanded = <InterviewCompletedExpendRight />;
                                }
                            } else if (employerAction === 'Shortlist' && candidateAction === 'Apply') {
                                statusInfo = 'Yet to interview';
                                rightButton = <ShortlistNormalRight />
                                rightButtonExpanded = <ShortlistExpandedRight type="expandedView" />
                                //rightButtonCard = <ShortlistExpandedRight type="cardView" />
                            }
                        } else if (path === "joboffers") {
                            if (candidateAction === "OfferAccepted" && employerAction === "JobOffer") {
                                rightButton = <JobOffersRight type="accepted" />
                                rightButtonExpanded = <JobOfferExpandedRight />
                            } else {
                                rightButton = <JobOffersRight type="waiting" />
                                rightButtonExpanded = <WaitForcandidateExpendedView />;
                            }
                        }
                        return (
                            <>
                                <Row className={`${styles.marginB} ${(isMatched && dataIndex !== 0) ? 'blur' : ""}`} key={dataIndex}>
                                    {!showExpanded[`tab${value}[${dataIndex}]`] ? (
                                        <>
                                            <Col md={12}>
                                                <div className={styles.tableCandidate}>
                                                    <table>
                                                        <tr>
                                                            {showButton && isPointerLabel && <td
                                                                className={`${styles.paddLeft} pointer`}
                                                                onDrop={() => handleAction(item, _id, jobId, candidateAction, 'negative', employerAction, interviewId, finalDateTimeInterview, interviewOccurred, isTimepassed)}
                                                                onDragOver={onDragOver}
                                                                id="negative"
                                                                onClick={() => handleAction(item, _id, jobId, candidateAction, 'negative', employerAction, interviewId, finalDateTimeInterview, interviewOccurred, isTimepassed)}
                                                            >
                                                                {leftButton}
                                                            </td>}
                                                            {showButton && !isPointerLabel && <td className={`${styles.paddLeft}`}>
                                                                {leftButton}
                                                            </td>}
                                                            <td className={`${styles.mdWidth}`} >
                                                                <div className={`${styles.candidateData} ${(path === 'interview') ? styles.interviewCandidateList : ''}`} draggable onDragStart={(e) => onDragStart(e, _id, jobId, candidateAction)}>
                                                                    {path === 'interview' && <div className={styles.statusCandidate}>
                                                                        <h3><span>Status:</span> {statusInfo}</h3>
                                                                    </div>}
                                                                    <div className={styles.candiData}>
                                                                        <div className={`${styles.openArrow} ${isPointerLabel ? 'pointer' : ''}`} onClick={() => handleExpanded(dataIndex)} >
                                                                            <img src={downArrow} alt='' />
                                                                        </div>
                                                                        <div>
                                                                            <td className={styles.newNameView}>
                                                                                {formattedRank?.length > 0 && <div className={styles.rankNew}>
                                                                                    <span>{formattedRank[0]}<br></br><span className={styles.slashName}>/</span>{formattedRank[1]}</span>
                                                                                </div>}
                                                                                <div className={styles.nameUser}>
                                                                                    <img src={profilePicUrl ? profilePicUrl : defaultUser} alt='' />
                                                                                    <h3><label>Name</label>{candidateName}</h3>
                                                                                </div>
                                                                            </td>
                                                                            <td className={styles.newName}>
                                                                                <h3>
                                                                                    <label>Experience</label>
                                                                                    {candidateExperience}
                                                                                </h3>

                                                                            </td>
                                                                            <td className={styles.newName}>
                                                                                <h3>
                                                                                    <label>Education</label>
                                                                                    <a className={styles.eduName}>{candidateEducation}</a>
                                                                                    <div className={styles.edutionNew}>
                                                                                        <ul>
                                                                                            <li><h3><span>Education</span>{candidateEducation}</h3></li>
                                                                                            <li><h3><span>Degree</span>{educationDegree ? educationDegree : "-"}</h3></li>
                                                                                            <li><h3><span>Branch / Stream</span>{educationBranch}</h3></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </h3>
                                                                                {/* <h3><label className={isPointerLabel ? 'pointer' : ''}>Education</label>{candidateEducation}</h3> */}

                                                                            </td>
                                                                            <td className={styles.newName}>
                                                                                <h3><label>Current Salary</label>{currentSalary ? `${currency} ${currentSalary}` : "-"}</h3>
                                                                            </td>
                                                                            <td className={styles.newName}>
                                                                                <h3><label>Expected Salary </label>{candidateDesiredSalary ? `${currency} ${candidateDesiredSalary}` : "-"}</h3>
                                                                            </td>
                                                                            <td className={styles.newName}>
                                                                                <h3><label>City</label>{candidateCity}</h3>
                                                                            </td>
                                                                        </div>
                                                                    </div>
                                                                    {path === 'interview' && finalDateTimeInterview && <div className={styles.interviewHis}>
                                                                        <div className={styles.interviewList}>
                                                                            <h3><span>Interview</span> - <p><span>Date: </span>{finalDateTimeInterview?.interviewDate ? (moment(finalDateTimeInterview.interviewDate).format("DD MMM`YY")) : '-'}</p> |<p><span>Time: </span>{finalDateTimeInterview?.interviewTime ? getLocalTimeFormatted(finalDateTimeInterview.interviewTime) : '-'}</p> | <p><span>Duration: </span>{finalDateTimeInterview?.interviewLength ?? '-'}</p> | <p><span>Interview: </span>On Call</p></h3>
                                                                        </div>
                                                                        <div className={styles.btnNew}>
                                                                            <div className={styles.effNew}></div>
                                                                            <button onClick={() => handleInterviewData(interviewId, item)}>Interview history<span></span></button>
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                            </td>
                                                            {showButton && isPointerLabel && <td
                                                                className={`${styles.paddRight} pointer`}
                                                                onDrop={() => handleAction(item, _id, jobId, candidateAction, 'positive', employerAction, interviewId, finalDateTimeInterview, interviewOccurred)}
                                                                onDragOver={onDragOver}
                                                                id="positive"
                                                                onClick={() => handleAction(item, _id, jobId, candidateAction, 'positive', employerAction, interviewId, finalDateTimeInterview, interviewOccurred, isTimepassed)}
                                                            >
                                                                {rightButton}
                                                            </td>}
                                                            {
                                                                showButton && !isPointerLabel && <td className={`${styles.paddLeft}`}>
                                                                    {rightButton}
                                                                </td>
                                                            }
                                                        </tr>
                                                    </table>
                                                </div>
                                            </Col>
                                        </>) : (
                                        <CandidateExpandedView
                                            data={item}
                                            dataIndex={dataIndex}
                                            onDragOver={onDragOver}
                                            onDragStart={onDragStart}
                                            handleExpanded={handleExpanded}
                                            handleDrop={handleDrop}
                                            currency={currency}
                                            handleAction={handleAction}
                                            path={path}
                                            showButton={showButton}
                                            statusInfo={statusInfo}
                                            handleInterviewData={handleInterviewData}
                                            rightButtonExpanded={rightButtonExpanded}
                                            leftButtonExpanded={leftButtonExpanded}
                                            isTimepassed={isTimepassed}
                                            showQuickCallButton={employerAction === 'Shortlist' && candidateAction === 'Apply' ? false : showQuickCallButton}
                                            handleQuickCall={handleQuickCall}
                                        />
                                    )
                                    }

                                </Row>
                            </>
                        )
                    })
                )
            })
            }

        </div>
    )
}
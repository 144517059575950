// import { style } from '@mui/system';
import { useState, useEffect, forwardRef } from 'react';
import { Container } from 'react-bootstrap';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import HomeLayout from '../../layouts/HomeLayout/HomeLayout';
import SubHeader from '../common/SubHeader/SubHeader';

import styles from './Candidates.module.scss';

import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import { grey } from '@mui/material/colors';

import { getAllInterview, getCandidatesByStatus, getCity, getPositionList, updateCandidateJobStatus } from '../../services/api/common';
import { /**checkIsInterviewTimePassed,*/ hideLoader, showLoader, setCookie } from '../../helpers';
import { toast } from 'react-toastify';
import { useLocation, useParams } from 'react-router-dom';
import NoDataFound from '../common/NoDataFound/NoDataFound';
import moment from 'moment';
import CandidateListView from './CandidateListView/CandidateListView';
import CandidateCardView from './CandidateCardView/CandidateCardView';
import InterviewHistory from '../common/Popup/InterviewHistory/InterviewHistory';
import RejectResponse from './RejectResponse/RejectResponse';
import ScheduleInterview from '../common/Popup/ScheduleInterview/ScheduleInterview';
import WaitingForCandidate from '../common/Popup/InterviewSetTime/InterviewSetTime';
import AcceptOrReschedule from '../common/Popup/AcceptOrReschedule/AcceptOrReschedule';
import InterviewTime from '../common/Popup/InterviewTime/InterviewTime';
import SmartSecretary from '../common/Popup/SmartSecretary/SmartSecretary';
import { normalCall, normalCallPassThru, quickCall, quickCallPassThru } from '../../services/api/interview';
import MatchNormalRight from '../common/Buttons/MatchButton/MatchRight/MatchNormalRight';
import MatchExpandedRight from '../common/Buttons/MatchButton/MatchRight/MatchExpandedRight';
import MatchNormalLeft from '../common/Buttons/MatchButton/MatchLeft/MatchNormalLeft';
import MatchExpandedLeft from '../common/Buttons/MatchButton/MatchLeft/MatchExpandedLeft';
import ShortlistNormalRight from '../common/Buttons/ShortlistButton/ShortlistNormalRight/ShortlistNormalRight';
import ShortlistExpandedRight from '../common/Buttons/ShortlistButton/ShortlistNormalRight/ShortlistExpandedRight';
// import NormalRejectButton from '../common/Buttons/NormalRejectButton/NormalRejectButton';
// import ExpandedRejectButton from '../common/Buttons/NormalRejectButton/ExpandedRejectButton';
import InReviewRight from '../common/Buttons/InReview/InReviewRight/InReviewRight';
import InReviewExpandedRight from '../common/Buttons/InReview/InReviewRight/InReviewExpandedRight';
// import JobOffersRight from '../common/Buttons/JobOffers/JobOffersRight/JobOffersRight';
// import JobOfferExpandedRight from '../common/Buttons/JobOffers/JobOffersRight/JobOfferExpandedRight';
import InterviewAccepted from '../common/Popup/InterviewAccepted/InterviewAccepted';
import InterviewFeedback from '../common/Popup/InterviewFeedback/InterviewFeedback';
import RejectInterviewFeedback from '../common/Popup/RejectInterviewFeedback/RejectInterviewFeedback';
import CallHistory from '../common/Popup/CallHistory/CallHistory';
import JobOfferSummary from '../common/Popup/JobOfferSummary/JobOfferSummary';
import CandidateHired from '../common/Popup/CandidateHired/CandidateHired';
import CandidateFilter from '../common/Popup/CandidateFilter/CandidateFilter';
import ChooseInReviewOption from '../common/Popup/ChooseInReviewOption/ChooseInReviewOption';

import { getProfilesCount } from "../../services/api/common";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




export default function Candidates() {
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const { path } = useParams();
    const { state } = useLocation();

    //state
    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const [filterdata, setFilterData] = useState([]);
    const [jobTitleData, setJobTitleData] = useState([]);
    const [showExpanded, setShowExpanded] = useState({});
    const [viewType, setViewType] = useState('listView');
    const [totalRecords, setTotalRecords] = useState(0);
    const [isOpenRejectResponse, setIsOpenRejectResponse] = useState(false);
    const [isOpenScheduleInterview, setIsOpenScheduleInterview] = useState(false);
    //const [isIntervieScheduled, setIsInterviewScheduled] = useState(false);
    const [interviewData, setInterviewData] = useState();
    const [showInterviewHistory, setShowInterviewHistory] = useState(false);
    const [candidateData, setCandidateData] = useState({});
    const [isOpenWaitingForCandidate, setIsOpenWaitingForCandidate] = useState(false);
    const [candidateInterviewData, setCandidateInterviewData] = useState({});
    const [isUpdateScheduleAction, setIsUpdateScheduleAction] = useState(false);
    const [isOpenAcceptInterview, setIsOpenAcceptInterview] = useState(false);
    const [isOpenStartCall, setIsOpenStartCall] = useState(false);
    const [isInterviewTImePassed, setIsInterviewTimePassed] = useState(false);
    const [isOpenSmartSeceretary, setIsOpenSmartSeceretary] = useState(false);
    const [isOpenInterviewAccepted, setIsOpenInterviewAccepted] = useState(false);
    const [isOpenPositiveFeedback, setIsOpenPositiveFeedback] = useState(false);
    const [isOpenRejectFeedback, setIsOpenRejectFeedback] = useState(false);
    const [isOpenCallHistory, setIsOpenCallHistory] = useState(false);
    const [isOpenJobOfferSummary, setIsOpenJobOfferSummary] = useState(false);
    const [isHiredSuccess, setIsHiredSuccess] = useState(false);
    const [isCandidateFilterOpen, setIsCandidateFilterOpen] = useState(false);
    const [positionData, setPositionData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [resetData, setResetData] = useState([]);
    const [unFormattedData, setUnFormattedData] = useState([]);
    const [isOpenInReviewOption, setIsOpenInReviewOption] = useState(false);

    useEffect(() => {
        if (path) {
            getCandidates(path, state);
            setResetData([]);
            setUnFormattedData([]);
            setValue(0);
        }
    }, [path, state]);
    useEffect(() => {
        getInterviewData();
    }, []);

    const isMatched = path === 'matched' ? true : false;

    const handleChange = (event, newValue) => {
        //   showLoader();
        //   setTimeout(() => {
        setValue(newValue);
        //   hideLoader();
        //   }, 500);
    };

    const getCandidates = async (path, state) => {
        try {
            let tabName = path === 'matched' ? 'Pending' : path === 'shortlisted' ? 'Shortlisted' : path === 'interview' ? 'Interviews' : path === 'inreview' ? 'In Review' : path === 'joboffers' ? 'Jobs Offers' : path === 'hired' ? 'Hired' : 'Rejected';
            let response = unFormattedData;
            showLoader();
            let param = {
                userType: 'employer',
                userId: userData ? userData._id : "",
                tabName

            }
            const {
                data: {
                    data
                } } = await getCandidatesByStatus(param);
            response = data;
            // console.log("tabName--->", tabName)
            if (tabName === 'Interviews') {
                param.tabName = 'Shortlisted'
                const {
                    data: {
                        data
                    } } = await getCandidatesByStatus(param);
                data.forEach((elements) => { response.push(elements); });
            }
            if (tabName === 'Interviews') {
                param.tabName = 'In Review'
                const {
                    data: {
                        data
                    } } = await getCandidatesByStatus(param);
                data.forEach((elements) => { response.push(elements); });
            }
            setUnFormattedData(response);
            formatAndSetData(response);
            getJobsAndProfilesCount()
            // toast.success(message ? message : "Success");
            hideLoader();

        } catch (error) {
            toast.error("Failed11");
            hideLoader();
        }
    }

    const getJobsAndProfilesCount = async () => {
        // showLoader();
        const {
            data: {
                data
            } } = await getProfilesCount();
        setCookie("profileCounts", JSON.stringify(data))
        // hideLoader();
    }

    const formatAndSetData = (data) => {
        setTotalRecords(data ? data.length : 0);
        let jobTitle = [];
        jobTitle = [...new Set(data.map(x => x.jobTitle ? x.jobTitle.trim() : x.jobTitle))];
        let newData = []

        jobTitle && jobTitle.length > 0 && jobTitle.map((item, index) => {
            let sample = {}
            sample = data.filter(o => o.jobTitle && item ? o.jobTitle.trim() === item.trim() : o.jobTitle === item);
            newData.push({ [item]: sample })
            return null;
        })
        if (state && state.jobTitle) {
            let titleIndex = jobTitle.findIndex(i => {
                return i === state.jobTitle
            });
            titleIndex !== -1 ? setValue(titleIndex) : toast.warning('No matched job');
        }
        setData(newData);
        setJobTitleData(jobTitle);
    }

    const getInterviewData = async () => {
        try {
            showLoader();
            const {
                data: {
                    data
                }
            } = await getAllInterview();
            setInterviewData(data);
            hideLoader();
        } catch (error) {
            hideLoader();
        }
    }

    const handleExpanded = (index) => {
        let currentTab = `tab${value}[${index}]`;
        if ((isMatched && index === 0) || !isMatched) {
            setShowExpanded({ [currentTab]: !showExpanded[currentTab] });
        }
    }

    const handleDrop = e => {

        let id = e.dataTransfer.getData("id");
        let jobId = e.dataTransfer.getData("jobId");
        let candidateAction = e.dataTransfer.getData("candidateAction");

        let actionId = e.currentTarget.id;
        handleClick(id, jobId, candidateAction, actionId);

    };
    const handleClick = (id, jobId, candidateAction, actionId, employerAction) => {
        // console.log("candidateAction---------->", candidateAction)
        // console.log("employerAction---------->", employerAction)
        // return;
        if (actionId === 'positive' && employerAction === "Shortlist") {
            handleScheduleInterview();
        } else if (actionId === 'negative' && path !== "matched") {
            handleRejectResponse();
        } else {
            // console.log('update status', jobId, id, actionId, candidateAction, employerAction)
            updateStatus(jobId, id, actionId, candidateAction, employerAction);
        }

        // if(path === 'interview' && isIntervieScheduled) {
        //     updateStatus(jobId, id, action);
        // } else if (path !== 'interview') {
        // }
    }

    const onDragStart = (e, id, jobId, candidateAction) => {
        // let element = e.currentTarget;
        e.dataTransfer.setData("id", id);
        e.dataTransfer.setData("jobId", jobId);
        e.dataTransfer.setData("candidateAction", candidateAction);
        // e.dataTransfer.effectAllowed = "move";
        // setTimeout(() => {
        //     element.style.display = "none";
        // }, 0);
    };

    const onDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    }

    const updateStatus = async (jobId, id, actionId, candidateAction, employerAction, actionVal = "") => {
        try {
            let action;
            if (actionId === 'positive') {
                // action = path === 'matched' ? 'Shortlist' : path === 'shortlisted' ? 'Interview' : (path === 'interview' && candidateAction === 'Apply') ? 'Interview' : (path === 'interview' && (candidateAction === 'InterviewAccepted' && employerAction !== 'InReview')) ? 'InReview' : (path === 'interview' && candidateAction === 'InterviewRescheduled' && employerAction === 'WaitingToAccept') ? 'InterviewAccepted' : (path === 'interview' && (candidateAction === 'InterviewAccepted' || employerAction === 'InReview')) ? 'JobOffer' : path === 'joboffers' ? 'Hire' : '';
                if (actionVal === "") {
                    if (path === 'matched') {
                        action = 'Shortlist'
                    } else if (path === 'interview' && candidateAction === 'Apply' && employerAction === "") {
                        action = 'Interview'
                    } else if (path === 'interview' && (candidateAction === 'InterviewAccepted' && employerAction !== 'InReview')) {
                        action = 'InReview'
                    } else if (path === 'interview' && candidateAction === 'InterviewRescheduled' && employerAction === 'WaitingToAccept') {
                        action = 'InterviewAccepted'
                    } else if (path === 'interview' && (candidateAction === 'InterviewAccepted' || employerAction === 'InReview' || employerAction === 'Interview')) {
                        action = 'JobOffer'
                    } else if (path === 'joboffers') {
                        action = 'Hire'
                    } else {
                        action = ''
                    }
                } else {
                    action = actionVal
                }

            } else if (actionId === 'reschedule') {
                action = "InterviewRescheduled";
            } else if (actionId === 'hire') {
                action = "Hire";
            } else {
                // action = path === 'matched' ? 'Reject' : path === 'shortlisted' ? 'Reject' : path === 'interview' && candidateAction === 'Apply' ? 'Reject' : path === 'interview' && candidateAction === 'interviewAccepted' ? 'Reject' : path === 'interview' && candidateAction === 'InterviewRescheduled' ? 'Reject' : path === 'inreview' ? 'Reject' : path === 'joboffers' ? 'Reject' : '';
                // handleRejectResponse();
                action = 'Reject';
            }
            showLoader();
            let params = {
                userType: "employer",
                userId: userData._id,
                jobId,
                _id: id,
                interviewAcceptedOrRescheduledDateTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                action
            }
            const {
                data: {
                    message
                } } = await updateCandidateJobStatus(params);
            getCandidates(path);
            getInterviewData();
            setShowExpanded({});
            hideLoader();
            if (action === 'Shortlist') {
                toast.success(message);
            } else if (action === 'Hire') {
                setIsHiredSuccess(true);
            }
        } catch (error) {
            hideLoader();
            toast.error("Failed");
        }
    }

    const handleViewType = (data) => {
        setViewType(data);
    }

    const handleRejectResponse = () => {
        setIsOpenRejectResponse(!isOpenRejectResponse);
    }

    const handleScheduleInterview = (id) => {
        setCandidateInterviewData({});
        setIsOpenScheduleInterview(!isOpenScheduleInterview);
    }

    const handleCandidateInterview = (id) => {
        setCandidateInterviewData(interviewData.find(i => i._id === id));
    }

    const handleInterviewData = (id, jobData) => {
        handleCandidateInterview(id);
        setCandidateData(jobData);
        setShowInterviewHistory(true);
    }

    const handleWaitingForCandidate = (id) => {
        setIsOpenWaitingForCandidate(!isOpenWaitingForCandidate);
        if (id) {
            handleCandidateInterview(id);
        }
    }

    const editScheduleInterview = (type, id) => {
        setIsOpenWaitingForCandidate(false);
        setIsOpenScheduleInterview(true);
        if (type === 'waitingForCandidate') {
            setIsUpdateScheduleAction(false);
        } else {
            setIsUpdateScheduleAction(true);
            if (id) {
                handleCandidateInterview(id);
            }
        }
    }

    const handleAcceptInterview = (id) => {
        setIsOpenAcceptInterview(!isOpenAcceptInterview);
        if (id) {
            handleCandidateInterview(id);
        }
    }

    const handleCallPopup = (id) => {
        if (id) {
            handleCandidateInterview(id);
        }
        // const interval = setInterval(() => {
        if (candidateInterviewData?.interviewVia === 'Phone Call') {
            // clearInterval(interval);
            setIsOpenStartCall(!isOpenStartCall);
        }
        // }, 500);
    }

    const handleAction = (scheduleData, _id, jobId, candidateAction, scenario, employerAction, interviewId, finalDateTimeInterview, interviewOccurred, isTimepassed) => {

        if (path !== "joboffers") {
            setCandidateData(scheduleData);
            setIsInterviewTimePassed(isTimepassed);
        }
        // let isTimepassed;
        // if(finalDateTimeInterview && finalDateTimeInterview.interviewDate && finalDateTimeInterview.interviewTime) {
        //     isTimepassed = checkIsInterviewTimePassed(finalDateTimeInterview?.interviewDate, finalDateTimeInterview?.interviewTime);
        //     setIsInterviewTimePassed(isTimepassed);
        // }

        // //test
        // if(candidateAction==='WaitingToAccept' && employerAction==='InterviewRescheduled') {
        //     handleAcceptInterview(interviewId);
        // }

        // //test
        if (path === 'interview' && isTimepassed && !interviewOccurred && scenario === 'positive') {
            if ((candidateAction === 'Apply' && employerAction === 'Interview') || (candidateAction === 'InterviewRescheduled' && employerAction === 'WaitingToAccept') || (candidateAction === 'WaitingToAccept' && employerAction === 'InterviewRescheduled') || (candidateAction === 'InterviewRescheduled' && employerAction === 'Interview')) {
                editScheduleInterview('reSchedule', interviewId);
            }
            //  else if(candidateAction === 'InterviewAccepted' || employerAction === 'InterviewAccepted') {
            //     editScheduleInterview('reSchedule', interviewId);
            // }
            // eslint-disable-next-line
            else if (scenario === 'positive' && (candidateAction === 'InterviewAccepted' && (employerAction === 'InterviewRescheduled' || 'Interview')) || (candidateAction === 'InterviewRescheduled' && employerAction === 'InterviewAccepted')) {
                //call
                handleCallPopup(interviewId);
            } else if (scenario === 'positive' && candidateAction === 'Apply' && employerAction === 'Shortlist') {
                handleClick(_id, jobId, candidateAction, scenario, employerAction);
            }

        } else if (path === 'interview' && !isTimepassed && !interviewOccurred) {
            // eslint-disable-next-line
            if (scenario === 'positive' && (candidateAction === 'Apply' && employerAction === 'Interview') || (candidateAction === 'WaitingToAccept' && employerAction === 'InterviewRescheduled')) {
                handleWaitingForCandidate(interviewId);
            } else if (scenario === 'positive' && candidateAction === 'InterviewAccepted' && (employerAction === 'InterviewRescheduled' || 'Interview')) {
                //call
                // handleCallPopup(interviewId);
                handleInterviewAccepted(interviewId);
            } else if (scenario === 'positive' && candidateAction === 'InterviewRescheduled' && (employerAction === 'WaitingToAccept' || employerAction === 'Interview')) {
                // handleAcceptInterview(interviewId);
            } else if (candidateAction === 'Apply' && employerAction === 'Shortlist') {
                handleClick(_id, jobId, candidateAction, scenario, employerAction);
            } else if (candidateAction === 'OfferAccepted' || employerAction === 'InReview') {
                if (scenario === 'positive') {
                    setIsOpenStartCall(!isOpenStartCall);
                } else {
                    handleRejectFeedback(interviewId);
                }
            }
            // else if (scenario === 'negative') {
            //     editScheduleInterview('reSchedule', interviewId);
            // }
        } else if (path === "interview" && interviewOccurred) {
            if (scenario === 'positive') {
                if (candidateAction === 'Apply' && employerAction === 'InReview') {
                    handleInReviewOption(interviewId);
                } else {
                    handlePositiveFeedback(interviewId);
                }
            } else {
                handleRejectFeedback(interviewId);
            }
        } else if (path === "inreview" && interviewOccurred) {
            if (scenario === 'positive') {
                // handleJobOfferSummary(interviewId);
                handleInReviewOption(interviewId);
            } else {
                handleClick(_id, jobId, candidateAction, scenario, employerAction);
            }
        } else if (path === "joboffers" && scenario === "positive") {
            if (candidateAction === "OfferAccepted" && employerAction === "JobOffer") {
                handleClick(_id, jobId, candidateAction, scenario, employerAction);
            } else {
                // console.log('job offer positive')
            }
        } else {
            handleClick(_id, jobId, candidateAction, scenario, employerAction);
        }

    }

    const handleInterviewCall = (interviewId) => {
        setIsOpenStartCall(false);
        setIsOpenSmartSeceretary(true);
        interviewCall(interviewId, 1);
    }

    const interviewCall = async (interviewId, number) => {
        try {
            showLoader();
            let inputData = {
                interviewId,
                number
            }
            const {
                data: {
                    data: {
                        TwilioResponse: {
                            Call: {
                                Sid
                            } } }
                } } = await normalCall(inputData);
            getNormalCallStatus(interviewId, Sid, number);
            hideLoader();
        } catch (error) {
            hideLoader();
        }
    }


    const getNormalCallStatus = async (interviewId, Sid, number) => {
        try {
            showLoader();
            let params = {
                CustomField: interviewId,
                CallSid: Sid
            }

            const interval = setInterval(async () => {
                const {
                    data: {
                        data: {
                            Status
                        }
                    } } = await normalCallPassThru(params);
                if (Status === 'completed') {
                    clearInterval(interval);
                    setIsOpenSmartSeceretary(false);
                    toast.success("Interview completed successfully");
                    getCandidates(path);
                } else if (Status === 'no-answer' || Status === 'canceled' || Status === 'busy' || Status === 'failed') {
                    clearInterval(interval);
                    if (number === 1) {
                        interviewCall(interviewId, 2);
                    } else {
                        setIsOpenSmartSeceretary(false);
                        toast.error("Try after 15 minutes")
                    }
                }
            }, 30000);

            hideLoader();
        } catch (error) {
            hideLoader();
        }
    }

    const handleQuickCall = (_id) => {
        setIsOpenScheduleInterview(false);
        setIsOpenSmartSeceretary(true);
        quickInterviewCall(_id, 1);
    }

    const quickInterviewCall = async (_id, number) => {
        try {
            showLoader();
            let inputData = {
                jobMatchId: _id,
                number
            }
            const {
                data: {
                    data: {
                        TwilioResponse: {
                            Call: {
                                Sid
                            } } }
                } } = await quickCall(inputData);
            getQuickCallStatus(_id, Sid, number);
            hideLoader();
        } catch (error) {
            hideLoader();
        }
    }

    const getQuickCallStatus = async (_id, Sid, number) => {
        try {
            showLoader();
            let params = {
                CustomField: _id,
                CallSid: Sid
            }
            const interval = setInterval(async () => {
                const {
                    data: {
                        data: {
                            Status
                        }
                    } } = await quickCallPassThru(params);
                if (Status === 'completed') {
                    clearInterval(interval);
                    setIsOpenSmartSeceretary(false);
                    toast.success("Interview completed successfully");
                    getCandidates(path);
                } else if (Status === 'no-answer' || Status === 'canceled' || Status === 'busy' || Status === 'failed') {
                    clearInterval(interval);
                    if (number === 1) {
                        quickInterviewCall(_id, 2);
                    } else {
                        setIsOpenSmartSeceretary(false);
                        toast.error("Try after 15 minutes")
                    }
                }
            }, 30000);

            hideLoader();
        } catch (error) {
            hideLoader();
        }
    }

    const handleInterviewAccepted = (id) => {
        setIsOpenInterviewAccepted(!isOpenInterviewAccepted);
        if (id) {
            handleCandidateInterview(id);
        }
    }

    const handlePositiveFeedback = (id) => {
        setIsOpenPositiveFeedback(!isOpenPositiveFeedback);
        if (id) {
            handleCandidateInterview(id);
        }
    }

    const handleRejectFeedback = (id) => {
        setIsOpenRejectFeedback(!isOpenRejectFeedback);
        if (id) {
            handleCandidateInterview(id);
        }
    }

    const handleCallHistory = () => {
        setIsOpenCallHistory(!isOpenCallHistory);
    }

    const handleJobOfferSummary = (id) => {
        setIsOpenJobOfferSummary(!isOpenJobOfferSummary);
        if (id) {
            handleCandidateInterview(id);
        }
    }

    const getJobPosition = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getPositionList();
            setPositionData(
                data && data.length > 0 && data.map(({ position, positionId, positionUid }) => {
                    return {
                        label: position,
                        value: positionId,
                        positionUid
                    }
                })
            );
        } catch (error) {
        }
    }

    const getCityList = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getCity();
            setCityData(
                data && data.length > 0 && data.map(({ stateName, districtName }) => {
                    let data = districtName.map((item) => { return { label: item, value: item, state: stateName } });
                    return {
                        label: stateName,
                        options: data
                    }
                })
            );
        } catch (error) {
        }
    }

    const openCandidateFilter = () => {
        getJobPosition();
        getCityList();
        setIsCandidateFilterOpen(true);
    }

    const handleCandidateFilter = (position, date, city, gender) => {
        let searchObj = {};
        if (position?.length !== 0) {
            searchObj.jobPositionId = position.value;
        }
        if (date) {
            searchObj.filterDate = date;
        }
        if (city?.length !== 0) {
            searchObj.jobCity = city.value;
        }
        if (gender?.length !== 0) {
            searchObj.candidateGender = gender.value;
        }
        if (Object.keys(city).length === 0 && Object.keys(position).length === 0 && !date && Object.keys(gender).length === 0) {
            if (resetData?.length > 0) {
                formatAndSetData(resetData);
                // setData(resetData)
            }
        } else {
            let toFilterData = [];
            if (resetData?.length > 0) {
                toFilterData = unFormattedData;
            } else {
                toFilterData = unFormattedData;
                setResetData(unFormattedData);
            }
            toFilterData = toFilterData.map(({ ...item }) => ({ ...item, filterDate: (moment(item.createdAt).format('YYYY-MM-DD')) }));
            let final = toFilterData.filter(function (item) {
                for (var key in searchObj) {
                    if (item[key] === undefined || searchObj[key] === undefined || item[key] !== searchObj[key])
                        return false;
                }
                return true;
            });
            formatAndSetData(final);

        }
    }

    const handleInReviewOption = (id) => {
        console.log('inside revier')
        setIsOpenInReviewOption(!isOpenInReviewOption);
        if (id) {
            handleCandidateInterview(id);
        }
    }

    // const handleinitai
    let showButton = (path === "hired" || path === "rejected") ? false : true;
    let showQuickCallButton = (path === 'matched' || path === 'shortlisted' || path === 'rejected') ? false : true;
    let rightButton, rightButtonExpanded, leftButton, leftButtonExpanded, rightButtonCard;
    // leftButton = <NormalRejectButton />;
    // leftButtonExpanded = <ExpandedRejectButton />;
    leftButton = <MatchNormalLeft />
    leftButtonExpanded = <MatchExpandedLeft />
    if (path === 'matched') {
        rightButton = <MatchNormalRight />
        rightButtonExpanded = <MatchExpandedRight type="expandedView" />
        rightButtonCard = <MatchExpandedRight type="cardView" />
        // leftButton= <MatchNormalLeft />
        // leftButtonExpanded= <MatchExpandedLeft />
    } else if (path === 'shortlisted') {
        rightButton = <ShortlistNormalRight />
        rightButtonExpanded = <ShortlistExpandedRight type="expandedView" />
        rightButtonCard = <ShortlistExpandedRight type="cardView" />
    } else if (path === 'inreview') {
        rightButton = <InReviewRight />
        rightButtonExpanded = <InReviewExpandedRight type="expandedView" />
        rightButtonCard = <InReviewExpandedRight type="cardView" />
    } else if (path === 'interview') {
        leftButtonExpanded = <MatchExpandedLeft />
    }
    function getSearchArray(obj, val) {
        const filterObj = JSON.parse(obj);
        const filtername = Object.keys(filterObj[value]);
        const jobName = filterObj[value];
        let searchArray = [];
        const searchVal = val.toLowerCase();
        for (let i = 0; i < jobName[filtername].length; i++) {
            let element = jobName[filtername][i];
            const candidateName = element.candidateName.toLowerCase();
            const candidateExperiance = element.candidateExperience.toLowerCase();
            const candidateEducation = element.candidateEducation.toLowerCase();
            const candidateCurrentSalary = element.currentSalary ? element.currentSalary : "";
            const candidateExpectSalary = element.candidateDesiredSalary ? element.candidateDesiredSalary : "";
            const candidateCity = element.candidateCity.toLowerCase();
            if (candidateName.includes(searchVal) || candidateExperiance.includes(searchVal) ||
                candidateEducation.includes(searchVal) || candidateCurrentSalary === parseInt(searchVal) ||
                candidateExpectSalary === parseInt(searchVal) || candidateCity.includes(searchVal)) {

                searchArray.push(element)
            }
        }
        return searchArray;
    }
    const searchFilter = (e) => {
        let searchData = JSON.stringify(data);
        let parseData = JSON.parse(searchData);
        if (e.key === 'Enter') {
            if (e.target.value) {
                const newArryay = getSearchArray(searchData, e.target.value);
                const keyVal = Object.keys(parseData[value]);
                Object.assign(parseData[value], { [keyVal]: newArryay })
                setFilterData(parseData)
            } else {
                setFilterData([])
            }
        } else {
            setFilterData([])
            setData(data)
        }
    }

    const TabScrollButton = forwardRef((props, ref) => {
        const { direction, ...other } = props;

        return (
            <ButtonBase
                component="div"
                ref={ref}
                style={{ opacity: other.disabled ? 0 : 1 }}
                {...other}
            >
                {direction === "left" ? (
                    <ArrowBackIcon fontSize="small" style={{ color: grey[50], marginBottom: "11px" }} />
                ) : (
                    <ArrowForwardIcon fontSize="small" style={{ color: grey[50], marginBottom: "11px" }} />
                )}
            </ButtonBase>
        );
    });

    return (
        <HomeLayout>
            <section className={styles.candidateBg}>
                <SubHeader handleType={handleViewType} count={totalRecords} handleFilter={openCandidateFilter} searchFilter={searchFilter} />
            </section>
            <section className={styles.newSec}>
                <Box className={styles.tabStyle} sx={{ width: '100%' }}>
                    {jobTitleData && jobTitleData.length > 0 && <Box className={styles.boxTab} sx={{ border: 1, borderColor: 'divider' }} >
                        {/* <h3>Job Post</h3> */}
                        <Tabs
                            className={styles.tabList}
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            scrollButtons="auto"
                            ScrollButtonComponent={TabScrollButton}
                            TabIndicatorProps={{
                                sx: {
                                    height: 0
                                },
                            }}

                        >

                            {jobTitleData.map((item, index) => {
                                return (
                                    <Tab
                                        key={index}
                                        className={`${styles.tabName} ${value === index ? styles.activeTab : ""}`}
                                        label={item} {...a11yProps(index)}
                                    />
                                )

                            })}

                        </Tabs>
                    </Box>
                    }

                    <div className={styles.scrollbarContainer}>
                        <div className={styles.scrollContainer}>
                            <div className={styles.scrollcontent}>
                                {jobTitleData && jobTitleData.length > 0 ? jobTitleData.map((item, index) => {
                                    return (
                                        <TabPanel
                                            className={styles.tabContent}
                                            value={value}
                                            index={index}
                                            key={index}
                                        >
                                            <section className={styles.candidateList}>
                                                <Container fluid className={styles.tabData}>
                                                    {viewType === "listView" ? (
                                                        <CandidateListView
                                                            data={filterdata.length === 0 ? data : filterdata}
                                                            item={item}
                                                            isMatched={isMatched}
                                                            handleExpanded={handleExpanded}
                                                            showExpanded={showExpanded}
                                                            value={value}
                                                            handleDrop={handleDrop}
                                                            onDragOver={onDragOver}
                                                            handleClick={handleClick}
                                                            onDragStart={onDragStart}
                                                            path={path}
                                                            showButton={showButton}
                                                            handleInterviewData={handleInterviewData}
                                                            setCandidateData={setCandidateData}
                                                            handleWaitingForCandidate={handleWaitingForCandidate}
                                                            editScheduleInterview={editScheduleInterview}
                                                            handleAcceptInterview={handleAcceptInterview}
                                                            handleCallPopup={handleCallPopup}
                                                            handleAction={handleAction}
                                                            rightButton={rightButton}
                                                            leftButton={leftButton}
                                                            rightButtonExpanded={rightButtonExpanded}
                                                            leftButtonExpanded={leftButtonExpanded}
                                                            showQuickCallButton={showQuickCallButton}
                                                            handleQuickCall={handleQuickCall}
                                                        />

                                                    ) : (
                                                        <CandidateCardView
                                                            data={filterdata.length === 0 ? data : filterdata}
                                                            item={item}
                                                            isMatched={isMatched}
                                                            handleExpanded={handleExpanded}
                                                            showExpanded={showExpanded}
                                                            value={value}
                                                            handleDrop={handleDrop}
                                                            onDragOver={onDragOver}
                                                            handleClick={handleClick}
                                                            onDragStart={onDragStart}
                                                            path={path}
                                                            showButton={showButton}
                                                            handleInterviewData={handleInterviewData}
                                                            setCandidateData={setCandidateData}
                                                            handleWaitingForCandidate={handleWaitingForCandidate}
                                                            editScheduleInterview={editScheduleInterview}
                                                            handleAcceptInterview={handleAcceptInterview}
                                                            handleCallPopup={handleCallPopup}
                                                            handleAction={handleAction}
                                                            rightButtonExpanded={rightButtonExpanded}
                                                            leftButtonExpanded={leftButtonExpanded}
                                                            showQuickCallButton={showQuickCallButton}
                                                            handleQuickCall={handleQuickCall}
                                                            rightButtonCard={rightButtonCard}
                                                        />
                                                    )}
                                                </Container>
                                            </section>
                                        </TabPanel>
                                    )
                                })
                                    : (
                                        <NoDataFound
                                            description="Looks like there is no candidate for this job for now, you can  check your specification if you mentioned them right."
                                        />)
                                }
                                <RejectResponse
                                    isOpen={isOpenRejectResponse}
                                    handleClose={handleRejectResponse}
                                    data={candidateData}
                                    updateStatus={updateStatus}
                                />
                                <ScheduleInterview
                                    isOpen={isOpenScheduleInterview}
                                    handleClose={handleScheduleInterview}
                                    data={candidateData}
                                    updateStatus={updateStatus}
                                    initialData={candidateInterviewData}
                                    isUpdateScheduleAction={isUpdateScheduleAction}
                                    handleQuickCall={handleQuickCall}
                                />
                                <InterviewHistory
                                    isOpen={showInterviewHistory}
                                    handleClose={() => setShowInterviewHistory(!showInterviewHistory)}
                                    data={candidateInterviewData}
                                    candidateData={candidateData}
                                />
                                <WaitingForCandidate
                                    isOpen={isOpenWaitingForCandidate}
                                    handleClose={() => setIsOpenWaitingForCandidate(false)}
                                    data={candidateInterviewData}
                                    editScheduleInterview={() => editScheduleInterview('waitingForCandidate', null)}
                                />
                                <AcceptOrReschedule
                                    isOpen={isOpenAcceptInterview}
                                    handleClose={() => setIsOpenAcceptInterview(false)}
                                    candidateInterviewData={candidateInterviewData}
                                    editScheduleInterview={() => editScheduleInterview('reSchedule', null)}
                                    candidateData={candidateData}
                                    isInterviewTimePassed={isInterviewTImePassed}
                                    updateStatus={updateStatus}
                                />
                                <InterviewTime
                                    isOpen={isOpenStartCall}
                                    handleClose={() => setIsOpenStartCall(false)}
                                    handleScheduleInterview={handleScheduleInterview}
                                    candidateData={candidateData}
                                    data={candidateInterviewData}
                                    handleInterviewCall={handleInterviewCall}
                                />
                                <SmartSecretary
                                    isOpen={isOpenSmartSeceretary}
                                />
                                <InterviewAccepted
                                    isOpen={isOpenInterviewAccepted}
                                    handleClose={() => setIsOpenInterviewAccepted(!isOpenInterviewAccepted)}
                                    data={candidateInterviewData}
                                />
                                <InterviewFeedback
                                    isOpen={isOpenPositiveFeedback}
                                    handleClose={() => setIsOpenPositiveFeedback(!isOpenPositiveFeedback)}
                                    handleInReviewOption={handleInReviewOption}
                                    data={candidateData}
                                    updateStatus={updateStatus}
                                    handleCallHistory={handleCallHistory}
                                    candidateInterviewData={candidateInterviewData}
                                />
                                <RejectInterviewFeedback
                                    isOpen={isOpenRejectFeedback}
                                    handleClose={() => setIsOpenRejectFeedback(!isOpenRejectFeedback)}
                                    data={candidateData}
                                    updateStatus={updateStatus}
                                    handleCallHistory={handleCallHistory}
                                    candidateInterviewData={candidateInterviewData}
                                />
                                <CallHistory
                                    isOpen={isOpenCallHistory}
                                    handleClose={handleCallHistory}
                                    data={candidateInterviewData}
                                />
                                <JobOfferSummary
                                    isOpen={isOpenJobOfferSummary}
                                    handleClose={() => setIsOpenJobOfferSummary(!isOpenJobOfferSummary)}
                                    jobMatchData={candidateData}
                                    updateStatus={updateStatus}
                                />
                                <CandidateHired
                                    isOpen={isHiredSuccess}
                                    handleClose={() => setIsHiredSuccess(false)}
                                />
                                <CandidateFilter
                                    isOpen={isCandidateFilterOpen}
                                    handleClose={() => setIsCandidateFilterOpen(false)}
                                    jobPositionData={positionData}
                                    cityData={cityData}
                                    handleCandidateFilter={handleCandidateFilter}
                                    path={path}
                                />
                                <ChooseInReviewOption
                                    isOpen={isOpenInReviewOption}
                                    handleClose={() => setIsOpenInReviewOption(false)}
                                    data={candidateData}
                                    handleJobOfferSummary={handleJobOfferSummary}
                                    updateStatus={updateStatus}
                                />

                            </div>
                        </div>
                    </div>
                </Box>
            </section>
        </HomeLayout>
    );
}
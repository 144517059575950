import { Col, /**Container, FormGroup,*/ Row } from "react-bootstrap";
import styles from './Activelylooking.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
// import croseClose from '../../../../assets/images/croseclose.svg';
// import checkBos from '../../../../assets/images/checkNew.svg';

import Popup from "../Popup";
// import { Dropdown } from "../../../common/Dropdown/Dropdown";
// import { useForm, Controller } from "react-hook-form";
// import InputField from "../../../common/InputField/InputField";
// import TextArea from "../../TextArea/TextArea";
import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import {
//    Duration
// } from '../../../../common/DropdownOptions';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function Activelylooking({
    isOpen,
    handleClose,
    matchJobBy,
    setMatchJob,
    jobNotification,
    setNotification,
    alertInterval,
    setInterval,
    title,
    handleCancel
}) {

//   const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm();

  return (
          <Popup isOpen={isOpen} isClose={handleCancel}>
            <div className={styles.newCss}>
            <img src={closeJob} onClick={handleCancel} alt=''/>
            <Col md={12} className={styles.newBg}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headReject}>
                            <h3>{title}<span></span></h3>
                        </div>
                    </Col> 
                    <Col md={12}>
                        <Row>
                            <Col md={6}>
                                <div className={styles.matchJobBy}>
                                    <div className={styles.optionSelect}>
                                        <h3>Match Job by</h3>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="smartAiAgent">Smart AI Agent</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="radio" 
                                            name="matchJobBy" 
                                            value={0} 
                                            className={styles.checkboxInput}
                                            onChange={()=>setMatchJob(0)}
                                            checked={matchJobBy === 0}
                                            id="smartAiAgent"
                                             />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="myCriteria">My Criteria Exactly</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="radio" 
                                            name="matchJobBy" 
                                            value={1} 
                                            className={styles.checkboxInput} 
                                            checked={matchJobBy === 1}
                                            onChange={()=>setMatchJob(1)}
                                            id="myCriteria"
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="wideprofile">Wide Profile Information</label>
                                        <label className={styles.checkbox}>
                                        <input 
                                            type="radio" 
                                            name="matchJobBy" 
                                            value={2} 
                                            className={styles.checkboxInput} 
                                            checked={matchJobBy === 2}
                                            onChange={()=>setMatchJob(2)}
                                            id="wideprofile"
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.alertBy}>
                                    <div className={styles.optionSelect}>
                                        <h3>Alert Interval</h3>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="asSoonAs">As soon as I get it</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="radio" 
                                            className={styles.checkboxInput} 
                                            id="asSoonAs"
                                            name="alertInterval"
                                            checked={alertInterval === 0}
                                            onChange={()=>setInterval(0)}
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="onlyOnTime">Only on Time Schedule</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="radio" 
                                            className={styles.checkboxInput} 
                                            id="onlyOnTime"
                                            name="alertInterval"
                                            checked={alertInterval === 1}
                                            onChange={()=>setInterval(1)}
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div> 
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className={styles.matchJobBy}>
                                    <div className={styles.optionSelect}>
                                        <h3>Notification Of</h3>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="interviewRequest">Interview Request</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="checkbox" 
                                            className={styles.checkboxInput} 
                                            onChange={()=>setNotification('interviewRequest')}
                                            checked={jobNotification.interviewRequest}
                                            id="interviewRequest"
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="shortlisted">Shortlisted</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="checkbox" 
                                            className={styles.checkboxInput}
                                            onChange={()=>setNotification('shortlisted')}
                                            checked={jobNotification.shortlisted}
                                            id="shortlisted"
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="requestCall">Request Call</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="checkbox" 
                                            className={styles.checkboxInput} 
                                            onChange={()=>setNotification('requestCall')}
                                            checked={jobNotification.requestCall}
                                            id="requestCall"
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="jobOffer">Job Offer</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="checkbox" 
                                            className={styles.checkboxInput} 
                                            onChange={()=>setNotification('jobOffer')}
                                            checked={jobNotification.jobOffer}
                                            id="jobOffer"
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="hired">Hired</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="checkbox" 
                                            className={styles.checkboxInput}
                                            onChange={()=>setNotification('hired')}
                                            checked={jobNotification.hired} 
                                            id="hired"
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                    <div className={styles.checkItm}>
                                        <label htmlFor="rejected">Rejected</label>
                                        <label className={styles.checkbox}>
                                            <input 
                                            type="checkbox" 
                                            className={styles.checkboxInput} 
                                            onChange={()=>setNotification('rejected')}
                                            checked={jobNotification.rejected}
                                            id="rejected"
                                            />
                                            <span className={styles.checkboxInner} />
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className={styles.btnNew}>
                            <div className={styles.btnCancel} onClick={handleCancel}>
                                <div className={styles.effNew}></div>
                                <button>Cancel <span></span></button>
                            </div>
                            <div className={styles.btnReset} onClick={handleClose}>
                                <div className={styles.effReset}></div>
                                <button>Apply<span></span></button>
                            </div>
                        </div>

                    </Col>
                </Row> 
            </Col>
            </div>
        </Popup>
  );
}







// import { useState, useEffect } from "react";
// import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './InterviewAccepted.module.scss';
import interviewAccepted from '../../../../../assets/images/interviewDateTime.svg';


export default function InterviewAcceptedCard(/**{
  children,
  isOpen,
  isClose
 }*/) {

  return (
    <div className={styles.actionButtons}>
        <div className={styles.interviewAcceptedcard}>
            <div className={styles.ShotlisteffViewcard}></div>
            <button><img src={interviewAccepted} alt='' /> Accepted<span></span> </button>
        </div>
    </div>
  );
}







import { Col, Row } from "react-bootstrap";
import styles from './ChoosePlan.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import trialImage from '../../../../assets/images/trial.svg';
import basicPlan from '../../../../assets/images/basicplan.svg';
import checkBos from '../../../../assets/images/checkNew.svg';
import checkImage from '../../../../assets/images/sahi.svg';
import infoStatus from '../../../../assets/images/infoStatus.svg';
import Tooltip from '@mui/material/Tooltip';

import Popup from "../Popup";
import { useEffect, useState } from "react";
import { orange } from "@mui/material/colors";

export default function ChoosePlan({
    isOpen,
    handleClose,
    setPlanStatus,
    submitJob,
    vacancy
}) {

    const [plan, setPlan] = useState("");
    const [isAgree, setIsAgree] = useState(false);
    const [planError, setPlanError] = useState(false);
    const [agreeError, setAgreeError] = useState("");
    const [selectedVacancy, setSelectedVacancy] = useState(0);
    const [customAmount, setCustomAmount] = useState(0);

    useEffect(() => {
        setSelectedVacancy(vacancy);
        if (selectedVacancy <= 2) {
            setCustomAmount(selectedVacancy * 2500)
        }
        if (selectedVacancy > 2 && selectedVacancy <= 4) {
            setCustomAmount(selectedVacancy * 2400)
        }
        if (selectedVacancy > 4 && selectedVacancy <= 6) {
            setCustomAmount(selectedVacancy * 2300)
        }
        if (selectedVacancy > 6 && selectedVacancy <= 8) {
            setCustomAmount(selectedVacancy * 2200)
        }
        if (selectedVacancy > 8 && selectedVacancy <= 10) {
            setCustomAmount(selectedVacancy * 2100)
        }
        if (selectedVacancy > 10) {
            setCustomAmount(21000)
        }

    }, [customAmount, selectedVacancy])
    const handlePlan = (data) => {
        setPlan(data);
        setPlanStatus(data);
        setPlanError(false);
        if (data === "CustomQuote" && vacancy > 10) {
            document.getElementById("postBtn").innerText = "GET ME A CUSTOM QUOTE"
        } else {
            document.getElementById("postBtn").innerText = "POST MY JOB"
        }
    }

    const postJob = () => {
        if (!plan) {
            setPlanError(true);
        } else if (!isAgree) {
            setAgreeError("Please check this box to agree on Terms and Conditions")
        } else {
            submitJob();
        }
    }
    return (
        <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleClose} alt='' />
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Choose your plan <span></span></h3>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={styles.btnsInterview}>
                                <ul>
                                    <li>
                                        {plan && plan === 'Free' && <img className={styles.checkList} src={checkBos} alt='' />}
                                        <a
                                            className={`${styles.trialPlan} ${plan === 'Free' ? styles.trialPlanSelected : ""}`}
                                            onClick={() => handlePlan('Free')}>
                                            <img src={trialImage} alt='' /> Trial Plan <span>₹0</span></a>
                                    </li>
                                    <li>
                                        {plan && plan === 'Basic' && <img className={styles.checkList} src={checkBos} alt='' />}
                                        <a
                                            className={`${styles.basicPlan} ${plan === 'Basic' ? styles.basicPlanSelected : ""}`}
                                            onClick={() => handlePlan('Basic')}>
                                            <img src={basicPlan} alt='' />Basic Plan <span>Pay ₹500</span> </a>
                                    </li>
                                    <li>
                                        {plan && plan === 'CustomQuote' && <img className={styles.checkList} src={checkBos} alt='' />}
                                        <a
                                            className={`${styles.basicPlan} ${plan === 'CustomQuote' ? styles.basicPlanSelected : ""}`}
                                            onClick={() => handlePlan('CustomQuote')}>
                                            <img src={basicPlan} alt='' />HR Assistance <span>Pay ₹{selectedVacancy > 10 ? customAmount + "+" : customAmount}</span> </a>
                                    </li>
                                </ul>
                                {planError && <p className="errorMessage mt-1 mb-0">Select any plan</p>}
                            </div>
                            {(plan === '' || plan === 'Free') && <div className={styles.listFunction}>
                                <ul>
                                    <li><img src={checkImage} alt='' /> Quick match</li>
                                    <li><img src={checkImage} alt='' /> Limited job post</li>
                                    <li><img src={checkImage} alt='' /> Limited candidate profiles</li>
                                    <li><img src={checkImage} alt='' /> Quick call or schedule interviews</li>
                                    <li><img src={checkImage} alt='' /> Issue job offers</li>
                                </ul>
                            </div>}
                            {plan === 'Basic' && <div className={styles.listFunction}>
                                <ul>
                                    <li><img src={checkImage} alt='' /> Superfast matching</li>
                                    <li><img src={checkImage} alt='' /> 100% matched & verified candidates</li>
                                    <li><img src={checkImage} alt='' /> Quick call or schedule interviews</li>
                                    <li><img src={checkImage} alt='' /> Issue job offers</li>
                                    <li><img src={checkImage} alt='' /> Reports</li>
                                    <li><img src={checkImage} alt='' /> Customer support</li>
                                </ul>
                            </div>}
                            {plan === 'CustomQuote' && <div className={styles.listFunction}>
                                <div className={styles.sideBoxQuate}>
                                    <p>(No. of vacancies X ₹2400)</p>
                                    <h3>Vacancies <br />you posted<h5>{selectedVacancy}</h5></h3>
                                    {/* <h3>Vacancies <br/>you posted<h5>{selectedVacancy}</h5></h3> */}


                                    <div className={styles.tooltipBox}>Price Plan
                                        <Tooltip arrow title={<div>
                                            <ul>
                                                <li style={{ color: selectedVacancy <= 2 ? "#ff6600" : "" }}><img src={checkImage} alt='' /> 1 - 2 Candidate ⇢ ₹2500/Vacancy</li>
                                                <li style={{ color: selectedVacancy > 2 && selectedVacancy <= 4 ? "#ff6600" : "" }}><img src={checkImage} alt='' /> 3 - 4 Candidates ⇢ ₹2400/Vacancy</li>
                                                <li style={{ color: selectedVacancy > 4 && selectedVacancy <= 6 ? "#ff6600" : "" }}><img src={checkImage} alt='' /> 5 - 6 Candidates ⇢ ₹2300/Vacancy</li>
                                                <li style={{ color: selectedVacancy > 6 && selectedVacancy <= 8 ? "#ff6600" : "" }}><img src={checkImage} alt='' /> 7 - 8 Candidates ⇢ ₹2200/Vacancy</li>
                                                <li style={{ color: selectedVacancy > 8 && selectedVacancy <= 10 ? "#ff6600" : "" }}><img src={checkImage} alt='' /> 9 - 10 Candidates ⇢ ₹2100/Vacancy</li>
                                                <li style={{ color: selectedVacancy > 10 ? "#ff6600" : "" }}><img src={checkImage} alt='' /> 11 or more candidates ⇢ Custom Quote</li>
                                            </ul>
                                        </div>
                                        } placement="right">
                                            <img src={infoStatus} alt="info" />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={styles.candiBaseList}>
                                    <h3>Get the best candidates with ease:</h3>
                                    <ul>
                                        <li><img src={checkImage} alt='' /> We’ll provide you with 3-4 top candidates per vacancy</li>
                                        <li><img src={checkImage} alt='' /> No more sorting through endless resumes</li>
                                        <li><img src={checkImage} alt='' /> No need to make numerous calls</li>
                                        <li><img src={checkImage} alt='' /> We’ll handle the first round of interviews</li>
                                        <li><img src={checkImage} alt='' /> Dedicated customer support and detailed hiring reports included</li>
                                        <li><img src={checkImage} alt='' /> Customer support</li>
                                    </ul>
                                </div>

                            </div>}
                            <div className={styles.checkItm}>
                                <label htmlFor="isAgree">I Agree to all purchasing <a>Terms and Conditions</a></label>
                                <label className={styles.checkbox}>
                                    <input
                                        type="checkbox"
                                        name="isAgree"
                                        id="isAgree"
                                        className={styles.checkboxInput}
                                        onChange={() => setIsAgree(!isAgree)}
                                        checked={isAgree}
                                    />
                                    <span className={styles.checkboxInner} />
                                </label>
                            </div>

                            {(agreeError !== "" && !isAgree) && <p style={{ textAlign: "center" }} className="errorMessage mt-1 mb-0">{agreeError}</p>}

                            <div className={styles.btnNew}>
                                <div className={styles.btnCancel}>
                                    <div className={styles.effNew}></div>
                                    <button>Back <span></span></button>
                                </div>
                                <div className={styles.btnReset} onClick={postJob}>
                                    <div className={styles.effReset}></div>
                                    <button id="postBtn">Post my job<span></span></button>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}







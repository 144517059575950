// import { useState, useEffect } from "react";
// import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './WaitForCandidateCardView.module.scss';
import CandidateResponds from '../../../../../assets/images/responsePeding.svg';


export default function WaitForCandidateListView(/**{
  children,
  isOpen,
  isClose
 }*/) {

  return (
    <div className={styles.paddRight}>
      <div className={styles.tooltipcss}>
        <span tooltip="Please wait for the candidate to respond" flow="left">
          <img src={CandidateResponds} alt='' />
        </span>
      </div>
    </div>
  );
}







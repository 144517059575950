// import { useState, useEffect } from "react";
import { Col, /**Container, FormGroup,*/ Row } from "react-bootstrap";
import styles from './CallHistory.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
// import pro from '../../../../assets/images/userProfile.jpg';
// import callInter from '../../../../assets/images/onCall.svg';
// import officeInter from '../../../../assets/images/officeInterview.svg';
// import checkBos from '../../../../assets/images/checkNew.svg';
// import SmarySec from '../../../../assets/images/smartsec.svg';

import Popup from "../Popup";
// import { Dropdown } from "../../../common/Dropdown/Dropdown";
// import { useForm, Controller } from "react-hook-form";
// import InputField from "../../../common/InputField/InputField";
import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import {
//     Duration
// } from '../../../../common/DropdownOptions';
import { getLocalTimeFormatted, getTimeDifference } from "../../../../helpers";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function CallHistory({
    isOpen,
    handleClose,
    data: {
        interviewList,
        interviewVia
    },
}) {

    return (
        <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleClose} alt=''/>
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Call History <span></span> </h3>
                                <div className={styles.scrollbarContainer}>
                                    <div className={styles.scrollContainer}>
                                        <div className={styles.scrollcontent}>
                                            {
                                                interviewList && interviewList.length > 0 && interviewList.map((
                                                    {
                                                        interviewStartedAt,
                                                        interviewEndedAt,
                                                        interviewOccurred,
                                                        // interviewStatus,
                                                        // statusLeg1,
                                                        // statusLeg2
                                                    }, index) => {
                                                    return (
                                                        <div className={styles.interviewset} key={index}>
                                                            <h3><span>Time:</span>{getLocalTimeFormatted(interviewStartedAt)}</h3>
                                                            <h3><span>Duration:</span>{getTimeDifference(interviewStartedAt, interviewEndedAt)}</h3>
                                                            <h3><span>Interview:</span>{interviewVia}</h3>
                                                            {interviewOccurred ? (
                                                                <h3 className={styles.resPonds}><p>Respond</p></h3>
                                                            ) :
                                                                (
                                                                    <h3 className={styles.notresPonds}><p>Not Response</p></h3>
                                                                )}
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* <div className={styles.interviewset}>
                                            <h3><span>Time:</span>10:15 am</h3>
                                            <h3><span>Duration:</span>30 Min</h3>
                                            <h3><span>Interview:</span>On Call</h3>
                                            <h3 className={styles.resPonds}><p>Respond</p></h3>
                                            <h3 className={styles.notresPonds}><p>Not Response</p></h3>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}







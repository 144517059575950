// import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './SelectTimeDay.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';


import Popup from "../Popup";
// import { Dropdown } from "../../../common/Dropdown/Dropdown";
import moment from "moment";
// import {
//     language,
// } from '../../../../common/DropdownOptions';
import { Controller, useForm } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { getLocalTime } from "../../../../helpers";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function SelectTimeDay({
    isOpen,
    handleClose,
    handleTime,
    timeSchedule: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        fromTime,
        toTime
    },
    handleworkingDay,
    handleCancel
}) {

    const { formState: { errors }, control } = useForm();

    const handleTimeDisable = (timeValue, clockType) => {
        if (clockType === 'hours' && timeValue <= moment(getLocalTime(fromTime), 'HH:mm:ss').format('H')) {
            return true;
        }
    }
    return (
        <Popup isOpen={isOpen} isClose={handleCancel}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleCancel} alt=""/>
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Select Time/Day<span></span></h3>
                            </div>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col md={12} className={styles.FormGroup}>
                                    <div className="dateAndTimePicker">
                                    <label>Choose time from</label>
                                    <Controller
                                        className={styles.newWidth}
                                        name="fromTime"
                                        ampmInClock
                                        control={control}
                                        render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    // placeholder="Choose time from"
                                                    // label="From"
                                                    value={moment(getLocalTime(fromTime), "HH:mm:ss")}
                                                    onChange={(newValue) => {
                                                        handleTime('fromTime', newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    inputRef={ref}
                                                />
                                            </LocalizationProvider>

                                        )}
                                        rules={{ required: 'Required' }}
                                        onFocus={errors.fromTime?.ref}
                                    />
                                    {errors !== undefined && errors.fromTime && (
                                        <span className="errorMessage">{errors.fromTime.message}</span>
                                    )}
                                    </div>
                                </Col>
                                <Col md={12} className={styles.FormGroup}>
                                <div className="dateAndTimePicker">
                                    <label>Choose time to</label>
                                    <Controller
                                        className={styles.newWidth}
                                        name="toTime"
                                        control={control}
                                        render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                <TimePicker
                                                    // label="toTime"
                                                    // placeholder="Choose time to"
                                                    value={moment(getLocalTime(toTime), "HH:mm:ss")}
                                                    onChange={(newValue) => {
                                                        handleTime('toTime', newValue)
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    inputRef={ref}
                                                    shouldDisableTime={handleTimeDisable}
                                                />
                                            </LocalizationProvider>

                                        )}
                                        rules={{ required: 'Required' }}
                                        onFocus={errors.timeTo?.ref}
                                    />
                                    {errors !== undefined && errors.timeTo && (
                                        <span className="errorMessage">{errors.timeTo.message}</span>
                                    )}
                                    </div>
                                </Col>
                                <Col md={12} className={styles.FormGroup}>
                                    <label>Working Day</label>
                                    <div className={styles.dayNames}>
                                        <span className={monday ? styles.dayNamesSelected : ""} onClick={() => handleworkingDay('monday')}>Mon</span>
                                        <span className={tuesday ? styles.dayNamesSelected : ""} onClick={() => handleworkingDay('tuesday')}>Tue</span>
                                        <span className={wednesday ? styles.dayNamesSelected : ""} onClick={() => handleworkingDay('wednesday')}>Wed</span>
                                        <span className={thursday ? styles.dayNamesSelected : ""} onClick={() => handleworkingDay('thursday')}>Thu</span>
                                        <span className={friday ? styles.dayNamesSelected : ""} onClick={() => handleworkingDay('friday')}>Fri</span>
                                        <span className={saturday ? styles.dayNamesSelected : ""} onClick={() => handleworkingDay('saturday')}>Sat</span>
                                        <span className={sunday ? styles.dayNamesSelected : ""} onClick={() => handleworkingDay('sunday')}>Sun</span>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className={styles.btnNew}>
                                        <div className={styles.btnReset} onClick={handleClose}>
                                            <div className={styles.effReset}></div>
                                            <button>Apply<span></span></button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}







import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './JobOfferSummary.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
// import pro from '../../../../assets/images/userProfile.jpg';
// import callInter from '../../../../assets/images/onCall.svg';
// import officeInter from '../../../../assets/images/officeInterview.svg';
// import checkBos from '../../../../assets/images/checkNew.svg';
// import SmarySec from '../../../../assets/images/smartsec.svg';

import Popup from "../Popup";
import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { useForm, Controller } from "react-hook-form";
import InputField from "../../../common/InputField/InputField";
import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
import {
    // SalaryExp,
    // Bouns,
    // jobTypeOption,
    // workingShiftOption,
    // Amount,
    // jobnoticPeriod,
    // jobOfferDate,
    // JobOfferTime,
    // incentivePeriodOption,
    JobOfferOneTimeBonus,
    currencyOptions
} from '../../../../common/DropdownOptions';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import TextArea from "../../TextArea/TextArea";
// import { useNavigate } from "react-router-dom";
import JobOfferPreview from "../JobOfferPreview/JobOfferPreview";
import { hideLoader, showLoader } from "../../../../helpers";
import { viewCandidate, viewJob } from "../../../../services/api/employer";
import { toast } from "react-toastify";
import GoogleAutoComplete from '../../../common/GoogleAutoComplete/GoogleAutoComplete'

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function JobOfferSummary({
    isOpen,
    handleClose,
    jobMatchData,
    updateStatus
}) {
    //   const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue, reset } = useForm();

    //state
    const { bonusPeriod, bonusAmount } = getValues();
    const [isOpenOfferLetter, setIsOpenJobOfferLetter] = useState(false);
    const [offerData, setOfferData] = useState();
    const [jobData, setJobData] = useState();
    const [candidateData, setCandidateData] = useState();
    const [officeAddress, setOfficeAddress] = useState("")
    const [place, setPlace] = useState({});
    const [isAddrEnter, setIsAddrEnter] = useState(false);
    useEffect(() => {
        if (jobMatchData && jobMatchData.jobId && jobMatchData.candidateId) {
            setOfficeAddress("")
            getJob(jobMatchData.jobId);
            getCandidate(jobMatchData.candidateId);
        }
    }, [jobMatchData]);

    const getJob = async (jobId) => {
        try {
            // const {jobId } = jobMatchData;
            showLoader();
            let params = {
                id: jobId
            }
            const {
                data: {
                    data
                }
            } = await viewJob(params);
            hideLoader();
            setJobData(data);

        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const getCandidate = async (candidateId) => {
        try {
            // const { candidateId } = jobMatchData;
            showLoader();
            let params = {
                candidateId
            }
            const {
                data: {
                    data /** ,message*/
                }
            } = await viewCandidate(params);
            hideLoader();
            setCandidateData(data);

        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const handleReset = () => {
        reset();
        setIsOpenJobOfferLetter(false);
        handleClose();
    }

    const submit = (submitData) => {
        // localStorage.setItem('jobSummaryData', JSON.stringify(submitData));
        // reset();
        // handleClose();
        // navigate('/jobofferletter',{
        //     state: {
        //         candidateData,
        //         jobSummaryData: submitData
        //     }
        // });


        if (officeAddress === "") {
            setIsAddrEnter(true)
        } else {
            setIsAddrEnter(false)
            submitData.jobGPSCoordinates = {
                lat: Object.keys(place).length !== 0 && place.lat ? place.lat : 0,
                lng: Object.keys(place).length !== 0 && place.lng ? place.lng : 0
            }
            submitData.jobLocation = officeAddress;
            setOfferData(submitData);
            setIsOpenJobOfferLetter(true);
        }

        //setOfferData(submitData);
        //setIsOpenJobOfferLetter(true);
    }

    return (
        <Popup isOpen={isOpen} isClose={handleReset}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleReset} alt="" />
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Send a job offer<span></span></h3>
                            </div>
                        </Col>
                    </Row>
                    <form onSubmit={handleSubmit(submit)}>
                        <Row>
                            <Col md={3}>
                                <div className={styles.FormGroup}>
                                    <label>Date of Joining</label>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <div className="dateAndTimePicker">
                                            <Controller
                                                name="joiningDate"
                                                control={control}
                                                render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                    <DatePicker
                                                        //   label="Date of Joining"
                                                        value={moment(value, "YYYY-MM-DD")}
                                                        onChange={(newValue) => {
                                                            onChange(moment(newValue).format("YYYY-MM-DD"));
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.joiningDate?.ref}
                                            />
                                            {errors !== undefined && errors.joiningDate && (
                                                <span className="errorMessage">{errors.joiningDate.message}</span>
                                            )}
                                        </div>
                                    </LocalizationProvider>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={styles.FormGroup}>
                                    <label>Notic Period (By Days)</label>
                                    <div className={styles.incenNew}>
                                        <InputField
                                            className={styles.formControl}
                                            name="noticePeriod"
                                            placeholder="Job Notice Period"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                    message: 'Notice period should contain only numbers'
                                                }
                                            }}
                                            error={errors?.noticePeriod}
                                            type="number"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={styles.FormGroup}>
                                    <label>Currency</label>
                                    <div className={styles.incenNew}>
                                        <Controller
                                            name="salaryCurrency"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    isSearchable={true}
                                                    options={currencyOptions}
                                                    placeholder="currency"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(e.value)}
                                                    error={errors?.salaryCurrency}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: true }}
                                            onFocus={errors.salaryCurrency?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col md={3}>
                                <div className={styles.FormGroup}>
                                    <label>Salary (By Month)</label>
                                    <InputField
                                        className={styles.formControl}
                                        name="salary"
                                        placeholder="Salary"
                                        register={register}
                                        validation={{
                                            required: 'Required',
                                            pattern: {
                                                value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                message: 'Salary should contain only numbers'
                                            }
                                        }}
                                        error={errors?.salary}
                                        type="number"
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={styles.FormGroup}>
                                    <label>One time bonus </label>
                                    <div className={styles.incenNew}>
                                        <Controller
                                            name="bonusPeriod"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    isSearchable={true}
                                                    options={JobOfferOneTimeBonus}
                                                    placeholder="Bonus period"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(e.value)}
                                                    error={errors?.bonusPeriod}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: true }}
                                            onFocus={errors.bonusPeriod?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col md={4}>
                                <div className={styles.FormGroup}>
                                    <label>Bonus amount</label>
                                    <div className={styles.incenNew}>
                                        <InputField
                                            className={styles.formControl}
                                            name="bonusAmount"
                                            placeholder="Bonus Amount"
                                            register={register}
                                            validation={{
                                                required: (bonusPeriod && !bonusAmount) ? 'Required' : false,
                                                pattern: {
                                                    value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                    message: 'Bonus amount should contain only numbers'
                                                }
                                            }}
                                            error={errors?.bonusAmount}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col md={4}>
                                <div className={styles.FormGroup}>
                                    <label>Offer letter expire</label>
                                    <Row>
                                        <Col md={6}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <div className="dateAndTimePicker">
                                                    <Controller
                                                        name="expireDate"
                                                        control={control}
                                                        render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                            <DatePicker
                                                                label="Expire Date"
                                                                value={moment(value, "YYYY-MM-DD")}
                                                                onChange={(newValue) => {
                                                                    onChange(moment(newValue).format("YYYY-MM-DD"));
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors.expireDate?.ref}
                                                    />
                                                    {errors !== undefined && errors.expireDate && (
                                                        <span className="errorMessage">{errors.expireDate.message}</span>
                                                    )}
                                                </div>
                                            </LocalizationProvider>
                                        </Col>
                                        <Col md={6}>
                                            {/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
                                            <div className="dateAndTimePicker">
                                                <Controller
                                                    name="expireTime"
                                                    control={control}
                                                    render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker
                                                                label="Expire Time"
                                                                value={moment(value, "HH:mm:ss")}
                                                                onChange={(newValue) => {
                                                                    onChange(moment(newValue).format("HH:mm:ss"));
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                inputRef={ref}
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                    rules={{ required: 'Required' }}
                                                    onFocus={errors.expireTime?.ref}
                                                />
                                                {errors !== undefined && errors.expireTime && (
                                                    <span className="errorMessage">{errors.expireTime.message}</span>
                                                )}
                                            </div>
                                            {/* </LocalizationProvider> */}
                                        </Col>
                                    </Row>


                                </div>
                            </Col>

                            <Col md={12}>
                                <div className={styles.FormGroup}>
                                    <label>Job Loction</label>
                                    {/* <TextArea
                                        className={styles.formControl}
                                        name="jobLocation"
                                        placeholder="Enter the location"
                                        register={register}
                                        maxLength='300'
                                        validation={{
                                            required: 'Required'
                                        }}
                                        error={errors?.jobLocation} /> */}
                                    <GoogleAutoComplete inputName="companyAddress" setOfficeAddress={setOfficeAddress}
                                        inputVal={officeAddress} setPlace={setPlace} styles={styles.formControl} />
                                    {isAddrEnter && <span className="errorMessage">Required</span>}
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={styles.FormGroup}>
                                    <label>Any additional information</label>
                                    <TextArea
                                        className={styles.formControl}
                                        name="comments"
                                        placeholder="Enter the information"
                                        register={register}
                                        maxLength='1000'
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={styles.schedBtn}>
                                    <div className={styles.editInter} onClick={handleReset}>
                                        <div className={styles.effNew}></div>
                                        <button>Cancel<span></span></button>
                                    </div>
                                    <div className={styles.okInter} onClick={handleSubmit(submit)}>
                                        <div className={styles.effNew}></div>
                                        <button type="submit">Show Offer<span></span></button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Col>
            </div>
            {candidateData && jobData && offerData && <JobOfferPreview
                isOpenOfferLetter={isOpenOfferLetter}
                handleCloseOfferLetter={() => setIsOpenJobOfferLetter(false)}
                jobData={jobData}
                offerData={offerData}
                jobMatchData={jobMatchData}
                candidateData={candidateData}
                updateStatus={updateStatus}
                handleReset={handleReset}
                updateJobMatchAction={true}
            />}
        </Popup>
    );
}







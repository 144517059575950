// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoDataFound_noData__ygYm0 {
  padding: 20px;
  background: #273457;
  border: 1px solid #3A4F9B;
  margin: 20px 0px 0px;
  border-radius: 8px;
}
.NoDataFound_noData__ygYm0 .NoDataFound_noDataCont__Hvdds {
  display: inline-block;
  margin-left: 30px;
  padding: 30px 0px 30px 0px;
}
.NoDataFound_noData__ygYm0 .NoDataFound_noDataCont__Hvdds h3 {
  font-size: 18px;
  color: #E4E6EA;
  letter-spacing: 0.03em;
  font-weight: 500;
  margin: 0px 0px 10px 0px;
}
.NoDataFound_noData__ygYm0 .NoDataFound_noDataCont__Hvdds p {
  color: #B0B3B7;
  letter-spacing: 0.03em;
  font-weight: 400;
  margin: 0px 0px 10px 0px;
  font-size: 14px;
}

.NoDataFound_addJobButton__BL6ow {
  width: 180px;
  height: 40px;
  border: 1px solid #ff6600 !important;
  float: left;
  text-align: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  margin: 0px 0 0px 0px;
  background-color: #273457 !important;
  color: #ff6600 !important;
}
.NoDataFound_addJobButton__BL6ow:hover {
  background-color: #ff6600 !important;
  border-color: #ff6600 !important;
  color: #fff !important;
  transition: all 0.5s ease;
}

@media screen and (max-width: 1366px) {
  .NoDataFound_noDataCont__Hvdds {
    margin-left: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/NoDataFound/NoDataFound.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AACI;EACI,qBAAA;EACA,iBAAA;EACA,0BAAA;AACR;AACQ;EACI,eAAA;EACA,cAAA;EACA,sBAAA;EACA,gBAAA;EACA,wBAAA;AACZ;AAEQ;EACI,cAAA;EACA,sBAAA;EACA,gBAAA;EACA,wBAAA;EACA,eAAA;AAAZ;;AAKA;EACI,YAAA;EACA,YAAA;EACA,oCAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,oCAAA;EACA,yBAAA;AAFJ;AAII;EACI,oCAAA;EACA,gCAAA;EACA,sBAAA;EACA,yBAAA;AAFR;;AAOA;EACI;IACI,iBAAA;EAJN;AACF","sourcesContent":[".noData {\n    padding: 20px;\n    background: #273457;\n    border: 1px solid #3A4F9B;\n    margin: 20px 0px 0px;\n    border-radius: 8px;\n\n    .noDataCont {\n        display: inline-block;\n        margin-left:30px;\n        padding: 30px 0px 30px 0px;\n\n        h3 {\n            font-size: 18px;\n            color: #E4E6EA;\n            letter-spacing: 0.03em;\n            font-weight: 500;\n            margin: 0px 0px 10px 0px;\n        }\n\n        p {\n            color: #B0B3B7;\n            letter-spacing: 0.03em;\n            font-weight: 400;\n            margin: 0px 0px 10px 0px;\n            font-size: 14px;\n        }\n    }\n}\n\n.addJobButton {\n    width: 180px;\n    height: 40px;\n    border: 1px solid #ff6600 !important;\n    float: left;\n    text-align: center;\n    cursor: pointer;\n    position: relative;\n    box-sizing: border-box;\n    border-radius: 5px;\n    overflow: hidden;\n    margin: 0px 0 0px 0px;\n    background-color: #273457 !important;\n    color: #ff6600 !important;\n\n    &:hover {\n        background-color: #ff6600 !important;\n        border-color: #ff6600 !important;\n        color: #fff !important;\n        transition: all .5s ease;\n    }\n}\n\n\n@media screen and (max-width: 1366px){\n    .noDataCont {\n        margin-left: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noData": `NoDataFound_noData__ygYm0`,
	"noDataCont": `NoDataFound_noDataCont__Hvdds`,
	"addJobButton": `NoDataFound_addJobButton__BL6ow`
};
export default ___CSS_LOADER_EXPORT___;

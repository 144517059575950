import { useEffect, useState } from "react";
import Footer from "../../components/common/Footer/Footer";
import Header from "../../components/common/Header/Header";
import Sidebar from "../../components/common/Sidebar/Sidebar";
import { hideLoader, showLoader } from "../../helpers";
import { getProfile } from "../../services/api/employer";
//css
import styles from './HomeLayout.module.scss';

const HomeLayout = ({ children }) => {

    const [userData, setUserData] = useState({});

    let data = localStorage.getItem('userData');

    useEffect(() => {
        getProfileData();
    }, [data]);

    /**
   * API-> https://api.jobsgaar.com/v1/employer/get/my/profile
   *This method is used to get the user information from the DB
   */
    const getProfileData = async () => {
        try {
            showLoader();
            let {
                data: {
                    data
                }
            } = await getProfile();
            hideLoader();
            setUserData(data);

        } catch (error) {
            hideLoader();
        }
    }

    // userData = JSON.parse(userData);
    return (
        <>
            <div className={styles.wrapper}>
                <Sidebar userData={userData}/>
                <Header userData={userData}/>
                <div className={styles.pageContentWrapper}>
                    {children}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default HomeLayout;
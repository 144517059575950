// import { useState, useEffect } from "react";
// import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './InterviewCompleted.module.scss';
import InterviewComplete from '../../../../../assets/images/interviewCom.svg';


export default function InterviewCompletedCardRight(/**{
  children,
  isOpen,
  isClose
 }*/) {

  return (
    <div className={styles.actionButtons}>
        <div className={styles.rejectcardButon}>
            <div className={styles.RejectcardeffNew}></div>
            <button><img src={InterviewComplete} alt='' /> feedback<span></span></button>
         </div>
    </div>
  );
}












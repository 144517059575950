// import { useState, useEffect } from "react";
// import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './SaveJob.module.scss';
import saveJob from '../../../assets/images/savejobimg.svg';
import closeJob from '../../../assets/images/closeIcon.svg';


import Popup from "../../common/Popup/Popup";
import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function SaveJob({
  isOpen,
  handleClose,
  onClick
}) {
  return (
      <Popup isOpen={isOpen} isClose={handleClose}>
          <div className={styles.newCss}>
            <img src={closeJob} onClick={handleClose} alt=""/>
            <div className={styles.popNew}>
              <img className={styles.closeIcon} src={saveJob}/>
              <h3>Save Job Post</h3>
              <p>Are you 100% sure, you want to edit this job!</p>
              <div className={styles.btnNew} type="submit" onClick={onClick}>
                <div className={styles.effNew}></div>
                <a>Submit<span></span></a>
              </div>
              <a className="pointer" onClick={handleClose}>No, Go Back</a>
            </div>
          </div>
      </Popup>
  );
}







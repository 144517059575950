// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyTrust_clientTrust__P8L98 {
  border-right: 1px solid #273457;
}
.CompanyTrust_clientTrust__P8L98 .CompanyTrust_cerasoleTest__HdDaN h3 {
  font-size: 18px;
  color: #B0B3B7;
  margin: 0px 0px 20px 0px;
}
.CompanyTrust_clientTrust__P8L98 .CompanyTrust_cerasoleTest__HdDaN .CompanyTrust_cerasoleNew__mlrv2 .CompanyTrust_CompanyLog__fDnLA {
  line-height: 120px;
}
.CompanyTrust_clientTrust__P8L98 .CompanyTrust_cerasoleTest__HdDaN .CompanyTrust_cerasoleNew__mlrv2 .CompanyTrust_CompanyLog__fDnLA ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.CompanyTrust_clientTrust__P8L98 .CompanyTrust_cerasoleTest__HdDaN .CompanyTrust_cerasoleNew__mlrv2 .CompanyTrust_CompanyLog__fDnLA ul li {
  display: inline-block;
  margin: 0px 37px 0px 0px;
}
.CompanyTrust_clientTrust__P8L98 .CompanyTrust_cerasoleTest__HdDaN .CompanyTrust_cerasoleNew__mlrv2 .CompanyTrust_CompanyLog__fDnLA ul li img {
  width: 100px;
  padding-top: 30px;
}
.CompanyTrust_clientTrust__P8L98 .CompanyTrust_SliderLogo__xQh5r .CompanyTrust_CompanyTwoLog__rJqrm {
  line-height: 120px;
}
.CompanyTrust_clientTrust__P8L98 .CompanyTrust_SliderLogo__xQh5r .CompanyTrust_CompanyTwoLog__rJqrm img {
  width: 170px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyTrust/CompanyTrust.module.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;AACJ;AACQ;EACI,eAAA;EACA,cAAA;EACA,wBAAA;AACZ;AAEY;EACI,kBAAA;AAAhB;AACgB;EACI,gBAAA;EACA,YAAA;EACA,WAAA;AACpB;AAAoB;EACI,qBAAA;EACA,wBAAA;AAExB;AAGwB;EACI,YAAA;EACA,iBAAA;AAD5B;AAWQ;EACI,kBAAA;AATZ;AAUY;EACI,YAAA;AARhB","sourcesContent":[".clientTrust{\n    border-right: 1px solid #273457;\n    .cerasoleTest{\n        h3{\n            font-size:18px;\n            color: #B0B3B7;\n            margin: 0px 0px 20px 0px;\n        }\n        .cerasoleNew{\n            .CompanyLog{\n                line-height: 120px;\n                ul{\n                    list-style: none;\n                    padding: 0px;\n                    margin:0px;\n                    li{\n                        display: inline-block;\n                        margin:0px 37px 0px 0px;\n                        // width: 120px;\n                        // background:#fff;\n                        // border-radius: 10px;\n                        // height: 70px;\n                        img{\n                            width: 100px;\n                            padding-top: 30px;\n                        }\n                    }\n                }\n            }\n            \n        }\n       \n    }\n    .SliderLogo{\n        .CompanyTwoLog{\n            line-height: 120px;\n            img{\n                width: 170px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientTrust": `CompanyTrust_clientTrust__P8L98`,
	"cerasoleTest": `CompanyTrust_cerasoleTest__HdDaN`,
	"cerasoleNew": `CompanyTrust_cerasoleNew__mlrv2`,
	"CompanyLog": `CompanyTrust_CompanyLog__fDnLA`,
	"SliderLogo": `CompanyTrust_SliderLogo__xQh5r`,
	"CompanyTwoLog": `CompanyTrust_CompanyTwoLog__rJqrm`
};
export default ___CSS_LOADER_EXPORT___;

import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './DeactivateAccount.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import croseClose from '../../../../assets/images/croseclose.svg';
import checkBos from '../../../../assets/images/checkNew.svg';

import Popup from "../Popup";
// import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { useForm } from "react-hook-form";
// import InputField from "../../../common/InputField/InputField";
import TextArea from "../../TextArea/TextArea";
import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import {
//     Duration
// } from '../../../../common/DropdownOptions';
import { hideLoader, showLoader } from "../../../../helpers";
import { deactivateAccount } from "../../../../services/api/employer";
import { toast } from "react-toastify";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function DeactivateAccount({
    isOpen,
    handleClose
}) {

     const { register, handleSubmit /** ,formState: { errors }, getValues, control, setValue */} = useForm();

    const [deactivationDuration, setDeactivateDuration] = useState('Deactivate for 1 Month');
    const [deactivationReason, setDeactivationReason] = useState(['Data privacy concern']);

    let optionOne = [
        'Data privacy concern',
        'I have got too many calls and notification',
        'I have another account',
        'I already found a job with Jobsgaar',
    ];

    let optionTwo = [
        'Found a job offline with other online platform',
        'I already found a job offline',
        'I am rethinking my professional career',
        'Other'
    ]

    const handleReason = (item) => {
        if (deactivationReason.includes(item)) {
            setDeactivationReason(deactivationReason.filter(i => i !== item));
        } else {
            deactivationReason.push(item);
            setDeactivationReason([
                ...deactivationReason
            ]);
        }
    }

    const submit = async(submitData) => {
        try {
            showLoader();
            let values = {
                changeTo: "Deactivated",
                deactivationDuration,
                deactivationReason,
                deactivationOtherReason: submitData.deactivationOtherReason ? submitData.deactivationOtherReason : ""
            }
            let {
                data: {
                  /**data,*/ message
                }
              } = await deactivateAccount(values);
              hideLoader();
              toast.success(message ? message : "Success");
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }


    return (
        <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleClose} alt=''/>
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Deactivate Account <span></span></h3>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={styles.btnsInterview}>
                                <ul>
                                    <li>
                                        {deactivationDuration === 'Deactivate for 1 Month' && <img className={styles.checkList} src={checkBos} alt=''/>}
                                        <a
                                            className={`${styles.trialPlan} ${deactivationDuration === 'Deactivate for 1 Month' ? styles.trialPlanSelected : ""}`}
                                            onClick={() => setDeactivateDuration('Deactivate for 1 Month')}>
                                            <img src={croseClose} alt=''/>
                                            Deactivate for 1 Month
                                        </a>
                                    </li>
                                    <li>
                                        {deactivationDuration === 'Deactivate for 3 Months' && <img className={styles.checkList} src={checkBos} alt=''/>}
                                        <a
                                            className={`${styles.trialPlan} ${deactivationDuration === 'Deactivate for 3 Months' ? styles.trialPlanSelected : ""}`}
                                            onClick={() => setDeactivateDuration('Deactivate for 3 Months')}>
                                            <img src={croseClose} alt=''/>
                                            Deactivate for 3 Months
                                        </a>
                                    </li>
                                    <li>
                                        {deactivationDuration === 'Deactivate permanently' && <img className={styles.checkList} src={checkBos} alt=''/>}
                                        <a
                                            className={`${styles.trialPlan} ${deactivationDuration === 'Deactivate permanently' ? styles.trialPlanSelected : ""}`}
                                            onClick={() => setDeactivateDuration('Deactivate permanently')}>
                                            <img src={croseClose} alt=''/>
                                            Deactivate permanently
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <Row>
                                <Col md={12}>
                                    <div className={styles.optionSelect}>
                                        <h3>Reason for Deactivation</h3>
                                    </div>
                                </Col>
                                <Col md="6">
                                    {optionOne?.length > 0 && optionOne.map((item, index) => {
                                        return (
                                            <div className={styles.checkItm} key={index}>
                                                <label htmlFor={item}>{item}</label>
                                                <label className={styles.checkbox}>
                                                    <input
                                                        type="checkbox"
                                                        className={styles.checkboxInput}
                                                        onChange={() => handleReason(item)}
                                                        checked={deactivationReason.includes(item)}
                                                        id={item}
                                                    />
                                                    <span className={styles.checkboxInner} />
                                                </label>
                                            </div>
                                        )
                                    })}
                                </Col>
                                <Col md="6">
                                    {optionTwo?.length > 0 && optionTwo.map((item, index) => {
                                        return (
                                            <div className={styles.checkItm} key={index}>
                                                <label htmlFor={item}>{item}</label>
                                                <label className={styles.checkbox}>
                                                    <input
                                                        type="checkbox"
                                                        className={styles.checkboxInput}
                                                        onChange={() => handleReason(item)}
                                                        checked={deactivationReason.includes(item)}
                                                        id={item}
                                                    />
                                                    <span className={styles.checkboxInner} />
                                                </label>
                                            </div>
                                        )
                                    })}
                                </Col>

                                {deactivationReason.includes('Other') && <Col md={12}>
                                    <div className={styles.FormGroup}>
                                        <label>Please describe the other reason</label>
                                        <TextArea
                                            className={styles.formControl}
                                            name="deactivationOtherReason"
                                            placeholder="Description here"
                                            register={register}
                                            maxLength='300' />
                                    </div>
                                </Col>}
                            </Row>
                            <div className={styles.btnNew}>
                                <div className={styles.btnCancel} onClick={handleClose}>
                                    <div className={styles.effNew}></div>
                                    <button>Cancel <span></span></button>
                                </div>
                                <div className={styles.btnReset} onClick={handleSubmit(submit)}>
                                    <div className={styles.effReset}></div>
                                    <button>Deactivate<span></span></button>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}







import { useState, useEffect, useRef } from "react";
// import { style } from "@mui/system";
import { Col, Container, /**FormGroup,*/ Row } from "react-bootstrap";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import { getCity, getEducationList, getExperienceList, getPositionList, getSpecializationList, sendUsEmail } from "../../../services/api/common";

import { Dropdown } from "../../common/Dropdown/Dropdown";
import newupdate from '../../../assets/images/newupdate.svg';
import croseImage from '../../../assets/images/croseIcon.svg';
import styles from './AddJob.module.scss';
import { useForm, Controller } from "react-hook-form";
import InputField from "../../common/InputField/InputField";
import _ from "underscore";
import TextArea from "../../common/TextArea/TextArea";
import { hideLoader, showLoader } from "../../../helpers";
import { fileUpload } from "../../../services/api/upload";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { createJob, cronNewJob } from "../../../services/api/employer";
import moment from "moment";
import {
    jobTypeOption,
    genderOption,
    workingFromOption,
    workingShiftOption,
    languageOptions,
    incentivePeriodOption,
    JobOfferstaffNecessity
} from '../../../common/DropdownOptions';

import { useNavigate } from "react-router-dom";
import SpecializationList from "../../common/Popup/SpecializationList/SpecializationList";
import ChoosePlan from "../../common/Popup/ChoosePlan/ChoosePlan";
import JobPosted from "../../common/Popup/JobPosted/JobPosted";
import CustomQuote from "../../common/Popup/CustomQuote/CustomQuote";
import { createEmployerSubscription, subscriptionTransactionEnd } from "../../../services/api/subscription";
import GoogleAutoComplete from '../../common/GoogleAutoComplete/GoogleAutoComplete'



export default function AddJob() {

    const navigate = useNavigate();
    let userData = localStorage.getItem('userData');
    userData = JSON.parse(userData);

    const { register, handleSubmit, formState: { errors }, getValues, control, setValue, clearErrors } = useForm();
    const { jobDegree, jobBranch, jobPosition } = getValues();

    const [cityData, setCityData] = useState([]);
    const [experienceData, setExperienceData] = useState([]);
    const [workingDays, setWorkingDays] = useState({
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false
    });
    const [educationData, setEducationData] = useState([]);
    const [dropdownData, setDropdownData] = useState({});
    const [degreeData, setDegreeData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [drivingLicense, setDrivingLicense] = useState({
        dl: false,
        ownBike: false,
        ownCar: false
    });
    const [isIncentive, setIsIncentive] = useState(false);
    // const [incentiveData, setIncentiveData] = useState({});
    const [isJobCharge, setIsJobCharge] = useState(false);
    const [fileKey, setFileKey] = useState();
    const [fileName, setFileName] = useState();
    const [workingTime, setWorkingTime] = useState({});
    const [positionData, setPositionData] = useState([]);
    const [specializationData, setSpecializationData] = useState([]);
    const [jobSpecialization, setJobSepecialization] = useState(null);
    // const [jobPositionId, setJobPositionId] = useState();
    const [jobSpecializationDefaultValue, setSpecializationDefaultValue] = useState([]);
    const [fromAgeData, setFromAgeData] = useState([]);
    const [toAgeData, setToAgeData] = useState([]);
    const [fromSalaryData, setFromSalaryData] = useState([]);
    const [toSalaryData, setToSalaryData] = useState([]);
    const [degreeBranchDefaultValue, setDegreeBranchDefaultValue] = useState({});
    const [isOpenSpecialization, setIsOpenSpecialization] = useState(false);
    const [isOpenChoosePlan, setIsChoosePlan] = useState(false);
    const [isJobPosted, setIsJobPosted] = useState(false);
    const [paymentData, setPaymentData] = useState({});
    const [isPaymentClosed, setIsPaymentClosed] = useState(false);
    const GOOGLE_MAP_API_KEY = "AIzaSyD8KBT1s2sy9Wwh3Ui3MosKSAIsfuGDOOs";
    const [loadMap, setLoadMap] = useState(false);
    const placeInputRef = useRef(null);
    const [place, setPlace] = useState({});
    const [officeAddress, setOfficeAddress] = useState("");
    const [isAddrEnter, setIsAddrEnter] = useState(false);
    const [vacancy, setVacancy] = useState("");
    const [openCustomQuote, setOpenCustomQuote] = useState(false);
    const [selectedJobId, setSelectedJobId] = useState("");

    useEffect(() => {
        getCityList();
        getExperience();
        getEducation();
        getJobPosition();
        getAgeData();
        getSalaryData();
        // createSubscription('634d3694eea2a8de7aca936b', 'Basic');
        // paytmCheckout('20221017125417130.48845', '"5f2bc389116047ae98152801f93538fa1666011257163"', 500);
    }, []);

    const getCityList = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getCity();
            setCityData(
                data && data.length > 0 && data.map(({ stateName, districtName }) => {
                    let data = districtName.map((item) => { return { label: item, value: item, state: stateName } });
                    return {
                        label: stateName,
                        options: data
                    }
                })
            );
        } catch (error) {
        }
    }

    const getExperience = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getExperienceList();
            setExperienceData(
                data && data.length > 0 && data.map(({ experience, experienceYears }) => {
                    return {
                        label: experience,
                        value: experienceYears
                    }
                })
            );
        } catch (error) {
        }
    }

    const getEducation = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getEducationList();
            let educationGroup = data && _.groupBy(data, 'education');
            let forDegree = [], forBranch = [];
            for (let [key, value] of Object.entries(educationGroup)) {
                if (value && value.length > 0) {
                    forDegree[key] = value.map((item) => {
                        if (item.degree) {
                            if (item.branch && item.branch.length > 0) {
                                // forBranch[item.degree] = item.branch;
                                let key = item.degree;
                                forBranch.push({ [key]: item.branch })
                            }
                            return {
                                label: item.degree,
                                value: item.degree
                            }

                        } else {
                            return null;
                        }

                    });
                }
            }
            let formattedBranch = []
            forBranch && forBranch.length > 0 && forBranch.map((item) => {
                for (let [key, value] of Object.entries(item)) {
                    if (value && value.length > 0) {
                        formattedBranch[key] = value.map((item) => {
                            return {
                                label: item,
                                value: item
                            }
                        });
                    }
                }

            });

            let educationContent = data && data.length > 0 && data.map(({ education, educationSort }) => {
                return {
                    label: education,
                    value: educationSort
                }
            });
            educationContent = educationContent.reduce((unique, o) => {
                if (!unique.some(obj => obj.label === o.label)) {
                    unique.push(o);
                }
                return unique;
            }, []);

            setEducationData(educationContent);
            setDegreeData(forDegree);
            setBranchData(formattedBranch);
        } catch (error) {
        }
    }

    const handleDropdown = (name, value) => {
        setDropdownData({
            ...dropdownData,
            [name]: value
        })
        if (name === 'jobDegree' || name === 'jobBranch') {
            setDegreeBranchDefaultValue({
                ...degreeBranchDefaultValue,
                [name]: {
                    label: value,
                    value: value
                }
            })
        } else if (name === 'fromAge') {
            getAgeData(value);
        } else if (name === 'minSalary') {
            getSalaryData(value);
        }
        return value;
    }

    // const handleDegreeBranch = (name, data) => {
    //     setDropdownData({
    //         ...dropdownData,
    //         [name]: value
    //     })
    //     setSpecializationDefaultValue({
    //         [name]:{
    //             label: value,
    //             value: value
    //         }
    //     })
    // }

    const handleworkingDay = (day) => {
        setWorkingDays({
            ...workingDays,
            [day]: !workingDays[day]
        });
    }

    // const showCount = (name, count) => {
    //     if (name === "jobAddress") {
    //         setJobAddressCount(count)
    //     } else if (name === "jobDescription") {
    //         setJobDescriptionCount(count)
    //     }

    // }

    const handleDriving = (name, value) => {
        setDrivingLicense({
            ...drivingLicense,
            [name]: !value
        })
        return drivingLicense;
    }

    const handleFileUpload = async ({ target: { files } }) => {
        try {
            showLoader();
            let content = new FormData();
            content.append('fileKey', files[0]);
            const {
                data: {
                    data
                } } = await fileUpload(content);
            setFileKey(data?.fileKey);
            setFileName(data?.fileName);
            hideLoader();

        } catch (error) {
            toast.error("Failed");
            hideLoader();
        }
    }

    const clearFileUpload = () => {
        setFileKey("");
        setFileName("");
        document.getElementById("video").value = null;
    }

    const handleTime = (name, value) => {
        if (value) {
            setWorkingTime({
                ...workingTime,
                [name]: moment(value).format("H")
            })
            return moment(value).format("hh:mm a");
        }
    }

    // const handleIncentive = (e) => {
    //     let value = e.target.value;
    //     setIncentiveData({
    //         [dropdownData.jobIncentivePeriod]: value
    //     })
    //     setValue('jobIncentiveAmount',)
    // }

    const getJobPosition = async () => {
        try {
            const {
                data: {
                    data
                }
            } = await getPositionList();
            setPositionData(
                data && data.length > 0 && data.map(({ position, positionId, positionUid }) => {
                    return {
                        label: position,
                        value: positionId,
                        positionUid
                    }
                })
            );
        } catch (error) {
        }
    }

    const getJobSpecializtion = async (positionId) => {
        try {
            let params = {
                positionId
            }
            const {
                data: {
                    data
                }
            } = await getSpecializationList(params);
            setSpecializationData(
                data && data[0] && data[0].specialization && data[0].specialization.length > 0 && data[0].specialization.map((item) => {
                    return {
                        label: item,
                        value: item,
                        isSelected: false
                    }
                })
            );
        } catch (error) {
        }
    }

    const handleJobPosition = ({ label, value, positionUid }) => {
        if (getValues('jobPositionId') === value) {
            setIsOpenSpecialization(!isOpenSpecialization);
        } else {
            handleDropdown('jobPosition', label);
            setValue('jobPosition', label);
            setValue('jobPositionId', value);
            setValue('positionUid', positionUid);
            setValue('jobSpecialization', []);
            // setJobPositionId(value);
            setJobSepecialization([]);
            getJobSpecializtion(value);
            setSpecializationDefaultValue([]);
            setTimeout(() => {
                setIsOpenSpecialization(!isOpenSpecialization);
            }, 1000);
        }

    }

    const handleSpecialization = (data) => {
        setSpecializationDefaultValue(data);
        let values = [];
        if (data && data.length > 0) {
            let array = data.filter(i => i.isSelected === true);
            values = array.map((item) => {
                return item.value;
            });
            // values = data.filter(function(i) {
            //     if(i.isSelected === true) {
            //         return i.value;
            //     }
            // });
        }
        setJobSepecialization(values)

        setValue('jobSpecialization', values);
    }

    const getAgeData = (age) => {
        let data = [];
        let startingAge = age ? age + 1 : 18;
        for (let i = startingAge; i <= 62; i++) {
            let obj = {
                label: i,
                value: i
            }
            data.push(obj);
        }
        if (age) {
            setToAgeData(data);
        } else {
            setFromAgeData(data);
            setToAgeData(data);
        }
    }

    const getSalaryData = (salary) => {
        let data = [];
        let startingSalary = salary ? salary + 500 : 5000;
        for (let i = startingSalary; i <= 150000; i += 500) {
            let obj = {
                label: i,
                value: i
            }
            data.push(obj);
        }
        if (salary) {
            setToSalaryData(data);
        } else {
            setFromSalaryData(data);
            setToSalaryData(data);
        }
    }

    const handleMultipleOptions = (name, { label, value }) => {
        if (name === "jobExperience") {
            setValue('jobExperience', label);
            setValue('jobExperienceYears', value)
        } else if (name === "jobEducation") {
            setValue('jobEducation', label);
            setValue('jobEducationSort', value)
            setDropdownData({
                ...dropdownData,
                [name]: label,
                'jobBranch': "",
                'jobDegree': ""
            });
            setValue('jobDegree', '');
            setValue('jobBranch', '');
            // setDropdownData({
            //     ...dropdownData,
            //     'jobDegree': "",
            //     'jobBranch': ''
            // });
            setDegreeBranchDefaultValue({
                jobDegree: null,
                jobBranch: null
            });
            clearErrors("jobBranch");
        }
    }

    const handleTimeDisable = (timeValue, clockType) => {
        if (clockType === 'hours' && timeValue <= workingTime.timeFrom) {
            return true;
        }
    }

    const removeSpecialization = (index, item) => {
        if (index > -1) {
            jobSpecialization.splice(index, 1);
            setJobSepecialization([...jobSpecialization]);
            jobSpecializationDefaultValue.map(i => i.value === item ? i.isSelected = false : i);
            // jobSpecializationDefaultValue[index]['isSelected'] = false;
            setSpecializationDefaultValue([...jobSpecializationDefaultValue]);
        }
    }

    const setPlanStatus = (data) => {
        setValue('planStatus', data);
    }

    const handleChoosePlan = () => {
        if (officeAddress === "") {
            setIsAddrEnter(true)
        } else {
            setIsAddrEnter(false)
            setIsChoosePlan(!isOpenChoosePlan);
        }
    }

    const handleCity = (city, state) => {
        setValue('jobCity', city);
        setValue('jobState', state);
    }

    const closeJobBox = () => {
        setIsJobPosted(false);
        navigate('/jobs/pending');
    }

    const sentCustomQuoteEmail = async (jobId) => {
        try {
            showLoader();
            const params = {
                subject: "Custom Quote Request",
                body: "Please provide a quote for my posted job",
                employerId: userData._id,
                jobId: jobId
            }
            const result = await sendUsEmail(params);
            const { data } = result;
            if (data && data.appStatus === 111) {
                setSelectedJobId(jobId);
                setOpenCustomQuote(true);
            }
            hideLoader();
        } catch (e) {
            const { message } = e;
            toast.error(message ? message : "Please try again")
            hideLoader();
        }
    }

    const submit = async (values) => {
        try {

            showLoader();

            const { jobTitle, jobVacancy, jobDescription, jobContactPerson, jobIncentiveAmount, jobCity, jobType, jobShift, workingFrom, minSalary, maxSalary, jobGender, fromAge, toAge, jobLanguage, jobExperience, jobEducation, jobDegree, jobBranch, jobPosition, timeFrom, timeTo, oneTimeCharge, recursiveCharge, jobPositionId, positionUid, jobEducationSort, jobExperienceYears, jobSpecialization, staffNecessity, planStatus, jobState } = values;
            let incentiveContent = {};
            if (isIncentive && dropdownData.jobIncentivePeriod && jobIncentiveAmount) {
                incentiveContent = {
                    [dropdownData.jobIncentivePeriod]: jobIncentiveAmount
                }
            }
            let submitData = {
                employerId: userData._id,
                employerName: userData.fullName,
                jobCity: jobCity,
                jobType: jobType,
                jobTitle: jobTitle,
                position: jobPosition,
                positionId: jobPositionId,
                positionUid: positionUid,
                specializations: jobSpecialization,
                jobSalary: minSalary,
                jobSalaryMax: maxSalary,
                jobVacancy: jobVacancy,
                jobGender: jobGender,
                jobAge: {
                    fromAge: fromAge ? fromAge : "",
                    toAge: toAge ? toAge : ""
                },
                workingFrom: workingFrom,
                jobShift: jobShift,
                workingTime: {
                    from: timeFrom ? timeFrom : "",
                    to: timeTo ? timeTo : ""
                },
                jobExperience: jobExperience,
                jobExperienceYears: jobExperienceYears,
                jobLanguage: jobLanguage,
                workingDays: workingDays,
                jobEducation: jobEducation,
                jobEducationSort: jobEducationSort,
                jobDegree: jobDegree,
                jobBranch: jobBranch,
                jobAddress: officeAddress,
                jobGPSCoordinates: {
                    lat: Object.keys(place).length !== 0 && place.lat ? place.lat : 0,
                    lng: Object.keys(place).length !== 0 && place.lng ? place.lng : 0
                },
                jobDescription: jobDescription,
                jobContactPerson: jobContactPerson,
                welcomeVideo: fileKey,
                drivingLicense: drivingLicense,
                jobIncentive: incentiveContent,
                jobCharges: [
                    {
                        oneTimeCharge: oneTimeCharge ? oneTimeCharge : "",
                        recursiveCharge: recursiveCharge ? recursiveCharge : ""
                    }
                ],
                companyAddressOnInvoice: " ",
                companyCityOnInvoice: " ",
                companyNameOnInvoice: " ",
                companyZipOnInvoice: " ",
                jobPlan: 15,
                jobState,
                certificateDiploma: false,
                certificateDiplomaName: "",
                gstOnInvoice: "",
                mobileOnInvoice: "",
                planStatus,
                jobURL: "",
                jobEmail: "",
                jobCompanyProof: "",
                jobPostedBy: userData.fullName,
                workingComments: "",
                callVia: {
                    phone: false,
                    whatsapp: false,
                    appPhone: false,
                    dontcall: false,
                    appVideo: false
                },
                jobStatusDescription: "",
                jobFrom: "dashboard",
                salaryCurrency: "INR",
                jobValid: true,
                jobExpiredDate: moment().add(7, 'days').format('YYYY-MM-DD'),
                jobImage: [],
                staffNecessity: staffNecessity
            }
            const {
                data: {
                    data,
                    message
                }
            } = await createJob(submitData);

            setIsChoosePlan(false);
            if (planStatus === 'Basic') {
                createSubscription(data?._id, planStatus);
            }
            if (planStatus === 'CustomQuote') {
                if (vacancy > 10) {
                    await sentCustomQuoteEmail(data?._id);
                } else {
                    createSubscription(data?._id, planStatus);
                }
            }
            else {
                setIsJobPosted(true);
            }

            await cronNewJobApi(data?._id)
            hideLoader();
            toast.success(message ? message : "Success");

        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const cronNewJobApi = async (jobId) => {
        try {
            const {
                data: {
                    message
                }
            } = await cronNewJob(jobId);
            toast.success(message ? message : "Success");
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const getsubsCriptionAmount = () => {
        if (vacancy <= 2) {
            return vacancy * 2500
        }
        if (vacancy > 2 && vacancy <= 4) {
            return vacancy * 2400
        }
        if (vacancy > 4 && vacancy <= 6) {
            return vacancy * 2300
        }
        if (vacancy > 6 && vacancy <= 8) {
            return vacancy * 2200
        }
        if (vacancy > 8 && vacancy <= 10) {
            return vacancy * 2100
        }
    }

    const createSubscription = async (jobId, planStatus) => {
        try {
            showLoader();
            let subscriptionAmount = 0;
            if (planStatus === 'Basic') {
                subscriptionAmount = 500
            }
            if (planStatus === 'CustomQuote') {
                subscriptionAmount = getsubsCriptionAmount();
            }
            let subscriptionData = {
                empId: userData?._id,
                fullName: userData?.fullName,
                mobileNumber: userData?.whatsappMobile,
                city: userData?.city,
                fullAddress: userData?.city,
                amount: planStatus === subscriptionAmount,
                subscriptionType: planStatus,
                currency: 'INR',
                paidBy: 'employer',
                jobId
            }
            const {
                data: {
                    data,
                    /**message*/
                }
            } = await createEmployerSubscription(subscriptionData);
            let checkoutData = {
                orderId: data?.orderId,
                token: data?.transactionToken,
                amount: subscriptionData.amount,
                jobId,
                planStatus
            }
            setPaymentData(checkoutData);
            paytmCheckout(checkoutData);
            hideLoader();

        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const paytmCheckout = (data) => {
        console.log('checD1111', data)
        const { orderId, token, amount, jobId, planStatus } = data;
        var config = {
            "root": "",
            "flow": "DEFAULT",
            "data": {
                "orderId": orderId, /* update order id */
                "token": token, /* update token value */
                "tokenType": "TXN_TOKEN",
                "amount": amount /* update amount */
            },
            merchant: {
                redirect: false
            },
            "handler": {
                "notifyMerchant": function (eventName, data) {
                    console.log("notifyMerchant handler function called");
                    console.log("eventName => ", eventName);
                    console.log("data => ", data);
                    if (eventName === "APP_CLOSED") {
                        setIsPaymentClosed(true);
                    } else if (eventName === "SESSION_EXPIRED") {
                        createSubscription(paymentData?.jobId, paymentData?.planStatus);
                    }
                },
                transactionStatus: function (data) {
                    transactionEnd(data, jobId, planStatus);
                    window.Paytm.CheckoutJS.close();
                    console.log("payment status ", data);
                }
            }
        };
        console.log('paytmData', config)
        if (window.Paytm && window.Paytm.CheckoutJS) {
            // initialze configuration using init method
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                // after successfully updating configuration, invoke JS Checkout
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
                console.log("error => ", error);
            });
        }
    }

    const transactionEnd = async (transactionData, jobId, planStatus) => {
        try {
            showLoader();
            const { ORDERID, CURRENCY, TXNAMOUNT, TXNID, BANKTXNID, TXNDATE, RESPCODE, RESPMSG, PAYMENTMODE, STATUS, GATEWAYNAME, BANKNAME } = transactionData;
            const { _id, fullName, whatsappMobile, city, companyAddress } = userData;

            let subscriptionEndData = {
                candidateID: _id,
                Name: fullName,
                MobileNumber: whatsappMobile,
                City: city,
                Address: companyAddress,
                OrderID: ORDERID,
                PaymentMethod: 'PPI',
                Currency: CURRENCY,
                Amount: TXNAMOUNT,
                SubscriptionType: planStatus,
                GatewayTransactionID: TXNID,
                BankTransactionID: BANKTXNID,
                TransactionDate: TXNDATE,
                TransactionStatus: RESPCODE,
                Comments: RESPMSG,
                PaymentMode: PAYMENTMODE,
                GatewayStatus: STATUS,
                GatewayResponseCode: RESPCODE,
                GatewayResponseMessage: RESPMSG,
                GatewayName: GATEWAYNAME,
                BankName: BANKNAME ? BANKNAME : PAYMENTMODE,
                paidBy: "employer",
                jobId
            }
            const {
                data: {
                    /**data,
                    message*/
                }
            } = await subscriptionTransactionEnd(subscriptionEndData);
            hideLoader();
            if (RESPCODE === '01') {
                toast.success(RESPMSG);
            } else {
                toast.error(RESPMSG);
            }
            setIsChoosePlan(false);
            setIsJobPosted(true);
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

    const setNoOfVacancy = (val) => {
        setVacancy(parseInt(val));
        setValue("jobVacancy", val);
    }



    return (
        <HomeLayout>
            <section className={styles.mainNew}>
                <Container fluid className="p-0">
                    <Row>
                        <Col md={12}>
                            <div className={styles.jobPost}>
                                <h3>Post a new Job</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className={styles.addHead}>
                            <Row className={styles.marNew}>
                                <h3>Step 1 Job Details</h3>
                                <Row className={styles.formBg}>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>City</label>
                                        <Controller
                                            name="jobCity"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    isSearchable={true}
                                                    options={cityData}
                                                    placeholder="Select your city/district"
                                                    className={styles.formControl}
                                                    onChange={(e) => handleCity(e.value, e.state)}
                                                    error={errors?.jobCity}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: true }}
                                            onFocus={errors.jobCity?.ref}
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Job Type</label>
                                        <Controller
                                            name="jobType"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    isSearchable={true}
                                                    options={jobTypeOption}
                                                    placeholder="Choose your job type"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(e.value)}
                                                    error={errors?.jobType}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.jobType?.ref}
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Job Name</label>
                                        <InputField
                                            name="jobTitle"
                                            type="text"
                                            placeholder="Enter the job name"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^[A-Za-z ]+$/,
                                                    message: 'Job name should contain only alphabets and spaces'
                                                }
                                            }}
                                            error={errors?.jobTitle}
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Salary (By Month)</label>
                                        <div className={styles.miniDropdown}>
                                            <Controller className={styles.salDrop}
                                                name="minSalary"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <Dropdown
                                                        options={fromSalaryData}
                                                        placeholder="Min salary"
                                                        className={styles.formControl}
                                                        onChange={(e) => onChange(handleDropdown('minSalary', e.value))}
                                                        error={errors?.minSalary}
                                                        inputRef={ref}
                                                    />
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.minSalary?.ref}
                                            />
                                            <Controller className={styles.salDrop}
                                                name="maxSalary"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <Dropdown
                                                        name="maxSalary"
                                                        options={toSalaryData}
                                                        placeholder="Max salary"
                                                        className={styles.formControl}
                                                        onChange={(e) => onChange(e.value)}
                                                        error={errors?.maxSalary}
                                                        inputRef={ref}
                                                        isOptionDisabled={(option) => (option.value <= dropdownData.minSalary)}
                                                    />
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.maxSalary?.ref}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={8} className={styles.FormGroup}>
                                        <label>Job Position/Designation</label>
                                        <Controller
                                            name="jobPosition"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    isSearchable={true}
                                                    options={positionData}
                                                    placeholder="Choose your job position"
                                                    className={styles.formControl}
                                                    onChange={(e) => handleJobPosition(e)}
                                                    error={errors?.jobPosition}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.jobPosition?.ref}
                                        />
                                    </Col>
                                    <Col md={12} className={styles.FormGroup}>
                                        {jobSpecialization && jobSpecialization.length > 0 && <div className={styles.specialBtn}>
                                            <label>Specialization</label>
                                            {jobSpecialization.map((item, index) => {
                                                return (
                                                    <span key={index}>{item}<img onClick={() => removeSpecialization(index, item)} src={croseImage} alt="" /></span>
                                                )
                                            })}
                                        </div>}
                                    </Col>
                                </Row>
                            </Row>

                            <Row className={styles.marNew}>
                                <h3>Step 2 Requirements</h3>
                                <Row className={styles.formBg}>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Number of Vacancy</label>
                                        <InputField
                                            name="jobVacancy"
                                            placeholder="How many candidate you want"
                                            register={register}
                                            maxLength="3"
                                            valueAsNumber={{
                                                valueAsNumber: true
                                            }}
                                            onChange={(e) => {
                                                if (/^[1-9]*$/.test(e.target.value) || e.target.value === "") {
                                                    setNoOfVacancy(e.target.value);
                                                }
                                            }}
                                            validation={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^[1-9][0-9]*$/,
                                                    message: 'Vacancy should contain only numbers, and 0 cannot be the first digit.'
                                                },
                                                maxLength: {
                                                    value: 3,
                                                    message: "Maximum length should be 3 digits."
                                                }
                                            }}
                                            error={errors?.jobVacancy}
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>How soon you require staff?</label>
                                        <Controller
                                            name="staffNecessity"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    isSearchable={true}
                                                    options={JobOfferstaffNecessity}
                                                    placeholder="Choose your requirement"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(e.value)}
                                                    error={errors?.staffNecessity}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.staffNecessity?.ref}
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Gender</label>
                                        <Controller
                                            name="jobGender"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    isSearchable={true}
                                                    options={genderOption}
                                                    placeholder="Choose candidate gender"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(e.value)}
                                                    error={errors?.jobGender}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.jobGender?.ref}
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Age</label>
                                        <div className={styles.miniDropdown}>
                                            <Controller
                                                name="fromAge"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <Dropdown
                                                        name="fromAge"
                                                        options={fromAgeData}
                                                        placeholder="From"
                                                        className={styles.formControl}
                                                        onChange={(e) => onChange(handleDropdown('fromAge', e.value).toString())}
                                                        error={errors?.fromAge}
                                                        inputRef={ref}
                                                    />
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.fromAge?.ref}
                                            />
                                            <Controller
                                                name="toAge"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <Dropdown
                                                        name="toAge"
                                                        options={toAgeData}
                                                        placeholder="To"
                                                        className={styles.formControl}
                                                        onChange={(e) => onChange(e.value.toString())}
                                                        error={errors?.toAge}
                                                        inputRef={ref}
                                                        isOptionDisabled={(option) => option.value <= dropdownData.fromAge}
                                                    />
                                                )}
                                                rules={{ required: 'Required' }}
                                                onFocus={errors.toAge?.ref}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Working from</label>
                                        <Controller
                                            name="workingFrom"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={workingFromOption}
                                                    placeholder="Choose work from"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(e.value)}
                                                    error={errors?.workingFrom}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.workingFrom?.ref}
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Working shift</label>
                                        <Controller
                                            name="jobShift"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={workingShiftOption}
                                                    placeholder="Choose working shift"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(e.value)}
                                                    error={errors?.jobShift}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.jobShift?.ref}
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Working time</label>
                                        <Row>
                                            <Col md={6}>
                                                <div className={styles.timePicker}>
                                                    {/* <div> */}
                                                    <Controller
                                                        className={styles.newWidth}
                                                        name="timeFrom"
                                                        ampmInClock
                                                        control={control}
                                                        defaultValue={moment("09:00:00 am", "hh:mm a")}
                                                        render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <TimePicker
                                                                    // placeholder="From"
                                                                    label="From"
                                                                    value={value ? moment(value, "hh:mm a") : moment("09:00:00 am", "hh:mm a")}
                                                                    onChange={(newValue) => {
                                                                        onChange(handleTime('timeFrom', newValue));
                                                                    }}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    inputRef={ref}
                                                                    shouldDisableTime={handleTimeDisable}
                                                                />
                                                            </LocalizationProvider>

                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors.timeFrom?.ref}
                                                    />
                                                    {errors !== undefined && errors.timeFrom && (
                                                        <span className="errorMessage">{errors.timeFrom.message}</span>
                                                    )}
                                                </div>

                                            </Col>
                                            <Col md={6}>

                                                <div className={styles.timePicker}>
                                                    <Controller
                                                        className={styles.newWidth}
                                                        name="timeTo"
                                                        control={control}
                                                        defaultValue={moment("06:00:00 pm", "hh:mm a")}
                                                        render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                                <TimePicker
                                                                    label="To"
                                                                    // placeholder="To"
                                                                    value={value ? moment(value, "hh:mm a") : moment("06:00:00 pm", "hh:mm a")}
                                                                    onChange={(newValue) => {
                                                                        onChange(moment(newValue).format("hh:mm a"));
                                                                    }}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    inputRef={ref}
                                                                    shouldDisableTime={handleTimeDisable}
                                                                />
                                                            </LocalizationProvider>

                                                        )}
                                                        rules={{ required: 'Required' }}
                                                        onFocus={errors.timeTo?.ref}
                                                    />
                                                    {errors !== undefined && errors.timeTo && (
                                                        <span className="errorMessage">{errors.timeTo.message}</span>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Required Experience</label>
                                        <Controller
                                            name="jobExperience"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={experienceData}
                                                    placeholder="Choose min & max exp."
                                                    className={styles.formControl}
                                                    onChange={(e) => handleMultipleOptions('jobExperience', e)}
                                                    error={errors?.jobExperience}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.jobExperience?.ref}
                                        />
                                    </Col>

                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Your Preferred Language</label>
                                        <Controller
                                            name="jobLanguage"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={languageOptions}
                                                    placeholder="Choose language"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(e.value)}
                                                    error={errors?.jobLanguage}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.jobLanguage?.ref}
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Working Day</label>
                                        <button className={workingDays.monday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('monday')}>Mon</button>
                                        <button className={workingDays.tuesday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('tuesday')}>Tue</button>
                                        <button className={workingDays.wednesday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('wednesday')}>Wed</button>
                                        <button className={workingDays.thursday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('thursday')}>Thu</button>
                                        <button className={workingDays.friday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('friday')}>Fri</button>
                                        <button className={workingDays.saturday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('saturday')}>Sat</button>
                                        <button className={workingDays.sunday ? styles.active : styles.dayTime} onClick={() => handleworkingDay('sunday')}>Sun</button>
                                    </Col>
                                </Row>
                            </Row>


                            <Row className={styles.marNew}>
                                <h3>Step 3 Education</h3>
                                <Row className={styles.formBg}>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Education</label>
                                        <Controller
                                            name="jobEducation"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={educationData}
                                                    placeholder="Choose the education"
                                                    className={styles.formControl}
                                                    onChange={(e) => handleMultipleOptions('jobEducation', e)}
                                                    error={errors?.jobEducation}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.jobEducation?.ref}
                                        />
                                    </Col>
                                    {degreeData[dropdownData.jobEducation] && degreeData[dropdownData.jobEducation][0] != null && <Col md={4} className={styles.FormGroup}>
                                        <label>Degree</label>
                                        <Controller
                                            name="jobDegree"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={degreeData[dropdownData.jobEducation] && degreeData[dropdownData.jobEducation].length > 1 ? degreeData[dropdownData.jobEducation] : []}
                                                    placeholder="Choose the degree"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(handleDropdown('jobDegree', e.value))}
                                                    isSearchable={true}
                                                    value={degreeBranchDefaultValue.jobDegree}
                                                />
                                            )}
                                            onFocus={errors.jobDegree?.ref}
                                        />
                                    </Col>}
                                    {branchData[dropdownData.jobDegree] && <Col md={4} className={styles.FormGroup}>
                                        <label>Branch/Stream</label>
                                        <Controller
                                            name="jobBranch"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    name="jobBranch"
                                                    options={branchData[dropdownData.jobDegree] ? branchData[dropdownData.jobDegree] : []}
                                                    placeholder="Choose the branch/stream"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(handleDropdown('jobBranch', e.value))}
                                                    isSearchable={true}
                                                    error={errors?.jobBranch}
                                                    value={degreeBranchDefaultValue.jobBranch}
                                                />
                                            )}
                                            onFocus={errors.jobBranch?.ref}
                                            rules={{
                                                required: (jobDegree && !jobBranch) ? true : false
                                            }}
                                        />
                                    </Col>}

                                </Row>
                            </Row>

                            <Row className={styles.marNew}>
                                <h3>Step 4 Other Details</h3>
                                <Row className={styles.formBg}>
                                    <Col md={12} className={styles.FormGroup}>
                                        <label>Address (Office Location)</label>
                                        <GoogleAutoComplete inputName="jobAddress" setOfficeAddress={setOfficeAddress}
                                            inputVal={officeAddress} setPlace={setPlace} styles={styles.formControl} />
                                        {/* <input ref={placeInputRef} value={officeAddress} onChange={e => enterOfficeAddress(e.value)} autoComplete="off"
                                            type="text" name="jobAddress" register={register} placeholder="Office Address" className={`${styles.formControl}`}
                                            validation={{
                                                required: 'Required',
                                            }}
                                        /> */}
                                        {isAddrEnter && <span className="errorMessage">Required</span>}
                                        {/* <TextArea
                                            type="text"
                                            name="jobAddress"
                                            placeholder="Office Address"ssssssss
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                            }}
                                            error={errors?.jobAddress}
                                            maxLength='100'
                                        /> */}
                                    </Col>
                                    <Col md={12} className={styles.FormGroup}>
                                        <label>Job Description</label>
                                        <TextArea
                                            name="jobDescription"
                                            placeholder="Job Description"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                            }}
                                            error={errors?.jobDescription}
                                            maxLength='300'
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Contact Person</label>
                                        <InputField
                                            name="jobContactPerson"
                                            type="text"
                                            placeholder="Enter the representer’s name"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^[A-Za-z ]+$/,
                                                    message: 'Job name should contain only alphabets and spaces'
                                                }
                                            }}
                                            error={errors?.jobContactPerson}
                                        />
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Job Video</label>
                                        <div className={styles.videoUpload}>
                                            {!fileName ? (<span className={styles.noVideo}>
                                                Upload your video here
                                            </span>) : (
                                                <span className={styles.videoUp}>
                                                    {fileName}  <FontAwesomeIcon icon={faX} onClick={clearFileUpload} />
                                                </span>)}
                                            <div className={styles.fileUpload}>
                                                <input
                                                    type="file"
                                                    onChange={handleFileUpload}
                                                    accept="video/mp4"
                                                    placeholder="Upload your video here"
                                                    id="video"
                                                />
                                                <img src={newupdate} alt="" />

                                                {/* <FontAwesomeIcon icon={faFileUpload} /> */}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <label>Driving Requirement</label>
                                        <div className={styles.newSelect}>
                                            <ul>
                                                <li>
                                                    <label htmlFor="dl">Candidate should have driving license</label>
                                                    <label className={styles.checkbox}>
                                                        <input type="checkbox" name="dl" id="dl" onChange={() => handleDriving('dl', drivingLicense.dl)} checked={drivingLicense.dl} className={styles.checkboxInput} />
                                                        <span className={styles.checkboxInner} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label htmlFor="ownBike">Candidate should have a motorcycle</label>
                                                    <label className={styles.checkbox}>
                                                        <input type="checkbox" name="ownBike" id="ownBike" onChange={() => handleDriving('ownBike', drivingLicense.ownBike)} checked={drivingLicense.ownBike} className={styles.checkboxInput} />
                                                        <span className={styles.checkboxInner} />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label htmlFor="ownCar">Candidate should have a car</label>
                                                    <label className={styles.checkbox}>
                                                        <input type="checkbox" name="ownCar" id="ownCar" onChange={() => handleDriving('ownCar', drivingLicense.ownCar)} checked={drivingLicense.ownCar} className={styles.checkboxInput} />
                                                        <span className={styles.checkboxInner} />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col md={4} className={styles.FormGroup}>
                                        <Row>
                                            <Col md={12}>
                                                <label>Yes we are providing an incentive</label>
                                                <div className={styles.radioButton}>
                                                    <ul>
                                                        <li>
                                                            <input type="radio" name="yesIncentive" id="yesIncentive" onChange={() => setIsIncentive(!isIncentive)} checked={isIncentive} />
                                                            <label htmlFor="yesIncentive">Yes</label>
                                                            <div className={styles.checkNew}></div>
                                                        </li>
                                                        <li>
                                                            <input
                                                                type="radio"
                                                                name="noIncentive"
                                                                id="noIncentive"
                                                                onChange={() => setIsIncentive(!isIncentive)}
                                                                // defaultChecked={!isIncentive}
                                                                checked={!isIncentive}
                                                            />
                                                            <label htmlFor="noIncentive">No</label>
                                                            <div className={styles.checkNew}></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            {isIncentive && <Col md={12}>
                                                <div className={styles.bgInc}>
                                                    <label>Tell us your incentive time and amount </label>
                                                    <Dropdown
                                                        name="jobIncentivePeriod"
                                                        options={incentivePeriodOption}
                                                        placeholder="Select"
                                                        className={styles.formControl}
                                                        onChange={(e) => handleDropdown('jobIncentivePeriod', e.value)}
                                                    />
                                                    <InputField
                                                        name="jobIncentiveAmount"
                                                        placeholder="Amount"
                                                        register={register}
                                                        validation={{
                                                            required: isIncentive ? 'Required' : '',
                                                            pattern: {
                                                                value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                message: 'Incentive should contain only numbers'
                                                            }
                                                        }}
                                                        error={errors?.jobIncentiveAmount}
                                                    // onChange={handleIncentive}
                                                    />
                                                </div>
                                            </Col>}
                                        </Row>
                                    </Col>
                                    <Col md={8} className={styles.FormGroup}>
                                        <Row>
                                            <Col md={6}>
                                                <label>Any Deposit or charge</label>
                                                <div className={styles.radioButton}>
                                                    <ul>
                                                        <li>
                                                            <input
                                                                type="radio"
                                                                name="yesCharge"
                                                                id="yesCharge"
                                                                onChange={() => setIsJobCharge(!isJobCharge)}
                                                                checked={isJobCharge}
                                                            />
                                                            <label htmlFor="yesCharge">Yes</label>
                                                            <div className={styles.checkNew}></div>
                                                        </li>
                                                        <li>
                                                            <input
                                                                type="radio"
                                                                name="noCharge"
                                                                id="noCharge"
                                                                onChange={() => setIsJobCharge(!isJobCharge)}
                                                                // defaultChecked={!isJobCharge}
                                                                checked={!isJobCharge}
                                                            />
                                                            <label htmlFor="noCharge">No</label>
                                                            <div className={styles.checkNew}><div className={styles.inside}></div></div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            {isJobCharge && <Col md={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className={styles.bgInc}>
                                                            <label>Charging as One Time Fee (Optional) </label>
                                                            <InputField
                                                                name="oneTimeChargeDescription"
                                                                type="text"
                                                                placeholder="Description"
                                                                register={register}
                                                            />
                                                            <InputField
                                                                name="oneTimeCharge"
                                                                placeholder="Amount"
                                                                register={register}
                                                                validation={{
                                                                    required: isJobCharge ? 'Required' : '',
                                                                    pattern: {
                                                                        value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                        message: 'Charge should contain only numbers'
                                                                    }
                                                                }}
                                                                error={errors?.oneTimeCharge}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={styles.bgInc}>
                                                            <label>Charging as Monthly Fee (Optional)</label>
                                                            <InputField
                                                                name="recursiveChargeDescription"
                                                                type="text"
                                                                placeholder="Office transport"
                                                                register={register}
                                                            />
                                                            <InputField
                                                                name="recursiveCharge"
                                                                placeholder="Amount"
                                                                register={register}
                                                                validation={{
                                                                    required: isJobCharge ? 'Required' : '',
                                                                    pattern: {
                                                                        value: /[+-]?([0-9]*[.])?[0-9]+/g,
                                                                        message: 'Charge should contain only numbers'
                                                                    }
                                                                }}
                                                                error={errors?.recursiveCharge}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>}
                                        </Row>
                                    </Col>

                                </Row>
                            </Row>


                        </Col>

                        <Col md={12}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnCancel} onClick={() => navigate(-1)}>
                                    <div className={styles.effNew}></div>
                                    <button>Cancel <span></span></button>
                                </div>
                                {isPaymentClosed ? (
                                    <div className={styles.btnReset} onClick={() => paytmCheckout(paymentData)}>
                                        <div className={styles.effReset}></div>
                                        <button>Pay<span></span></button>
                                    </div>
                                ) : (
                                    <div className={styles.btnReset} onClick={handleSubmit(handleChoosePlan)}>
                                        <div className={styles.effReset}></div>
                                        <button>Post<span></span></button>
                                    </div>
                                )}

                            </div>
                        </Col>
                    </Row>
                    <SpecializationList
                        isOpen={isOpenSpecialization}
                        data={specializationData}
                        jobPosition={jobPosition}
                        handleClose={() => setIsOpenSpecialization(!isOpenSpecialization)}
                        handleSpecialization={handleSpecialization}
                    />
                    <ChoosePlan
                        isOpen={isOpenChoosePlan}
                        handleClose={() => setIsChoosePlan(false)}
                        setPlanStatus={setPlanStatus}
                        submitJob={handleSubmit(submit)}
                        vacancy={parseInt(vacancy)}
                    />
                    <JobPosted
                        isOpen={isJobPosted}
                        handleClose={closeJobBox}
                    />
                    <CustomQuote
                        isOpen={openCustomQuote}
                        selectedJobId={selectedJobId}
                        handleClose={() => setOpenCustomQuote(false)}
                    />
                </Container>
            </section >
        </HomeLayout >
    )
}
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styles from './CandidateFilter.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';

import Popup from "../Popup";
import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { useForm, Controller } from "react-hook-form";
import * as React from 'react';
import moment from "moment";
import {
    genderOption
} from '../../../../common/DropdownOptions';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from "@mui/material";

export default function CandidateFilter({
    isOpen,
    handleClose,
    jobPositionData = [],
    cityData = [],
    handleCandidateFilter,
    path
}) {

  const { /**register, handleSubmit, formState: { errors }, getValues,*/ control /** ,setValue */} = useForm();

  const [positionValue, setPositionValue] = useState([]);
  const [cityValue, setCityValue] = useState([]);
  const [jobDate, setJobDate] = useState(null);
  const [gender, setGender] = useState([]);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setCityValue([]);
    setPositionValue([]);
    setJobDate(null);
    setGender([]);
  }, [path]);
  

  const handleReset = () => {
    setCityValue([]);
    setPositionValue([]);
    setJobDate(null);
    setGender([]);
    handleCandidateFilter({}, null, {}, {});
    handleClose();
}

const handleApply = () => {
    if(cityValue?.length === 0 && positionValue?.length === 0 && !jobDate && gender?.length === 0) {
        setIsError(true);
    } else {
        setIsError(false);
        handleCandidateFilter(positionValue, jobDate, cityValue, gender);
        handleClose();
    }
}

  return (
          <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.canddiateFilter}>
                <img src={closeJob} onClick={handleClose} alt=''/>
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Candidate Filter<span></span></h3>
                            </div>
                        </Col>  
                    </Row> 
                    <Row>
                        <Col md={6} className={styles.FormGroup}>
                            <label>Job Position</label>
                            <Dropdown
                                name="jobPosition"
                                isSearchable={true}
                                options={jobPositionData}
                                placeholder="Select the Job Position"
                                className={styles.formControlselect}
                                onChange={setPositionValue}
                                value={positionValue}
                            />
                        </Col>
                        <Col md={6} className={styles.FormGroup}>
                            <label>City</label>
                            <Dropdown
                                name="city"
                                isSearchable={true}
                                options={cityData}
                                placeholder="Select City"
                                className={styles.formControlselect}
                                onChange={setCityValue}
                                value={cityValue}
                            />
                        </Col>
                        <Col md={6} className={styles.FormGroup}>
                            <label>By Date</label>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <div className="dateAndTimePicker">
                                    <Controller
                                        name="jobDate"
                                        control={control}
                                        render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                            <DatePicker
                                                label="Job Date"
                                                value={jobDate}
                                                onChange={(newValue) => {
                                                    setJobDate(moment(newValue).format("YYYY-MM-DD"));
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        )}
                                    />
                                </div>
                            </LocalizationProvider>
                        </Col>
                        <Col md={6} className={styles.FormGroup}>
                            <label>Gender</label>
                            <Dropdown
                                name="gender"
                                isSearchable={true}
                                options={genderOption}
                                placeholder="Select gender"
                                className={styles.formControlselect}
                                onChange={setGender}
                                value={gender}
                            />
                        </Col>
                    </Row>
                    {isError && (
                                <p className="errorMessage alignCenter">Please select any filter option</p>
                            )}
                    <Row>
                        <Col md={12} className={styles.textCenter}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnCancel} onClick={handleReset}>
                                    <div className={styles.effNew}></div>
                                    <button>Reset <span></span></button>
                                </div>
                                <div className={styles.btnReset} onClick={handleApply}>
                                    <div className={styles.effReset}></div>
                                    <button>Apply<span></span></button>
                                </div>
                            </div>  
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
  );
}







import { useState } from "react";
import { Col, /**Container, FormGroup,*/ Row } from "react-bootstrap";
import styles from './RejectResponse.module.scss';
//import saveJob from '../../../assets/images/savejobimg.svg';
import closeJob from '../../../assets/images/closeIcon.svg';

import Popup from "../../common/Popup/Popup";
import InputField from "../../common/InputField/InputField";
import { useForm, /**Controller*/ } from "react-hook-form"; 
import { toast } from "react-toastify";
import { hideLoader, showLoader } from "../../../helpers";
import { rejectJob } from "../../../services/api/common";


// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function RejectResponse({
    isOpen,
    handleClose,
    updateStatus,
    data: {
        jobId, 
        candidateId, 
        employerId,
        _id,
        candidateAction
    }
}) {
    const { register, handleSubmit, /**formState: { errors }, getValues, control,*/ reset } = useForm();


    const [employerReasonsToReject, setEmployerReasonsToReject] = useState({
        locationMismatch: false,
        salaryMismatch: false,
        wrongExpectation: false,
        falseInfo: false,
        candidateMismatch: false,
        candidateNotSerious: false,
        candidateDidnotAttend: false,
        candidateJoinedElsewhere: false,
        candidateNotGood: false
    });
    const [error, setError] = useState(false);

    // useEffect(() => {
    //     hideLoader();
    //     reset();
    //     setEmployerReasonsToReject({
    //         locationMismatch: false,
    //         salaryMismatch: false,
    //         wrongExpectation: false,
    //         falseInfo: false,
    //         candidateMismatch: false,
    //         candidateNotSerious: false,
    //         candidateDidnotAttend: false,
    //         candidateJoinedElsewhere: false,
    //         candidateNotGood: false
    //     });
    // }, [])

    const handleCheckbox  = ({target: {name, checked}}) => {
        setEmployerReasonsToReject({
            ...employerReasonsToReject,
            [name]: checked
        })
    }

    const handleReset = () =>{
        reset();
        setError(false);
        setEmployerReasonsToReject({
            locationMismatch: false,
            salaryMismatch: false,
            wrongExpectation: false,
            falseInfo: false,
            candidateMismatch: false,
            candidateNotSerious: false,
            candidateDidnotAttend: false,
            candidateJoinedElsewhere: false,
            candidateNotGood: false
        });
        handleClose();
    }
    const submit = async (submitData) => {
        try {
            const {locationMismatch, salaryMismatch, wrongExpectation, falseInfo, candidateMismatch, candidateNotSerious, candidateDidnotAttend, candidateJoinedElsewhere, candidateNotGood} = employerReasonsToReject;

            if((!locationMismatch && !salaryMismatch && !wrongExpectation && !falseInfo && !candidateMismatch && !candidateNotSerious && !candidateDidnotAttend && !candidateJoinedElsewhere && !candidateNotGood ) && (!submitData.otherReason)) {
                setError(true);
                return;
            } else {
                setError(false);
                
            showLoader();
            submitData.employerId = employerId;
            submitData.jobId = jobId;
            submitData.candidateId = candidateId;
            submitData.whoRejected = 'employer';
            submitData.employerReasonsToReject = employerReasonsToReject;
            submitData.candidateReasonsToReject = {};


            const {
                data: {
                    /**data,*/ message
                } } = await rejectJob(submitData);
            hideLoader();
            toast.success(message ? message : "Success");
            handleReset();
            updateStatus(jobId, _id, 'negative', candidateAction);
        }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }

  return (
          <Popup isOpen={isOpen} isClose={handleReset}>
            <div className={styles.newCss}>
            <img src={closeJob} onClick={handleReset} alt=''/>
            <Col md={12} className={styles.newBg}>
                {/* <img src={closeJob}/> */}
                <Row>
                    <Col md={12}>
                        <div className={styles.headReject}>
                            <h3>Reject Response<span>Reject this candidate</span></h3>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={styles.rejectOp}>
                            <ul>
                                <li>
                                    <label htmlFor="locationMismatch">Location</label>
                                    <label className={styles.checkbox}>
                                        <input 
                                            type="checkbox" 
                                            id="locationMismatch" 
                                            name="locationMismatch" 
                                            className={styles.checkboxInput} 
                                            checked={employerReasonsToReject.locationMismatch}
                                            onChange={handleCheckbox}
                                        />
                                        <span className={styles.checkboxInner} />
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="falseInfo">False <br/>informations</label>
                                    <label className={styles.checkbox}>
                                        <input 
                                            type="checkbox" 
                                            id="falseInfo" 
                                            name="falseInfo"
                                            className={styles.checkboxInput} 
                                            checked={employerReasonsToReject.falseInfo}
                                            onChange={handleCheckbox}
                                        />
                                        <span className={styles.checkboxInner} />
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="candidateDidnotAttend">Candidate didn’t attend the interview</label>
                                    <label className={styles.checkbox}>
                                        <input 
                                            type="checkbox" 
                                            id="candidateDidnotAttend" 
                                            name="candidateDidnotAttend"
                                            className={styles.checkboxInput} 
                                            checked={employerReasonsToReject.candidateDidnotAttend}
                                            onChange={handleCheckbox}
                                        />
                                        <span className={styles.checkboxInner} />
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={styles.rejectOp}>
                            <ul>
                                <li>
                                    <label htmlFor="salaryMismatch">Salary mismatch</label>
                                    <label className={styles.checkbox}>
                                        <input 
                                            type="checkbox" 
                                            id="salaryMismatch" 
                                            name="salaryMismatch"
                                            className={styles.checkboxInput} 
                                            checked={employerReasonsToReject.salaryMismatch}
                                            onChange={handleCheckbox}
                                        />
                                        <span className={styles.checkboxInner} />
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="candidateMismatch">Candidate/Profile mismatch</label>
                                    <label className={styles.checkbox}>
                                        <input 
                                            type="checkbox" 
                                            id="candidateMismatch" 
                                            name="candidateMismatch"
                                            className={styles.checkboxInput} 
                                            checked={employerReasonsToReject.candidateMismatch}
                                            onChange={handleCheckbox}
                                        />
                                        <span className={styles.checkboxInner} />
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="candidateJoinedElsewhere">Candidate already joined elsewhere</label>
                                    <label className={styles.checkbox}>
                                        <input 
                                            type="checkbox" 
                                            id="candidateJoinedElsewhere" 
                                            name="candidateJoinedElsewhere"
                                            className={styles.checkboxInput} 
                                            checked={employerReasonsToReject.candidateJoinedElsewhere}
                                            onChange={handleCheckbox}
                                        />
                                        <span className={styles.checkboxInner} />
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={styles.rejectOp}>
                            <ul>
                                <li>
                                    <label htmlFor="wrongExpectation">Wrong expectation</label>
                                    <label className={styles.checkbox}>
                                        <input 
                                            type="checkbox" 
                                            id="wrongExpectation" 
                                            name="wrongExpectation"
                                            className={styles.checkboxInput} 
                                            checked={employerReasonsToReject.wrongExpectation}
                                            onChange={handleCheckbox}
                                        />
                                        <span className={styles.checkboxInner} />
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="candidateNotSerious">Candidate not <br/>serious</label>
                                    <label className={styles.checkbox}>
                                        <input 
                                            type="checkbox" 
                                            id="candidateNotSerious" 
                                            name="candidateNotSerious"
                                            className={styles.checkboxInput} 
                                            checked={employerReasonsToReject.candidateNotSerious}
                                            onChange={handleCheckbox}
                                        />
                                        <span className={styles.checkboxInner} />
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="candidateNotGood">Candidate not good enough</label>
                                    <label className={styles.checkbox}>
                                        <input 
                                            type="checkbox" 
                                            id="candidateNotGood" 
                                            name="candidateNotGood"
                                            className={styles.checkboxInput} 
                                            checked={employerReasonsToReject.candidateNotGood}
                                            onChange={handleCheckbox}
                                        />
                                        <span className={styles.checkboxInner} />
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={styles.FormGroup}>
                        <label>Any other reason?</label>
                        <InputField 
                            className={styles.formControl} 
                            name="otherReason" 
                            placeholder="Enter you reason" 
                            register={register}
                        />
                       {error && <p className="errorMessage mt-3 mb-0">Select any option or enter the reason</p>}
                    </Col>
                    <Col md={12}>
                        <div className={styles.btnNew}>
                            <div className={styles.btnReset} onClick={handleSubmit(submit)}>
                                <div className={styles.effReset}></div>
                                <button type="submit">Submit<span></span></button>
                            </div>
                        </div>
                    </Col>
                </Row> 
            </Col>
            </div>
        </Popup>
  );
}







import { Col, Row } from "react-bootstrap";
import styles from './ChooseInReviewOption.module.scss'
import closeJob from '../../../../assets/images/closeIcon.svg';
import trialImage from '../../../../assets/images/sendNormal.svg';
import basicPlan from '../../../../assets/images/hireNormal.svg';
import checkBos from '../../../../assets/images/checkNew.svg';
import infoIcon from '../../../../assets/images/infoiconBlue.svg';
import defaultUser from '../../../../assets/images/profile.svg';


import Popup from "../Popup";
import { useState } from "react";
// import { updateJobStatus } from "../../../../services/api/common";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default function ChooseInReviewOption({
    isOpen,
    handleClose,
    data: {
        starRank,
        profilePicUrl,
        candidateName,
        candidateDesignationName,
        jobId,
        _id,
        candidateAction,
        interviewId,
        employerAction
    },
    handleJobOfferSummary,
    updateStatus
}) {

    const [option, setOption] = useState("");
    const [planError, setPlanError] = useState(false);

    const handleOption = (data) => {
        setOption(data);
        setPlanError(false);
    }

    const submit = () => {
        if (!option) {
            setPlanError(true);
        } else {
            handleReset();
            if (option === "JobOffer") {
                handleJobOfferSummary(interviewId);
                updateStatus(jobId, _id, 'positive', candidateAction, employerAction, "Interview");
            } else {
                updateStatus(jobId, _id, 'hire', candidateAction, employerAction)
            }
        }
    }

    const handleReset = () => {
        setOption("");
        setPlanError(false);
        handleClose();
    }

    let formattedRank = starRank ? starRank.split("/") : [];

    return (
        <Popup isOpen={isOpen} isClose={handleReset}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleReset} alt='' />
                <Col md={12} className={styles.newBg}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Choose an option<span></span></h3>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={styles.interviewPro}>
                                <div className={styles.newTab}>
                                    <div className={styles.rankNew}>
                                        <span>{formattedRank && formattedRank.length > 0 && formattedRank[0]}<br></br><span className={styles.slashName}>/</span>{formattedRank && formattedRank.length > 0 && formattedRank[1]}</span>
                                    </div>
                                    <div className={styles.profileNew}>
                                        <img src={profilePicUrl ? profilePicUrl : defaultUser} alt='' />
                                        <h3>{candidateName}<span>{candidateDesignationName}</span></h3>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={styles.btnsInterview}>
                                <ul>
                                    <li>
                                        {option && option === 'JobOffer' && <img className={styles.checkList} src={checkBos} alt='' />}
                                        <a
                                            className={`${styles.trialPlan} ${option === 'JobOffer' ? styles.trialPlanSelected : ""}`}
                                            onClick={() => handleOption('JobOffer')}>
                                            <img src={trialImage} alt='' /> Send Offer</a>
                                    </li>
                                    <li>
                                        {option && option === 'Hire' && <img className={styles.checkList} src={checkBos} alt='' />}
                                        <a
                                            className={`${styles.basicPlan} ${option === 'Hire' ? styles.basicPlanSelected : ""}`}
                                            onClick={() => handleOption('Hire')}>
                                            <img src={basicPlan} alt='' />Hire Candidate </a>
                                    </li>
                                </ul>
                                {planError && <p className="errorMessage mt-1 mb-0">Select any option</p>}
                            </div>
                            <div className={styles.listFunction}>
                                <p><img src={infoIcon} alt='' /> Send Job Offer to candidate for acceptance after salary and joining discussion or you
                                    can directly hire candidate for joining.</p>
                            </div>
                            <div className={styles.btnNew}>
                                <div className={styles.btnReset} onClick={submit}>
                                    <div className={styles.effReset}></div>
                                    <button>Select <span></span></button>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}






